const paper = require('paper')
const fonts = require('../fonts/fonts.json')

module.exports = function (template, box) {
  console.log('Rendering back template', template.fields)
  console.log(fonts)
  const fields = template.fields
  let guide = box.clone()

  let unit = guide.size.width 
  let h1 = unit * 0.0475
  let h2 = unit * 0.038

  let ingredientsGroup = new paper.Group()
  let ingredients = new paper.PointText(box.bounds.center)
  ingredients.position.x -= unit * 0.33
  ingredients.position.y -= unit * 0.1175
  ingredients.fontFamily = fonts.GTSectraBook.name
  ingredients.fillColor = 'black'
  ingredients.justification = 'left'
  ingredients.fontSize = h1
  let indent = '                '
  if (fields[0].value) ingredients.content = indent + fields[0].value
  else ingredients.content = indent + fields[0].placeholder
  ingredientsGroup.addChild(ingredients)

  let cartouche = new paper.Path.Rectangle(ingredients.bounds.scale(1.1, 1.2), 20)
  cartouche.fillColor = 'black'
  cartouche.fillColor.alpha = 0.05
  ingredientsGroup.addChild(cartouche)

  let ingredientsLabel = new paper.PointText(ingredients.bounds.topLeft)
  ingredientsLabel.fontFamily = fonts.GTAmericaCompressedMedium.name
  ingredientsLabel.fillColor = 'black'
  ingredientsLabel.justification = 'left'
  ingredientsLabel.fontSize = h2
  ingredientsLabel.content = 'Ingredients:'.toUpperCase()
  ingredientsLabel.position.y += unit * 0.0425
  ingredientsGroup.addChild(ingredientsLabel)
  
  // re-center ingredients
  ingredientsGroup.position.x = box.bounds.center.x
  ingredientsGroup.position.y = box.bounds.center.y - guide.size.width * 0.075 

  let bestby = new paper.PointText(box.bounds.center)
  bestby.fontFamily = fonts.GTAmericaCompressedMedium.name
  bestby.fillColor = 'black'
  bestby.justification = 'center'
  bestby.fontSize = h2
  bestby.position.y += guide.size.height * 0.13

  // best-by date + batch number
  let bestby_str = 'ENJOY BY ' + (fields[1].value ? fields[1].value.toUpperCase() : fields[1].placeholder.toUpperCase())
  let batch_str = 'BATCH ' + (fields[2].value ? fields[2].value.toUpperCase() : fields[2].placeholder.toUpperCase())
  bestby.content = bestby_str + ' Ã¢ÂÂ¢ ' + batch_str

  let netweight = new paper.PointText(box.bounds.center)
  netweight.fontFamily = fonts.GTAmericaCompressedMedium.name
  netweight.fillColor = 'black'
  netweight.justification = 'center'
  netweight.fontSize = h2
  netweight.position.y += guide.size.height * 0.35
  if(fields[3].value) netweight.content = 'NET WT ' + fields[3].value.toUpperCase()
  else netweight.content = 'NET WT ' + fields[3].placeholder.toUpperCase()

  console.log('Template rendered Ã¢ÂÂ')
}