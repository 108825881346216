const reset = require('./reset.js').attach()
const fonts = require('./fonts.js').attach()
const css = require('sheetify')

const body = css`
  :host {
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    font-size: 1.20em;
    line-height: 1.4;
    letter-spacing: 0px;
    background-color: #eeeeee;
  }

  a, a:visited {
    color: blue;
    text-decoration: none;
  }

  a:hover {
  }

  :host p {
    margin-bottom: 3em;
    max-width: 800px;
  }

  p strong {
    font-weight: bold;
  }

  p em {
    font-style: italic;
  }
`

module.exports = body