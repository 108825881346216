const html = require('choo/html')
const raw = require('choo/html/raw')
const css = require('sheetify')

const style = css`
  :host {
    padding: 1em;
    padding-top: 0;
  }

  :host > label {
    display: block;
    margin-top: 1.5em;
    margin-bottom: 0.25em;
  }

  :host > input {
    border-radius: 1em;
    border: 1px solid lightgrey;
    padding: 0.4em 0.5em 0.6em 0.5em;
    font-size: inherit;
    width: calc(300px - 3em);
    text-indent: 0.25em;
  }

  :host > textarea {
    resize: none;
    border-radius: 1em;
    border: 1px solid lightgrey;
    padding: 0.75em;
    font-size: inherit;
    width: calc(300px - 3.5em);
  }

  :host > input:focus {
    outline: none;
  }

  :host > textarea:focus {
    outline: none;
  }

  :host > select {
    font-size: large;
    background: white;
    border: 2px solid lightgrey;
  }

  :host > select:focus {
    outline: none;
  }

`

module.exports = function (template, emit) {

  let html = ''
  
  for (let field of template.fields) {
    switch (field.type) {
      case 'text':
        html += `<label for="${field.slug}">${field.label}</label>`
        html += `<input type="text" name="${field.slug}" id="${field.slug}" value="${field.value}" placeholder="${field.placeholder}" autocomplete="off">`
        break

      case 'textarea':
        html += `<label for="${field.slug}">${field.label}</label>`
        html += `<textarea rows="7" name="${field.slug}" id="${field.slug}" placeholder="${field.placeholder}">${field.value}</textarea>`
        break

      case 'select':
        html += `<label for="${field.slug}">${field.label}</label>`
        html += `<select name="${field.slug}">`

        html += `<option value="${field.value}">${field.value}</option>`
        for (var i = field.options.length - 1; i >= 0; i--) {
          if (field.options[i] == field.value) continue
          html += `<option value="${field.options[i]}">${field.options[i]}</option>`
        }

        html += `</select>`
        break

      default:
        break
    }
  }

  return html`
    <form class="${style}" oninput=${ontemplateinput}>
      ${raw(html)}
    </form>
  `

  function ontemplateinput (e) {
    emit('template:input', template, e.target.name, e.target.value)
  }

}