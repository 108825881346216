"use strict";

// Generated data for sbcs codec. Don't edit manually. Regenerate using generation/gen-sbcs.js script.
module.exports = {
  "437": "cp437",
  "737": "cp737",
  "775": "cp775",
  "850": "cp850",
  "852": "cp852",
  "855": "cp855",
  "856": "cp856",
  "857": "cp857",
  "858": "cp858",
  "860": "cp860",
  "861": "cp861",
  "862": "cp862",
  "863": "cp863",
  "864": "cp864",
  "865": "cp865",
  "866": "cp866",
  "869": "cp869",
  "874": "windows874",
  "922": "cp922",
  "1046": "cp1046",
  "1124": "cp1124",
  "1125": "cp1125",
  "1129": "cp1129",
  "1133": "cp1133",
  "1161": "cp1161",
  "1162": "cp1162",
  "1163": "cp1163",
  "1250": "windows1250",
  "1251": "windows1251",
  "1252": "windows1252",
  "1253": "windows1253",
  "1254": "windows1254",
  "1255": "windows1255",
  "1256": "windows1256",
  "1257": "windows1257",
  "1258": "windows1258",
  "28591": "iso88591",
  "28592": "iso88592",
  "28593": "iso88593",
  "28594": "iso88594",
  "28595": "iso88595",
  "28596": "iso88596",
  "28597": "iso88597",
  "28598": "iso88598",
  "28599": "iso88599",
  "28600": "iso885910",
  "28601": "iso885911",
  "28603": "iso885913",
  "28604": "iso885914",
  "28605": "iso885915",
  "28606": "iso885916",
  "windows874": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂ¦Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ Ã Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸Â Ã Â¸Â¡Ã Â¸Â¢Ã Â¸Â£Ã Â¸Â¤Ã Â¸Â¥Ã Â¸Â¦Ã Â¸Â§Ã Â¸Â¨Ã Â¸Â©Ã Â¸ÂªÃ Â¸Â«Ã Â¸Â¬Ã Â¸Â­Ã Â¸Â®Ã Â¸Â¯Ã Â¸Â°Ã Â¸Â±Ã Â¸Â²Ã Â¸Â³Ã Â¸Â´Ã Â¸ÂµÃ Â¸Â¶Ã Â¸Â·Ã Â¸Â¸Ã Â¸Â¹Ã Â¸ÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â¸Â¿Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "win874": "windows874",
  "cp874": "windows874",
  "windows1250": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃ¯Â¿Â½Ã¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡Ã¯Â¿Â½Ã¢ÂÂ°ÃÂ Ã¢ÂÂ¹ÃÂÃÂ¤ÃÂ½ÃÂ¹Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¢ÂÂ¢ÃÂ¡Ã¢ÂÂºÃÂÃÂ¥ÃÂ¾ÃÂºÃÂ ÃÂÃÂÃÂÃÂ¤ÃÂÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ»ÃÂ°ÃÂ±ÃÂÃÂÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂÃÂÃÂ»ÃÂ½ÃÂÃÂ¾ÃÂ¼ÃÂÃÂÃÂÃÂÃÂÃÂ¹ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂ°ÃÂÃÂÃÂ¢ÃÂÃÂÃÂ¡ÃÂ¢ÃÂÃÂ¤ÃÂºÃÂÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂÃÂ­ÃÂ®ÃÂÃÂÃÂÃÂÃÂ³ÃÂ´ÃÂÃÂ¶ÃÂ·ÃÂÃÂ¯ÃÂºÃÂ±ÃÂ¼ÃÂ½ÃÂ£ÃÂ"
  },
  "win1250": "windows1250",
  "cp1250": "windows1250",
  "windows1251": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡Ã¢ÂÂ¬Ã¢ÂÂ°ÃÂÃ¢ÂÂ¹ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¢ÂÂ¢ÃÂÃ¢ÂÂºÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂ¤ÃÂÃÂ¦ÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂÃÂ°ÃÂ±ÃÂÃÂÃÂÃÂµÃÂ¶ÃÂ·ÃÂÃ¢ÂÂÃÂÃÂ»ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ"
  },
  "win1251": "windows1251",
  "cp1251": "windows1251",
  "windows1252": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°ÃÂ Ã¢ÂÂ¹ÃÂÃ¯Â¿Â½ÃÂ½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ¢ÃÂ¡Ã¢ÂÂºÃÂÃ¯Â¿Â½ÃÂ¾ÃÂ¸ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿"
  },
  "win1252": "windows1252",
  "cp1252": "windows1252",
  "windows1253": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡Ã¯Â¿Â½Ã¢ÂÂ°Ã¯Â¿Â½Ã¢ÂÂ¹Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¢ÂÂ¢Ã¯Â¿Â½Ã¢ÂÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ ÃÂÃÂÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©Ã¯Â¿Â½ÃÂ«ÃÂ¬ÃÂ­ÃÂ®Ã¢ÂÂÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂÃÂµÃÂ¶ÃÂ·ÃÂÃÂÃÂÃÂ»ÃÂÃÂ½ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡Ã¯Â¿Â½ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½"
  },
  "win1253": "windows1253",
  "cp1253": "windows1253",
  "windows1254": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°ÃÂ Ã¢ÂÂ¹ÃÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ¢ÃÂ¡Ã¢ÂÂºÃÂÃ¯Â¿Â½Ã¯Â¿Â½ÃÂ¸ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ°ÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ±ÃÂÃÂ¿"
  },
  "win1254": "windows1254",
  "cp1254": "windows1254",
  "windows1255": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°Ã¯Â¿Â½Ã¢ÂÂ¹Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ¢Ã¯Â¿Â½Ã¢ÂÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ ÃÂ¡ÃÂ¢ÃÂ£Ã¢ÂÂªÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂ·ÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃ¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½"
  },
  "win1255": "windows1255",
  "cp1255": "windows1255",
  "windows1256": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬ÃÂ¾Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°ÃÂ¹Ã¢ÂÂ¹ÃÂÃÂÃÂÃÂÃÂ¯Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂ©Ã¢ÂÂ¢ÃÂÃ¢ÂÂºÃÂÃ¢ÂÂÃ¢ÂÂÃÂºÃÂ ÃÂÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂ¾ÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂÃÂÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂ¢ÃÂÃÂÃÂÃÂÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂÃÂÃÂ®ÃÂ¯ÃÂÃÂÃÂÃÂÃÂ´ÃÂÃÂÃÂ·ÃÂÃÂ¹ÃÂÃÂ»ÃÂ¼Ã¢ÂÂÃ¢ÂÂÃÂ"
  },
  "win1256": "windows1256",
  "cp1256": "windows1256",
  "windows1257": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃ¯Â¿Â½Ã¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡Ã¯Â¿Â½Ã¢ÂÂ°Ã¯Â¿Â½Ã¢ÂÂ¹Ã¯Â¿Â½ÃÂ¨ÃÂÃÂ¸Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¢ÂÂ¢Ã¯Â¿Â½Ã¢ÂÂºÃ¯Â¿Â½ÃÂ¯ÃÂÃ¯Â¿Â½ÃÂ Ã¯Â¿Â½ÃÂ¢ÃÂ£ÃÂ¤Ã¯Â¿Â½ÃÂ¦ÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¦ÃÂÃÂ®ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¹ÃÂÃÂ¢ÃÂ¶ÃÂªÃÂ»ÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ²ÃÂÃÂÃÂªÃÂÃÂ»ÃÂ½ÃÂÃÂÃÂ¯ÃÂÃÂÃÂ¤ÃÂ¥ÃÂÃÂÃÂÃÂ©ÃÂºÃÂÃÂ£ÃÂ·ÃÂ«ÃÂ¼ÃÂ¡ÃÂÃÂÃÂ³ÃÂÃÂµÃÂ¶ÃÂ·ÃÂ³ÃÂÃÂÃÂ«ÃÂ¼ÃÂ¼ÃÂ¾ÃÂ"
  },
  "win1257": "windows1257",
  "cp1257": "windows1257",
  "windows1258": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬Ã¯Â¿Â½Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°Ã¯Â¿Â½Ã¢ÂÂ¹ÃÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ¢Ã¯Â¿Â½Ã¢ÂÂºÃÂÃ¯Â¿Â½Ã¯Â¿Â½ÃÂ¸ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂ±ÃÂ£ÃÂ³ÃÂ´ÃÂ¡ÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ°Ã¢ÂÂ«ÃÂ¿"
  },
  "win1258": "windows1258",
  "cp1258": "windows1258",
  "iso88591": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿"
  },
  "cp28591": "iso88591",
  "iso88592": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂ¤ÃÂ½ÃÂÃÂ§ÃÂ¨ÃÂ ÃÂÃÂ¤ÃÂ¹ÃÂ­ÃÂ½ÃÂ»ÃÂ°ÃÂÃÂÃÂÃÂ´ÃÂ¾ÃÂÃÂÃÂ¸ÃÂ¡ÃÂÃÂ¥ÃÂºÃÂÃÂ¾ÃÂ¼ÃÂÃÂÃÂÃÂÃÂÃÂ¹ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂ°ÃÂÃÂÃÂ¢ÃÂÃÂÃÂ¡ÃÂ¢ÃÂÃÂ¤ÃÂºÃÂÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂÃÂ­ÃÂ®ÃÂÃÂÃÂÃÂÃÂ³ÃÂ´ÃÂÃÂ¶ÃÂ·ÃÂÃÂ¯ÃÂºÃÂ±ÃÂ¼ÃÂ½ÃÂ£ÃÂ"
  },
  "cp28592": "iso88592",
  "iso88593": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¦ÃÂÃÂ£ÃÂ¤Ã¯Â¿Â½ÃÂ¤ÃÂ§ÃÂ¨ÃÂ°ÃÂÃÂÃÂ´ÃÂ­Ã¯Â¿Â½ÃÂ»ÃÂ°ÃÂ§ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¥ÃÂ·ÃÂ¸ÃÂ±ÃÂÃÂÃÂµÃÂ½Ã¯Â¿Â½ÃÂ¼ÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¬ÃÂÃÂÃÂ ÃÂ¡ÃÂ¢Ã¯Â¿Â½ÃÂ¤ÃÂÃÂÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯Ã¯Â¿Â½ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂ¡ÃÂ¶ÃÂ·ÃÂÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ­ÃÂÃÂ"
  },
  "cp28593": "iso88593",
  "iso88594": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂ¸ÃÂÃÂ¤ÃÂ¨ÃÂ»ÃÂ§ÃÂ¨ÃÂ ÃÂÃÂ¢ÃÂ¦ÃÂ­ÃÂ½ÃÂ¯ÃÂ°ÃÂÃÂÃÂÃÂ´ÃÂ©ÃÂ¼ÃÂÃÂ¸ÃÂ¡ÃÂÃÂ£ÃÂ§ÃÂÃÂ¾ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂªÃÂÃÂÃÂÃÂ¶ÃÂÃÂÃÂÃÂÃÂÃÂ²ÃÂÃÂÃÂÃÂ¨ÃÂªÃÂÃÂÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ¯ÃÂÃÂ©ÃÂÃÂ«ÃÂÃÂ­ÃÂ®ÃÂ«ÃÂÃÂÃÂÃÂ·ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ³ÃÂºÃÂ»ÃÂ¼ÃÂ©ÃÂ«ÃÂ"
  },
  "cp28594": "iso88594",
  "iso88595": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ­ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ§ÃÂÃÂ"
  },
  "cp28595": "iso88595",
  "iso88596": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ¤Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂÃÂ­Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂÃ¯Â¿Â½ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "cp28596": "iso88596",
  "iso88597": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã¢ÂÂÃ¢ÂÂÃÂ£Ã¢ÂÂ¬Ã¢ÂÂ¯ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂºÃÂ«ÃÂ¬ÃÂ­Ã¯Â¿Â½Ã¢ÂÂÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂÃÂÃÂÃÂ·ÃÂÃÂÃÂÃÂ»ÃÂÃÂ½ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡Ã¯Â¿Â½ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½"
  },
  "cp28597": "iso88597",
  "iso88598": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã¯Â¿Â½ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂ·ÃÂ»ÃÂ¼ÃÂ½ÃÂ¾Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃ¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½"
  },
  "cp28598": "iso88598",
  "iso88599": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ°ÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ±ÃÂÃÂ¿"
  },
  "cp28599": "iso88599",
  "iso885910": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂ¢ÃÂªÃÂ¨ÃÂ¶ÃÂ§ÃÂ»ÃÂÃÂ ÃÂ¦ÃÂ½ÃÂ­ÃÂªÃÂÃÂ°ÃÂÃÂÃÂ£ÃÂ«ÃÂ©ÃÂ·ÃÂ·ÃÂ¼ÃÂÃÂ¡ÃÂ§ÃÂ¾Ã¢ÂÂÃÂ«ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¨ÃÂÃÂ²ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ¯ÃÂÃÂ©ÃÂÃÂ«ÃÂÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂÃÂÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ©ÃÂ¸ÃÂ³ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¸"
  },
  "cp28600": "iso885910",
  "iso885911": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸Â Ã Â¸Â¡Ã Â¸Â¢Ã Â¸Â£Ã Â¸Â¤Ã Â¸Â¥Ã Â¸Â¦Ã Â¸Â§Ã Â¸Â¨Ã Â¸Â©Ã Â¸ÂªÃ Â¸Â«Ã Â¸Â¬Ã Â¸Â­Ã Â¸Â®Ã Â¸Â¯Ã Â¸Â°Ã Â¸Â±Ã Â¸Â²Ã Â¸Â³Ã Â¸Â´Ã Â¸ÂµÃ Â¸Â¶Ã Â¸Â·Ã Â¸Â¸Ã Â¸Â¹Ã Â¸ÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â¸Â¿Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "cp28601": "iso885911",
  "iso885913": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã¢ÂÂÃÂ¢ÃÂ£ÃÂ¤Ã¢ÂÂÃÂ¦ÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂÃÂ°ÃÂ±ÃÂ²ÃÂ³Ã¢ÂÂÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¦ÃÂÃÂ®ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¹ÃÂÃÂ¢ÃÂ¶ÃÂªÃÂ»ÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ²ÃÂÃÂÃÂªÃÂÃÂ»ÃÂ½ÃÂÃÂÃÂ¯ÃÂÃÂÃÂ¤ÃÂ¥ÃÂÃÂÃÂÃÂ©ÃÂºÃÂÃÂ£ÃÂ·ÃÂ«ÃÂ¼ÃÂ¡ÃÂÃÂÃÂ³ÃÂÃÂµÃÂ¶ÃÂ·ÃÂ³ÃÂÃÂÃÂ«ÃÂ¼ÃÂ¼ÃÂ¾Ã¢ÂÂ"
  },
  "cp28603": "iso885913",
  "iso885914": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã¡Â¸ÂÃ¡Â¸ÂÃÂ£ÃÂÃÂÃ¡Â¸ÂÃÂ§Ã¡ÂºÂÃÂ©Ã¡ÂºÂÃ¡Â¸ÂÃ¡Â»Â²ÃÂ­ÃÂ®ÃÂ¸Ã¡Â¸ÂÃ¡Â¸ÂÃÂ ÃÂ¡Ã¡Â¹ÂÃ¡Â¹ÂÃÂ¶Ã¡Â¹ÂÃ¡ÂºÂÃ¡Â¹ÂÃ¡ÂºÂÃ¡Â¹Â Ã¡Â»Â³Ã¡ÂºÂÃ¡ÂºÂÃ¡Â¹Â¡ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ´ÃÂÃÂÃÂÃÂÃÂÃÂÃ¡Â¹ÂªÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¶ÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂµÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶Ã¡Â¹Â«ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ·ÃÂ¿"
  },
  "cp28604": "iso885914",
  "iso885915": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£Ã¢ÂÂ¬ÃÂ¥ÃÂ ÃÂ§ÃÂ¡ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ½ÃÂµÃÂ¶ÃÂ·ÃÂ¾ÃÂ¹ÃÂºÃÂ»ÃÂÃÂÃÂ¸ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿"
  },
  "cp28605": "iso885915",
  "iso885916": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃ¢ÂÂ¬Ã¢ÂÂÃÂ ÃÂ§ÃÂ¡ÃÂ©ÃÂÃÂ«ÃÂ¹ÃÂ­ÃÂºÃÂ»ÃÂ°ÃÂ±ÃÂÃÂÃÂ½Ã¢ÂÂÃÂ¶ÃÂ·ÃÂ¾ÃÂÃÂÃÂ»ÃÂÃÂÃÂ¸ÃÂ¼ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂÃÂ¤ÃÂÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂÃÂ²ÃÂ³ÃÂ´ÃÂÃÂ¶ÃÂÃÂ±ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂÃÂÃÂ¿"
  },
  "cp28606": "iso885916",
  "cp437": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ ÃÂ¥ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂ¯ÃÂ®ÃÂ¬ÃÂÃÂÃÂÃÂ¦ÃÂÃÂ´ÃÂ¶ÃÂ²ÃÂ»ÃÂ¹ÃÂ¿ÃÂÃÂÃÂ¢ÃÂ£ÃÂ¥Ã¢ÂÂ§ÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂªÃÂºÃÂ¿Ã¢ÂÂÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ±ÃÂÃÂÃÂÃÂ£ÃÂÃÂµÃÂÃÂ¦ÃÂÃÂ©ÃÂ´Ã¢ÂÂÃÂÃÂµÃ¢ÂÂ©Ã¢ÂÂ¡ÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤Ã¢ÂÂ Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm437": "cp437",
  "csibm437": "cp437",
  "cp737": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂ¬ÃÂ­ÃÂ®ÃÂÃÂ¯ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤ÃÂªÃÂ«ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm737": "cp737",
  "csibm737": "cp737",
  "cp775": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂÃÂ¤ÃÂ£ÃÂ¥ÃÂÃÂÃÂÃÂÃÂÃÂ«ÃÂ¹ÃÂÃÂÃÂÃÂ¦ÃÂÃÂÃÂ¶ÃÂ¢ÃÂ¢ÃÂÃÂÃÂÃÂÃÂ¸ÃÂ£ÃÂÃÂÃÂ¤ÃÂÃÂªÃÂ³ÃÂ»ÃÂ¼ÃÂºÃ¢ÂÂÃÂ¦ÃÂ©ÃÂ®ÃÂ¬ÃÂ½ÃÂ¼ÃÂÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂÃÂÃÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ®ÃÂ Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂ²ÃÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ½ÃÂÃÂÃÂÃÂÃÂ¯ÃÂ¡ÃÂ³ÃÂ«ÃÂ¾Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂµÃÂÃÂµÃÂÃÂ¶ÃÂ·ÃÂ»ÃÂ¼ÃÂÃÂÃÂÃ¢ÂÂÃÂ­ÃÂ±Ã¢ÂÂÃÂ¾ÃÂ¶ÃÂ§ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·ÃÂ¹ÃÂ³ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm775": "cp775",
  "csibm775": "cp775",
  "cp850": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ ÃÂ¥ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂ¯ÃÂ®ÃÂ¬ÃÂÃÂÃÂÃÂ¦ÃÂÃÂ´ÃÂ¶ÃÂ²ÃÂ»ÃÂ¹ÃÂ¿ÃÂÃÂÃÂ¸ÃÂ£ÃÂÃÂÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂªÃÂºÃÂ¿ÃÂ®ÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂÃÂÃÂ©Ã¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¢ÃÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂ£ÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ¤ÃÂ°ÃÂÃÂÃÂÃÂÃÂ±ÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¦ÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂµÃÂÃÂµÃÂ¾ÃÂÃÂÃÂÃÂÃÂ½ÃÂÃÂ¯ÃÂ´ÃÂ­ÃÂ±Ã¢ÂÂÃÂ¾ÃÂ¶ÃÂ§ÃÂ·ÃÂ¸ÃÂ°ÃÂ¨ÃÂ·ÃÂ¹ÃÂ³ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm850": "cp850",
  "csibm850": "cp850",
  "cp852": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ¯ÃÂÃÂ§ÃÂÃÂ«ÃÂÃÂÃÂ®ÃÂ¹ÃÂÃÂÃÂÃÂ¹ÃÂºÃÂ´ÃÂ¶ÃÂ½ÃÂ¾ÃÂÃÂÃÂÃÂÃÂ¤ÃÂ¥ÃÂÃÂÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂÃÂÃÂ½ÃÂ¾ÃÂÃÂÃÂ¬ÃÂºÃÂÃÂÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂÃÂÃÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ»ÃÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ¤ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¢ÃÂ®Ã¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂÃÂÃÂÃÂ°ÃÂ½ÃÂÃÂ£ÃÂ´ÃÂ­ÃÂÃÂÃÂÃÂÃÂ§ÃÂ·ÃÂ¸ÃÂ°ÃÂ¨ÃÂÃÂ±ÃÂÃÂÃ¢ÂÂ ÃÂ "
  },
  "ibm852": "cp852",
  "csibm852": "cp852",
  "cp855": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂªÃÂ°ÃÂÃÂ±ÃÂÃÂÃÂ¦ÃÂ´ÃÂÃÂµÃÂÃÂÃÂ¤ÃÂ³ÃÂÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂ¥ÃÂ¸ÃÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¹ÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂºÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ¤ÃÂ»ÃÂÃÂ¼ÃÂÃÂ½ÃÂÃÂ¾ÃÂÃÂ¿Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃÂ¯ÃÂÃÂ ÃÂÃÂ¡ÃÂÃÂ¢ÃÂÃÂ£ÃÂ¶ÃÂÃÂ²ÃÂÃÂÃÂ¬Ã¢ÂÂÃÂ­ÃÂÃÂ«ÃÂ·ÃÂÃÂÃÂ¨ÃÂÃÂ­ÃÂÃÂ©ÃÂÃÂ§ÃÂ§Ã¢ÂÂ ÃÂ "
  },
  "ibm855": "cp855",
  "csibm855": "cp855",
  "cp856": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃ¯Â¿Â½ÃÂ£Ã¯Â¿Â½ÃÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ®ÃÂ¬ÃÂ½ÃÂ¼Ã¯Â¿Â½ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ©Ã¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¢ÃÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ¤Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¦Ã¯Â¿Â½Ã¢ÂÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂµÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ¯ÃÂ´ÃÂ­ÃÂ±Ã¢ÂÂÃÂ¾ÃÂ¶ÃÂ§ÃÂ·ÃÂ¸ÃÂ°ÃÂ¨ÃÂ·ÃÂ¹ÃÂ³ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm856": "cp856",
  "csibm856": "cp856",
  "cp857": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ ÃÂ¥ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂ¯ÃÂ®ÃÂ±ÃÂÃÂÃÂÃÂ¦ÃÂÃÂ´ÃÂ¶ÃÂ²ÃÂ»ÃÂ¹ÃÂ°ÃÂÃÂÃÂ¸ÃÂ£ÃÂÃÂÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂÃÂÃÂ¿ÃÂ®ÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂÃÂÃÂ©Ã¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¢ÃÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂ£ÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ¤ÃÂºÃÂªÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¦ÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂµÃÂÃÂµÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂ¬ÃÂ¿ÃÂ¯ÃÂ´ÃÂ­ÃÂ±Ã¯Â¿Â½ÃÂ¾ÃÂ¶ÃÂ§ÃÂ·ÃÂ¸ÃÂ°ÃÂ¨ÃÂ·ÃÂ¹ÃÂ³ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm857": "cp857",
  "csibm857": "cp857",
  "cp858": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ ÃÂ¥ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂ¯ÃÂ®ÃÂ¬ÃÂÃÂÃÂÃÂ¦ÃÂÃÂ´ÃÂ¶ÃÂ²ÃÂ»ÃÂ¹ÃÂ¿ÃÂÃÂÃÂ¸ÃÂ£ÃÂÃÂÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂªÃÂºÃÂ¿ÃÂ®ÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂÃÂÃÂ©Ã¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¢ÃÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂ£ÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ¤ÃÂ°ÃÂÃÂÃÂÃÂÃ¢ÂÂ¬ÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¦ÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂµÃÂÃÂµÃÂ¾ÃÂÃÂÃÂÃÂÃÂ½ÃÂÃÂ¯ÃÂ´ÃÂ­ÃÂ±Ã¢ÂÂÃÂ¾ÃÂ¶ÃÂ§ÃÂ·ÃÂ¸ÃÂ°ÃÂ¨ÃÂ·ÃÂ¹ÃÂ³ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm858": "cp858",
  "csibm858": "cp858",
  "cp860": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ£ÃÂ ÃÂÃÂ§ÃÂªÃÂÃÂ¨ÃÂÃÂÃÂ¬ÃÂÃÂÃÂÃÂÃÂÃÂ´ÃÂµÃÂ²ÃÂÃÂ¹ÃÂÃÂÃÂÃÂ¢ÃÂ£ÃÂÃ¢ÂÂ§ÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂªÃÂºÃÂ¿ÃÂÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ±ÃÂÃÂÃÂÃÂ£ÃÂÃÂµÃÂÃÂ¦ÃÂÃÂ©ÃÂ´Ã¢ÂÂÃÂÃÂµÃ¢ÂÂ©Ã¢ÂÂ¡ÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤Ã¢ÂÂ Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm860": "cp860",
  "csibm860": "cp860",
  "cp861": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ ÃÂ¥ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂÃÂ°ÃÂÃÂÃÂÃÂÃÂ¦ÃÂÃÂ´ÃÂ¶ÃÂ¾ÃÂ»ÃÂÃÂ½ÃÂÃÂÃÂ¸ÃÂ£ÃÂÃ¢ÂÂ§ÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂÃÂÃÂÃÂÃÂ¿Ã¢ÂÂÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ±ÃÂÃÂÃÂÃÂ£ÃÂÃÂµÃÂÃÂ¦ÃÂÃÂ©ÃÂ´Ã¢ÂÂÃÂÃÂµÃ¢ÂÂ©Ã¢ÂÂ¡ÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤Ã¢ÂÂ Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm861": "cp861",
  "csibm861": "cp861",
  "cp862": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ¢ÃÂ£ÃÂ¥Ã¢ÂÂ§ÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂªÃÂºÃÂ¿Ã¢ÂÂÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ±ÃÂÃÂÃÂÃÂ£ÃÂÃÂµÃÂÃÂ¦ÃÂÃÂ©ÃÂ´Ã¢ÂÂÃÂÃÂµÃ¢ÂÂ©Ã¢ÂÂ¡ÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤Ã¢ÂÂ Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm862": "cp862",
  "csibm862": "cp862",
  "cp863": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂÃÂ ÃÂ¶ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂ¯ÃÂ®Ã¢ÂÂÃÂÃÂ§ÃÂÃÂÃÂÃÂ´ÃÂÃÂÃÂ»ÃÂ¹ÃÂ¤ÃÂÃÂÃÂ¢ÃÂ£ÃÂÃÂÃÂÃÂ¦ÃÂ´ÃÂ³ÃÂºÃÂ¨ÃÂ¸ÃÂ³ÃÂ¯ÃÂÃ¢ÂÂÃÂ¬ÃÂ½ÃÂ¼ÃÂ¾ÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ±ÃÂÃÂÃÂÃÂ£ÃÂÃÂµÃÂÃÂ¦ÃÂÃÂ©ÃÂ´Ã¢ÂÂÃÂÃÂµÃ¢ÂÂ©Ã¢ÂÂ¡ÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤Ã¢ÂÂ Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm863": "cp863",
  "csibm863": "cp863",
  "cp864": {
    "type": "_sbcs",
    "chars": "\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f !\"#$ÃÂª&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~ÃÂ°ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂ¤Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂ´Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ²Ã¢ÂÂÃÂÃÂ±ÃÂ½ÃÂ¼Ã¢ÂÂÃÂ«ÃÂ»Ã¯Â»Â·Ã¯Â»Â¸Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â»Â»Ã¯Â»Â¼Ã¯Â¿Â½ÃÂ ÃÂ­Ã¯ÂºÂÃÂ£ÃÂ¤Ã¯ÂºÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃÂÃ¯ÂºÂÃ¯ÂºÂ¡Ã¯ÂºÂ¥ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©Ã¯Â»ÂÃÂÃ¯ÂºÂ±Ã¯ÂºÂµÃ¯ÂºÂ¹ÃÂÃÂ¢Ã¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯Â»ÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂ£Ã¯ÂºÂ§Ã¯ÂºÂ©Ã¯ÂºÂ«Ã¯ÂºÂ­Ã¯ÂºÂ¯Ã¯ÂºÂ³Ã¯ÂºÂ·Ã¯ÂºÂ»Ã¯ÂºÂ¿Ã¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃÂ¦ÃÂ¬ÃÂ·ÃÂÃ¯Â»ÂÃÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»Â£Ã¯Â»Â§Ã¯Â»Â«Ã¯Â»Â­Ã¯Â»Â¯Ã¯Â»Â³Ã¯ÂºÂ½Ã¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»Â¡Ã¯Â¹Â½ÃÂÃ¯Â»Â¥Ã¯Â»Â©Ã¯Â»Â¬Ã¯Â»Â°Ã¯Â»Â²Ã¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂµÃ¯Â»Â¶Ã¯Â»ÂÃ¯Â»ÂÃ¯Â»Â±Ã¢ÂÂ Ã¯Â¿Â½"
  },
  "ibm864": "cp864",
  "csibm864": "cp864",
  "cp865": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¼ÃÂ©ÃÂ¢ÃÂ¤ÃÂ ÃÂ¥ÃÂ§ÃÂªÃÂ«ÃÂ¨ÃÂ¯ÃÂ®ÃÂ¬ÃÂÃÂÃÂÃÂ¦ÃÂÃÂ´ÃÂ¶ÃÂ²ÃÂ»ÃÂ¹ÃÂ¿ÃÂÃÂÃÂ¸ÃÂ£ÃÂÃ¢ÂÂ§ÃÂÃÂ¡ÃÂ­ÃÂ³ÃÂºÃÂ±ÃÂÃÂªÃÂºÃÂ¿Ã¢ÂÂÃÂ¬ÃÂ½ÃÂ¼ÃÂ¡ÃÂ«ÃÂ¤Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ±ÃÂÃÂÃÂÃÂ£ÃÂÃÂµÃÂÃÂ¦ÃÂÃÂ©ÃÂ´Ã¢ÂÂÃÂÃÂµÃ¢ÂÂ©Ã¢ÂÂ¡ÃÂ±Ã¢ÂÂ¥Ã¢ÂÂ¤Ã¢ÂÂ Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂ¿ÃÂ²Ã¢ÂÂ ÃÂ "
  },
  "ibm865": "cp865",
  "csibm865": "cp865",
  "cp866": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ°Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂÃÂ¤Ã¢ÂÂ ÃÂ "
  },
  "ibm866": "cp866",
  "csibm866": "cp866",
  "cp869": {
    "type": "_sbcs",
    "chars": "Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂÃ¯Â¿Â½ÃÂ·ÃÂ¬ÃÂ¦Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂÃÂÃÂÃÂªÃÂÃ¯Â¿Â½Ã¯Â¿Â½ÃÂÃÂ«ÃÂ©ÃÂÃÂ²ÃÂ³ÃÂ¬ÃÂ£ÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ½ÃÂÃÂÃÂ«ÃÂ»Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤ÃÂÃÂÃÂÃÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼ÃÂ ÃÂ¡Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂ±ÃÂ²ÃÂ³Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ´ÃÂµÃ¢ÂÂÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂ­ÃÂ±ÃÂÃÂÃÂÃÂ§ÃÂÃÂÃÂ°ÃÂ¨ÃÂÃÂÃÂ°ÃÂÃ¢ÂÂ ÃÂ "
  },
  "ibm869": "cp869",
  "csibm869": "cp869",
  "cp922": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®Ã¢ÂÂ¾ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ½ÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ¡ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿"
  },
  "ibm922": "cp922",
  "csibm922": "cp922",
  "cp1046": {
    "type": "_sbcs",
    "chars": "Ã¯ÂºÂÃÂÃÂ·Ã¯Â£Â¶Ã¯Â£ÂµÃ¯Â£Â´Ã¯Â£Â·Ã¯Â¹Â±ÃÂÃ¢ÂÂ Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¯Â¹Â¹Ã¯Â¹Â»Ã¯Â¹Â½Ã¯Â¹Â¿Ã¯Â¹Â·Ã¯ÂºÂÃ¯Â»Â°Ã¯Â»Â³Ã¯Â»Â²Ã¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»Â¶Ã¯Â»Â¸Ã¯Â»ÂºÃ¯Â»Â¼ÃÂ Ã¯Â£ÂºÃ¯Â£Â¹Ã¯Â£Â¸ÃÂ¤Ã¯Â£Â»Ã¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂ£ÃÂÃÂ­Ã¯ÂºÂ§Ã¯ÂºÂ³ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©Ã¯ÂºÂ·ÃÂÃ¯ÂºÂ»Ã¯ÂºÂ¿Ã¯Â»ÂÃÂÃ¯Â»ÂÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·Ã¯Â»ÂÃÂ¹ÃÂºÃ¯Â»ÂÃ¯ÂºÂÃ¯ÂºÂÃ¯ÂºÂÃ¯Â»ÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â»ÂÃ¯Â£Â¼Ã¯Â»ÂµÃ¯Â»Â·Ã¯Â»Â¹Ã¯Â»Â»Ã¯Â»Â£Ã¯Â»Â§Ã¯Â»Â¬Ã¯Â»Â©Ã¯Â¿Â½"
  },
  "ibm1046": "cp1046",
  "csibm1046": "cp1046",
  "cp1124": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ­ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ§ÃÂÃÂ"
  },
  "ibm1124": "cp1124",
  "csibm1124": "cp1124",
  "cp1125": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¡Ã¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ£Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ´Ã¢ÂÂ¬Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ©Ã¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂ¬Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ«Ã¢ÂÂªÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ·Ã¢ÂÂÃ¢ÂÂÃÂ¤Ã¢ÂÂ ÃÂ "
  },
  "ibm1125": "cp1125",
  "csibm1125": "cp1125",
  "cp1129": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ¸ÃÂµÃÂ¶ÃÂ·ÃÂÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂ±ÃÂ£ÃÂ³ÃÂ´ÃÂ¡ÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ°Ã¢ÂÂ«ÃÂ¿"
  },
  "ibm1129": "cp1129",
  "csibm1129": "cp1129",
  "cp1133": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂªÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂÃ ÂºÂ¡Ã ÂºÂ¢Ã ÂºÂ£Ã ÂºÂ¥Ã ÂºÂ§Ã ÂºÂ«Ã ÂºÂ­Ã ÂºÂ®Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã ÂºÂ¯Ã ÂºÂ°Ã ÂºÂ²Ã ÂºÂ³Ã ÂºÂ´Ã ÂºÂµÃ ÂºÂ¶Ã ÂºÂ·Ã ÂºÂ¸Ã ÂºÂ¹Ã ÂºÂ¼Ã ÂºÂ±Ã ÂºÂ»Ã ÂºÂ½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ¯Â¿Â½Ã Â»ÂÃ Â»ÂÃ¢ÂÂ­Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ Â»ÂÃ¯Â¿Â½Ã¯Â¿Â½ÃÂ¢ÃÂ¬ÃÂ¦Ã¯Â¿Â½"
  },
  "ibm1133": "cp1133",
  "csibm1133": "cp1133",
  "cp1161": {
    "type": "_sbcs",
    "chars": "Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â¹ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸Â Ã Â¸Â¡Ã Â¸Â¢Ã Â¸Â£Ã Â¸Â¤Ã Â¸Â¥Ã Â¸Â¦Ã Â¸Â§Ã Â¸Â¨Ã Â¸Â©Ã Â¸ÂªÃ Â¸Â«Ã Â¸Â¬Ã Â¸Â­Ã Â¸Â®Ã Â¸Â¯Ã Â¸Â°Ã Â¸Â±Ã Â¸Â²Ã Â¸Â³Ã Â¸Â´Ã Â¸ÂµÃ Â¸Â¶Ã Â¸Â·Ã Â¸Â¸Ã Â¸Â¹Ã Â¸ÂºÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ¢ÂÂ¬Ã Â¸Â¿Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃÂ¢ÃÂ¬ÃÂ¦ÃÂ "
  },
  "ibm1161": "cp1161",
  "csibm1161": "cp1161",
  "cp1162": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂ¬ÃÂÃÂÃÂÃÂÃ¢ÂÂ¦ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸Â Ã Â¸Â¡Ã Â¸Â¢Ã Â¸Â£Ã Â¸Â¤Ã Â¸Â¥Ã Â¸Â¦Ã Â¸Â§Ã Â¸Â¨Ã Â¸Â©Ã Â¸ÂªÃ Â¸Â«Ã Â¸Â¬Ã Â¸Â­Ã Â¸Â®Ã Â¸Â¯Ã Â¸Â°Ã Â¸Â±Ã Â¸Â²Ã Â¸Â³Ã Â¸Â´Ã Â¸ÂµÃ Â¸Â¶Ã Â¸Â·Ã Â¸Â¸Ã Â¸Â¹Ã Â¸ÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â¸Â¿Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "ibm1162": "cp1162",
  "csibm1162": "cp1162",
  "cp1163": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£Ã¢ÂÂ¬ÃÂ¥ÃÂ¦ÃÂ§ÃÂÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ¸ÃÂµÃÂ¶ÃÂ·ÃÂÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂ±ÃÂ£ÃÂ³ÃÂ´ÃÂ¡ÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ°Ã¢ÂÂ«ÃÂ¿"
  },
  "ibm1163": "cp1163",
  "csibm1163": "cp1163",
  "maccroatian": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ ÃÂ¢ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ­ÃÂ¬ÃÂ®ÃÂ¯ÃÂ±ÃÂ³ÃÂ²ÃÂ´ÃÂ¶ÃÂµÃÂºÃÂ¹ÃÂ»ÃÂ¼Ã¢ÂÂ ÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ Ã¢ÂÂ¢ÃÂ´ÃÂ¨Ã¢ÂÂ ÃÂ½ÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂÃÂµÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ¡Ã¢ÂÂ«ÃÂªÃÂºÃ¢ÂÂ¦ÃÂ¾ÃÂ¸ÃÂ¿ÃÂ¡ÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃÂÃÂ«ÃÂÃ¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃ¯Â¿Â½ÃÂ©Ã¢ÂÂÃÂ¤Ã¢ÂÂ¹Ã¢ÂÂºÃÂÃÂ»Ã¢ÂÂÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ±ÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂ¸ÃÂÃÂ¦ÃÂ"
  },
  "maccyrillic": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯Ã¢ÂÂ ÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂÃÂÃ¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂÃÂµÃ¢ÂÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¤"
  },
  "macgreek": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂ¹ÃÂ²ÃÂÃÂ³ÃÂÃÂÃÂÃÂ ÃÂ¢ÃÂ¤ÃÂÃÂ¨ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ£Ã¢ÂÂ¢ÃÂ®ÃÂ¯Ã¢ÂÂ¢ÃÂ½Ã¢ÂÂ°ÃÂ´ÃÂ¶ÃÂ¦ÃÂ­ÃÂ¹ÃÂ»ÃÂ¼Ã¢ÂÂ ÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂ®ÃÂ©ÃÂ£ÃÂªÃÂ§Ã¢ÂÂ ÃÂ°ÃÂÃÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂ¥ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¦ÃÂ«ÃÂ¨ÃÂ©ÃÂ¬ÃÂÃÂ¬ÃÂÃÂ¡Ã¢ÂÂÃÂ¤ÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂ¥ÃÂ§ÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·ÃÂÃÂÃÂÃÂÃÂ­ÃÂ®ÃÂ¯ÃÂÃÂÃÂÃÂ±ÃÂ²ÃÂÃÂ´ÃÂµÃÂÃÂ³ÃÂ·ÃÂ¹ÃÂ¾ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂ¸ÃÂÃÂÃÂÃÂÃÂ¶ÃÂÃÂÃÂÃÂ°Ã¯Â¿Â½"
  },
  "maciceland": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ ÃÂ¢ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ­ÃÂ¬ÃÂ®ÃÂ¯ÃÂ±ÃÂ³ÃÂ²ÃÂ´ÃÂ¶ÃÂµÃÂºÃÂ¹ÃÂ»ÃÂ¼ÃÂÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂ´ÃÂ¨Ã¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂ¥ÃÂµÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ«ÃÂªÃÂºÃ¢ÂÂ¦ÃÂ¦ÃÂ¸ÃÂ¿ÃÂ¡ÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂ¿ÃÂ¸Ã¢ÂÂÃÂ¤ÃÂÃÂ°ÃÂÃÂ¾ÃÂ½ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂ±ÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂ¸ÃÂÃÂÃÂ"
  },
  "macroman": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ ÃÂ¢ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ­ÃÂ¬ÃÂ®ÃÂ¯ÃÂ±ÃÂ³ÃÂ²ÃÂ´ÃÂ¶ÃÂµÃÂºÃÂ¹ÃÂ»ÃÂ¼Ã¢ÂÂ ÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂ´ÃÂ¨Ã¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂ¥ÃÂµÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ«ÃÂªÃÂºÃ¢ÂÂ¦ÃÂ¦ÃÂ¸ÃÂ¿ÃÂ¡ÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂ¿ÃÂ¸Ã¢ÂÂÃÂ¤Ã¢ÂÂ¹Ã¢ÂÂºÃ¯Â¬ÂÃ¯Â¬ÂÃ¢ÂÂ¡ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂ±ÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂ¸ÃÂÃÂÃÂ"
  },
  "macromania": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ ÃÂ¢ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ­ÃÂ¬ÃÂ®ÃÂ¯ÃÂ±ÃÂ³ÃÂ²ÃÂ´ÃÂ¶ÃÂµÃÂºÃÂ¹ÃÂ»ÃÂ¼Ã¢ÂÂ ÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂ´ÃÂ¨Ã¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂ¥ÃÂµÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ«ÃÂªÃÂºÃ¢ÂÂ¦ÃÂÃÂÃÂ¿ÃÂ¡ÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂ¿ÃÂ¸Ã¢ÂÂÃÂ¤Ã¢ÂÂ¹Ã¢ÂÂºÃÂ¢ÃÂ£Ã¢ÂÂ¡ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂ±ÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂ¸ÃÂÃÂÃÂ"
  },
  "macthai": {
    "type": "_sbcs",
    "chars": "ÃÂ«ÃÂ»Ã¢ÂÂ¦Ã¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¢ÂÂÃ¢ÂÂÃ¯Â¢ÂÃ¯Â¿Â½Ã¢ÂÂ¢Ã¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¯Â¢ÂÃ¢ÂÂÃ¢ÂÂÃ¯Â¿Â½ÃÂ Ã Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸Â Ã Â¸Â¡Ã Â¸Â¢Ã Â¸Â£Ã Â¸Â¤Ã Â¸Â¥Ã Â¸Â¦Ã Â¸Â§Ã Â¸Â¨Ã Â¸Â©Ã Â¸ÂªÃ Â¸Â«Ã Â¸Â¬Ã Â¸Â­Ã Â¸Â®Ã Â¸Â¯Ã Â¸Â°Ã Â¸Â±Ã Â¸Â²Ã Â¸Â³Ã Â¸Â´Ã Â¸ÂµÃ Â¸Â¶Ã Â¸Â·Ã Â¸Â¸Ã Â¸Â¹Ã Â¸ÂºÃ¯Â»Â¿Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ Â¸Â¿Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ¢ÂÂ¢Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃÂ®ÃÂ©Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "macturkish": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ ÃÂ¢ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ­ÃÂ¬ÃÂ®ÃÂ¯ÃÂ±ÃÂ³ÃÂ²ÃÂ´ÃÂ¶ÃÂµÃÂºÃÂ¹ÃÂ»ÃÂ¼Ã¢ÂÂ ÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂ´ÃÂ¨Ã¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂ¥ÃÂµÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ«ÃÂªÃÂºÃ¢ÂÂ¦ÃÂ¦ÃÂ¸ÃÂ¿ÃÂ¡ÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂ¿ÃÂ¸ÃÂÃÂÃÂ°ÃÂ±ÃÂÃÂÃ¢ÂÂ¡ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂ¸ÃÂÃÂÃÂ"
  },
  "macukraine": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯Ã¢ÂÂ ÃÂ°ÃÂÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂÃÂÃ¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂÃÂµÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃÂÃÂÃÂÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¤"
  },
  "koi8r": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¬Ã¢ÂÂ´Ã¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¥ÃÂ Ã¢ÂÂ¡ÃÂ°ÃÂ²ÃÂ·ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ¢Ã¢ÂÂ£Ã¢ÂÂ¤Ã¢ÂÂ¥Ã¢ÂÂ¦Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ©Ã¢ÂÂªÃ¢ÂÂ«Ã¢ÂÂ¬ÃÂ©ÃÂÃÂ°ÃÂ±ÃÂÃÂ´ÃÂµÃÂÃÂ³ÃÂÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂ¶ÃÂ²ÃÂÃÂÃÂ·ÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂÃÂ¦ÃÂÃÂÃÂ¤ÃÂÃÂ¥ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂÃÂÃÂ¬ÃÂ«ÃÂÃÂ¨ÃÂ­ÃÂ©ÃÂ§ÃÂª"
  },
  "koi8u": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¬Ã¢ÂÂ´Ã¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¥ÃÂ Ã¢ÂÂ¡ÃÂ°ÃÂ²ÃÂ·ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ Ã¢ÂÂ¡ÃÂÃÂÃ¢ÂÂ£ÃÂÃÂÃ¢ÂÂ¦Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ©Ã¢ÂÂªÃÂÃ¢ÂÂ¬ÃÂ©ÃÂÃÂ°ÃÂ±ÃÂÃÂ´ÃÂµÃÂÃÂ³ÃÂÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂ¶ÃÂ²ÃÂÃÂÃÂ·ÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂÃÂ¦ÃÂÃÂÃÂ¤ÃÂÃÂ¥ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂÃÂÃÂ¬ÃÂ«ÃÂÃÂ¨ÃÂ­ÃÂ©ÃÂ§ÃÂª"
  },
  "koi8ru": {
    "type": "_sbcs",
    "chars": "Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¬Ã¢ÂÂ´Ã¢ÂÂ¼Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ Ã¢ÂÂ Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¤Ã¢ÂÂ¥ÃÂ Ã¢ÂÂ¡ÃÂ°ÃÂ²ÃÂ·ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ Ã¢ÂÂ¡ÃÂÃÂÃ¢ÂÂ£ÃÂÃÂÃ¢ÂÂ¦Ã¢ÂÂ§Ã¢ÂÂ¨Ã¢ÂÂ©Ã¢ÂÂªÃÂÃÂÃÂ©ÃÂÃÂ°ÃÂ±ÃÂÃÂ´ÃÂµÃÂÃÂ³ÃÂÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂ¶ÃÂ²ÃÂÃÂÃÂ·ÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂÃÂ¦ÃÂÃÂÃÂ¤ÃÂÃÂ¥ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂÃÂÃÂ¬ÃÂ«ÃÂÃÂ¨ÃÂ­ÃÂ©ÃÂ§ÃÂª"
  },
  "koi8t": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡Ã¯Â¿Â½Ã¢ÂÂ°ÃÂ³Ã¢ÂÂ¹ÃÂ²ÃÂ·ÃÂ¶Ã¯Â¿Â½ÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¢ÂÂ¢Ã¯Â¿Â½Ã¢ÂÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ¯ÃÂ®ÃÂÃÂ¤ÃÂ£ÃÂ¦ÃÂ§Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ«ÃÂ¬ÃÂ­ÃÂ®Ã¯Â¿Â½ÃÂ°ÃÂ±ÃÂ²ÃÂÃ¯Â¿Â½ÃÂ¢ÃÂ¶ÃÂ·Ã¯Â¿Â½Ã¢ÂÂÃ¯Â¿Â½ÃÂ»Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½ÃÂ©ÃÂÃÂ°ÃÂ±ÃÂÃÂ´ÃÂµÃÂÃÂ³ÃÂÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂ¶ÃÂ²ÃÂÃÂÃÂ·ÃÂÃÂÃÂÃÂÃÂÃÂ®ÃÂÃÂÃÂ¦ÃÂÃÂÃÂ¤ÃÂÃÂ¥ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¯ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂÃÂÃÂ¬ÃÂ«ÃÂÃÂ¨ÃÂ­ÃÂ©ÃÂ§ÃÂª"
  },
  "armscii8": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ Ã¯Â¿Â½ÃÂÃÂ)(ÃÂ»ÃÂ«Ã¢ÂÂ.ÃÂ,-ÃÂÃ¢ÂÂ¦ÃÂÃÂÃÂÃÂ±ÃÂ¡ÃÂ²ÃÂ¢ÃÂ³ÃÂ£ÃÂ´ÃÂ¤ÃÂµÃÂ¥ÃÂ¶ÃÂ¦ÃÂ·ÃÂ§ÃÂ¸ÃÂ¨ÃÂ¹ÃÂ©ÃÂºÃÂªÃÂ»ÃÂ«ÃÂ¼ÃÂ¬ÃÂ½ÃÂ­ÃÂ¾ÃÂ®ÃÂ¿ÃÂ¯ÃÂÃÂ°ÃÂÃÂ±ÃÂÃÂ²ÃÂÃÂ³ÃÂÃÂ´ÃÂÃÂµÃÂÃÂ¶ÃÂÃÂ·ÃÂÃÂ¸ÃÂÃÂ¹ÃÂÃÂºÃÂÃÂ»ÃÂÃÂ¼ÃÂÃÂ½ÃÂÃÂ¾ÃÂÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½"
  },
  "rk1048": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡Ã¢ÂÂ¬Ã¢ÂÂ°ÃÂÃ¢ÂÂ¹ÃÂÃÂÃÂºÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃ¯Â¿Â½Ã¢ÂÂ¢ÃÂÃ¢ÂÂºÃÂÃÂÃÂ»ÃÂÃÂ ÃÂ°ÃÂ±ÃÂÃÂ¤ÃÂ¨ÃÂ¦ÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ®ÃÂ°ÃÂ±ÃÂÃÂÃÂ©ÃÂµÃÂ¶ÃÂ·ÃÂÃ¢ÂÂÃÂÃÂ»ÃÂÃÂ¢ÃÂ£ÃÂ¯ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ"
  },
  "tcvn": {
    "type": "_sbcs",
    "chars": "\u0000ÃÂÃ¡Â»Â¤\u0003Ã¡Â»ÂªÃ¡Â»Â¬Ã¡Â»Â®\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010Ã¡Â»Â¨Ã¡Â»Â°Ã¡Â»Â²Ã¡Â»Â¶Ã¡Â»Â¸ÃÂÃ¡Â»Â´\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~ÃÂÃ¡ÂºÂ¢ÃÂÃÂÃ¡ÂºÂ Ã¡ÂºÂ¶Ã¡ÂºÂ¬ÃÂÃ¡ÂºÂºÃ¡ÂºÂ¼ÃÂÃ¡ÂºÂ¸Ã¡Â»ÂÃÂÃ¡Â»ÂÃÂ¨ÃÂÃ¡Â»ÂÃÂÃ¡Â»ÂÃÂÃÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â Ã¡Â»ÂÃ¡Â»Â¢ÃÂÃ¡Â»Â¦ÃÂ¨ÃÂ ÃÂÃÂÃÂÃÂÃÂ ÃÂ¯ÃÂÃÂÃÂ¢ÃÂªÃÂ´ÃÂ¡ÃÂ°ÃÂÃ¡ÂºÂ°ÃÂÃÂÃÂÃÂÃÂ£ÃÂ Ã¡ÂºÂ£ÃÂ£ÃÂ¡Ã¡ÂºÂ¡Ã¡ÂºÂ²Ã¡ÂºÂ±Ã¡ÂºÂ³Ã¡ÂºÂµÃ¡ÂºÂ¯Ã¡ÂºÂ´Ã¡ÂºÂ®Ã¡ÂºÂ¦Ã¡ÂºÂ¨Ã¡ÂºÂªÃ¡ÂºÂ¤Ã¡Â»ÂÃ¡ÂºÂ·Ã¡ÂºÂ§Ã¡ÂºÂ©Ã¡ÂºÂ«Ã¡ÂºÂ¥Ã¡ÂºÂ­ÃÂ¨Ã¡Â»ÂÃ¡ÂºÂ»Ã¡ÂºÂ½ÃÂ©Ã¡ÂºÂ¹Ã¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡ÂºÂ¿Ã¡Â»ÂÃÂ¬Ã¡Â»ÂÃ¡Â»ÂÃ¡ÂºÂ¾Ã¡Â»ÂÃÂ©ÃÂ­Ã¡Â»ÂÃÂ²Ã¡Â»ÂÃ¡Â»ÂÃÂµÃÂ³Ã¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â¡Ã¡Â»ÂÃ¡Â»Â£ÃÂ¹Ã¡Â»ÂÃ¡Â»Â§ÃÂ©ÃÂºÃ¡Â»Â¥Ã¡Â»Â«Ã¡Â»Â­Ã¡Â»Â¯Ã¡Â»Â©Ã¡Â»Â±Ã¡Â»Â³Ã¡Â»Â·Ã¡Â»Â¹ÃÂ½Ã¡Â»ÂµÃ¡Â»Â"
  },
  "georgianacademy": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°ÃÂ Ã¢ÂÂ¹ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ¢ÃÂ¡Ã¢ÂÂºÃÂÃÂÃÂÃÂ¸ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿Ã¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂ Ã¡ÂÂ¡Ã¡ÂÂ¢Ã¡ÂÂ£Ã¡ÂÂ¤Ã¡ÂÂ¥Ã¡ÂÂ¦Ã¡ÂÂ§Ã¡ÂÂ¨Ã¡ÂÂ©Ã¡ÂÂªÃ¡ÂÂ«Ã¡ÂÂ¬Ã¡ÂÂ­Ã¡ÂÂ®Ã¡ÂÂ¯Ã¡ÂÂ°Ã¡ÂÂ±Ã¡ÂÂ²Ã¡ÂÂ³Ã¡ÂÂ´Ã¡ÂÂµÃ¡ÂÂ¶ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿"
  },
  "georgianps": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃ¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂ¦Ã¢ÂÂ Ã¢ÂÂ¡ÃÂÃ¢ÂÂ°ÃÂ Ã¢ÂÂ¹ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ¢ÃÂ¡Ã¢ÂÂºÃÂÃÂÃÂÃÂ¸ÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿Ã¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂ±Ã¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂ²Ã¡ÂÂÃ¡ÂÂÃ¡ÂÂÃ¡ÂÂ Ã¡ÂÂ¡Ã¡ÂÂ¢Ã¡ÂÂ³Ã¡ÂÂ£Ã¡ÂÂ¤Ã¡ÂÂ¥Ã¡ÂÂ¦Ã¡ÂÂ§Ã¡ÂÂ¨Ã¡ÂÂ©Ã¡ÂÂªÃ¡ÂÂ«Ã¡ÂÂ¬Ã¡ÂÂ­Ã¡ÂÂ®Ã¡ÂÂ´Ã¡ÂÂ¯Ã¡ÂÂ°Ã¡ÂÂµÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿"
  },
  "pt154": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂ®ÃÂÃ¢ÂÂÃ¢ÂÂ¦ÃÂ¶ÃÂ®ÃÂ²ÃÂ¯ÃÂ ÃÂ¢ÃÂ¢ÃÂÃÂºÃÂ¸ÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂ¢Ã¢ÂÂÃ¢ÂÂÃÂ³ÃÂ·ÃÂ¡ÃÂ£ÃÂ£ÃÂÃÂ»ÃÂ¹ÃÂ ÃÂÃÂÃÂÃÂ¨ÃÂÃÂ°ÃÂ§ÃÂÃÂ©ÃÂÃÂ«ÃÂ¬ÃÂ¯ÃÂ®ÃÂÃÂ°ÃÂ±ÃÂÃÂÃÂÃÂ©ÃÂ¶ÃÂ·ÃÂÃ¢ÂÂÃÂÃÂ»ÃÂÃÂªÃÂ«ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂ¡ÃÂ¢ÃÂ£ÃÂ¤ÃÂ¥ÃÂ¦ÃÂ§ÃÂ¨ÃÂ©ÃÂªÃÂ«ÃÂ¬ÃÂ­ÃÂ®ÃÂ¯ÃÂ°ÃÂ±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃÂ¶ÃÂ·ÃÂ¸ÃÂ¹ÃÂºÃÂ»ÃÂ¼ÃÂ½ÃÂ¾ÃÂ¿ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ"
  },
  "viscii": {
    "type": "_sbcs",
    "chars": "\u0000\u0001Ã¡ÂºÂ²\u0003\u0004Ã¡ÂºÂ´Ã¡ÂºÂª\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010\u0011\u0012\u0013Ã¡Â»Â¶\u0015\u0016\u0017\u0018Ã¡Â»Â¸\u001a\u001b\u001c\u001dÃ¡Â»Â´\u001f !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~Ã¡ÂºÂ Ã¡ÂºÂ®Ã¡ÂºÂ°Ã¡ÂºÂ¶Ã¡ÂºÂ¤Ã¡ÂºÂ¦Ã¡ÂºÂ¨Ã¡ÂºÂ¬Ã¡ÂºÂ¼Ã¡ÂºÂ¸Ã¡ÂºÂ¾Ã¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â¢Ã¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â¦ÃÂ¨Ã¡Â»Â¤Ã¡Â»Â²ÃÂÃ¡ÂºÂ¯Ã¡ÂºÂ±Ã¡ÂºÂ·Ã¡ÂºÂ¥Ã¡ÂºÂ§Ã¡ÂºÂ©Ã¡ÂºÂ­Ã¡ÂºÂ½Ã¡ÂºÂ¹Ã¡ÂºÂ¿Ã¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â ÃÂ Ã¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â°Ã¡Â»Â¨Ã¡Â»ÂªÃ¡Â»Â¬ÃÂ¡Ã¡Â»ÂÃÂ¯ÃÂÃÂÃÂÃÂÃ¡ÂºÂ¢ÃÂÃ¡ÂºÂ³Ã¡ÂºÂµÃÂÃÂÃÂÃ¡ÂºÂºÃÂÃÂÃÂ¨Ã¡Â»Â³ÃÂÃ¡Â»Â©ÃÂÃÂÃÂÃ¡ÂºÂ¡Ã¡Â»Â·Ã¡Â»Â«Ã¡Â»Â­ÃÂÃÂÃ¡Â»Â¹Ã¡Â»ÂµÃÂÃ¡Â»Â¡ÃÂ°ÃÂ ÃÂ¡ÃÂ¢ÃÂ£Ã¡ÂºÂ£ÃÂÃ¡Â»Â¯Ã¡ÂºÂ«ÃÂ¨ÃÂ©ÃÂªÃ¡ÂºÂ»ÃÂ¬ÃÂ­ÃÂ©Ã¡Â»ÂÃÂÃ¡Â»Â±ÃÂ²ÃÂ³ÃÂ´ÃÂµÃ¡Â»ÂÃ¡Â»ÂÃ¡Â»Â¥ÃÂ¹ÃÂºÃÂ©Ã¡Â»Â§ÃÂ½Ã¡Â»Â£Ã¡Â»Â®"
  },
  "iso646cn": {
    "type": "_sbcs",
    "chars": "\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f !\"#ÃÂ¥%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}Ã¢ÂÂ¾Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "iso646jp": {
    "type": "_sbcs",
    "chars": "\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[ÃÂ¥]^_`abcdefghijklmnopqrstuvwxyz{|}Ã¢ÂÂ¾Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "hproman8": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ´ÃÂÃÂÃÂ¨ÃÂÃÂÃÂÃ¢ÂÂ¤ÃÂ¯ÃÂÃÂ½ÃÂ°ÃÂÃÂ§ÃÂÃÂ±ÃÂ¡ÃÂ¿ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂÃÂ¢ÃÂ¢ÃÂªÃÂ´ÃÂ»ÃÂ¡ÃÂ©ÃÂ³ÃÂºÃÂ ÃÂ¨ÃÂ²ÃÂ¹ÃÂ¤ÃÂ«ÃÂ¶ÃÂ¼ÃÂÃÂ®ÃÂÃÂÃÂ¥ÃÂ­ÃÂ¸ÃÂ¦ÃÂÃÂ¬ÃÂÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂÃÂ£ÃÂÃÂ°ÃÂÃÂÃÂÃÂÃÂÃÂµÃÂ ÃÂ¡ÃÂÃÂ¸ÃÂ¿ÃÂÃÂ¾ÃÂ·ÃÂµÃÂ¶ÃÂ¾Ã¢ÂÂÃÂ¼ÃÂ½ÃÂªÃÂºÃÂ«Ã¢ÂÂ ÃÂ»ÃÂ±Ã¯Â¿Â½"
  },
  "macintosh": {
    "type": "_sbcs",
    "chars": "ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂ¡ÃÂ ÃÂ¢ÃÂ¤ÃÂ£ÃÂ¥ÃÂ§ÃÂ©ÃÂ¨ÃÂªÃÂ«ÃÂ­ÃÂ¬ÃÂ®ÃÂ¯ÃÂ±ÃÂ³ÃÂ²ÃÂ´ÃÂ¶ÃÂµÃÂºÃÂ¹ÃÂ»ÃÂ¼Ã¢ÂÂ ÃÂ°ÃÂ¢ÃÂ£ÃÂ§Ã¢ÂÂ¢ÃÂ¶ÃÂÃÂ®ÃÂ©Ã¢ÂÂ¢ÃÂ´ÃÂ¨Ã¢ÂÂ ÃÂÃÂÃ¢ÂÂÃÂ±Ã¢ÂÂ¤Ã¢ÂÂ¥ÃÂ¥ÃÂµÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂÃ¢ÂÂ«ÃÂªÃÂºÃ¢ÂÂ¦ÃÂ¦ÃÂ¸ÃÂ¿ÃÂ¡ÃÂ¬Ã¢ÂÂÃÂÃ¢ÂÂÃ¢ÂÂÃÂ«ÃÂ»Ã¢ÂÂ¦ÃÂ ÃÂÃÂÃÂÃÂÃÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃ¢ÂÂÃÂ·Ã¢ÂÂÃÂ¿ÃÂ¸Ã¢ÂÂÃÂ¤Ã¢ÂÂ¹Ã¢ÂÂºÃ¯Â¬ÂÃ¯Â¬ÂÃ¢ÂÂ¡ÃÂ·Ã¢ÂÂÃ¢ÂÂÃ¢ÂÂ°ÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃÂÃ¯Â¿Â½ÃÂÃÂÃÂÃÂÃÂ±ÃÂÃÂÃÂ¯ÃÂÃÂÃÂÃÂ¸ÃÂÃÂÃÂ"
  },
  "ascii": {
    "type": "_sbcs",
    "chars": "Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  },
  "tis620": {
    "type": "_sbcs",
    "chars": "Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸ÂÃ Â¸Â Ã Â¸Â¡Ã Â¸Â¢Ã Â¸Â£Ã Â¸Â¤Ã Â¸Â¥Ã Â¸Â¦Ã Â¸Â§Ã Â¸Â¨Ã Â¸Â©Ã Â¸ÂªÃ Â¸Â«Ã Â¸Â¬Ã Â¸Â­Ã Â¸Â®Ã Â¸Â¯Ã Â¸Â°Ã Â¸Â±Ã Â¸Â²Ã Â¸Â³Ã Â¸Â´Ã Â¸ÂµÃ Â¸Â¶Ã Â¸Â·Ã Â¸Â¸Ã Â¸Â¹Ã Â¸ÂºÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã Â¸Â¿Ã Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ Â¹ÂÃ¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½Ã¯Â¿Â½"
  }
}