module.exports=[
["0","\u0000",127,"â¬"],
["8140","ä¸ä¸ä¸ä¸ä¸ä¸ä¸ä¸ä¸ ä¸¡ä¸£ä¸¦ä¸©ä¸®ä¸¯ä¸±ä¸³ä¸µä¸·ä¸¼ä¹ä¹ä¹ä¹ä¹ä¹ä¹ä¹ä¹ä¹ä¹ä¹¢ä¹£ä¹¤ä¹¥ä¹§ä¹¨ä¹ª",5,"ä¹²ä¹´",9,"ä¹¿",6,"äºäº"],
["8180","äºäºäºäºäºäºäºäº£äºªäº¯äº°äº±äº´äº¶äº·äº¸äº¹äº¼äº½äº¾ä»ä»ä»ä»ä»ä»ä»ä»ä» ä»¢ä»¦ä»§ä»©ä»­ä»®ä»¯ä»±ä»´ä»¸ä»¹ä»ºä»¼ä»¾ä¼ä¼",6,"ä¼ä¼ä¼",4,"ä¼ä¼ä¼¡ä¼£ä¼¨ä¼©ä¼¬ä¼­ä¼®ä¼±ä¼³ä¼µä¼·ä¼¹ä¼»ä¼¾",4,"ä½ä½ä½",5,"ä½ä½ä½ä½¡ä½¢ä½¦ä½¨ä½ªä½«ä½­ä½®ä½±ä½²ä½µä½·ä½¸ä½¹ä½ºä½½ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾¡ä¾¢"],
["8240","ä¾¤ä¾«ä¾­ä¾°",4,"ä¾¶",8,"ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿",4,"ä¿ä¿ä¿ ä¿¢ä¿¤ä¿¥ä¿§ä¿«ä¿¬ä¿°ä¿²ä¿´ä¿µä¿¶ä¿·ä¿¹ä¿»ä¿¼ä¿½ä¿¿",11],
["8280","åååååååååååå å¢å£å¤å§å«å¯",10,"å»å½å¿ååååååååååå",4,"åååååå",7,"å¦",5,"å­",8,"å¸å¹åºå¼å½ååååååååååå",20,"å¤å¦åªå«å­",4,"å³",6,"å¼"],
["8340","å½",17,"å",5,"åååå",10,"å¨å©åªå«å¯å°å±å²å´å¶",4,"å¼",9,"å"],
["8380","ååå",5,"å",13,"å¢",28,"åååååååååååååå",4,"å£å¤å¦å§å©åªå¯å²åºå¾å¿åååååååååååååååååå¡å£å¦",4,"å­å®å´å¸å¹åºå¾å¿åååååååååå",5],
["8440","ååååååå¢å£å¥",5,"å¬å®å±å²å´å·å¾åååååååååååååå¡å¢å£å¥å¦å§åªå¬å¯å±å²å´åµå¼å¾å",5,"åååååååå"],
["8480","åååååå å¢å£å¤å¦å¨å«å¬å­å®å°å±å³",9,"å¾åå",4,"å",6,"ååå",6,"åå¤å¥å¦å§å®å¯å°å´",9,"åååååååååååååååååå",5,"å å¡å¢å£å¥",10,"å±",7,"å»å¼å½åååååååååå"],
["8540","åååååååååå¢å¤å¥å§å¨å©å«å¬å­å¯",9,"å¼å½åååååååååååååå¥å¨åªå¬å­å²å¶å¹å»å¼å½å¾åååååååå"],
["8580","å",4,"ååååååå å¡å¤å§åªå«å¬å­å¯",6,"å·å¸å¹åºå¼å½å¾åå",4,"ååååååååååå¡å¢å§å´åºå¾å¿ååååååååååå¢å¤å¥åªå°å³å¶å·åºå½å¿ååååååååååååå",4,"å£å¥å§å©",7,"å´å¹åºå¾å¿åååååååååååååååå å¡"],
["8640","å¢å¥å®å°å²åµå¶å·å¹åºå¼å¾åååååååå ",4,"å«å¬å¯å°å±å´",5,"å»å¾ååååååå",4,"ååå",5,"ååååå¡å¥å¦"],
["8680","å¨å©å«å­å²å´åµå¶å¸å¹åºå»å½åååååå",4,"ååååå",4,"åååå å¢å£å¨å©å«å¯",5,"å¹åºå½å¿ååååååååååååååå ",6,"å¨",8,"å²å´å¶å¸åºå¼å¿",4,"åååååååååå",4,"åå å¢å§å©å­å®å°å±å´å¶å¸",4,"å¿åååå"],
["8740","åååååå",7,"ååååå å¡å¢å¥å¦å¨å©åªå«å®å¯å°å³åµå·å¸åºå¼å½å¾å",11,"å",4,"ååååå",4],
["8780","å£å¥å¦å§å­å®å¯å°å²å³å´åµå·å¸å¹åºå½",7,"å",6,"åååå",14,"å¤",10,"å°",6,"å¸å¹åºå»å½",12,"å",8,"åååååå£å¥",5,"å¬å®å¯å²å³å¶å·å¸å»å¼åååååå",6],
["8840","å",9,"ååå å¡å¢å¤å¥å¦å§å«å±å²å´",4,"å¼å½å¿ååååååååå",4,"ååå¢å£å¥å§å¬å®å°å±å²å´åµå¸å¹åºå½å¾å¿å"],
["8880","åååååå",4,"å",6,"ååååå¥å¨åªå¬å¯å°å±å³åµå¶å·å¹",8,"å",6,"ååååååååååå¡å¢å£å¥",7,"å®å°å±å²å³åµå¶å·å»å¼å¾å¿å å å å å å å å å å å å å å å å å å å å å å å ¢å £å ¥",4,"å «",4,"å ±å ²å ³å ´å ¶",7],
["8940","å ¾",5,"å¡",6,"å¡å¡å¡å¡å¡å¡å¡å¡å¡",4,"å¡",5,"å¡¦",4,"å¡­",16,"å¡¿å¢å¢å¢å¢å¢å¢å¢å¢"],
["8980","å¢",4,"å¢",4,"å¢å¢å¢å¢ ",7,"å¢ª",17,"å¢½å¢¾å¢¿å£å£å£å£å£",10,"å£å£å£å£",13,"å£¥",5,"å£­å£¯å£±å£²å£´å£µå£·å£¸å£º",7,"å¤å¤å¤å¤",4,"å¤å¤å¤å¤å¤å¤å¤å¤å¤å¤å¤ å¤¡å¤¢å¤£å¤¦å¤¨å¤¬å¤°å¤²å¤³å¤µå¤¶å¤»"],
["8a40","å¤½å¤¾å¤¿å¥å¥å¥å¥å¥å¥å¥å¥å¥å¥å¥å¥",4,"å¥¡å¥£å¥¤å¥¦",12,"å¥µå¥·å¥ºå¥»å¥¼å¥¾å¥¿å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦å¦ å¦¡å¦¢å¦¦"],
["8a80","å¦§å¦¬å¦­å¦°å¦±å¦³",5,"å¦ºå¦¼å¦½å¦¿",6,"å§å§å§å§å§å§å§å§å§å§å§å§",4,"å§¤å§¦å§§å§©å§ªå§«å§­",11,"å§ºå§¼å§½å§¾å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨å¨¡å¨¢å¨¤å¨¦å¨§å¨¨å¨ª",6,"å¨³å¨µå¨·",4,"å¨½å¨¾å¨¿å©",4,"å©å©å©",9,"å©å©å©å©å©",5],
["8b40","å©¡å©£å©¤å©¥å©¦å©¨å©©å©«",8,"å©¸å©¹å©»å©¼å©½å©¾åª",17,"åª",6,"åª",13,"åª«åª¬"],
["8b80","åª­",4,"åª´åª¶åª·åª¹",4,"åª¿å«å«",5,"å«å«å«",4,"å«å«å«å«å«å«å«å«å«å«¢å«¤å«¥å«§å«¨å«ªå«¬",4,"å«²",22,"å¬",11,"å¬",25,"å¬³å¬µå¬¶å¬¸",7,"å­",6],
["8c40","å­",7,"å­å­å­å­ å­¡å­§å­¨å­«å­­å­®å­¯å­²å­´å­¶å­·å­¸å­¹å­»å­¼å­¾å­¿å®å®å®å®å®å®å®å®å®å®å®å®§å®¨å®©å®¬å®­å®®å®¯å®±å®²å®·å®ºå®»å®¼å¯å¯å¯å¯å¯å¯å¯å¯å¯å¯"],
["8c80","å¯å¯",8,"å¯ å¯¢å¯£å¯¦å¯§å¯©",4,"å¯¯å¯±",6,"å¯½å¯¾å°å°å°å°å°å°å°å°å°å°å°å°å°å°å°å°å°å°å° å°¡å°£å°¦å°¨å°©å°ªå°«å°­å°®å°¯å°°å°²å°³å°µå°¶å°·å±å±å±å±å±å±å±å±å±å±å±å±å±å±å±å±å±å±¢å±¤å±§",6,"å±°å±²",6,"å±»å±¼å±½å±¾å²å²",4,"å²å²å²å²å²å²å²å²å²",4,"å²¤",4],
["8d40","å²ªå²®å²¯å²°å²²å²´å²¶å²¹å²ºå²»å²¼å²¾å³å³å³å³",5,"å³",5,"å³",5,"å³",6,"å³¢å³£å³§å³©å³«å³¬å³®å³¯å³±",9,"å³¼",4],
["8d80","å´å´å´å´",5,"å´",4,"å´å´å´å´å´å´å´å´",4,"å´¥å´¨å´ªå´«å´¬å´¯",4,"å´µ",7,"å´¿",7,"åµåµåµ",10,"åµåµåµåµ",10,"åµªåµ­åµ®åµ°åµ±åµ²åµ³åµµ",12,"å¶",21,"å¶å¶å¶å¶å¶å¶ "],
["8e40","å¶¡",21,"å¶¸",12,"å·",6,"å·",12,"å·å·å· å·£å·¤å·ªå·¬å·­"],
["8e80","å·°å·µå·¶å·¸",4,"å·¿å¸å¸å¸å¸å¸å¸å¸å¸å¸å¸å¸å¸",7,"å¸¨",4,"å¸¯å¸°å¸²",4,"å¸¹å¸ºå¸¾å¸¿å¹å¹å¹å¹",5,"å¹",6,"å¹",4,"å¹å¹å¹å¹ å¹£",14,"å¹µå¹·å¹¹å¹¾åºåºåºåºåºåºåºåºåºåºåºåºåºåº¡åº¢åº£åº¤åº¨",4,"åº®",4,"åº´åººåº»åº¼åº½åº¿",6],
["8f40","å»å»å»å»",5,"å»å»å»å»å»å»å»",11,"å»©å»«",8,"å»µå»¸å»¹å»»å»¼å»½å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼å¼¡å¼¢å¼£å¼¤"],
["8f80","å¼¨å¼«å¼¬å¼®å¼°å¼²",6,"å¼»å¼½å¼¾å¼¿å½",14,"å½å½å½å½å½å½å½å½å½ å½£å½¥å½§å½¨å½«å½®å½¯å½²å½´å½µå½¶å½¸å½ºå½½å½¾å½¿å¾å¾å¾å¾å¾å¾å¾å¾å¾å¾å¾å¾å¾å¾å¾ å¾¢",5,"å¾©å¾«å¾¬å¾¯",5,"å¾¶å¾¸å¾¹å¾ºå¾»å¾¾",4,"å¿å¿å¿å¿å¿å¿å¿å¿å¿å¿å¿å¿å¿å¿¢å¿£å¿¥å¿¦å¿¨å¿©å¿¬å¿¯å¿°å¿²å¿³å¿´å¿¶å¿·å¿¹å¿ºå¿¼æ"],
["9040","æææææææææææææ¢æ£æ¤æ¬æ­æ®æ°",4,"æ¶",4,"æ½æ¾ææ",6,"ææææææææææææææ æ¡æ¥æ¦æ®æ±æ²æ´æµæ·æ¾æ"],
["9080","ææææææææææææææææææææ¡æ¢æ¤æ¥æ§æ©æªæ®æ°æ³æµæ¶æ·æ¹æºæ½",7,"ææææ",4,"æææææææææ¡",4,"æªæ±æ²æµæ·æ¸æ»",4,"æææææææææ",4,"æææææææææ¡æ¢æ¥æ¨æ©æªæ¬",18,"æ",6],
["9140","ææææææææææ",6,"æææ æ¡æ£æ¤æ¥æ¦æ©",6,"æ±æ²æ³æ´æ¶æ¸",18,"æææ",4,"æ"],
["9180","æ",6,"æ",8,"æªæ«æ­",9,"æ¸",5,"æ¿æææ",4,"ææ",4,"ææ",16,"æ§",13,"æ¶",8,"æ",5,"æææææææææ æ£æ¦æ§æ¨æ©æ«æ­æ¯æ°æ±æ²æµæ¶æ¸",4,"æææææ"],
["9240","ææææææææ",6,"æ¤æ¥æ¨æ±æ²æ´æµæ·æ¸æºæ»æ½ææææææææ",5,"ææææææ£æ¦æ§æ©æªæ­æ®æ¯æ°æ²æ³æ´æ¶æ·æ¸æºæ¾ææ"],
["9280","ææææææææ æ¡æ¤æªæ«æ°æ²æµæ¸æ¹æºæ»ææææææææææææææææææææ¦æ§æ©æ¬æ­æ®æ°æ±æ³",5,"æ»æ¼æ¾æ¿æææææææææææ",7,"æ æ¤æ¥æ¦æ¨æªæ«æ¬æ¯æ°æ²æ³æ´æµæ¸æ¹æ¼æ½æ¾æ¿æææææææææææææ",6,"æ¡æ¤æ¦æ«æ¯æ±æ²æµæ¶æ¹æ»æ½æ¿æ"],
["9340","ææææææææææææææ",6,"ææ¢æ¤",4,"æ«æ¬æ®æ¯æ°æ±æ³æµæ·æ¹æºæ»æ¼æ¾æææ",4,"æææææ",5,"æææ¢æ£æ¤"],
["9380","æ¥æ§æ¨æ©æ«æ®",5,"æµ",4,"æ»æ¼æ¾æææææ",6,"æææææ",4,"æ",7,"æ¨æªæ«æ¬æ®",9,"æ»",6,"æææ",8,"æææææææææ",4,"æ¥æ¦æ§æ¨æªæ«æ¯æ±æ²æ³æ´æ¶æ¹æ»æ½æ¾æ¿ææææ",6,"ææææææææ"],
["9440","æææææ æ¡æ£æ¥æ§",24,"æ",7,"æ",7,"æ",4,"æ",8],
["9480","æ¢æ£æ¤æ¦",4,"æ¬æ­æ°æ±æ²æ³æ·æºæ¼æ½æ",4,"ææææææææææææææææ æ¡æ¤æ¥æ§æ¨æ©æªæ­æ®æ¯æ±æ³æµæ¶æ¸",14,"æææææææææææææææ æ¢æ£æ¦æ¨æªæ¬æ®æ±",7,"æºæ»æ¾æ¿æææææææææææææ",7,"æ¡æ£æ¤æªæ«"],
["9540","æ²æ³æ´æµæ¸æ¹æ»",4,"æææææææææææææææææææ¡æ¢æ£æ¤æ¦æ©æªæ«æ¬æ®æ°æ²æ³æ·",4,"æ½æ¿æææ",6,"æææææ"],
["9580","ææææææ æ¢æ£æ¥æ§æ©",4,"æ±æ²æ³æµæ¸æ¹æ»æ¼æ½æ¿ææææææææææææææææææ",4,"æ",8,"æ©",4,"æ¯",4,"æµæ¶æ·æ¸æºæ»æ¼æ½æ¿",25,"ææ",7,"æ§æ¨æª",5,"æ±æµæ¶æ¸æºæ»æ½æææ"],
["9640","æææææææææææææææææ ",5,"æ§æ©æ®æ°æ²æ³æ¶æ·æ¸æ¹æ»æ¼æ¾æ¿æææææææææææ",4,"ææ¢æ£æ¤æ¦æ§æ«æ¬æ®æ±æ´æ¶"],
["9680","æ¸æ¹æºæ»æ½ææææææææææææææææææææ æ¡æ¤æ¦æ©æ¬æ®æ±æ²æ´æ¹",7,"ææ",9,"ææææææ¡æ£æ¤æ¦æ§æ¨æªæ«æ­æ®æ²æµ",7,"æ¾æ æ æ æ æ æ æ æ æ æ æ ",4,"æ æ æ  æ ¢",6,"æ «",6,"æ ´æ µæ ¶æ ºæ »æ ¿æ¡æ¡æ¡æ¡æ¡æ¡",5],
["9740","æ¡æ¡æ¡æ¡æ¡ªæ¡¬",7,"æ¡µæ¡¸",8,"æ¢æ¢æ¢",7,"æ¢æ¢æ¢æ¢æ¢æ¢æ¢",9,"æ¢£æ¢¤æ¢¥æ¢©æ¢ªæ¢«æ¢¬æ¢®æ¢±æ¢²æ¢´æ¢¶æ¢·æ¢¸"],
["9780","æ¢¹",6,"æ£æ£",5,"æ£æ£æ£æ£æ£æ£æ£æ£æ£æ£æ£æ£",4,"æ£¡æ£¢æ£¤",9,"æ£¯æ£²æ£³æ£´æ£¶æ£·æ£¸æ£»æ£½æ£¾æ£¿æ¤æ¤æ¤æ¤æ¤",4,"æ¤æ¤æ¤æ¤",11,"æ¤¡æ¤¢æ¤£æ¤¥",7,"æ¤®æ¤¯æ¤±æ¤²æ¤³æ¤µæ¤¶æ¤·æ¤¸æ¤ºæ¤»æ¤¼æ¤¾æ¥æ¥æ¥",16,"æ¥æ¥æ¥æ¥æ¥æ¥æ¥"],
["9840","æ¥¡æ¥¢æ¥¤æ¥¥æ¥§æ¥¨æ¥©æ¥ªæ¥¬æ¥­æ¥¯æ¥°æ¥²",4,"æ¥ºæ¥»æ¥½æ¥¾æ¥¿æ¦æ¦æ¦æ¦æ¦æ¦æ¦",5,"æ¦æ¦æ¦æ¦æ¦",9,"æ¦©æ¦ªæ¦¬æ¦®æ¦¯æ¦°æ¦²æ¦³æ¦µæ¦¶æ¦¸æ¦¹æ¦ºæ¦¼æ¦½"],
["9880","æ¦¾æ¦¿æ§æ§",7,"æ§æ§æ§æ§æ§æ§æ§",5,"æ§æ§æ§æ§¡",11,"æ§®æ§¯æ§°æ§±æ§³",9,"æ§¾æ¨",9,"æ¨",11,"æ¨",5,"æ¨ æ¨¢",5,"æ¨©æ¨«æ¨¬æ¨­æ¨®æ¨°æ¨²æ¨³æ¨´æ¨¶",6,"æ¨¿",4,"æ©æ©æ©",7,"æ©",6,"æ©"],
["9940","æ©",4,"æ©¢æ©£æ©¤æ©¦",10,"æ©²",6,"æ©ºæ©»æ©½æ©¾æ©¿æªæªæªæª",8,"æªæª",4,"æª",7,"æª¡",5],
["9980","æª§æª¨æªªæª­",114,"æ¬¥æ¬¦æ¬¨",6],
["9a40","æ¬¯æ¬°æ¬±æ¬³æ¬´æ¬µæ¬¶æ¬¸æ¬»æ¬¼æ¬½æ¬¿æ­æ­æ­æ­æ­æ­æ­æ­æ­",11,"æ­",7,"æ­¨æ­©æ­«",13,"æ­ºæ­½æ­¾æ­¿æ®æ®æ®"],
["9a80","æ®æ®æ®æ®æ®æ®æ®æ®æ®æ®æ®",4,"æ®¢",7,"æ®«",7,"æ®¶æ®¸",6,"æ¯æ¯æ¯æ¯",4,"æ¯æ¯æ¯æ¯æ¯æ¯æ¯",4,"æ¯¢",7,"æ¯¬æ¯­æ¯®æ¯°æ¯±æ¯²æ¯´æ¯¶æ¯·æ¯¸æ¯ºæ¯»æ¯¼æ¯¾",6,"æ°",4,"æ°æ°æ°æ°æ°æ°æ° æ°£æ°¥æ°«æ°¬æ°­æ°±æ°³æ°¶æ°·æ°¹æ°ºæ°»æ°¼æ°¾æ°¿æ±æ±æ±æ±æ±",4,"æ±æ±æ±æ±æ±"],
["9b40","æ±æ±æ±¢æ±£æ±¥æ±¦æ±§æ±«",4,"æ±±æ±³æ±µæ±·æ±¸æ±ºæ±»æ±¼æ±¿æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ² æ²¢æ²¨æ²¬æ²¯æ²°æ²´æ²µæ²¶æ²·æ²ºæ³æ³æ³æ³æ³æ³æ³æ³æ³æ³æ³æ³æ³æ³"],
["9b80","æ³æ³æ³æ³æ³æ³¤æ³¦æ³§æ³©æ³¬æ³­æ³²æ³´æ³¹æ³¿æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´æ´",5,"æ´¦æ´¨æ´©æ´¬æ´­æ´¯æ´°æ´´æ´¶æ´·æ´¸æ´ºæ´¿æµæµæµæµæµæµæµæµæµæµæµæµæµæµ¡æµ¢æµ¤æµ¥æµ§æµ¨æµ«æµ¬æµ­æµ°æµ±æµ²æµ³æµµæµ¶æµ¹æµºæµ»æµ½",4,"æ¶æ¶æ¶æ¶æ¶æ¶æ¶æ¶æ¶æ¶æ¶",4,"æ¶æ¶¢æ¶¥æ¶¬æ¶­æ¶°æ¶±æ¶³æ¶´æ¶¶æ¶·æ¶¹",5,"æ·æ·æ·æ·æ·æ·"],
["9c40","æ·æ·æ·æ·æ·æ·æ·æ·æ·æ·æ·æ·æ·æ·¢æ·£æ·¥æ·§æ·¨æ·©æ·ªæ·­æ·¯æ·°æ·²æ·´æ·µæ·¶æ·¸æ·ºæ·½",7,"æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸æ¸¢æ¸¦æ¸§æ¸¨æ¸ªæ¸¬æ¸®æ¸°æ¸±æ¸³æ¸µ"],
["9c80","æ¸¶æ¸·æ¸¹æ¸»",7,"æ¹",7,"æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹ ",10,"æ¹¬æ¹­æ¹¯",14,"æºæºæºæºæºæºæº",4,"æº",6,"æºæºæºæºæºæº æº¡æº£æº¤æº¦æº¨æº©æº«æº¬æº­æº®æº°æº³æºµæº¸æº¹æº¼æº¾æº¿æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»£æ»§æ»ª",5],
["9d40","æ»°æ»±æ»²æ»³æ»µæ»¶æ»·æ»¸æ»º",7,"æ¼æ¼æ¼æ¼æ¼æ¼",4,"æ¼æ¼æ¼æ¼",9,"æ¼¡æ¼¢æ¼£æ¼¥æ¼¦æ¼§æ¼¨æ¼¬æ¼®æ¼°æ¼²æ¼´æ¼µæ¼·",6,"æ¼¿æ½æ½æ½"],
["9d80","æ½æ½æ½æ½æ½æ½æ½æ½",9,"æ½æ½æ½æ½æ½æ½ æ½¡æ½£æ½¤æ½¥æ½§",5,"æ½¯æ½°æ½±æ½³æ½µæ½¶æ½·æ½¹æ½»æ½½",6,"æ¾æ¾æ¾æ¾æ¾æ¾",12,"æ¾æ¾æ¾æ¾ æ¾¢",4,"æ¾¨",10,"æ¾´æ¾µæ¾·æ¾¸æ¾º",5,"æ¿æ¿",5,"æ¿",6,"æ¿",10,"æ¿æ¿¢æ¿£æ¿¤æ¿¥"],
["9e40","æ¿¦",7,"æ¿°",32,"ç",7,"ç",6,"ç¤",6],
["9e80","ç«",9,"ç¶ç·ç¸çº",17,"ççç",13,"ç",11,"ç®ç±ç²ç³ç´ç·ç¹çºç»ç½ççççççççççççççççççç",12,"ç°ç²ç´çµç¶çºç¾ç¿çççççç",12,"ç"],
["9f40","çççç ç¡ç¢ç£ç¥çªç®ç°",6,"ç¸çºç»ç¼ç¾",10,"ç",4,"ççççç",10,"ç§",7,"ç²ç³ç´"],
["9f80","çµç·",13,"ççççççç",12,"çç",4,"ç¥ç©",4,"ç¯ç°ç±ç´çµç¶ç·ç¹ç»ç¼ç¾",5,"ç",4,"çççççççççççç",4,"ç¡",6,"ç©çªç«ç­",5,"ç´ç¶ç·ç¸çº",8,"ç",9,"ç",4],
["a040","ç",9,"ç¡ç¢ç£ç¤ç¦ç¨",5,"ç¯",9,"çº",11,"ç",19],
["a080","ççç",9,"ç©ç«ç­ç®ç¯ç²ç³ç´çºç¼ç¾ç",6,"çççççççççççççççç ç£ç¤ç¥ç¨çªç«ç¬ç­ç°ç±ç³ç´ç¶ç·ç¸ç»ç¼ç½ççç",4,"ççççç",11,"ç ",11,"ç®ç±ç²ç³çµçº",6,"çççççççççççççççç"],
["a1a1","ãããÂ·ËËÂ¨ããâï½ââ¦ââââããã",7,"ããããÂ±ÃÃ·â¶â§â¨âââªâ©ââ·ââ¥â¥â âââ«â®â¡âââ½ââ â®â¯â¤â¥ââµâ´ââÂ°â²â³âï¼Â¤ï¿ ï¿¡â°Â§âââââââââ¡â â³â²â»ââââã"],
["a2a1","â°",9],
["a2b1","â",19,"â´",19,"â ",9],
["a2e5","ã ",9],
["a2f1","â ",11],
["a3a1","ï¼ï¼ï¼ï¿¥ï¼",88,"ï¿£"],
["a4a1","ã",82],
["a5a1","ã¡",85],
["a6a1","Î",16,"Î£",6],
["a6c1","Î±",16,"Ï",6],
["a6e0","ï¸µï¸¶ï¸¹ï¸ºï¸¿ï¹ï¸½ï¸¾ï¹ï¹ï¹ï¹"],
["a6ee","ï¸»ï¸¼ï¸·ï¸¸ï¸±"],
["a6f4","ï¸³ï¸´"],
["a7a1","Ð",5,"ÐÐ",25],
["a7d1","Ð°",5,"ÑÐ¶",25],
["a840","ËËËâââ¥âµâââââââââ£ââ¦â§â¿â",35,"â",6],
["a880","â",7,"ââââ¼â½â¢â£â¤â¥ââããã"],
["a8a1","ÄÃ¡ÇÃ ÄÃ©ÄÃ¨Ä«Ã­ÇÃ¬ÅÃ³ÇÃ²Å«ÃºÇÃ¹ÇÇÇÇÃ¼ÃªÉ"],
["a8bd","ÅÅ"],
["a8c0","É¡"],
["a8c5","ã",36],
["a940","ã¡",8,"ã£ãããããã¡ãããããï¸°ï¿¢ï¿¤"],
["a959","â¡ã±"],
["a95c","â"],
["a960","ã¼ããã½ã¾ãããï¹",9,"ï¹ï¹ï¹ï¹ï¹",8],
["a980","ï¹¢",4,"ï¹¨ï¹©ï¹ªï¹«"],
["a996","ã"],
["a9a4","â",75],
["aa40","çççç¢",5,"çªç«çµç¶ç¹ç½ç¾ç¿ççç",5,"ççççççççççççç ç£ç¤ç¦ç§ç¨ç­ç¯ç°ç²ç³çµç¶çºç»ç¼ç½ç",8],
["aa80","çççççççççççç",7,"ç¡",10,"ç®ç°ç±"],
["ab40","ç²",11,"ç¿",4,"çççççççççççççççççççç ç¡ç£",5,"çªç¬ç­ç±ç´çµç¶ç¸ç¹ç¼ç½ç¾ç¿çç",4],
["ab80","çççç",6,"çççççç¡ç¢ç£ç¤ç¦ç¨çªç«ç¬ç®ç¯ç°ç±ç³",4],
["ac40","ç¸",10,"çççççççç",8,"ç",5,"ç£ç¤ç§ç©ç«ç­ç¯ç±ç²ç·",4,"ç½ç¾ç¿çç",11],
["ac80","ç",6,"çççç ",12,"ç®ç¯ç±",4,"ç¸ç¹çº"],
["ad40","ç»ç¼ç½ç¿ççççççççççç",10,"çç",7,"çª",15,"ç»",12],
["ad80","ç",9,"ç",8,"ççç¡ç¥ç§",6,"ç°ç±ç²"],
["ae40","ç³çµç¸",6,"ççççç",7,"ççççççççççç ",4,"ç¦ç§çªç®ç´ç¶ç¹ç¼ç½ç¿ççççççççççççççççç"],
["ae80","ç",7,"ç§ç¨ç©ç«",6,"ç³çµç¶ç·çº",4,"çççççç"],
["af40","çççççççççççççç¢ç¦",4,"ç­ç¶ç·çºç»ç¿ççççççççççççççççç ç¡ç¥ç©ç¬ç­ç®ç¯ç²ç³çµç¶ç·ç¸çºç»ç½ç¾çççç"],
["af80","çççççççççççççççç¡ç£ç§ç¨ç¬ç®ç¯ç±ç²ç¶ç·ç¹çºç»ç½ççç"],
["b040","ç",6,"ç",5,"çç",4,"ççç ç¡ç¢ç¤",6,"ç¬ç­ç®ç°",7,"ç¹çºç¼ç¿ççççççççççççççççç"],
["b080","ç",7,"ç¥",8,"ç¯ç°ç³çµ",9,"çççåé¿åæ¨åååççè¼ç®è¾ç¢ç±ééæ°¨å®ä¿ºææå²¸èºæ¡è®æçå¹æç¬ç¿±è¢å²å¥¥ææ¾³è­ææå­å§ç¬å«ç¤å·´æè·é¶æèåé¸ç½¢ç¸ç½æç¾æä½°è´¥æç¨æç­æ¬æ³è¬é¢æ¿çæ®æä¼´ç£ååç»é¦å¸®æ¢æ¦èç»æ£ç£èéåè°¤èèåè¤å¥"],
["b140","ççççççççççççç ",4,"ç¦",7,"ç°ç³çµç¶ç·çºç»ç½ç¿çççççççç",10,"ççççç¡ç£ç¤ç¥ç§çªç«"],
["b180","ç¬ç®ç°",4,"ç¹ç»ç½ç¾ç¿ççççç",7,"ç",7,"çèé¹ä¿å ¡é¥±å®æ±æ¥æ´è±¹é²çæ¯ç¢æ²ååè¾èè´é¡åçå¤æ«çè¢«å¥è¯æ¬ç¬¨å´©ç»·ç­æ³µè¹¦è¿¸é¼é¼»æ¯éç¬å½¼ç¢§èè½æ¯æ¯æ¯å¸åºç¹é­æå¼å¿è¾å£èé¿éé­è¾¹ç¼è´¬æä¾¿ååè¾¨è¾©è¾«éæ å½ªèè¡¨é³æå«çªå½¬ææ¿æ»¨å®¾æåµå°æä¸ç§é¥¼ç³"],
["b240","çççç ç¤ç§ç©çªç­",11,"çºç»ç¼çççç",5,"ççç",11,"ç¡ç£ç¤ç¦ç¨ç«ç­ç®ç¯ç±ç²ç´ç¶",4],
["b280","ç¼ç¾ç",12,"ç",8,"çççç",4,"ç¤çå¹¶ç»è æ­æ¨éµæ³¢ååæéç®ä¼¯å¸è¶èèæ¸¤æ³é©³æååºè¡¥å ä¸å¸æ­¥ç°¿é¨ææ¦çè£ææè´¢ç¬è¸©éå½©èè¡é¤åèæ®æ­æ¨ç¿èè±ä»æ²§èæç³æ§½æ¹èåç­ä¾§åæµå±è¹­æåè¬è¶æ¥ç¢´æ½å¯å²å·®è¯§ææ´è±ºææºèé¦è°ç¼ é²äº§éé¢¤æç"],
["b340","ç¦ç¨çªç¯ç°ç±ç²ç´çµç·ç¹çºç»ç¼ç ",5,"ç ç ç ç ç ç ç ç ç ç ç  ç ¡ç ¢ç ¤ç ¨ç ªç «ç ®ç ¯ç ±ç ²ç ³ç µç ¶ç ½ç ¿ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡"],
["b380","ç¡ç¡ç¡",11,"ç¡¯",7,"ç¡¸ç¡¹ç¡ºç¡»ç¡½",6,"åºå°å¸¸é¿å¿è åæçå±å¡è¶æéæå²æ½®å·¢åµçè½¦æ¯æ¤æ£å½»æ¾é´è£è¾°å°æ¨å¿±æ²éè¶è¡¬æç§°åæ©æåä¹ç¨æ©æ¾è¯æ¿ééªç§¤åç´æåæ± è¿å¼é©°è»é½¿ä¾å°ºèµ¤ç¿æ¥ç½åå²è«å´å® æ½é¬ç´è¸ç¨ æç­¹ä»ç»¸çä¸è­ååºæ©±å¨èºééæ»é¤æ¥"],
["b440","ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ ç¢¢ç¢¤ç¢¦ç¢¨",7,"ç¢µç¢¶ç¢·ç¢¸ç¢ºç¢»ç¢¼ç¢½ç¢¿ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£",9],
["b480","ç£¤ç£¥ç£¦ç£§ç£©ç£ªç£«ç£­",4,"ç£³ç£µç£¶ç£¸ç£¹ç£»",5,"ç¤ç¤ç¤ç¤",6,"ç¡å¨çæè§¦å¤æ£å·ç©¿æ¤½ä¼ è¹åä¸²ç®çªå¹¢åºé¯åå¹çæ¶é¤åæ¥æ¤¿éåæ·³çº¯è ¢æ³ç»°çµè¨ç£éè¾æç·è¯æ­¤åºèµæ¬¡èªè±å±åä»ä¸åç²éç°ä¿è¹¿ç¯¡çªæ§å´å¬èçç²¹æ·¬ç¿ æå­å¯¸ç£æ®ææªæ«éæ­è¾¾ç­ç©æå¤§åæ­¹å£æ´å¸¦æ®ä»£è´·è¢å¾é®"],
["b540","ç¤",5,"ç¤",9,"ç¤",4,"ç¤¥",14,"ç¤µ",4,"ç¤½ç¤¿ç¥ç¥ç¥ç¥ç¥ç¥",8,"ç¥ç¥ç¥ç¥ç¥¡ç¥£"],
["b580","ç¥¤ç¥¦ç¥©ç¥ªç¥«ç¥¬ç¥®ç¥°",6,"ç¥¹ç¥»",4,"ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦æ è½æä¸¹åé¸æ¸èæ¦æ°®ä½æ®æ·¡è¯å¼¹èå½æ¡åè¡æ¡£åæ£è¹åå²ç¥·å¯¼å°ç¨»æ¼éçå¾·å¾çè¹¬ç¯ç»ç­çªå³éå ¤ä½æ»´è¿ªæç¬çæ¶¤ç¿å«¡æµåºå°èç¬¬å¸å¼éç¼é¢ ææ»ç¢ç¹å¸éå«çµä½ç¸åºæ¦å¥ æ·æ®¿ç¢å¼éååæåéè°è·ç¹ç¢è¶è¿­è°å "],
["b640","ç¦",6,"ç¦",11,"ç¦¨",10,"ç¦´",4,"ç¦¼ç¦¿ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§",5,"ç§ ç§¡ç§¢ç§¥ç§¨ç§ª"],
["b680","ç§¬ç§®ç§±",6,"ç§¹ç§ºç§¼ç§¾ç§¿ç¨ç¨ç¨ç¨ç¨ç¨ç¨ç¨ç¨",4,"ç¨ç¨ç¨ç¨ç¨ç¨ä¸ç¯å®éé¡¶é¼é­å®è®¢ä¸¢ä¸å¬è£æå¨æ ä¾æ«å»æ´åææé¡è±éçé½ç£æ¯çç¬è¯»å µç¹èµæéèåº¦æ¸¡å¦ç«¯ç­é»æ®µæ­ç¼å åéå¯¹å¢©å¨è¹²æ¦é¡¿å¤éç¾éæåå¤å¤ºåèº²æµè·ºèµåæ°å è¾å³¨é¹ä¿é¢è®¹å¨¥æ¶åæ¼ééé¥¿æ©èå¿è³å°é¥µæ´±äº"],
["b740","ç¨ç¨ç¨¡ç¨¢ç¨¤",14,"ç¨´ç¨µç¨¶ç¨¸ç¨ºç¨¾ç©",5,"ç©",9,"ç©",4,"ç©",16],
["b780","ç©©",6,"ç©±ç©²ç©³ç©µç©»ç©¼ç©½ç©¾çªçªçªçªçªçªçªçªçªçªçªçªçªçªçªçªçª¡çª¢è´°åç½ç­ä¼ä¹éæ³çè©å¸çªç¿»æ¨ç¾éç¹å¡ç¦åè¿èè´©ç¯é¥­æ³åè³æ¹èªæ¿é²å¦¨ä»¿è®¿çººæ¾è²éå¡é£è¥åªè¯½å èºåºæ²¸è´¹è¬éå©æ°åçº·åçæ±¾ç²å¥ä»½å¿¿æ¤ç²ªä¸°å°æ«èå³°éé£ç¯ç½é¢å¯ç¼è®½å¥å¤ä½å¦å¤«æ·è¤å­µæ¶æè¾å¹æ°ç¬¦ä¼ä¿æ"],
["b840","çª£çª¤çª§çª©çªªçª«çª®",4,"çª´",10,"ç«",10,"ç«",9,"ç«ç«ç«ç«ç«ç«ç«¡ç«¢ç«¤ç«§",5,"ç«®ç«°ç«±ç«²ç«³"],
["b880","ç«´",4,"ç«»ç«¼ç«¾ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬¡ç¬¢ç¬£ç¬§ç¬©ç¬­æµ®æ¶ªç¦è¢±å¼ç«æè¾ä¿¯éæ§è¯èåºèèµ´å¯è¦èµå¤åä»éç¶è¹è´å¯è®£éå¦ç¼åå¶åè¯¥æ¹æ¦éçæºå¹²çææç«¿èèµ¶æç§æ¢èµ£ååé¢ç¼¸èçº²å²æ¸¯æ ç¯çé«èç¾ç³æéç¨¿åå¥æ­ææé¸½è³çå²é©èæ ¼è¤ééé¬ä¸ªåç»æ ¹è·èæ´åºç¾¹"],
["b940","ç¬¯ç¬°ç¬²ç¬´ç¬µç¬¶ç¬·ç¬¹ç¬»ç¬½ç¬¿",5,"ç­ç­ç­ç­ç­ç­ç­ç­ç­ç­ç­ç­ç­¡ç­£",10,"ç­¯ç­°ç­³ç­´ç­¶ç­¸ç­ºç­¼ç­½ç­¿ç®ç®ç®ç®ç®",6,"ç®ç®"],
["b980","ç®ç®ç®ç®ç®ç®ç®ç®ç®ç®ç® ç®£ç®¤ç®¥ç®®ç®¯ç®°ç®²ç®³ç®µç®¶ç®·ç®¹",7,"ç¯ç¯ç¯åè¿æ¢å·¥æ»åæ­é¾ä¾èº¬å¬å®«å¼å·©æ±æ±è´¡å±é©å¾æ²èçå¢æè´­å¤è¾èåç®ä¼°æ²½å­¤å§é¼å¤èéª¨è°·è¡æé¡¾åºéå®çåå¯¡æè¤ä¹ææªæ£ºå³å®å è§ç®¡é¦ç½æ¯çè´¯åå¹¿éç°è§å­ç¡å½é¾éºè½¨é¬¼è¯¡ç¸æ¡æè·ªè´µå½è¾æ»æ£éé­å½æè£¹è¿å"],
["ba40","ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯",4,"ç¯ç¯ç¯ç¯ç¯ ç¯¢ç¯£ç¯¤ç¯§ç¯¨ç¯©ç¯«ç¯¬ç¯­ç¯¯ç¯°ç¯²",4,"ç¯¸ç¯¹ç¯ºç¯»ç¯½ç¯¿",7,"ç°ç°ç°ç°ç°ç°",5,"ç°ç°ç°"],
["ba80","ç°",4,"ç° ",5,"ç°¨ç°©ç°«",12,"ç°¹",5,"ç±éª¸å­©æµ·æ°¦äº¥å®³éªé£æ¨é¯é©å«æ¶µå¯å½åç½ç¿°æ¼ææ±æ¾æçæ±æ±å¤¯æ­èªå£åè±ªæ¯«éå¥½èå·æµ©åµåè·èæ ¸ç¦¾åä½åçè²éæ²³æ¶¸èµ«è¤é¹¤è´ºå¿é»çå¾ç æ¨å¼äº¨æ¨ªè¡¡æè½°åçè¹é¸¿æ´ªå®å¼çº¢åä¾¯ç´å¼åååå¼ä¹å¿½çå£¶è«è¡è´çç³æ¹"],
["bb40","ç±",9,"ç±",36,"ç±µ",5,"ç±¾",9],
["bb80","ç²ç²",6,"ç²ç²ç²ç²ç²ç²ç² ç²¡ç²£ç²¦ç²§ç²¨ç²©ç²«ç²¬ç²­ç²¯ç²°ç²´",4,"ç²ºç²»å¼§èå¬æ¤äºæ²ªæ·è±ååç¾æ»ç»ååè¯æ§å¾ææ·®åæ¬¢ç¯æ¡è¿ç¼æ¢æ£å¤çªè±¢çæ¶£å®¦å¹»èæé»ç£ºèç°§çå°æ¶çæå¹æè°ç°æ¥è¾å¾½æ¢èåæ¯ææ§åæ æ¦è´¿ç§½ä¼ç©æ±è®³è¯²ç»è¤æå©é­æµæ··è±æ´»ä¼ç«è·ææéè´§ç¥¸å»å¾åºæºç¸ç¨½ç§¯ç®"],
["bc40","ç²¿ç³ç³ç³ç³ç³ç³ç³ç³",6,"ç³ç³ç³ç³ç³ç³¡",6,"ç³©",5,"ç³°",7,"ç³¹ç³ºç³¼",13,"ç´",5],
["bc80","ç´",14,"ç´¡ç´£ç´¤ç´¥ç´¦ç´¨ç´©ç´ªç´¬ç´­ç´®ç´°",6,"èé¥¥è¿¹æ¿è®¥é¸¡å§¬ç»©ç¼åææ£è¾ç±éåæ¥ç¾æ±²å³å«çº§æ¤å èå·±èæåå­£ä¼ç¥­åæ¸æµå¯å¯è®¡è®°æ¢å¿éå¦ç»§çºªåæ·å¤¹ä½³å®¶å èé¢è´¾ç²é¾åç¨¼ä»·æ¶é©¾å«æ­¼çåå°ç¬ºé´çå¼è©è°å¥¸ç¼è§æ£æ¬ç¢±ç¡·æ£æ¡ç®ä¿­åªåèæ§é´è·µè´±è§é®ç®­ä»¶"],
["bd40","ç´·",54,"çµ¯",7],
["bd80","çµ¸",32,"å¥è°åé¥¯æ¸æºæ¶§å»ºåµå§å°æµæ±çèæ¡¨å¥è®²å é±éèæ¤ç¤ç¦è¶äº¤éæµéªå¨å¼æé°ç«ä¾¥èç¡è§é¥ºç¼´ç»å¿æéµè½¿è¾å«çªæ­æ¥çç§¸è¡é¶æªå«èæ¡æ°æ·ç«ç«­æ´ç»è§£å§æèè¥çåä»ç¥è¯«å±å·¾ç­æ¤éä»æ´¥è¥ç´§é¦ä»è°¨è¿é³æç¦è¿ç¬æµ¸"],
["be40","ç¶",12,"ç¶§",6,"ç¶¯",42],
["be80","ç·",32,"å°½å²èå¢èçæ¶é²¸äº¬æç²¾ç²³ç»äºè­¦æ¯é¢éå¢æ¬éå¾çéç«ç«åç¯çªæªç©¶çº çé­ä¹ç¸ä¹éå©ææ§è¼èåå°±çé æçç½å±é©¹èå±åç©ä¸¾æ²®èææ®å·¨å·è·è¸é¯ä¿±å¥æ§ç¬å§æé¹å¨å¦ç·å·ç»¢ææ«ææåçµè§å³è¯ç»åèé§ååå³»"],
["bf40","ç·»",62],
["bf80","ç¸ºç¸¼",4,"ç¹",4,"ç¹",21,"ä¿ç«£æµé¡éªååå¡å¯å¼æ©æ¥·å¯æ¨åå ªååç çåº·æ·ç³ ææäº¢çèæ·ç¤é å·èæ¯æ£µç£é¢ç§å£³å³å¯æ¸´åå»å®¢è¯¾è¯åå¦æ³åå­ç©ºæå­æ§æ å£æ£å¯æ¯å­çªè¦é·åºè£¤å¤¸å®æè·¨è¯åç­·ä¾©å¿«å®½æ¬¾å¡ç­çæ¡ç¿ç¶æ·åµäºçå²¿çª¥èµå¥é­å"],
["c040","ç¹",35,"çº",23,"çºçºçº"],
["c080","çº®çº´çº»çº¼ç»ç»¤ç»¬ç»¹ç¼ç¼ç¼ç¼·ç¼¹ç¼»",6,"ç½ç½",9,"ç½ç½é¦æ§æºå¤ææå°æ¬æ©å»éåæåè¡èè¾£å¦è±æ¥èµèå©ªæ æ¦ç¯®éå°æ¾è°°æ½è§æç¼çæ»¥çæ¦ç¼å»éææµªæå³ç¢èä½¬å§¥éªçæ¶åä¹é·é­è¾ç£ç´¯å¡åæèç±»æ³ªæ£±æ¥å·åæ¢¨çé»ç¯±ç¸ç¦»æ¼çæéé²¤ç¤¼èèåæ ä¸½åå±ç ¾åå©åä¾ä¿"],
["c140","ç½ç½ç½ç½ç½ç½ç½ ç½£",4,"ç½«ç½¬ç½­ç½¯ç½°ç½³ç½µç½¶ç½·ç½¸ç½ºç½»ç½¼ç½½ç½¿ç¾ç¾",7,"ç¾ç¾ç¾",4,"ç¾",4,"ç¾ç¾ç¾ ç¾¢ç¾£ç¾¥ç¾¦ç¾¨",6,"ç¾±"],
["c180","ç¾³",4,"ç¾ºç¾»ç¾¾ç¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿",4,"ç¿ç¿ç¿",5,"ç¿¢ç¿£ç¢ç«ç²æ²¥é¶åçå©ä¿©èè²è¿é°å»ææ¶å¸æè¸é¾æç¼ç»ç²®åæ¢ç²±è¯ä¸¤è¾éæ¾äº®è°æ©èåççå¯¥è¾½æ½¦äºæé£å»æåè£çå£çç³æç£·éä¸´é»é³æ·åèµåæç²è±é¶é¾éä¼¶ç¾åçµéµå²­é¢å¦ä»¤æºçæ¦´ç¡«é¦çåç¤æµæ³å­é¾èåç¬¼çª¿"],
["c240","ç¿¤ç¿§ç¿¨ç¿ªç¿«ç¿¬ç¿­ç¿¯ç¿²ç¿´",6,"ç¿½ç¿¾ç¿¿èèèèèèèèèèèèèèè¡è£è¤è«",5,"è²è´è¹èºè¼è¾èèèèèèèèèèèèèèè"],
["c280","èè",13,"è«",5,"è²",11,"éåæ¢éæ¥¼å¨æç¯æ¼éè¦å¢é¢åºçæ³å¤èé²éºç¢é²è·¯èµé¹¿æ½ç¦å½éæ®é©´åéä¾£æå±¥å±¡ç¼èæ°¯å¾çæ»¤ç»¿å³¦æå­ªæ»¦åµä¹±æ ç¥æ¡è½®ä¼¦ä»æ²¦çº¶è®ºèèºç½é»é£ç®©éª¡è£¸è½æ´éªç»å¦éº»çç èé©¬éªåååä¹°éº¦åè¿èçé¦è®æ»¡èæ¼æ¢æ¼«"],
["c340","è¾èèèèèè",5,"èèèèèè£è¦è§è¨è¬è°è³èµè¶è¸è¹è»èè",4,"è",6,"èèè è¢è£è¦è®èµè·è¹è»è¾è¿èèèèèèèè"],
["c380","èèèèèèèè",12,"è­è®è°è³è´èµè·è¹",4,"è¿è°©èè«ç²æ°å¿è½ç«èéæ¯çéå¯èåå¸½è²è´¸ä¹ç«ææ¢é¶éç¤æ²¡çåªéæ¯ç¾æ§å¯å¦¹åªé¨é·ä»¬èèæª¬çé°çæ¢¦å­ç¯éé¡ç³è¿·è°å¼¥ç±³ç§è§æ³èå¯å¹æ£ç ç»µåååå¨©ç¼é¢èæçèç§æ¸ºåºå¦èç­æ°æ¿ç¿ææ¯é½æèé¸£é­åå½è°¬æ¸"],
["c440","è",5,"èèèèèèèèèè",4,"è¡è¢è£è¤è¦è¨èªè«è¬è¯è²è³èµè¶è·è¸èè",4,"èèèèèèè",5,"èèè",4,"è¤è¥"],
["c480","è§è©è«",7,"è´",5,"è¼è½è¾è¿èèèèèèè",6,"æ¹èæ¨¡èç£¨æ©é­æ¹æ«è«å¢¨é»æ²«æ¼ å¯éè°çææç¡äº©å§æ¯å¢æ®å¹åææ¨ç®ç¦ç§ç©æ¿åªåé é£å¨çº³æ°ä¹å¥¶èå¥åç·é¾åæ èæ¼é¹æ·å¢é¦åå«©è½å¦®éåªæ³¥å°¼æä½ å¿è»éæººè«æå¹´ç¢¾æµæ»å¿µå¨é¿é¸å°¿æèå­½å®ééæ¶æ¨æ çåå®"],
["c540","è",14,"è¤è¥è¦è¨è©è«è®",4,"èµ",5,"è½è¿èè",4,"èèèèè",5,"èè è¤è¥è¦è§è©è®è²èºè¼è½è¿"],
["c580","èèèèèèèèèèèè",7,"èèèèèè ",7,"è©æ§æ³çæ­é®çº½èæµåå¼å¥´åªæå¥³æèçæªæ¦ç³¯è¯ºå¦æ¬§é¸¥æ®´èåå¶æ²¤åªè¶´ç¬å¸æç¶ææçå¾æ¹æ´¾ææ½çç£ç¼çå¤åä¹åºæèªèæåå¨ç®è¢è·æ³¡å¸èå¹è£´èµéªéä½©æ²å·çç °æ¨ç¹æ¾å½­è¬æ£ç¡¼ç¯·è¨æé¹æ§ç¢°å¯ç é¹æ¹æ«åçµæ¯"],
["c640","èªè«è¬è­è±èµè¶è·è¸è»è¼èèèèèèèèèèèèèèèèè è¢è£è§è²èµè¶èºè»è¼è¿èèèèèèèèèèèè¢è§è¨è©èªè¬è­è®è°è²è³èµè¶è¸"],
["c680","èºè¼",4,"èèèèèèèèèè",9,"è©èªè®è°è²è·è»è½å¤è¾ç²ç®å¹çå»å±è­¬ç¯åçéªé£æ¼ç¢ç¥¨æç¥æ¼é¢è´«åèä¹åªè¹èå¹³å­ç¶è¯å±å¡æ³¼é¢å©ç ´é­è¿«ç²åæéºä»èè¡è©è²åæ´åæ®æµ¦è°±æçææ¬ºæ æå¦»ä¸åæ¼ææ²å¶æ£å¥æ­§ç¦å´èé½æç¥ç¥éªèµ·å²ä¹ä¼å¯å¥ç å¨æ°è¿å¼æ±½æ³£è®«æ"],
["c740","è¾è¿èèèèèè",4,"èè",4,"èè¢è°",6,"è¹èºè¾",6,"èèèèèèèèèèèèèèèèèè¡",6,"è¬è­è®"],
["c780","è¯èµè»è¾è¿èèèèèèèèèèèèèèèèèèèè¢è£è¤è¦è§è¨è«è¬è­æ°æ´½çµæ¦ééåè¿ç­¾ä»è°¦ä¹¾é»é±é³åæ½é£æµè°´å åµæ¬ æ­æªåèç¾å¢è·å¼ºæ¢æ©é¹æ²ææ¡¥ç§ä¹ä¾¨å·§éæ¬ç¿å³­ä¿çªåèä¸æ¯çªé¦ä¾µäº²ç§¦ç´å¤è¹æç¦½å¯æ²éè½»æ°¢å¾å¿æ¸ææ´æ°°æé¡·è¯·åºç¼ç©·ç§ä¸é±çæ±åéæ³è¶åºèæ²èº¯å±é©±æ¸ "],
["c840","è®è¯è³",4,"èºè»è¼è¾è¿èèèèèèèèè",5,"èèèè",5,"è©",7,"è²",5,"è¹èºè»è¾",7,"èèè"],
["c880","è",6,"è",4,"èèèèè è¢è¤",4,"èªè®è¯è°è²è´è·è¹è»è¼åå¨¶é¾è¶£å»åé¢§æéæ³å¨çæ³ç¬å¸åç¼ºçç¸å´é¹æ¦·ç¡®éè£ç¾¤ç¶çåæç¤å£¤æå·è®©é¥¶æ°ç»æ¹ç­å£¬ä»äººå¿é§ä»»è®¤åå¦çº«æä»æ¥æè¸èè£èçæº¶å®¹ç»åææèè¹è åå­ºå¦è¾±ä¹³æ±å¥è¤¥è½¯é®èçéé°æ¶¦è¥å¼±ææ´è¨è®é³å¡èµä¸å"],
["c940","è½",4,"èèèèèèè",7,"èèèèèèè è¢",12,"è°è±è³èµè¶è·è»è¼è¾èèèèèèèèèèèèèèè"],
["c980","è",4,"èè¡è¢è¤è§",4,"è­è®è¯è±",10,"è½è¾èèèä¼æ£æ¡åä¸§æéªæ«å«çè²æ¶©æ£®å§èç æå¹æ²çº±å»å¥çç­æçè«æå±±å ç½è¡«éªéæèµ¡è³åæ±æç¼®å¢ä¼¤åèµæä¸å°è£³æ¢¢æç¨ç§èåºé¶å°å¨éµç»å¥¢èµèèèèµ¦æå°ææ¶ç¤¾è®¾ç ·ç³å»ä¼¸èº«æ·±å¨ ç»ç¥æ²å®¡å©¶çè¾ææ¸å£°çç¥ç²åç»³"],
["ca40","è",8,"èèèèèèèèèèèèèèè è¢",8,"è­",9,"è¾",4,"èèèèè",10],
["ca80","èèèèèèè",4,"è¥è¦è§è©",8,"è³èµè¶è·è¸è¼è½è¿èèççå©èå£å¸å¤±ç®æ½æ¹¿è¯å°¸è±åç³æ¾æ¶ä»é£èå®è¯å²ç¢ä½¿å±é©¶å§å¼ç¤ºå£«ä¸æ¿äºæ­èªéå¿æ¯åå¬éä»ä¾éé¥°æ°å¸æå®¤è§è¯æ¶æé¦å®å¯¿æå®åç¦å½è¬æ¢æ¢³æ®æè¾åèæ·çä¹¦èµå­°çè¯ææç½²èé»é¼ å±æ¯è¿°æ ææç«å¢åº¶æ°æ¼±"],
["cb40","èèèè",6,"è",10,"è",6,"è¥è¦è§è©è«è¬è­è±",5,"è¸èº",6,"è",6,"è",4,"èè"],
["cb80","èè",5,"è",6,"è¥è¦è§è¨èª",14,"æå·èæè¡°ç©å¸æ æ´éåç½è°æ°´ç¡ç¨å®ç¬é¡ºèè¯´ç¡æçæ¯æå¶æç§å¸ä¸æ­»èå¯ºå£åä¼ºä¼¼é¥²å·³æ¾è¸æé¢éå®è®¼è¯µæèæå½èé¥ä¿ç´ éç²å³å¡æº¯å®¿è¯èé¸èç®è½ééç»¥é«ç¢å²ç©éé§ç¥å­æç¬èæ¢­åç¼©çç´¢éæå¡ä»å®å¥¹å¡"],
["cc40","è¹èºè¼è½è¾è",4,"è",10,"èèèèè",15,"è¨èª",13,"è¹èºè»è½è¾è¿è"],
["cc80","è",11,"èèè",4,"èèèèè è¡è£",7,"ç­æè¹è¸èèæ¬å°æ³°éå¤ªææ±°åæè´ªç«æ»©åæªç°æ½­è°­è°å¦æ¯¯è¢ç¢³æ¢å¹ç­æ±¤å¡æªå æ£ èåç³åèººæ·è¶ç«ææ¶æ»ç»¦èæ¡éæ·é¶è®¨å¥ç¹è¤è¾ç¼èªæ¢¯åè¸¢éæé¢è¹å¼ä½æ¿åææ¶åå±å¤©æ·»å¡«ç°çæ¬èèææ¡è¿¢çºè·³è´´éå¸åå¬ç"],
["cd40","è­è¯è°è²",6,"è",6,"è",4,"èè",5,"è",4,"è¥è¦è«è­è®è²è³è·è¸è¹è»",4,"èèèèèèèèèèèèèè"],
["cd80","èè è¡è¢è£è¥è¦è§è¨èªè«è¬è¯èµè¶è·èºè»è¼è½è¿èèèèèèèèèèèèæ±å»·åäº­åº­æºèéæ¡é®ç³åéå½¤ç«¥æ¡¶æç­ç»çå·æå¤´éå¸ç§çªå¾å¾éæ¶å± åååæ¹å¢æ¨é¢è¿èè¤ªéåå±¯èææè±é¸µéé©®é©¼æ¤­å¦¥æå¾æåèæ´¼å¨ç¦è¢æ­ªå¤è±å¼¯æ¹¾ç©é¡½ä¸¸ç·å®ç¢æ½æçæå®å©ä¸èæ±ªçäº¡æç½å¾æºæå¿å¦å¨"],
["ce40","èèèèè è¤è¦è§è¨èªè«è¬è­è¯è°è²è³èµè¶è¸è¹èºè¼è½è",6,"èèèèèèèèèèèè",5,"è¡è¢è¦",7,"è¯è±è²è³èµ"],
["ce80","è·è¸è¹èºè¿èèèèèèèèè",4,"èèèè",6,"è ",4,"å·å¾®å±é¦è¿æ¡å´å¯æä¸ºæ½ç»´èèå§ä¼ä¼ªå°¾çº¬æªèå³çèåé­ä½æ¸­è°å°æ°å«çæ¸©èæé»çº¹å»ç¨³ç´é®å¡ç¿ç®æèæ¶¡çªææ¡å§æ¡æ²å·«åé¨ä¹æ±¡è¯¬å±æ èæ¢§å¾å´æ¯æ­¦äºæåèä¼ä¾®åæé¾æ¤ç©å¿å¡æè¯¯æçæè¥¿ç¡ç½æ°å»å¸é¡çº"],
["cf40","è¥è¦è§è©èªè®è°è±è²è´è¶è·è¸è¹è»è¼è¾è¿è",4,"èèèè",4,"è",6,"èèèèè¡è¢è£è¤è¦è§è¨è©è«è¬è­è¯",9],
["cf80","èºè»è¼è½è¿è è è è ",5,"è ",7,"è è è è è è ",4,"è £ç¨æ¯å¸æèå¤æçç¯æºªæ±çæªè¢­å¸­ä¹ åª³åé£æ´ç³»éæç»çè¾å£éè¾æå³¡ä¾ ç­ä¸å¦å¤åæé¨åä»é²çº¤å¸è´¤è¡è·é²æ¶å¼¦å«æ¾é©ç°ç®å¿èºé¦ç¾¡å®ªé·éçº¿ç¸å¢é¶é¦ç®±è¥æ¹ä¹¡ç¿ç¥¥è¯¦æ³åäº«é¡¹å··æ©¡ååè±¡è§ç¡éåå®å£éæ¶å®µæ·æ"],
["d040","è ¤",13,"è ³",5,"è ºè »è ½è ¾è ¿è¡è¡è¡è¡",5,"è¡",5,"è¡è¡è¡è¡",6,"è¡¦è¡§è¡ªè¡­è¡¯è¡±è¡³è¡´è¡µè¡¶è¡¸è¡¹è¡º"],
["d080","è¡»è¡¼è¢è¢è¢è¢è¢è¢è¢è¢è¢è¢è¢è¢è¢è¢è¢",4,"è¢",4,"è¢£è¢¥",5,"å°å­æ ¡èå¸ç¬ææ¥äºæ­èéåææºéªæèè°åæ¢°å¸è¹ææ³æ³»è°¢å±èªè¯éæ¬£è¾æ°å¿»å¿ä¿¡è¡æè¥ç©æºå´ååå½¢é¢è¡éå¹¸ææ§å§åå¶è¸åæ±¹éçä¼ä¿®ç¾æ½åéç§è¢ç»£å¢æéèåé¡»å¾è®¸èéåæ­åºçæ¤çµ®å©¿ç»ªç»­è½©å§å®£æ¬æç"],
["d140","è¢¬è¢®è¢¯è¢°è¢²",4,"è¢¸è¢¹è¢ºè¢»è¢½è¢¾è¢¿è£è£è£è£è£è£è£è£è£è£è£è£è£è£è£è£",4,"è£ è£¡è£¦è£§è£©",6,"è£²è£µè£¶è£·è£ºè£»è£½è£¿è¤è¤è¤",5],
["d180","è¤è¤",4,"è¤è¤",4,"è¤",4,"è¤¢è¤£è¤¤è¤¦è¤§è¤¨è¤©è¤¬è¤­è¤®è¤¯è¤±è¤²è¤³è¤µè¤·éç£ç©ç»é´èå­¦ç©´éªè¡åçå¾ªæ¬è¯¢å¯»é©¯å·¡æ®æ±è®­è®¯éè¿åæ¼é¸¦é¸­åä¸«è½çèå´è¡æ¶¯éåäºè®¶çå½éçæ·¹çä¸¥ç èå²©å»¶è¨é¢éçæ²¿å¥æ©ç¼è¡æ¼è³å °çåç éåå½¦ç°å®´è°éªæ®å¤®é¸¯ç§§æ¨æ¬ä½¯ç¡ç¾æ´é³æ°§ä»°çå»æ ·æ¼¾éè°å¦ç¶"],
["d240","è¤¸",8,"è¥è¥è¥",24,"è¥ ",5,"è¥§",19,"è¥¼"],
["d280","è¥½è¥¾è¦è¦è¦è¦è¦",26,"æå°§é¥çªè°£å§å¬èè¯è¦èæ¤°åè¶ç·éå¶ä¹é¡µæä¸å¶æ³èå¤æ¶²ä¸å£¹å»æé±ä¾ä¼è¡£é¢å¤·éç§»ä»ªè°çæ²å®å§¨å½æ¤èåå·²ä¹ç£ä»¥èºææéå±¹äº¿å½¹èé¸èç«äº¦è£ææ¯å¿ä¹çæº¢è¯£è®®è°è¯å¼ç¿¼ç¿ç»èµè«å æ®·é³é´å§»åé¶æ·«å¯é¥®å°¹å¼é"],
["d340","è¦¢",30,"è§è§è§è§è§è§è§è§è§è§è§è§ è§¡è§¢è§¤è§§è§¨è§©è§ªè§¬è§­è§®è§°è§±è§²è§´",6],
["d380","è§»",4,"è¨",5,"è¨",21,"å°è±æ¨±å©´é¹°åºç¼¨è¹è¤è¥è§èè¿èµ¢çå½±é¢ç¡¬æ åæ¥ä½£èçåº¸éè¸è¹åæ³³æ¶æ°¸æ¿åç¨å¹½ä¼æ å¿§å°¤ç±é®éç¹æ²¹æ¸¸éæåå³ä½éè¯±åå¹¼è¿æ·¤äºçæ¦èæèä½ä¿é¾é±¼ææ¸æ¸éäºå¨±é¨ä¸å±¿ç¦¹å®è¯­ç¾½çåèéåéå»å³ªå¾¡ææ¬²ç±è²èª"],
["d440","è¨",31,"è¨¿",8,"è©",21],
["d480","è©",25,"è©º",6,"æµ´å¯è£é¢è±«é©­é¸³æ¸å¤åå£è¢åæ´è¾å­ååç¿æºç¼è¿èæ¿æ¨é¢æ°çº¦è¶è·é¥å²³ç²¤ææ¦éèäºé§åé¨åè¿è´éæéµå­åç ¸ææ ½åç¾å®°è½½åå¨å±ææèµèµèè¬é­ç³å¿è»æ£æ©æ¾¡è¤èºåªé çç¶ç¥è´£æ©åæ³½è´¼æå¢ææ¾èµ æå³æ¸£æ­è½§"],
["d540","èª",7,"èª",7,"èª",46],
["d580","è«",32,"é¡é¸ç¨æ æ¦¨åä¹ç¸è¯ææå®çªåºå¯¨ç»æ¯¡è©¹ç²æ²¾çæ©è¾å´­å±è¸æ å æç«æ¹ç»½æ¨ç« å½°æ¼³å¼ ææ¶¨æä¸å¸è´¦ä»èç´éææ­æ¾æ²¼èµµç§ç½©åèå¬é®æå²è°è¾èéèè¿æµçæççç §è»è´éä¾¦æç¹è¯éæ¯ééµè¸æ£çå¾ç°äºææ´æ¯æ­£æ¿"],
["d640","è«¤",34,"è¬",27],
["d680","è¬¤è¬¥è¬§",30,"å¸§çéè¯èææ¯å±èç¥è¢èæ±ä¹ç»èç´æ¤æ®æ§å¼ä¾åææ­¢è¶¾åªæ¨çº¸å¿ææ·è³è´ç½®å¸å³å¶æºç§©ç¨è´¨ççæ»æ²»çªä¸­çå¿ éè¡·ç»ç§è¿éä»²ä¼èå¨å·æ´²è¯ç²¥è½´èå¸åç±å®æ¼éª¤ç æ ªèæ±çªè¯¸è¯éç«¹çç®æç©å±ä¸»èæ±å©èè´®é¸ç­"],
["d740","è­",31,"è­§",4,"è­­",25],
["d780","è®",24,"è®¬è®±è®»è¯è¯è¯ªè°è°ä½æ³¨ç¥é©»æçªæ½ä¸ç è½¬æ°èµç¯æ¡©åºè£å¦æå£®ç¶æ¤é¥è¿½èµå ç¼è°åææåæ¡ç¢èéåçç¼æµå¹å¨èµå§¿æ»æ·å­ç´«ä»ç±½æ»å­èªæ¸å­é¬æ£è¸ªå®ç»¼æ»çºµé¹èµ°å¥æç§è¶³åæç¥è¯é»ç»é»çºå´éæç½ªå°éµæ¨å·¦ä½æåä½ååº§"],
["d840","è°¸",8,"è±è±è±è±è±è±è±è±",7,"è±è±è±è±è±",5,"è±£",6,"è±¬",6,"è±´è±µè±¶è±·è±»",6,"è²è²è²è²"],
["d880","è²è²è²",6,"è²è²è²è²",20,"äºä¸åä¸å»¿åä¸äºä¸é¬²å­¬å©ä¸¨ç¦ºä¸¿åä¹å¤­ç»å®æ°åè¤é¦æ¯ç¾é¼ä¸¶äºé¼ä¹ä¹©äºèå­å¬åä»ååå£å¥å®é¥èµååµå¦å®å¾èµå¦å£åååå­å³å¿åååå¡åè¯å½åååååç½äº»ä»ä»ä»ä»¨ä»¡ä»«ä»ä¼ä»³ä¼¢ä½¤ä»µä¼¥ä¼§ä¼ä¼«ä½ä½§æ¸ä½ä½"],
["d940","è²®",62],
["d980","è³­",32,"ä½ä½ä¼²ä¼½ä½¶ä½´ä¾ä¾ä¾ä¾ä½¾ä½»ä¾ªä½¼ä¾¬ä¾ä¿¦ä¿¨ä¿ªä¿ä¿ä¿£ä¿ä¿ä¿ä¿¸å©åä¿³å¬åå®å­ä¿¾ååå¥å¨å¾ååååå¬å»å¥å§å©åºååå­å¬å¦å®ååä»æ°½ä½ä½¥ä¿é¾ æ±ç±´å®å·½é»é¦åå¤å¹åè¨åå«å¤åäº åäº³è¡®è¢¤äºµèè£ç¦å¬´è ç¾¸å«å±å½å¼"],
["da40","è´",14,"è´ èµèµèµèµèµ¥èµ¨èµ©èµªèµ¬èµ®èµ¯èµ±èµ²èµ¸",8,"è¶è¶è¶è¶è¶è¶è¶",4,"è¶è¶è¶",9,"è¶ è¶¡"],
["da80","è¶¢è¶¤",12,"è¶²è¶¶è¶·è¶¹è¶»è¶½è·è·è·è·è·è·è·è·è·è·è·è·è·ååå¢å¥è® è®¦è®§è®ªè®´è®µè®·è¯è¯è¯è¯è¯è¯è¯è¯è¯è¯è¯è¯è¯è¯ è¯¤è¯¨è¯©è¯®è¯°è¯³è¯¶è¯¹è¯¼è¯¿è°è°è°è°è°è°è°è°è°è°è°è°è°è°è°è°è° è°¡è°¥è°§è°ªè°«è°®è°¯è°²è°³è°µè°¶å©åºéé¢é¡é±éªé½é¼ééééé§é¬é²é´éééé°ééééé¬é¡é´é³é¶éº"],
["db40","è·è·è·è·è· è·¡è·¢è·¥è·¦è·§è·©è·­è·®è·°è·±è·²è·´è·¶è·¼è·¾",6,"è¸è¸è¸è¸è¸è¸è¸è¸è¸è¸è¸",7,"è¸ è¸¡è¸¤",4,"è¸«è¸­è¸°è¸²è¸³è¸´è¸¶è¸·è¸¸è¸»è¸¼è¸¾"],
["db80","è¸¿è¹è¹è¹è¹",4,"è¹",5,"è¹",11,"è¹§è¹¨è¹ªè¹«è¹®è¹±é¸é°ééé¾ééééé¦é¢éééé«é¯é¾éé¢éé£é±é¯é¹ééåå¥å¢å¬å­å¾å¿ååå°åç®çå»´åµå¼é¬¯å¶å¼çå·¯åå©å¡å¡¾å¢¼å£å£å©å¬åªå³å¹å®å¯åå»åå©åå«åå¼å»å¨å­å¶å³å­å¤åå²åå§å´åå åååååå¸å´å¯å¸å¤å"],
["dc40","è¹³è¹µè¹·",4,"è¹½è¹¾èºèºèºèºèºèº",6,"èºèºèºèº",6,"èºèº",11,"èº­èº®èº°èº±èº³",6,"èº»",7],
["dc80","è»",10,"è»",21,"å å å½å­å å å å¡å  å¡¥å¡¬å¢å¢å¢å¢é¦¨é¼æ¿è¹è½è¿èèè¨èèèèèè«è¸è¾è°èèè£èè·è®èèèè©è´è¡èªèèèè¤è¡èè·è¤èèèè´èèèè»èèèèèèè èèèèèèèè¼è´è±èèè¯èèèèèèè è­èºè³è¦è¥"],
["dd40","è»¥",62],
["dd80","è¼¤",32,"è¨èè©è¬èªè­è®è°è¸è³è´è èªèèèè¼è¶è©è½è¸è»èèè¨èºè¼èèè¥èå èèèè½èèè¸èèèèèèè¸è¹èªèèè¦è°è¡èèèèè³èèèºèè¸è¼èè©è¶èèè±è­èèèè¦è½èèè¿èºè è¡è¹è´èè¥è£èçè¸è°è¹èèº"],
["de40","è½",32,"è½ªè¾è¾è¾è¾è¾ è¾¡è¾¢è¾¤è¾¥è¾¦è¾§è¾ªè¾¬è¾­è¾®è¾¯è¾²è¾³è¾´è¾µè¾·è¾¸è¾ºè¾»è¾¼è¾¿è¿è¿è¿"],
["de80","è¿",4,"è¿è¿è¿è¿è¿è¿ è¿¡è¿£è¿§è¿¬è¿¯è¿±è¿²è¿´è¿µè¿¶è¿ºè¿»è¿¼è¿¾è¿¿éééééééèè»è¿è¼èèè¨è¤èèºç¢èè²è»è¤è¨èèè¹è®èèè¹è·è°èèèè¿è§èè©èè¼å»¾å¼å¤¼å¥è·å¥å¥å¥åå°¢å°¥å°¬å°´ææªææ»ææææ®æ¢æ¶æ¹æææ­æ¶æ±æºææ´æ­æ¬ææ©æ®æ¼æ²æ¸æ æ¿ææææææ¾æææææ ææ¦æ¡æææ­æ"],
["df40","ééé£é¤é¥é§",5,"é°",4,"é·é¹éºé½é¿ééééé",4,"ééééééé",5,"é¤é¦é§é©éªé«é¬é¯",4,"é¶",6,"é¾é"],
["df80","ééééééé",4,"ééééééééé é¤é¥é§é¨é©é«é­é²é·é¼é½é¿éæºæ·æ¸ææºææææ¤æ¢ææ¥æ®å¼å¿çå¼åå±å½å©å¨å»åååååååååå¡ååå£å²ååå·å±å¤åååå¶å¦ååå­åå´åå§å¦ååå²å£åå»å¿ååååå©åªå¤ååååå§å å½åå³å¢å£ååå§åªå§ååµåå­ååå¿åå¼"],
["e040","éééééééééééééééééé é£é¤é¥é©éªé¬é®é°é±é²é³éµé¶é·é¹éºé»é¼é¿éééé",19,"ééé"],
["e080","ééé é¡é¤",10,"é°é²",6,"éº",8,"éå·ååµå¶å·å³å°ååååå±å¹åååå¾ååå»åå½å¾åååªå·åååå«å¬åå¦ååå¯å¥å²å³ååå¨åµå¤è¾ååååå¤å£å¾åå§å­åå¹åå¬åå¢åååååå¤å±å«å»å¼ååå¯åååå¡åµå«å¹å¿ååååå¸å¸å¸å¸å¸±å¸»å¸¼"],
["e140","éééééééééééééé é¦é§é¨é«é­é³éºé»é¼é",4,"éééééé",6,"é",5,"é¤",5,"é«é¬é°é±é²é³é¶é·é¸é¹é»"],
["e180","é¼",10,"éééé",9,"é",8,"å¸·å¹å¹å¹å¹å¹¡å²å±ºå²å²å²å²å²å²å²å²å²å²µå²¢å²½å²¬å²«å²±å²£å³å²·å³å³å³¤å³å³¥å´å´å´§å´¦å´®å´¤å´å´å´åµå´¾å´´å´½åµ¬åµåµ¯åµåµ«åµåµåµ©åµ´å¶å¶å¶è±³å¶·å·å½³å½·å¾å¾å¾å¾å¾å¾å¾å¾¨å¾­å¾µå¾¼è¡¢å½¡ç­ç°ç´ç·ç¸çççççç¨ç¯ç©ç²ç´ç·çç³ççº"],
["e240","é¦",62],
["e280","é¥",32,"ç»ççç¡ççççç¢ç¹ç¥ç¬ç¸ç±çççç ç¬ç¯ç¾èå¤¥é£§å¤¤å¤é¥£é¥§",5,"é¥´é¥·é¥½é¦é¦é¦é¦é¦é¦é¦é¦é¦é¦åºåºåºåºåº¥åº åº¹åºµåº¾åº³èµå»å»å»å»¨å»ªèºå¿å¿å¿å¿æå¿®æå¿¡å¿¤å¿¾ææå¿ªå¿­å¿¸ææµæ¦ææææ©æ«ææ¿æ¡æ¸æ¹æ»æºæ"],
["e340","é",45,"éµ",16],
["e380","é",7,"é",24,"æªæ½æææ­ææææææ¬æ»æ±æææææ´æ æ¦ææ£æ´æææ«ææµæ¬ææ§æ·ææµå¿é³é©é«é±é³éµé¶é¼é¾éééééééééééééééä¸¬ç¿ææ°µæ±æ±æ±æ²£æ²æ²æ²æ²æ±¨æ±©æ±´æ±¶æ²æ²©æ³æ³æ²­æ³·æ³¸æ³±æ³æ²²æ³ æ³æ³ºæ³«æ³®æ²±æ³æ³¯æ³¾"],
["e440","é¨",5,"é¯",24,"é",31],
["e480","é©",32,"æ´¹æ´§æ´æµæµæ´æ´æ´æ´æ´«æµæ´®æ´µæ´æµæµæµæ´³æ¶æµ¯æ¶æ¶ æµæ¶æ¶æµæµ æµ¼æµ£æ¸æ·æ·æ·æ¸æ¶¿æ· æ¸æ·¦æ·æ·æ¸æ¶«æ¸æ¶®æ¸«æ¹®æ¹æ¹«æº²æ¹æºæ¹æ¹æ¸²æ¸¥æ¹æ»æº±æºæ» æ¼­æ»¢æº¥æº§æº½æº»æº·æ»æº´æ»æºæ»æºæ½¢æ½æ½æ¼¤æ¼æ»¹æ¼¯æ¼¶æ½æ½´æ¼ªæ¼æ¼©æ¾æ¾æ¾æ½¸æ½²æ½¼æ½ºæ¿"],
["e540","é",51,"é¿",10],
["e580","é",31,"é«æ¿æ¾§æ¾¹æ¾¶æ¿æ¿¡æ¿®æ¿æ¿ æ¿¯çç£çç¹çµççå®å®å®å®å®¥å®¸ç¯éªæ´å¯¤å¯®è¤°å¯°è¹è¬è¾¶è¿è¿è¿¥è¿®è¿¤è¿©è¿¦è¿³è¿¨éééé¦éééé¡éµé¶é­é¯ééééé¨éé¢éæ¹é´é½ééééå½å½å½å½å°»å«å±å±å­±å±£å±¦ç¾¼å¼ªå¼©å¼­è´å¼¼é¬»å±®å¦å¦å¦å¦©å¦ªå¦£"],
["e640","é¬",34,"é",27],
["e680","é¬",29,"éééå¦å§å¦«å¦å¦¤å§å¦²å¦¯å§å¦¾å¨å¨å§å¨å§£å§å§¹å¨å¨å¨²å¨´å¨å¨£å¨å©å©§å©å©å¨¼å©¢å©µè¬åªªåªå©·å©ºåª¾å««åª²å«å«åª¸å« å«£å«±å«å«¦å«å«å¬å¬å¬å¬²å¬·å­å°å°å­å­¥å­³å­å­å­¢é©µé©·é©¸é©ºé©¿é©½éªéªéªéªéªéªéªéªéªéªéªéªéªéªéª éª¢éª£éª¥éª§çºçº¡çº£çº¥çº¨çº©"],
["e740","é",7,"é",54],
["e780","é",32,"çº­çº°çº¾ç»ç»ç»ç»ç»ç»ç»ç»ç»ç»ç» ç»¡ç»¨ç»«ç»®ç»¯ç»±ç»²ç¼ç»¶ç»ºç»»ç»¾ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼ç¼¡",6,"ç¼ªç¼«ç¼¬ç¼­ç¼¯",4,"ç¼µå¹ºç¿å·ç¾éççç®ç¢ççççç·ç³çççç¥çé¡¼çç©ç§ççºç²ççªçç¦ç¥ç¨ç°ç®ç¬"],
["e840","é¯",14,"é¿",43,"é¬é­é®é¯"],
["e880","é°",20,"ééééééééé¦é»éé çççççççç·ç­ç¾çççççççç¨ç©çç§ççºéªé«é¬æææææ©æ¥ææªæ³ææ§æµæ¨ææ­ææ·æ¼æ°æ ææ æ©æ°æ ææµææ³ææ ææ¸æ¢æ ææ½æ ²æ ³æ¡ æ¡¡æ¡æ¡¢æ¡æ¡¤æ¢æ æ¡æ¡¦æ¡æ¡§æ¡æ ¾æ¡æ¡æ ©æ¢µæ¢æ¡´æ¡·æ¢æ¡«æ£æ¥®æ£¼æ¤æ¤ æ£¹"],
["e940","é§é³é½éééééé é®é´éµé·",7,"é",42],
["e980","é«",32,"æ¤¤æ£°æ¤æ¤æ¥æ££æ¤æ¥±æ¤¹æ¥ æ¥æ¥æ¦æ¥«æ¦æ¦æ¥¸æ¤´æ§æ¦æ¦æ§æ¦æ¥¦æ¥£æ¥¹æ¦æ¦§æ¦»æ¦«æ¦­æ§æ¦±æ§æ§æ§æ¦æ§ æ¦æ§¿æ¨¯æ§­æ¨æ¨æ©¥æ§²æ©æ¨¾æª æ©æ©æ¨µæªæ©¹æ¨½æ¨¨æ©æ©¼æªæªæª©æªæª«ç·çæ®æ®æ®æ®æ®æ®æ®æ®æ®æ®¡æ®ªè½«è½­è½±è½²è½³è½µè½¶è½¸è½·è½¹è½ºè½¼è½¾è¾è¾è¾è¾è¾"],
["ea40","é",27,"é¬é¿éééééé é£",6,"é«é¬é­é¯é°é·é¸é¹éºé¾éééééééééé"],
["ea80","ééééééé é£é¥é¦é«é­",4,"é³é¸",12,"éééè¾è¾è¾è¾è¾è»æææææ¢æ¡æ¥æ¤æ¬è§ç¯ç´ç¿çççæ´æ®æ¯æ°æææ²æææçæ·ææ´æ±æ¶æµèææææææ¡ææ·æææ§ææ¾æææ¦æ©è´²è´³è´¶è´»è´½èµèµèµèµèµèµèµèµèµè§è§è§è§è§è§è§è§ç®ççç¦ç¯ç¾ç¿çççççææ²æ°"],
["eb40","éééééééééé",9,"é¨",7,"é±é²é´éµé·é¸éºé»é¿éééééééééé",9,"é¡",6,"é«"],
["eb80","é¬é­é®é°é±é²é´éµé¸éºé»é¼é½é¿éééééééééééé",4,"ééé æ¿æèæ¯ªæ¯³æ¯½æ¯µæ¯¹æ°æ°æ°æ°æ°æ°æ°æ°æ°¡æ°©æ°¤æ°ªæ°²æµææ«çççç°è¢åèèèè¼æè½è±è«è­è´è·è§è¨è©èªèèèèèèæèè«è±è´è­èèè²è¼æèè±è¶èè¬èè²èèèè´èèè±è è©è¼è½è­è§å¡åªµèèèæ»è£èªèæ¦èè»"],
["ec40","é¡",8,"é«é¬é®é¯é±é³",4,"éºé»é¼é½é¿",18,"ééééééééé£é¤é¦é§é¨éª",7],
["ec80","é²éµé·",4,"é½",7,"é",4,"ééééééééé",4,"èè¦æ¬¤æ¬·æ¬¹æ­æ­æ­é£é£é£é£é£é£æ®³å½æ¯è§³æé½ææ¼æææææææççççç»çç·ç«ç±ç¨ççççç¯ç±ç³çç¨çç²çç¸çºçç³çµç¨ç ç çç§ç¹çç¨ç¬çç¦ç¹æ¾æ½æææç¤»ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç¥¢ç¥ç¥ ç¥¯ç¥§ç¥ºç¦ç¦ç¦ç¦§ç¦³å¿å¿"],
["ed40","ééé¡é¢é¤",6,"é¬é®é°é±é³éµ",46],
["ed80","é¤é¥é¨é®",4,"é´é·",23,"æ¼æææ§æææ£æ«ææææ©ææææèè¿æ²æ³¶æ·¼ç¶ç¸ç ç ç ç ç æ«ç ­ç ç ç ¹ç ºç »ç ç ¼ç ¥ç ¬ç £ç ©ç¡ç¡­ç¡ç¡ç ¦ç¡ç¡ç¡ç¡ªç¢ç¢ç¢ç¢ç¢ç¢¡ç¢£ç¢²ç¢¹ç¢¥ç£ç£ç£ç£¬ç£²ç¤ç£´ç¤ç¤¤ç¤ç¤´é¾é»¹é»»é»¼ç±ççç¹çççç¢çç­ç¦çµç¸çççççç¨"],
["ee40","é ",62],
["ee80","é¡",32,"ç¢ç¥ç¿çç½ççççç ç°çµç½çºçççççç²ç¹çç½ç½¡ç½è©ç½¨ç½´ç½±ç½¹ç¾ç½¾çç¥è ²éééééééééééééééé£é¤é«éªé­é¬é¯é°é²é´é¶",4,"é¼é½é¿éé",6,"éééééééééééé é¢é¤é¥é§é¨éª"],
["ef40","é¡¯",5,"é¢é¢é¢é¢é¢é¢£é¢¨",37,"é£é£é£é£é£é£é£é£é£ ",4],
["ef80","é£¥é£¦é£©",30,"é©é«é®é¯é³é´éµé·é¹é¼é½é¿éééééééééé",4,"éééééé¢éªé«é©é¬é±é²é´é¶é·é¸é¼é¾é¿ééµééééééééééééééééééé¡é¢é¤",8,"é¯é±é²é³éºç§ç¬éç§ç§­ç§£ç§«ç¨åµç¨ç¨ç¨ç¨"],
["f040","é¤",4,"é¤é¤é¤",28,"é¤¯",26],
["f080","é¥",9,"é¥",12,"é¥¤é¥¦é¥³é¥¸é¥¹é¥»é¥¾é¦é¦é¦ç¨¹ç¨·ç©é»é¦¥ç©°ççççç¤çç ç¬é¸ é¸¢é¸¨",4,"é¸²é¸±é¸¶é¸¸é¸·é¸¹é¸ºé¸¾é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹£é¹¦",6,"é¹±é¹­é¹³çççç çç¬ç£ç³ç´ç¸çç±ç°ççççç£ç¨ç¦ç¤ç«ç§çç±ç¼ç¿ççççççç¥ççç"],
["f140","é¦é¦é¦",10,"é¦¦é¦§é¦©",47],
["f180","é§",32,"çç¼ç¢ç çç­ç°ç¿çµçç¾ç³çççççç«ç¯ç¿ç«¦ç©¸ç©¹çªçªçªçªçª¦çª çª¬çª¨çª­çª³è¡¤è¡©è¡²è¡½è¡¿è¢è¢¢è£è¢·è¢¼è£è£¢è£è££è£¥è£±è¤è£¼è£¨è£¾è£°è¤¡è¤è¤è¤è¤è¤´è¤«è¤¶è¥è¥¦è¥»çè¥ç²ç´çèèèèè è¢è¥è¦è§è©è¨è±èèµèèèèè©è±è¦é¡¸é¢é¢"],
["f240","é§º",62],
["f280","é¨¹",32,"é¢é¢é¢é¢é¢é¢é¢é¢é¢é¢¡é¢¢é¢¥é¢¦èèè¬è®è¿èºè¼è»è¨èèè¬èè§è£èªèè©è¶èèµèè°èºè±è¯èèè´è©è±è²è­è³èèèè´èèèèèè¸èèèèè£è»èè¥è®èè¾èè´è±è©è·è¿èè¢è½è¾è»è è°èè®èèè£è¼è¤èè¥èè¯è¨è"],
["f340","é©",17,"é©²éªéªéªéªéªéªéªéª¦éª©",6,"éª²éª³éª´éªµéª¹éª»éª½éª¾éª¿é«é«é«",4,"é«é«é«é«é«é«é«é«é«é«é«é«é«"],
["f380","é«é«é« é«¢é«£é«¤é«¥é«§é«¨é«©é«ªé«¬é«®é«°",8,"é«ºé«¼",6,"é¬é¬é¬èèèè­èèè«è¥è¬èµè³èèè½èèèèèªè è®è è è¾è è è ¡è ¹è ¼ç¼¶ç½ç½ç½èç«ºç«½ç¬ç¬ç¬ç¬ç¬ç¬«ç¬ç­ç¬¸ç¬ªç¬ç¬®ç¬±ç¬ ç¬¥ç¬¤ç¬³ç¬¾ç¬ç­ç­ç­ç­µç­ç­ç­ ç­®ç­»ç­¢ç­²ç­±ç®ç®¦ç®§ç®¸ç®¬ç®ç®¨ç®ç®ªç®ç®¢ç®«ç®´ç¯ç¯ç¯ç¯ç¯ç¯¥ç¯¦ç¯ªç°ç¯¾ç¯¼ç°ç°ç°"],
["f440","é¬é¬",5,"é¬é¬é¬é¬",10,"é¬ é¬¡é¬¢é¬¤",10,"é¬°é¬±é¬³",7,"é¬½é¬¾é¬¿é­é­é­é­é­é­é­é­é­é­",5],
["f480","é­",32,"ç°ç°ªç°¦ç°¸ç±ç±è¾èèèè¬è¡è¡è¢è£è­è¯è¨è«è¸è»è³è´è¾èèèèèèè¨è¡¾è¢è¢è£è£è¥ç¾ç¾ç¾§ç¾¯ç¾°ç¾²ç±¼æç²ç²ç²ç²ç²¢ç²²ç²¼ç²½ç³ç³ç³ç³ç³ç³ç³ç³¨è®æ¨ç¾¿ç¿ç¿ç¿¥ç¿¡ç¿¦ç¿©ç¿®ç¿³ç³¸çµ·ç¶¦ç¶®ç¹çºéº¸éº´èµ³è¶è¶è¶è¶±èµ§èµ­è±è±ééééé¤"],
["f540","é­¼",62],
["f580","é®»",32,"é¢é¡é°é©é¯é½é¾é²é´é¹éééééé¢é£éªé­é®é¯éµé´éºè±é¹¾è¶¸è·«è¸è¹è¹©è¶µè¶¿è¶¼è¶ºè·è·è·è·è·è·è·è·è·è·¬è··è·¸è·£è·¹è·»è·¤è¸è·½è¸è¸è¸è¸¬è¸®è¸£è¸¯è¸ºè¹è¸¹è¸µè¸½è¸±è¹è¹è¹è¹è¹è¹è¹°è¹¶è¹¼è¹¯è¹´èºèºèºèºèºèºè±¸è²è²è²è²è²æè§è§è§è§"],
["f640","é¯",62],
["f680","é°",32,"è§¥è§«è§¯è¨¾è¬¦éé©é³é¯ééééééªé­é°é¾é¾é¾é¾",5,"é¾é»¾é¼é¼é¹é¼é½ééç¿é éé®éé¾éªééé¾é«é±¿é²é²é²é²é²ç¨£é²é²é²é²é²é²é²é²é²é²",5,"é²¥",4,"é²«é²­é²®é²°",7,"é²ºé²»é²¼é²½é³é³é³é³é³é³"],
["f740","é°¼",62],
["f780","é±»é±½é±¾é²é²é²é²é²é²é²é²é²é²é²é²é²é²ªé²¬é²¯é²¹é²¾",4,"é³é³é³é³é³é³é³ é³¡é³",4,"é³é³é³é³é³é³é³é³é³é³¢é¼ééééé¯é«é£é²é´éª±éª°éª·é¹éª¶éªºéª¼é«é«é«é«é«é«é«é­é­é­é­é­é­é­é£¨é¤é¤®é¥é¥é«é«¡é«¦é«¯é««é«»é«­é«¹é¬é¬é¬é¬é¬£éº½éº¾ç¸»éºéºéºéºéºééºéºé»é»é»é» é»é»¢é»©é»§é»¥é»ªé»¯é¼¢é¼¬é¼¯é¼¹é¼·é¼½é¼¾é½"],
["f840","é³£",62],
["f880","é´¢",32],
["f940","éµ",62],
["f980","é¶",32],
["fa40","é¶£",62],
["fa80","é·¢",32],
["fb40","é¸",27,"é¸¤é¸§é¸®é¸°é¸´é¸»é¸¼é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹é¹ é¹¡é¹¢é¹¥é¹®é¹¯é¹²é¹´",9,"éº"],
["fb80","éºéºéºéºéºéºéºéº",5,"éº",8,"éºéº ",5,"éº§éº¨éº©éºª"],
["fc40","éº«",8,"éºµéº¶éº·éº¹éººéº¼éº¿",4,"é»é»é»é»é»é»é»é»é»é»é»é»é»é»é»é»é»¡é»£é»¤é»¦é»¨é»«é»¬é»­é»®é»°",8,"é»ºé»½é»¿",6],
["fc80","é¼",4,"é¼é¼é¼é¼é¼é¼é¼é¼é¼",5,"é¼¡é¼£",8,"é¼­é¼®é¼°é¼±"],
["fd40","é¼²",4,"é¼¸é¼ºé¼¼é¼¿",4,"é½",10,"é½",38],
["fd80","é½¹",5,"é¾é¾é¾",11,"é¾é¾é¾é¾¡",4,"ï¤¬ï¥¹ï¦ï§§ï§±"],
["fe40","ï¨ï¨ï¨ï¨ï¨ï¨ï¨ï¨ï¨ï¨ ï¨¡ï¨£ï¨¤ï¨§ï¨¨ï¨©"]
]
