const html = require('choo/html')
const css = require('sheetify')
const style = require('../style/index.js')

const aside = require('./aside.js')
const Canvas = require('./canvas.js')
const canvas = new Canvas()

const ui = css`
  :host {
    display: flex;
    justify-content: space-between;
  }
`

module.exports = function (state, emit) {

  if (state.params.label) {
    emit('template:change', state.params.label)
  }

  let requestPDF = false
  if (state.requestPDF) {
    requestPDF = true
    state.requestPDF = false
  }

  let requestIMG = false
  if (state.requestIMG) {
    requestIMG = true
    state.requestIMG = false
  }

  return html`
    <body class="${style}">
      <main class="${ui}">
        ${canvas.render(state.editing, requestPDF, requestIMG)}
        ${aside(state, emit)}
      </main>
    </body>
  `

}