const PDFDocument = require('pdfkit')
const blobStream = require('blob-stream')
const SVGtoPDF = require('svg-to-pdfkit')

module.exports = function (label) {
  console.log('Exporting to PDF...')

  const doc = new PDFDocument({
    autoFirstPage: false,
    size: 'LETTER',
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    layout: 'portrait'
  })
  const stream = doc.pipe(blobStream())

  doc.addPage({
    margin: 0
  })

  var width = 160
  var coordinates = [
    [37, 37],
    [226, 37],
    [415, 37],
    [37, 222.95],
    [226, 222.95],
    [415, 222.95],
    [37, 408.95],
    [226, 408.95],
    [415, 408.95],
    [37, 594.9],
    [226, 594.9],
    [415, 594.9],
  ]

  for (var i = coordinates.length - 1; i >= 0; i--) {
    doc.image(label, coordinates[i][0], coordinates[i][1], {width: width})
  }

  doc.end()
  stream.on('finish', () => {
    let blob = stream.toBlob('application/pdf')
    window.location.replace(URL.createObjectURL(blob))
  })
}
