module.exports=[
["8740","ä°ä°²ää¦ä¸ð§§äµ·ä³ð§²±ä³¢ð§³ã®ä¶ää±ä±ð¤¿ð£ð§ð¦ºð§ä±ðªäää²ð§±¬ä´äª¤ä¡ð¦¬£ç¥ð¥©ð¡©£ð£¸ð£½¡æå»"],
["8767","ç¶å¤ð¨®¹ã·´é´ð§¯¯å¯ð¡µåª¤ã¥ð©º°å«å®·å³¼æ®èð©¥ç¡çã¡µð¡µð£ð¦¡ã»¬"],
["87a1","ð¥£ã«µç«¼é¾ð¤¡ð¨¤ð£ªð ªð£äèé¾é¯ä¤°èå¢ééç§ç¨²æ æ¨©è¢çç¯æç¨¬åéã¦çð¥¶¹çé¿å³ä¤¯åä±ð£å ç©²ð§­¥è®ä®ð¦ºäð¥¶ç®®ð¢¼é¿ð¢ð¢ð¢é¿èð£»ä´é¿ä¡ðª·¿æç®é¿"],
["8840","ã",4,"ð ãð ð ããð ð¡¿¨ãð ããããð ããÄÃÇÃÄÃÄÃÅÃÇÃà¿¿ÃÌáº¾à¿¿ÃÌá»ÃÄÃ¡ÇÃ ÉÄÃ©ÄÃ¨Ä«Ã­ÇÃ¬ÅÃ³ÇÃ²Å«ÃºÇÃ¹ÇÇÇ"],
["88a1","ÇÃ¼à¿¿ÃªÌáº¿à¿¿ÃªÌá»ÃªÉ¡ââ"],
["8940","ðª©ð¡"],
["8943","æ"],
["8946","ä¸½æ»éµé"],
["894c","ð§µæä¼ä¼¨ä¾¨åå´åå¤å¡å¨å»åååå¢å£°å¤å¤å¤²å¤´å­¦å®å®å²åºæ»ææ¾æ æ¡¥æµç¼çµçº¤çº¬çººç»ç»ç»ç¼ç¼·èºèè¯è§è®¾è¯¢è½¦è½§è½®"],
["89a1","çç³¼ç·æ¥ç«å§"],
["89ab","éç¢¸éè¼"],
["89b0","è´è¶ð §§"],
["89b5","èé»ä³é·é¸ä°¾ð©·¶ð§é¸ðª³ã"],
["89c1","æºè¾ç"],
["89c5","ä¤é©¬éªé¾ç¦ð¨¬ð¡·ð ð¢«¦ä¸¤äºäºäºäº¿ä»«ä¼·ãä¾½ã¹ååã½ãã¥åå¤åå¼åäºå¹åå§ãå©ãå°ãåå£ã­ã²ãåå£å´å¹åå¯åå£å¨ãå¿ã¥ã¿åã"],
["8a40","ð§¶å¥"],
["8a43","ð ±ð ´ð¥«åð¢³ã§¬ð è¹ð¤¶¸ð©¥äð¨¾çºð¢°¸ã¨´äð¨ð¦§²ð¤·ªæð µ¼ð ¾´ð ³ð¡´æè¹¾ð ºð °ð ½¤ð¢²©ð¨ð¤"],
["8a64","ð µð©©ð¨©ä´ð¤º§ð¢³éª²ã©§ð©´ã¿­ãð¥ð©ð§£ð¢µéµ®é "],
["8a76","äð¦¥æ´å£ð¢µð¢¯ð¡·ã§»ð¡¯"],
["8aa1","ð¦ð¦ð§¦ æªð¥ð ±è¹¨ð¢¡ð¨­ð ±"],
["8aac","ä ð ©ã¿ºå¡³ð¢¶"],
["8ab2","ð¤ð ¼ð¦ð ½ð ¶å¹ä»äº"],
["8abb","äª´ð¢©¦ð¡èªé£µð ¶æ¹ã§¾ð¢µè·å¡æ¼ã¹"],
["8ac9","ðªð ¸ð¢«ð¢³"],
["8ace","ð¡ð£§ã¦ã¨ð¨ã¸ð¥¹ð¢åð ¼±ð¢²²ð© ã¼æ°½ð¤¸»"],
["8adf","ð§´ð¢ºð¢ðªð¨³ð ¹ºð °´ð¦ ç¾ð¡ð¢ ð¢¤¹ã»ð¥£ð ºð ¾ð ºªã¾ð ¼°ð µð¡ð ¹"],
["8af6","ð º«ð ®©ð µð¡ð¡½ã¿¹ð¢æ²ð ¾­"],
["8b40","ð£´ð§¹ð¢¯ð µ¾ð µ¿ð¢±ð¢±ã¨ð ºð¡ð ¼®ðª²ð¦­ð¨³ð¨¶ð¨³éªåèå¹"],
["8b55","ð©»é°¦éª¶ð§ð¢·®çè­è¬å°ð¦²è´ãåð¨½é¶ð »ºð ¸ð ¹·ð »»ãð¤·«ãð ³å¯ð¢µð¡ð ¸ð ¹¸ð¡¸ð¡ð¨ð¡ð ¹¹ð¤¹ð¢¶¤å©ð¡ð¡ð¡µð¡¶åð ¸"],
["8ba1","ð§ð¨ð ¾µð ¹»ð¥¾ãð ¾¶ð¡ð¥ðª½ð¤§ð¡ ºð¤·ð¨¼å¢å¨ãð¥½ç®²å­¨ä ä¬¬é¼§ä§§é°é®ð¥­´ð£½å»ã²åä¸¨å¤ð¡¯ð¯¡¸éð ä¹äº»ã¾å°£å½å¿ã£ºææµæ­ºæ°µæ°ºç¬ç«ä¸¬ç­ð¤£©ç½ç¤»ç³¹ç½ð¦ªã"],
["8bde","ð¦èèð¦ð¦¥åè¡¤è§ð§¢²è® è´éé¸é¿é¨ð¨¸é¦é¡µé£é£é¥£ð© é±¼é¸é»æ­¯ï¤ä¸·ð éæ·é¢"],
["8c40","å»æ·¾ð©±³é¾¦ã·è¢ð¤ç·å³µä¬ ð¥ãð¥´°æ¢ð¨¨²è¾§é¶çæçºð£ðªã²ð¡¦ä¬ç£¤çå®ð¨äæ©£ðªºä£èð ©¯ç¨ªð©¥ð¨«ªéçå¤ð¢¾é´çð¨§£é¾§çäº£ä¿°å¼ä¸¯ä¼é¾¨å´ç¶å¢å£ð¡¶¶åºåºå¿ð¢æ"],
["8ca1","ð£¹æ¤æ©ð£±£æ³¿"],
["8ca7","çð¤çã»ð¤¨å¬ç¹è®ð¥²¤ð¥çªç¯¬ç³ç¹¬è¸èé¾©è¢é¾ªèº¹é¾«è¿èé§ é¡é¾¬ð¨¶¹ð¡¿ä±ä¢å¨"],
["8cc9","é¡¨æ«ä¶å½"],
["8cce","èð¤¥»è¿ð§ä²ð¦µ´åµ»ð¦¬ð¦¾¾é¾­é¾®å®é¾¯æ§ç¹æ¹ç§ã¶äð£ð¢ää¶"],
["8ce6","å³ð£¬è«¹å±¸ã´ð£åµ¸é¾²çäð¤¬ð¡¸£ä±·ã¥¸ãð ¤ð¦±è«ä¾´ð ¹å¦¿è¬é¡ð©£ºå¼»"],
["8d40","ð ®"],
["8d42","ð¢ð¨¥­ää»ð©¹ã¼é¾³ðªµä¸ãä·ð¦±ä¼ð¨²ð§¿ä­ã£ð¥ä¡ää¶ä±»äµ¶äªã¿ð¤¬ã¡ää½ä­å´¾åµåµã·¼ã å¶¤å¶¹ã  ã ¸å¹åº½å¼¥å¾ã¤ã¤ã¤¿ã¥ææ½å³¥ã¦æ·æ¹æã¦¸æ¬ææ¥æã§¸å±"],
["8da1","ã¨æ¢æ»ææã©æå´å¡é¾ãªæãª½æ¿æã«²æã¬¢æã­æ¤æ ã­æ¡æ¢ã­²ã­±ã­»æ¤æ¥çæ¥¤æ¦æ¦ã®¼æ§ã¯æ©¥æ©´æ©±æªã¯¬æªã¯²æª«æªµæ«æ«¶æ®æ¯æ¯ªæ±µæ²ªã³æ´æ´æ´¦æ¶ã³¯æ¶¤æ¶±æ¸æ¸æ¸©æºð¨§æº»æ»¢æ»é½¿æ»¨æ»©æ¼¤æ¼´ãµð£½æ¾æ¾¾ãµªãµµç·å²ã¶ç¬ã¶ççç¯ç¿çð ¥äã±ð »"],
["8e40","ð£»å¾ð¦»ç¾ð¥ ãæ¦¢ð¨¯©å­´ç©ð¥£¡ð©ç©¥ç©½ð¥¦¬çª»çª°ç«ç«çð¦äç«ç«ç«ªä¯å²ð¥°ç¬ç­ç¬©ð¥ð¥³¾ç®¢ç­¯èð¥®´ð¦±¿ç¯è¡ç®ç®¸ð¥´ ã¶­ð¥±¥èç¯ºç°ç°µð¥³ç±ç²ð¤¢ç²¦æ½ð¤¸ç³ç³ç³¦ç±´ç³³ç³µç³"],
["8ea1","ç¹§äð¦¹çµð¦»çç¶ç¶«çµç¶³ç·ð¤ð¦©ç·¤ã´ç·µð¡¹ç·¥ð¨­ç¸ð¦¡ð¦ç¹®çºä«é¬ç¸§ç½ç½ç½ç¤¶ð¦é§¡ç¾ð¦ç¾£ð¡¡ð ¨äð£¦äð¨ºç¿ºð¦èèèè¨è¯ðªð¦³è»è¼è¡ð¢ä¦ð¦¦ð£·£ð¦¨æ¥è§ð¨©èèå¢°ð¢¶æ±¿ð¦ð¤¾¸æ§ð¡èð¡¡æ©ð¤©¥ð¤ªäºè©ð ¬ð¦©ð£µ¾ä¿¹ð¡½è¢è¢ð¦¬ð¤¦§ð£°ð¡³ð£·¸èªæ¤ð¯¦ä"],
["8f40","èèèð ¸ð¡´ãð£½ð£è»è¢èð£ºð¦¶£ð¦¬ð¦®ð£ã¶¿èå¬èäð¦¶¥è¬èèã¾ð¦»æ©èãð¦¹ð¢»¯èð¥¯¤è±ã·ä¤æª§èð£²µç¥è¨ð¦®ð¦¹·ð¦¹èèèä èè¤ð¥²äð¥³äè´å«²ð¦ºä§è³äæ¿è"],
["8fa1","ð¨¥ð¨»èð§èð¡ð§ð¯¦²äªè¨ãð¡¢¢å·ð§è¾è±ðª¸è®ð¢°§è±èè å¡è¬æ¡äè¡è¡ð§ ð£¶¹ð§¤è¡è¢äè¢´è¢µæè£ç·ð§è¦è¦è¦¦è¦©è¦§è¦¼ð¨¨¥è§§ð§¤¤ð§ª½èªçé¾èªð§©ç«©ð§¬ºð£¾äð§¬¸ç¼è¬è¬ð¥°ð¥¥è¬¿è­è­èª©ð¤©ºè®è®èª¯ð¡äè¡è²ð§µð§¶ð¯§ã¥ð§µè³ð§¶ð§¶½è´è´ð¡¤è³çè´ð¤³ã»èµ·"],
["9040","è¶©ð¨ð¡ð¤¦ã­¼ð¨¼ð§ç«§èº­èº¶è»éè¼è¼­ð¨¥ð¨è¾¥éðªð ©è¾³ä¤ªð¨§ð¨½ð£¶»å»¸ð£¢è¿¹ðªð¨¼ð¨ð¢¥ã¦ð¦»é·ð¨¼ð§ª¾é¡ð¨¬ð¨é¨ð¨éð¨¦é®é½é§ã«°é©éç²¬ð¨¤³ð¡ºéæ²éé¢ð¥¹é¹ð¨«ð£²ð¨¬ð¥"],
["90a1","ð ´±é¬é«ð¨«¡ð¨¯«çå«ð¨«¢ð¨«¥ä¥¥éð¨¯¬ð¨°¹ð¨¯¿é³éèº¼éé¦é¦é æ¿¶ä¹ð¢ºð¨ð¡¼ð£¸®ä§æ°é»éä¬é£ð¦»æé¶ç£µð¨« é½åä¦¡ð¦²¸ð ´ð¦ð©¯ð©¥ð¤«ð¡¤ð£é±èé¶ä¨ä½äð¤«©çµå­ééð©éå­ð©«éé¥åð£·ð£¼ééé±é¾ééé ð¥¬é®çð©³é¿éµð©ð§¥ºä«é ´é ³é¡é¡¦ã¬ð§µãµð °ð¤"],
["9140","ð¥é£é¢·é£é£ä«¿ð¦´§ð¡å°é£¡é£¦é£¬é¸é¤¹ð¤¨©ä­²ð©¡ð©¤é§µé¨é¨»é¨é©ð¥¥ãð©±ð©¯é« é«¢ð©¬é«´ä°é¬é¬­ð¨å´é¬´ð¦¦¨ã£ð£½é­é­ð©´¾å©ð¡¡£é®ð¤é°é¯¿é°ð©¹¨é·ð©¾·ðªðª«ðª¡ðª£ðªéµ¾é¶ðª´é¸æ¢"],
["91a1","é·ð¢ðªðª ð¡¤»ðª³é´¹ðª¹ðª´éºéºéºéº¢ä´´éºªéº¯ð¤¤é»ã­ ã§¥ã´ä¼²ã¾ð¨°«é¼é¼ä®é¤ð¦¶¢é¼é¼é¼¹ååé½é¦¸ð©é²è¿é½¢é½©ç«é¾çä®¾ð¤¥µð¤¦»ç·ð¤§¸ð¤ð¤©çð¨¯ð¡£ºç¦ð¨¥¾ð¨¸¶é©é³ð¨©é¬ééð¨¥¬ð¤¹çã»«ç²ç©çð¤³ð¤¸ç¾ð¡¯ç£ð¡¢¾ð£ã»ð¡¢ð¥¯ð¡¸ã¢ð¡»ð¡ ¹ã¡ð¡´ð¡£ð¥½ã£ð¡åð¤¨¥ð¡¾ð¡¨"],
["9240","ð¡ð¡¶èð£¦èèð¤¦ð§¥ð£¸±ð¥ð£»»ð§ä´ð£®ð©¦ð¦¼¦æ¹ã³ã°ã·§å¡¬ð¡¤¢æ äð£¿ð¤¡ð¤ð¤ð¦°¡ååð¦±åð ¿ð ®¨ð ¸éð¨¬éä»¸å«ã ð¤¶äº¼ð ¥ð ¿ä½ä¾ð¥å©¨ð «ð ã¦ð ð ãµä¼©ð ð¨º³ð µè«ð äº"],
["92a1","ååä¾¢ä¼ð¤¨ð£ºä½å®å¬åä¿ä¿¥åå¼ååååæ¹¶ð£ð£¸¹ð£º¿æµ²ð¡¢ð£ºå¨åð  äð £ð ð èµºð¨ªð åå¤ð ¡³å¡é®äºçð¤ð ° ð¤¦¬ð¡¤æ§ð ¸ç¹ã»ççççä®ð¤ª¼ð¤åãçð¤å´ð åð ¯åéé´é¦ååå¿ã¾å£åªð¨©ð¡ºð¡¯ð¡å¨¬å¦¸éå©¾å«å¨ð¥¥ð¡§³ð¡¡¡ð¤ãµæ´çå¨¡ð¥º"],
["9340","åªð¨¯ð é çð¡çä¥²éð¨§»é½ã å°å²å¹å¹ð¡¦ð¡¥¼ð£«®å»å­ð¡¤ð¡¤ãð¡¢ ãð¡¾ãèªð¨©ð¡¶ºð£²ð¨¦¨å¼å¼ð¡¤§ð¡«å©«ð¡»å­èð§½è¡ æ¾ð¢¡ ð¢«å¿ãº¸ð¢¯ð¢¾ð©ð¦½³æð ¾ð ð¢æææµð¢²ð¢´ð¤ð©"],
["93a1","æ±ð¤¥ð¢­ªã¨©ð¢¬¢ð£ð©£ªð¢¹¸æ·ðªæ¶æ±æð¤§£ð¢µ§æ¤ð¢²¡æ»æ«æ¥²ã¯´ð£ð£­ð¤¦ð£«åð£ ð¡£ð©¿æð£ð£³ã« äð¥ð¨¬¢ð¥ð¡¼ð¥ð¥¥ç£®ð£ð¡ ªð£´ã¤ð£ð£ð¤æð¦´¤æ«ä®æ°ð§¡°ð¡·«æ£ð£ð£¡æð¥¡²ã£ð£ ºð£¼ã®ð£¢ð£¾çã®æð¤ªæ¢¶æ ã¯æª¾ã¡£ð£ð¤æ¨³æ©æ«æ¬ð¡¤ææ¢æ©ã¯æ©ºæ­ð£¿ð£²é é²ð¨¯ªð¨«"],
["9440","éð¨ð¨§é§æ¶¥æ¼ð¤§¬æµ§ð£½¿ã¶æ¸ð¤¼å¨½æ¸å¡æ´¤ç¡ç»ð¤ð¤¶ç±çççð¤ð¤¥å¹ð¤ª¤ð «çºð£»¸ð£ð¤©ð¤¤ð¥¿¡ã¼ãº±ð¤«ð¨°£ð£¼µæ§ã»³çç¼éç·äð¦·ªäçã½£ð¤³ð¤´ã½çç³ðªã¬ç¨ð¨«ð¤¦«ð¤¦ã«»"],
["94a1","ã·ð¤©ã»¿ð¤§ð¤£³éºå²éð¨«£ð¡¡¤åð¥¡ð¥§ç¸ð£²ççç»ð¤ð£ã©ð¤£°ç¸çãº¿ð¤ªºð¤«äð¤ªð¦®éð¥ç ç¢ç¢ç£çç¥ð§ð¥£äç¦èç¦¥æ¨­ð£»ºç¨ºç§´ä®ð¡¦ä²éµç§±ð µð¤¦ð ð£¶ºð¡®ãå«ã°ãªð ð °ç«¢å©ð¢µð¥ª¯ð¥ªå¨ð ç£°å¨ªð¥¯ç«¾ä¹ç±ç±­äð¥®³ð¥º¼ð¥º¦ç³ð¤§¹ð¡°ç²ç±¼ç²®æª²ç·ç¸ç·ç½ð¦¡"],
["9540","ð¦ð§­ç¶ð¥ºäªð¦­µð ¤æð ð£åð¦ð¦¸ð¤¥¢ç¿ç¬§ð  ¬ð¥«©ð¥µç¬ð¥¸é§¦èé©£æ¨ð£¿ã§¢ð¤§·ð¦­é¨ð¦ èð§§ð¦³äªè·äèèèð¦´é£ð¦©è¢è¥ð¦©èð¦¶§èð§åªä¿ð¡¡å¬«ð¡¢¡å«¤ð¡£è ð¯¦¼ð£¶è ­ð§¢å¨"],
["95a1","è¡®ä½è¢è¢¿è£¦è¥¥è¥ð¥è¥ð§ð§ð¨¯µð¨¯ð¨®ð¨§¹ãº­è£äµäã²è¨½è¨ð©å½é«ð¤æç©çð¡¡éµ­è²è³©ð§·å¦çå§°ä®ãè¸ªèº§ð¤°è¼°è½ä´æ±æ¾»ð¢¡ä¢æ½¹æºð¡é¯©ãµð¤¤¯é»éå±ä¤é»éð¨©ä¢ð¨«¼é§ð¨°ð¨°»è¥è¨«éé§ééð¨´´çã»ð¤£¿ð¤©ð¤ªã»§ð£¥éð¨»§ð¨¹¦ð¨¹¥ã»ð¤§­ð¤©¸ð£¿®çç«ã»¼éð©°"],
["9640","æ¡ä¨ð©ð¥éé¨ð¨¦ð¨°¦ð¨¬¯ð¦¾éºå¬è­©ä¤¼ç¹ð¤éé±é¤¸ð ¼¦å·ð¨¯ð¤ª²é ð©é¶ð©é¥äð¨­ð¤©§ð¨­¤é£ð¨©ã¼éªä¤¥èé¤»é¥ð§¬ã·½é¦ä­¯é¦ªé©ð¨­¥ð¥£æªé¨¡å«¾é¨¯ð©£±ä®ð©¥é¦¼ä®½ä®é½å¡²ð¡å ¢ð¤¦¸"],
["96a1","ð¡¨ç¡ð¢ð£¶¸æ£ãµ½éã¤§æð¢ð¢¥«æé±é±é±»é°µé°é­¿é¯ð©¸­é®ðªµðª¾é´¡ä²®ð¤é¸ä²°é´ðª´ðª­ðª³ð©¤¯é¶¥è½ð¦¸ð¦¿ð¦®è¼ä³ð¦¶¤ð¦ºð¦·°è è®ð¦¸ð£ð¦¤ç§¢ð£ð£ä¤­ð¤§ãµ¢éé¾éð ¿ç¢¹é·éä¿¤ãé¤ð¥ç ½ç¡ç¢¶ç¡ð¡ð£ð¤¥ãä½²æ¿æ¿ççåð¤µå»å£³åé´åç´ã¯ð¤¬ç«ð¦±ð¤¾å¬¨ð¡µð¨©"],
["9740","æå«å¨ä¼ð¤ã¬ä­»ð¨§¼é»é¸ð¡£ð ¼è²ð¦³ð¡ð¤ºð¢°¦ð¤å¦ð£¶·ð¦ç¶¨ð¦ð¦¤ð¤¦¹ð¤¦ð¨§ºé¥ç¢ã»©ç´ð¨­£ð¡¢ã»¡ð¤ª³æ«ç³ç»ã»ð¤¨¾ð¤ªð¡ð¤©¦ð §ð¡¤ð¤§¥çð¤¤ç¥ð¤¥¶éç¦éð ¾é±ð¨«ð¨¨éð¨¯§ð¥ä¤µð¨ªç«"],
["97a1","ð¤¥ð ³¿å¤ð ð ¯«ð ²¸åç§ð¡ºç·¾ð¡ð¤©ð¡¡ä®éãð¨«ð¤¦­å¦°ð¡¢¿ð¡¢ð§åª¡ã¢ð£µã°éå©¹ð¨ªð¡¡¢é´ã³ð ª´äªã¦å´ãµ©ãµð¡çµä»ð¨æ¸ð©¤ä«æµð§¹ç§æ²¯ã³ð£¿­ð£¸­æ¸æ¼ãµ¯ð µçã¼ãäã»ä¡±å§é®ä¤¾è½ð¨°ð¦¯å åãð¡ç¾ð¤¢ð¤©±ð¢¿£ð¡°ð¢½æ¢¹æ¥§ð¡ð£¥ð§¯´ð£ð¨ªð£ð£ºð¤²æ¨ð£­ð¦²·è¾ää"],
["9840","ð¦´¦ð¦µð¦²ð¦¿æ¼ð§è½ð¡ºè­ð¦²ð§ð¡å¦åªð¡³å©¡å©±ð¡¤ð¤¼ã­å§¯ð¡¼ãçéæð¤¥å©®å¨«ð¤æ¨«ð£»¹ð§¶ð¤ð¤çð¤ð¨§¡ä¾°ð¦´¨å³ð¤ð§¹ð¤½æ¨ð¤ð¡ç¦ç³ð¤©ã¶¥æ³ð¯ ¥ð¤©ç¹¥å§«å´¯ã·³å½ð¤©ð¡ç¶¤è¦"],
["98a1","åð£«ºð£ð å¾ð £ð ã¿¥ð¡¾ðª¶çð©åµ°çç³ð¨©ð© ä¿ç¿§ççð§«´ç¸ç¹ð¥¶ççãº©ð§¬é¬çµð¤£²ç¡è¶ã»çã»æ²¢å½çççã»¢ã»°ã»´ã»ºçã¼ã½çç­ç²çã½¼ççã¿çã¿ç´ã¿çºð¤½çå£è¦å¡©äçä¹æ¡äãçäªä¯å±ç¾çå£²ç ç¹ç ä¨ç ¹ç¡ç¡ç¡¦èð¥µç¤³æ ç¤²ä"],
["9940","äç¦ç¦è¾»ç¨è¾¼ä§çªä²çª¼è¹äç«ç«ää¸¡ç­¢ç­¬ç­»ç°ç°ä äºç±»ç²äç²¸äç³­è¾çð ³ç·ç·ç·ç·½ç¾®ç¾´çäè è¥ç¬¹è®è±èã·å´ç è·è©ä­èçªèèç èäã¬¹èèè"],
["99a1","äå ºè¼èä¥èä­è¥å¯èè¤èäè¦è¶èèè¿ä°èé©æ¦èçµè¤æ£èäè¾è¡è¸èè¸èä»è¯è°è ä·è²èè²è¯éèääè¢®è£¿è¤¤è¥è¦ð§¥§è¨©è¨¸èªèª´è±è³è³²è´äå¡è·ä­ä»®è¸ºååè¹±åµèº°ä ·è»è»¢è»¤è»­è»²è¾·è¿è¿è¿é³é§ä¢­é£ éä¤é¨éé«é±é®é¿"],
["9a40","é£é«é³é´é½ééé­ä¥ä¥éº¿éåéé­é¾ä¥ªéé¹é­é¢ä¦§é´é³ä§¥æ ä¨¤éä¨µé²éåä«¤æ¨é¢¹ä¬é£±å¡é¤é¤å´é¤é¤·é¥é¥é¥¢ä­°é§ä®é¨¼é¬çªé­©é®é¯é¯±é¯´ä±­é° ã¯ð¡¯éµé°º"],
["9aa1","é»¾åé¶é¶½é·é·¼é¶è¾¶é¹»éº¬éº±éº½é»éé»¢é»±é»¸ç«é½ð ð ·ð  æ¤éå¦¬ð å¡éã¹ð ð ð ¶ð¡ºåç³ð «ð «ð ®¿åªð¯ »ð ¯åð ¯»ð °»ð ±ð ±¥ð ±¼æ§ð ²åºð ²µð ³ð ³­ð µ¯ð ¶²ð ·æ¥é°¯è¥ð ¸ð ¸ð »ð ¾ð ¼­ð ¹³å° ð ¾¼å¸ð¡ð¡ð¡¶æð¡»ð¡ð¡ãð¡¿ð¡ð¡¯ð¡»å¤è­ð¡£ð¡µð¡¶è®ð¡·ð¡ð¡ð¡ä¹¸ç»ð¡ ­ð¡¥ª"],
["9b40","ð¡¨­ð¡©ð¡°ªð¡±°ð¡²¬ð¡»æð¡»ð¡¼çæ¡ð¢æ§©ãð¢¼ð¢ð¢ºð¢ªð¢¡±ð¢¥è½ð¢¥§ð¢¦ð¢«è¦¥ð¢«¨è¾ ð¢¬é¸ð¢¬¿é¡éª½ð¢±"],
["9b62","ð¢²ð¢²·ð¥¯¨ð¢´ð¢´ð¢¶·ð¢¶ð¢¹ð¢½´ð¢¿ð£³ð£¦ð£ð£å¾±ææ¿ð§©¹ð£§ð£³çð¤¦ºçð£ð£çºð å¢µæ"],
["9ba1","æ¤ð£ª§ð§ð¥¿¢ð£¸ð£º¹ð§¾ð¢ä£äª¸ð¤ð¨ªð¤®ð¤ð¤»ð¤´ð¤ð¤©ð åð å¦ð¡º¨ã®¾ð£³¿ð¤ð¤åð¤´ã¦ð¤¯ð¨¨ð©§ã¢ð¢è­ð¨­é§ð¤ ð¤£»ð¤¨çð¤«ð ±¸å¥¥ð¤º¥ð¤¾ð ¹è»ð¥¬åå¿ç±ð¥ð¥ð£½ð¤ª§å¼ð¥ð¥®ð¦­éã³ð¥¿ð§²ð¥äð¥¢ð¥¦ð¥ð¤¤¿ð¥¡å¦ã»ð£æð¥¤ä¼ð¨¥ð¥ª®ð¥®ð¥°ð¡¶å¡çæ¾¶ð¦ð§°éð¦²ð¤¾è­¢ð¦ð¦"],
["9c40","åµð¦¯·è¼¶ð¦ð¡¤è«ªð¤§¶ð¦ð£¿¯ð¦ä¯ð¦¿ð¦µð¢é¥ð¥¡æå¨§ð¯£ä¾»å¹ð¤¡ð¦¼ä¹ªð¤¤´éæ¶ð¦²½ãè¥·ð¦ð¦¡®ð¦ð¦¡çð¦£ç­ð©ð ¨ð¦¤¦éð¦¤¹ç©é·°ð¦§ºé¨¦ð¦¨­ãð¦©ð ¡ç¦ð¦¨´ð¦­å´¬ð£èð¦®äð¦²¤ç»è¡¥ð¦¶®å¢¶"],
["9ca1","ãð¢ð§ð§ã±ð§ð§éð¢ºð§é°ð§¦ð¤§æ°¹éð§ð »¸è §è£µð¢¤¦ð¨³ð¡±æº¸ð¤¨ªð¡  ã¦¤ã¹å°ç§£ä¿æ¶ð©²­ð©¢¤è¥ð§ð§¡åäð¡ã¦¡ð£¯ð¨¨ð¡ç­è¦ð§§ð©¨å©§ä²·ð§¯ð¨¦«ð§§½ð§¨ð§¬ð§µ¦ð¤ºç­ç¥¾ð¨æ¾µðªæ¨ð¨å¢ð¦¸é¿æ ¶éð¨¯ð¨£ð¦¦µð¡­ð£¯ð¨å¶ð¨°°ð¨åé £ð¨¥å¶«ð¤¦æ¾æ§åð¤ª¥ð£¾ã°æ¶ð¨ð¨´ð¨®ð¡¾¡ð¨"],
["9d40","ð¨ð¨¯ð¨ð¨ð¨¯ð¨ãð¨¨ð¨ªä£ºæ¦ð¨¥ç éð¨¦¸ä²ð¨§§äð¨§¨ð¨­ð¨¯å§¸ð¨°è¼ð¨¿ð©¬ç­ð©ð©¼ã··ð©ð¤«è¿çåð©§ð©©ð©°ð©¸ð©²ð©£ð©¥ð©¥ªð©§ð©¨¨ð©¬ð©µð©¶çºð©»¸ð©¼£ä²¤éðªç¢ðª¿ä¶éðªä¶ð ²è¾¾å"],
["9da1","è¾ºð¢°è¾¹ð¤ªäç¹¿æ½æª±ä»ªã¤ð¨¬¬ð§¢ãºèºð¡µð¨¤ð¨­¬ð¨®ð§¨¾ð¦¯ã·«ð§ð£²·ð¥µð¥¥äºð¥ºð¦å¿ð ¹­è¸å­­ð£ºð¤²ææð¡¶ð¡¡»æ°å­ð¥±åð¥ã·ð©¶ä±½å¢åç½ð¥»å¥µð£µè°ä¸ð ¿ªð µð£ºèéµè´ç»é±çç¹éå²èè·å¥è²èè½åªç¥¢ååð ºãåå±æ±ð¨¢ã­ç´å°åºåå²ð ±ð ²å»ð¥ð ¹¶ð¢±¢"],
["9e40","ð º¢éº«çµåð¡µæé­åè³ç¶é¶æ¼æ¹æ¾å©ð¢­é±²ð¢º³åãð ¶§å§åååç¦è¸­ð¦¢ç±è¶è èè£è¶èð¡ä¬çð¤å®èð¦¢å»ð¢´ð§´¯ð¤£ð§µ³ð¦»ð§¶é°ð¡éð£³¼ðª©ð º¬ð »¹ç¦ð¡²¢äð¤¿ð§¿¹ð ¿«äº"],
["9ea1","é±æð¢¶ ä£³ð¤ ð©µ¼ð ¿¬ð ¸æ¢ð§£ð ¿­"],
["9ead","ð¦ð¡ç£çºéµä¸ä¸ã·å¬æ²²å§ã¬ã§å½ã¥ð¤å¢ð¤­®è­ååªð¥ªð ¥¹"],
["9ec5","ã©ð¢¥ç´ð©º¬ä´é¯­ð£³¾ð©¼°ä±ð¤¾©ð©ð©¿èð£¶¶ð§²ð¦³ð£ æ®ç´¥ð£»·ð£¸¬ã¨ªéåã¹´ãºä©ð çå«°ð º¶ç¡ºð§¼®å¢§ä¿å¼é®åµ´çðª´éºä³¡ç¹ã»æð£ð¤²"],
["9ef5","åð¡©å§ð¤¥£ð©¸å´ð§®ã­æ±éµ¼"],
["9f40","ç±é¬¹åð¡¬å±æð©ð¦µð§¤è­ð ´¨ð¦´¢ð¤«¢ð µ±"],
["9f4f","å¾ð¡¼å¶éð¡·éºéç¬é¬å³ç®£æ¨æµé«¿ç¯é¬ªç±¾é¬®ç±ç²é°ç¯¼é¬é¼é°ð¤¤¾é½å³å¯ä¿½éºä¿²å ã¸åå§åå¦·å¸éé¶«è½å©é´é¥éºå¬æ°"],
["9fa1","æ¤¬åé°é´ä°»éæ¦å¦çð¡­é§å³"],
["9fae","ééé"],
["9fb2","éð¨ºæ¿ð¦´£æ«åéçºæð ¼çç±°ð¥°¡ð£³½"],
["9fc1","ð¤¤çé®ä¸ªð ³è¾è¡"],
["9fc9","å±æ§å­åºåå·µä»æ°±ð ²ä¼¹åååè¶ã¾å¼ã³"],
["9fdb","æ­é¼é¾¥é®é ®é¢´éªºéº¨éºçºç¬"],
["9fe7","æ¯ºè ç½¸"],
["9feb","å ðªè¹·é½"],
["9ff0","è·è¹é¸è¸æð¨½è¸¨è¹µç«ð¤©·ç¨¾ç£æ³ªè©§ç"],
["a040","ð¨©é¼¦æ³èçðª²ç¡ð¯¡è´ç¢ç±è¬­çç±è³«ð¤ª»è¯å¾ºè¢ ä·"],
["a055","ð¡ »ð¦¸"],
["a058","è©¾ð¢"],
["a05b","æ½ç§é«éµé®é®èµ"],
["a063","è è³·ç¬é¡é®°ãç²ä°ç±é¥ð¦æä°éºæ½"],
["a073","åæ¯æ¦æ¹æã©æ¢åªð£µæ¤æ ã"],
["a0a1","åµð¨¯è¿ð¨¸¹"],
["a0a6","åð¡µç¤å²é¸ð ¼»ä¥"],
["a0ae","ç¾"],
["a0b0","ç³ð¥¼ç³ç¨­è¦è£çµçç²è¦èæè¢ð§èç°èç¤è¦ð¦çð¦»è©èèèè©è²­è­ç«ç¸èãµæ¦²è¶¦"],
["a0d4","è¦©ç¨æ¶¹èð¤ç§ã·ç¶æ¤æã³ç¢æ·"],
["a0e2","ç½±ð¨¬­çæ©ä­¾å ã°ð£³ð¥»ð§ð¥±ð¡¥ð¡¾ð©¤ð¦·ð§­å³ð¦­ð¨¨ð£·ð ®ð¦¡ð¤¼ä¢å¬ð¦é½éº¦ð¦«"],
["a3c0","â",31,"â¡"],
["c6a1","â ",9,"â´",9,"â°",9,"ä¸¶ä¸¿äºäº ååå«å¹å¸å©å¶å¤å®å·â¼³å¹¿å»´å½å½¡æ´æ çç¶è¾µé¶Â¨Ëã½ã¾ãããä»ãããã¼ï¼»ï¼½â½ã",23],
["c740","ã",58,"ã¡ã¢ã£ã¤"],
["c7a1","ã¥",81,"Ð",5,"ÐÐ",4],
["c840","Ð",26,"ÑÐ¶",25,"â§â¸â¹ãð ä¹ð åä"],
["c8a1","é¾°åé¾±ð§"],
["c8cd","ï¿¢ï¿¤ï¼ï¼ã±ââ¡ããâºâºâºâºâºâºâºâºâºâºâºâº¥âº§âºªâº¬âº®âº¶âº¼âº¾â»â»â»â»â»â»â»â»â»£"],
["c8f5","ÊÉÉÉÉµÅÃ¸ÅÊÉª"],
["f9fe","ï¿­"],
["fa40","ð éð ð£¿èäµç¯åµãð¤¥ð¨§¤éð¡§è®ð£³ç ¼ææð¤¤³ð¨¦ªð  ð¦®³ð¡ä¾«ð¢­åð¦´©ð§ªð£ð¤ª±ð¢å©ð ¾å¾¤ð ð æ»ð å½åãºåé¡¬ãèð¤¦¤ð å ð£´åªð ¯¿ð¢¼ð ¥ð¢°ð ð£³ð¡¦å®è½ð ³ð£²å²å¸"],
["faa1","é´´åååã³åð¤ª¦å³å¢åå­èæ¤¾ð£­å½»åå¦å¼åµååå¹åç°èå èð¦¬åð¨«åæ»ð£¾ð ¥ð£¿¬å³åð ¯¢æ³ð¡¦æ çæãºªã£ð¡¨çä¢å­å´ð¨«å¾å¿ð¡ð¡ç¦åð¨ªå å«å®ç§ð¥²ã½çååæ±ä¹å¾åãª«ð ®å ð£¿«ð¢¶£å¶ð ±·åç¹å«ææµå­ð¦­ð µ´ååå¤ä¦ð¡ð »ã¶´ð µ"],
["fb40","ð¨¦¼ð¢åä³­å¯çåå©åð¡£ð¤ºäð¤µæ³ð¡´å·æð£æ¤æ­ååç£±å±éå¾åå¯å­ð¨­¦ã£ð¡åð¤¥æ±®çåã±ð¦±¾å¦ð¡å ð¡ð¤£å ¦ð¤¯µå¡å¢ªã¡å£ å£ð¡¼å£»å¯¿åðªð¤¸éã¡å¤æ¢¦ãæ¹"],
["fba1","ð¡¾å¨¤åð¡èå§ð µð¦²ð¦´ªð¡å§ð¡»ð¡²ð¦¶¦æµ±ð¡ ¨ð¡å§¹ð¦¹åª«å©£ã¦ð¤¦©å©·ãåªç¥å«ð¦¾¡ð¢ã¶ð¡¤ã²ð¡¸åºåå­¶æå­¼ð§¨ää¡ð å¯æ ð¡¨´ð¥§ð ¥å¯³å®ä´å°ð¡­å°çå°ð¡²¥ð¦¬¨å±ä£å²å³©å³¯å¶ð¡·¹ð¡¸·å´å´åµð¡º¤å²ºå·è¼ã ­ð¤¤ð¢ð¢³èã ¶ã¯å¸®æªå¹µå¹ºð¤¼ð ³å¦äº·å»å¨ð¡±å¸å»´ð¨"],
["fc40","å»¹å»»ã¢ å»¼æ ¾éå¼ð ð¯¢ã«ä¢®ð¡ºå¼ºð¦¢ð¢å½ð¢±å½£é½ð¦¹®å½²éð¨¨¶å¾§å¶¶ãµð¥ð¡½ªð§¸ð¢¨éð ð¨¨©æ±æð¡¡·ã¥£ã·ã¹åð¢´ç¥±ã¹ææ¤æ³ð¤¦ð¤¦ð§©ç¤å¡åª æ¤è¤æð¯¢¦ð¦»æå´ð æå®ªð£¾·"],
["fca1","ð¢¡æð¨®ð©¥æã¤²ð¢¦ð¢£æ£æææð æð¡°æð¢¸æ¬ð¤§ã¨æ¸æ¸ð¡ð¡¼ææ¾ð¢¸¶é ð¤ð¥æ¡æ¥é»ã©¦æºã©ææ¼ð¤¨¨ð¤¨£ææ­æð£¾æµð¤¥ä¬·æäð¡ ©æ æ£å¿ð£æð£·ð£¸æð£¤ð£¥æð ¹µæ§ð¥¦æ³æ´ð¡¸½ð£±ð¨´ð£ð¥çð¢£·é¦¤æð¤ð¤¨¡ã¬«æ§ºð£ææ§æ¢ð¤ð©­æä©æ ¢æ¹é¼æ ð£¦ð¦¶ æ¡"],
["fd40","ð£¯æ§¡æ¨ð¨«æ¥³æ£ð£æ¤æ¤ã´²ã¨ð£¼ã®æ¬æ¥¡ð¨©ä¼æ¤¶æ¦ã®¡ð è£åæ§¹ð£ð¢ªæ©ð£æªã¯³æ±æ«ð©ã°æ¬ð ¤£ææ¬µæ­´ð¢æºµð£«ð µð¡¥ãå¡ð£­æ¯¡ð£»¼æ¯æ°·ð¢ð¤£±ð¦­æ±è¦æ±¹ð£¶¼äð£¶½ð¤¤ð¤¤ð¤¤"],
["fda1","ð£³ã¥ã³«ð ´²é®ð£¹ð¢ç¾æ ·ð¦´¥ð¦¶¡ð¦·«æ¶æµæ¹¼æ¼ð¤¥¿ð¤ð¦¹²è³ð¦½´åæ²æ¸è®ð¨¬¡æ¸¯ð£¸¯çð£¾ç§æ¹åªð£æ¿¸ãæ¾ð£¸°æ»ºð¡ð¤½äé°æ½æ½ãµæ½´ð©°ã´»æ¾ð¤æ¿ð¤ð¤ð¤¹ð£¿°ð£¾´ð¤¿åð¤ð¤ð¤ð¦çç¾ç§çççççäã·¨ç´çð¤·ç«çåªçç®å²ð¤¥çé¢ð¤ç¬ð¤ð¤¨§ð¤¨¢çºð¨¯¨ç½ç"],
["fe40","éçå¤éç¤éð¥ç®çð¤¥´æ¢½ççã¹ð£æ æ¼½ççªç«ð¤ £ð¨ «ä£­ð¨ ç¨ç®ççªð °ºð¦¨®ççð¤¢ð¡§ð¤¨¤æ£ãð¤¦·ð¤¦ð¤§»ç·çæ¤ð¤¨¦ç¹ð ã»çð¢¢­ç ð¨º²çç¤ç¶è¹ç¬ã°ç´é±æ¨¬çä¥ð¤ª"],
["fea1","ð¤ð¤©¹ð¨®å­ð¨°ð¡¢çð¡¦çç©çð¨»ð¡©å¯ð¨º¬éççç§ç®ð¤¾ã¼ð¤´çççç´çç¬ççç¯ç¶ð¦µçè¯ã¸ð¦¤ð¦¤ç¡ç¥ç·çð¦¾è¢ð¥ð¥½ð¡¸çç¦çæ¯ð¥ çð£¬ç¯ð¨¥¤ð¨¥¨ð¡ç´ç ð¡¶ð¤¨æ£ç¢¯ç£ç£é¥ç¤®ð¥ ç£ç¤´ç¢±ð§è¾¸è¢ð¨¬«ð¦ð¢ç¦è¤æ¤ç¦ð¥¡ç¦ð§¬¹ç¤¼ç¦©æ¸ªð§¦ãº¨ç§ð©ç§"]
]
