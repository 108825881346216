const html = require('choo/html')
const raw = require('choo/html/raw')
const css = require('sheetify')

const style = css`
  :host {
    font-size: large;
    background: white;
    border: 2px solid black;
  }

  :host:focus {
    outline: none;
  }
`

module.exports = function (state) {
  
  let str = `<option value="${state.editing.slug}">${state.editing.name}</option>`

  for (var i = 0; i < state.templates.length; i++) {
    if (state.templates[i].slug !== state.editing.slug) {
      str += `<option value="${state.templates[i].slug}">${state.templates[i].name}</option>`
    }
  }
  
  return html`
    <select class="${style}" name="template">
      ${raw(str)}
    </select>
  `
}