const html = require('choo/html')
const css = require('sheetify')

const style = css`
  :host {
    width: 300px;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
  }
`

const header = css`
  :host {
    padding: 1.5em 1em;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
  }
`

const footer = css`
  :host {
    padding: 1.5em 1em;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid lightgrey;
    position: absolute;
    bottom: 0;
    width: calc(300px - 2em);
  }
`

const button = css`
  :host {
    font-size: large;
    background: white;
    border: 2px solid black;
    border-radius: 4px;
  }

  :host:focus {
    outline: none;
  }

  :host:active {
    background: black;
    color: white;
  }
`

const secondary = css`
  :host {
    border-color: lightgrey;
  }
`

const templates = require('./templates.js')
const fields = require('./fields.js')

module.exports = function (state, emit) {

  return html`
    <aside class="${style}">
      <header class="${header}">
        <form onchange=${ontemplatechange}>
            ${templates(state)}
        </form>
      </header>
      ${fields(state.editing, emit)}
      <footer class="${footer}">
        <button class="${button} ${secondary}" onclick=${requestIMG}>Save Single</button>
        <button class="${button}" onclick=${requestPDF}>Save Sheet</button>
      </footer>
    </aside>
  `

  function ontemplatechange () {
    emit('template:change', document.querySelector('select[name="template"]').value)
  }

  function requestPDF () {
    emit('export:pdf')
  }

  function requestIMG () {
    emit('export:img')
  }

}