module.exports=[
["0","\u0000",127],
["8141","ê°ê°ê°ê°ê°",4,"ê°ê°ê°ê°¡ê°¢ê°£ê°¥",6,"ê°®ê°²ê°³ê°´"],
["8161","ê°µê°¶ê°·ê°ºê°»ê°½ê°¾ê°¿ê±",9,"ê±ê±",5,"ê±"],
["8181","ê±ê±ê±ê±ê±ê±",18,"ê±²ê±³ê±µê±¶ê±¹ê±»",4,"ê²ê²ê²ê²ê²ê²ê²ê²ê²ê²",6,"ê²ê²¢",5,"ê²«ê²­ê²®ê²±",6,"ê²ºê²¾ê²¿ê³ê³ê³ê³ê³ê³ê³ê³ê³ê³",7,"ê³ê³",7,"ê³¢ê³£ê³¥ê³¦ê³©ê³«ê³­ê³®ê³²ê³´ê³·",4,"ê³¾ê³¿ê´ê´ê´ê´ê´",4,"ê´ê´ê´ê´"],
["8241","ê´ê´ê´ê´ê´ê´ê´ê´ê´ê´ê´¡",7,"ê´ªê´«ê´®",5],
["8261","ê´¶ê´·ê´¹ê´ºê´»ê´½",6,"êµêµêµ",5,"êµêµêµêµêµêµ"],
["8281","êµ",7,"êµ¢êµ¤",7,"êµ®êµ¯êµ±êµ²êµ·êµ¸êµ¹êµºêµ¾ê¶ê¶",4,"ê¶ê¶ê¶ê¶ê¶ê¶",10,"ê¶",5,"ê¶¥",17,"ê¶¸",7,"ê·ê·ê·ê·ê·ê·",6,"ê·ê·",7,"ê·ê·ê·ê·¡ê·¢ê·£ê·¥",18],
["8341","ê·ºê·»ê·½ê·¾ê¸",5,"ê¸ê¸ê¸",5,"ê¸",7],
["8361","ê¸",18,"ê¸²ê¸³ê¸µê¸¶ê¸¹ê¸»ê¸¼"],
["8381","ê¸½ê¸¾ê¸¿ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹",4,"ê¹ê¹¢ê¹£ê¹¤ê¹¦ê¹§ê¹ªê¹«ê¹­ê¹®ê¹¯ê¹±",6,"ê¹ºê¹¾",5,"êº",5,"êº",46,"êº¿ê»ê»ê»ê»",6,"ê»ê»",5,"ê»ê»ê»",8],
["8441","ê»¦ê»§ê»©ê»ªê»¬ê»®",5,"ê»µê»¶ê»·ê»¹ê»ºê»»ê»½",8],
["8461","ê¼ê¼ê¼ê¼ê¼ê¼ê¼ê¼",18],
["8481","ê¼¤",7,"ê¼®ê¼¯ê¼±ê¼³ê¼µ",6,"ê¼¾ê½ê½ê½ê½ê½ê½",5,"ê½",10,"ê½",5,"ê½¦",18,"ê½º",5,"ê¾ê¾ê¾ê¾ê¾ê¾ê¾",6,"ê¾ê¾ê¾ê¾",5,"ê¾",26,"ê¾ºê¾»ê¾½ê¾¾"],
["8541","ê¾¿ê¿",5,"ê¿ê¿ê¿",4,"ê¿",6,"ê¿",4],
["8561","ê¿¢",5,"ê¿ª",5,"ê¿²ê¿³ê¿µê¿¶ê¿·ê¿¹",6,"ëë"],
["8581","ë",6,"ëëëëëëë",6,"ë",9,"ë©",26,"ëëëëëëëëëëëëëëë",29,"ë¾ë¿ëëëë",6,"ëëë",5,"ëëëë£ë¤"],
["8641","ë¥ë¦ë§ëªë°ë²ë¶ë·ë¹ëºë»ë½",6,"ëë",5,"ë"],
["8661","ëëëëë",6,"ë¡ë¢ë£ë¤ë¦",10],
["8681","ë±",22,"ëëëëëëëëëëë",4,"ë¦ë§ë©ëªë«ë­",6,"ë¶ëº",5,"ëëëëëë",6,"ëëëëëëëëëëë¡",22,"ëºë»ë½ë¾ë¿ëë",4,"ëëëëëëëëëëëëë"],
["8741","ë",9,"ë©",15],
["8761","ë¹",18,"ëëëëëëë"],
["8781","ë",5,"ëë ",7,"ëªë«ë­ë®ë¯ë±",7,"ëºë¼ë¾",5,"ëëëëë",6,"ëëë",5,"ë¡",18,"ëµ",6,"ë½",26,"ëëëëëëë¡",6,"ëª",4],
["8841","ë¯",4,"ë¶",5,"ë½",6,"ëëëë",4],
["8861","ëëëëëëë",4,"ë¢ë¤ë§ë¨ë©ë«ë­ë®ë¯ë±ë²ë³ëµë¶ë·"],
["8881","ë¸",15,"ëëëëëëë",4,"ëëëëë ë¡ë£ë§ë©ëªë°ë±ë²ë¶ë¼ë½ë¾ëëëëëë",6,"ëë",5,"ë",54,"ëëëëë ë¡ë¢ë£"],
["8941","ë¦ë¨ëªë¬ë­ë¯ë²ë³ëµë¶ë·ë¹",6,"ëë",5,"ë"],
["8961","ëëëëëë",10,"ë¢",5,"ë©ëªë«ë­"],
["8981","ë®",21,"ëëëëëëëëëëëëëëëë¡ë¢ë£ë¥ë¦ë§ë©",18,"ë½",18,"ë",6,"ëëëëëëë¡",6,"ëªë¬",7,"ëµ",15],
["8a41","ë",10,"ëëëëëë",6,"ë¢ë¤ë¦"],
["8a61","ë§",4,"ë­",18,"ëë"],
["8a81","ë",4,"ë",19,"ë",5,"ë¥ë¦ë§ë©ëªë«ë­",7,"ë¶ë¸ëº",5,"ëëëëëëë",6,"ëëëëë",5,"ëëë¡ë¢ë¥ë§",4,"ë®ë°ë²",5,"ë¹",26,"ëëëëë"],
["8b41","ë",5,"ë¦ë«",4,"ë²ë³ëµë¶ë·ë¹",6,"ëë"],
["8b61","ëëëëëëëëëë",6,"ëë¢",8],
["8b81","ë«",52,"ë¢ë£ë¥ë¦ë§ë©ë¬ë­ë®ë¯ë²ë¶",4,"ë¾ë¿ëëëë",6,"ëë",5,"ë",18,"ë­",18],
["8c41","ë",15,"ëëëëëë",4],
["8c61","ë",6,"ë¦",5,"ë­",6,"ëµ",5],
["8c81","ë»",12,"ë",26,"ë¥ë¦ë§ë©",50,"ëëë¡ë¢ë£ë¥",5,"ë­ë®ë¯ë°ë²",16],
["8d41","ë",16,"ë",8],
["8d61","ë",17,"ë±ë²ë³ëµë¶ë·ë¹ëº"],
["8d81","ë»",4,"ëëëë",33,"ëªë«ë­ë®ë±",6,"ëºë¼",7,"ëëëëëëë",6,"ë",9,"ë¡ë¢ë£ë¥ë¦ë§ë©",6,"ë²ë´ë¶",5,"ë¾ë¿ëëëë",6,"ëëëëëëëë"],
["8e41","ëë¡",6,"ëªë®",5,"ë¶ë·ë¹",8],
["8e61","ë",4,"ëë",19],
["8e81","ë",13,"ë®ë¯ë±ë²ë³ëµ",6,"ë¾ë ",4,"ë ë ë ë ë ë ",6,"ë ë ë ",5,"ë ¦ë §ë ©ë ªë «ë ­",6,"ë ¶ë º",5,"ë¡ë¡ë¡ë¡",11,"ë¡ë¡",7,"ë¡ë¡ë¡¡ë¡¢ë¡£ë¡¥",6,"ë¡®ë¡°ë¡²",5,"ë¡¹ë¡ºë¡»ë¡½",7],
["8f41","ë¢",7,"ë¢",17],
["8f61","ë¢ ",7,"ë¢©",6,"ë¢±ë¢²ë¢³ë¢µë¢¶ë¢·ë¢¹",4],
["8f81","ë¢¾ë¢¿ë£ë£ë£",5,"ë£ë£ë£ë£ë£ë£ë£",7,"ë£ë£ ë£¢",5,"ë£ªë£«ë£­ë£®ë£¯ë£±",6,"ë£ºë£¼ë£¾",5,"ë¤",18,"ë¤",6,"ë¤¡",26,"ë¤¾ë¤¿ë¥ë¥ë¥ë¥",6,"ë¥ë¥ë¥ë¥",5],
["9041","ë¥ë¥ë¥ë¥ë¥ë¥¡",6,"ë¥ªë¥¬ë¥®",5,"ë¥¶ë¥·ë¥¹ë¥ºë¥»ë¥½"],
["9061","ë¥¾",5,"ë¦ë¦ë¦ë¦ë¦",15],
["9081","ë¦",12,"ë¦®ë¦¯ë¦±ë¦²ë¦³ë¦µ",6,"ë¦¾ë§ë§",5,"ë§ë§ë§ë§",4,"ë§ë§ë§ë§ ë§¢ë§¦ë§§ë§©ë§ªë§«ë§­",6,"ë§¶ë§»",4,"ë¨",5,"ë¨",11,"ë¨",33,"ë¨ºë¨»ë¨½ë¨¾ë¨¿ë©ë©ë©ë©ë©"],
["9141","ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©",6,"ë©¦ë©ª",5],
["9161","ë©²ë©³ë©µë©¶ë©·ë©¹",9,"ëªëªëªëªëªëª",5],
["9181","ëª",20,"ëªªëª­ëª®ëª¯ëª±ëª³",4,"ëªºëª¼ëª¾",5,"ë«ë«ë«ë«",14,"ë«",33,"ë«½ë«¾ë«¿ë¬ë¬ë¬ë¬",7,"ë¬ë¬ë¬",5,"ë¬ë¬ë¬ë¬ë¬ë¬ë¬¡",6],
["9241","ë¬¨ë¬ªë¬¬",7,"ë¬·ë¬¹ë¬ºë¬¿",4,"ë­ë­ë­ë­ë­ë­ë­ë­"],
["9261","ë­ë­ë­ë­ë­",7,"ë­¢ë­¤",7,"ë­­",4],
["9281","ë­²",21,"ë®ë®ë®ë®ë®ë®ë®",18,"ë®¥ë®¦ë®§ë®©ë®ªë®«ë®­",6,"ë®µë®¶ë®¸",7,"ë¯ë¯ë¯ë¯ë¯ë¯ë¯",6,"ë¯ë¯ë¯",35,"ë¯ºë¯»ë¯½ë¯¾ë°"],
["9341","ë°",4,"ë°ë°ë°ë°ë°ë°ë°ë° ë°¡ë°¢ë°£ë°¦ë°¨ë°ªë°«ë°¬ë°®ë°¯ë°²ë°³ë°µ"],
["9361","ë°¶ë°·ë°¹",6,"ë±ë±ë±ë±ë±ë±ë±ë±ë±",8],
["9381","ë±ë±ë±ë±",37,"ë²ë²ë²ë²ë²ë²",4,"ë²ë²ë²",4,"ë²¢ë²£ë²¥ë²¦ë²©",6,"ë²²ë²¶",5,"ë²¾ë²¿ë³ë³ë³ë³",7,"ë³ë³ë³ë³ë³ë³ë³ë³ë³ë³",22,"ë³·ë³¹ë³ºë³»ë³½"],
["9441","ë³¾",5,"ë´ë´ë´",5,"ë´ë´ë´ë´",8],
["9461","ë´",5,"ë´¥",6,"ë´­",12],
["9481","ë´º",5,"ëµ",6,"ëµëµëµëµëµëµ",6,"ëµ",9,"ëµ¥ëµ¦ëµ§ëµ©",22,"ë¶ë¶ë¶ë¶ë¶",4,"ë¶ë¶ë¶ë¶ë¶ë¶ë¶",6,"ë¶¥",10,"ë¶±",6,"ë¶¹",24],
["9541","ë·ë·ë·ë·ë·ë·ë·ë·",11,"ë·ª",5,"ë·±"],
["9561","ë·²ë·³ë·µë·¶ë··ë·¹",6,"ë¸ë¸ë¸ë¸",5,"ë¸ë¸ë¸ë¸ë¸"],
["9581","ë¸",6,"ë¸ë¸ ",35,"ë¹ë¹ë¹ë¹ë¹ë¹ë¹",4,"ë¹ë¹ë¹ë¹ë¹ë¹ë¹¢ë¹£ë¹¥ë¹¦ë¹§ë¹©ë¹«",4,"ë¹²ë¹¶",4,"ë¹¾ë¹¿ëºëºëºëº",6,"ëºëº",5,"ëº",13,"ëº©",14],
["9641","ëº¸",23,"ë»ë»"],
["9661","ë»ë»ë»",6,"ë»¡ë»¢ë»¦",5,"ë»­",8],
["9681","ë»¶",10,"ë¼",5,"ë¼",13,"ë¼ë¼",33,"ë½ë½ë½ë½ë½ë½",6,"ë½ë½ë½ë½",44],
["9741","ë¾",16,"ë¾",8],
["9761","ë¾",17,"ë¾±",7],
["9781","ë¾¹",11,"ë¿",5,"ë¿ë¿ë¿ë¿ë¿ë¿",6,"ë¿ë¿ë¿ ë¿¢",89,"ì½ì¾ì¿"],
["9841","ì",16,"ì",5,"ììì"],
["9861","ìììì¡",6,"ìª",15],
["9881","ìº",21,"ìììììì",6,"ì¢ì¤ì¦",5,"ì®ì±ì²ì·",4,"ì¾ììììììììììì",6,"ìì",5,"ì¦ì§ì©ìªì«ì­",6,"ì¶ì¸ìº",5,"ììììììì",6,"ììììì",5,"ì¡ì¢ì¥ì¨ì©ìªì«ì®"],
["9941","ì²ì³ì´ìµì·ìºì»ì½ì¾ì¿ì",6,"ìì",5,"ìì"],
["9961","ìììì",6,"ì¦ìª",5,"ì±ì²ì³ìµì¶ì·ì¹ìºì»"],
["9981","ì¼",8,"ì",5,"ìììììì",4,"ìì ì¢ì£ì¤ì¦ì§ìªì«ì­ì®ì¯ì±",11,"ì¾",5,"ììììììì",6,"ììì",6,"ì¡ì¢ì£ì¥ì¦ì§ì©",6,"ì²ì´",7,"ì¾ì¿ìììì",6,"ììì",5,"ììììì¡ì¢ì£"],
["9a41","ì¤ì¥ì¦ì§ìªì¬ì®ì°ì³ìµ",16],
["9a61","ììì",6,"ìììììì",6,"ì¡ì¢ì£ì¤ì¦"],
["9a81","ì§",4,"ì®ì¯ì±ì²ì³ìµ",6,"ì¾ìì",5,"ì",5,"ì",6,"ìììì",5,"ì¦ì§ì©ìªì«ì®",5,"ì¶ì¸ìº",33,"ììì¡ì¢ì¥",5,"ì®ì°ì²ì³ì´ìµì·ìºì½ì¾ì¿ì",6,"ìììì"],
["9b41","ììììììììì",6,"ì¦ì§ìª",8],
["9b61","ì³",17,"ì",7],
["9b81","ì",25,"ìªì«ì­ì®ì¯ì±ì³",4,"ìºì»ì¾",5,"ììììììì",50,"ì",22,"ì"],
["9c41","ìììì¡ì£",4,"ìªì«ì¬ì®",5,"ì¶ì·ì¹",5],
["9c61","ì¿",8,"ì",6,"ì",9],
["9c81","ì",8,"ì¥",6,"ì­ì®ì¯ì±ì²ì³ìµ",6,"ì¾",9,"ì",26,"ì¦ì§ì©ìªì«ì­",6,"ì¶ì·ì¸ìº",5,"ì",18,"ì",6,"ì",12],
["9d41","ìª",13,"ì¹ìºì»ì½",8],
["9d61","ì",25],
["9d81","ì ",8,"ìª",5,"ì²ì³ìµì¶ì·ì¹ì»ì¼ì½ì¾ì",9,"ììììììì",6,"ì",10,"ìªì«ì­ì®ì¯ì±",6,"ìºì¼ì¾",5,"ììììììììììììì¢ì£ì¥ì¦ì§ì©",6,"ì²ì¶",5,"ì¾ì¿ìììììììììììììì"],
["9e41","ìììììììì¡",7,"ìª",9,"ì¶"],
["9e61","ì·ìºì¿",4,"ììììììììì",6,"ì¢ì¤ì¦ì§"],
["9e81","ì¨ì©ìªì«ì¯ì±ì²ì³ìµì¸ì¹ìºì»ìììììììììì",6,"ìì",6,"ì¦ì§ì©ìªì«ì¯ì±ì²ì¶ì¸ìºì¼ì½ì¾ì¿ìììììì",6,"ìì",5,"ììì¡",10,"ì­ì®ì°ì²",5,"ìºì»ì½ì¾ì¿ì",6,"ììì",5,"ìììììì",6,"ì¦"],
["9f41","ì¨ìª",5,"ì²ì³ìµì¶ì·ì»",4,"ììì",5,"ì"],
["9f61","ììììì",6,"ììì¢",5,"ìªì«ì­ì®ì¯ì±ì²"],
["9f81","ì³",4,"ìºì»ì¼ì¾",5,"ìììììì",6,"ììì",5,"ì¢ì£ì¥ì¦ì§ì©",6,"ì²ì´ì¶ì¸ì¹ìºì»ì¾ì¿ìììì",4,"ìììììììììì¡",6,"ì©ìªì¬",7,"ì¶ì·ì¹ìºì»ì¿ììììììììììììì",4,"ì¢ì§",4,"ì®ì¯ì±ì²ì³ìµì¶ì·"],
["a041","ì¸ì¹ìºì»ì¾ì",5,"ììììì",6,"ìììì"],
["a061","ì",5,"ì¥ì¦ì§ì©ìªì«ì­",13],
["a081","ì»",4,"ì ì ì ì ì ì ì ",4,"ì ì ì ",4,"ì ì ì ¡ì ¢ì £ì ¥",6,"ì ®ì °ì ²",5,"ì ¹ì ºì »ì ½ì ¾ì ¿ì¡",6,"ì¡ì¡ì¡",5,"ì¡",26,"ì¡²ì¡³ì¡µì¡¶ì¡·ì¡¹ì¡»",4,"ì¢ì¢ì¢ì¢ì¢ì¢",5,"ì¢",7,"ì¢ì¢ ì¢¢ì¢£ì¢¤"],
["a141","ì¢¥ì¢¦ì¢§ì¢©",18,"ì¢¾ì¢¿ì£ì£"],
["a161","ì£ì£ì£ì£ì£ì£ì£ì£ì£",6,"ì£ì£ì£",5,"ì£¢ì££ì£¥"],
["a181","ì£¦",14,"ì£¶",5,"ì£¾ì£¿ì¤ì¤ì¤ì¤",4,"ì¤ãããÂ·â¥â¦Â¨ãÂ­ââ¥ï¼¼â¼ââââããã",9,"Â±ÃÃ·â â¤â¥ââ´Â°â²â³ââ«ï¿ ï¿¡ï¿¥âââ â¥ââââ¡âÂ§â»ââââââââ¡â â³â²â½â¼âââââãâªâ«ââ½ââµâ«â¬âââââââªâ©â§â¨ï¿¢"],
["a241","ì¤ì¤",5,"ì¤",18],
["a261","ì¤­",6,"ì¤µ",18],
["a281","ì¥",7,"ì¥ì¥ì¥ì¥ì¥ì¥",6,"ì¥¢ì¥¤",7,"ì¥­ì¥®ì¥¯ââââÂ´ï½ËËËËËÂ¸ËÂ¡Â¿Ëâ®ââÂ¤ââ°âââ·â¶â¤â â¡â¥â§â£âââ£ââââ¤â¥â¨â§â¦â©â¨ââââÂ¶â â¡ââââââ­â©âªâ¬ã¿ãâãâ¢ããâ¡â¬Â®"],
["a341","ì¥±ì¥²ì¥³ì¥µ",6,"ì¥½",10,"ì¦ì¦ì¦ì¦ì¦"],
["a361","ì¦",6,"ì¦ì¦ì¦",16],
["a381","ì¦¯",16,"ì§ì§ì§ì§ì§ì§",4,"ì§ì§ì§ì§ì§ï¼",58,"ï¿¦ï¼½",32,"ï¿£"],
["a441","ì§ì§ì§¡ì§£ì§¥ì§¦ì§¨ì§©ì§ªì§«ì§®ì§²",5,"ì§ºì§»ì§½ì§¾ì§¿ì¨ì¨ì¨ì¨"],
["a461","ì¨ì¨ì¨ì¨ì¨",5,"ì¨ì¨ì¨ì¨",12],
["a481","ì¨¦ì¨§ì¨¨ì¨ª",28,"ã±",93],
["a541","ì©",4,"ì©ì©ì©ì©ì©ì©",6,"ì©ì©¢",5,"ì©©ì©ª"],
["a561","ì©«",17,"ì©¾",5,"ìªìª"],
["a581","ìª",16,"ìª",14,"â°",9],
["a5b0","â ",9],
["a5c1","Î",16,"Î£",6],
["a5e1","Î±",16,"Ï",6],
["a641","ìª¨",19,"ìª¾ìª¿ì«ì«ì«ì«"],
["a661","ì«",5,"ì«ì«ì«ì«ì«ì«ì«ì«",5,"ì«¡",6],
["a681","ì«¨ì«©ì«ªì««ì«­",6,"ì«µ",18,"ì¬ì¬ââââââââ¬â¤â´â¼âââââââ£â³â«â»ââ â¯â¨â·â¿ââ°â¥â¸ââââââââââââ¡â¢â¦â§â©âªâ­â®â±â²âµâ¶â¹âºâ½â¾âââ",7],
["a741","ì¬",4,"ì¬ì¬ì¬ì¬ì¬ì¬ì¬",6,"ì¬¢",7],
["a761","ì¬ª",22,"ì­ì­ì­"],
["a781","ì­ì­ì­ì­ì­ì­ì­ì­ì­",6,"ì­ì­ì­ì­",5,"ì­¥",7,"ãããâããã£ã¤ã¥ã¦ã",9,"ããããããããã§ã¨ã°",9,"ã",4,"ãº",5,"ã",4,"â¦ãããããããã­ã®ã¯ãã©ãªã«ã¬ããããããã"],
["a841","ì­­",10,"ì­º",14],
["a861","ì®",18,"ì®",6],
["a881","ì®¤",19,"ì®¹",11,"ÃÃÂªÄ¦"],
["a8a6","Ä²"],
["a8a8","Ä¿ÅÃÅÂºÃÅ¦Å"],
["a8b1","ã ",27,"â",25,"â ",14,"Â½ââÂ¼Â¾ââââ"],
["a941","ì¯",14,"ì¯",10],
["a961","ì¯ ì¯¡ì¯¢ì¯£ì¯¥ì¯¦ì¯¨ì¯ª",18],
["a981","ì¯½",14,"ì°ì°ì°ì°ì°ì°",6,"ì°ì°ì° ì°£ì°¤Ã¦ÄÃ°Ä§Ä±Ä³Ä¸ÅÅÃ¸ÅÃÃ¾Å§ÅÅã",27,"â",25,"â´",14,"Â¹Â²Â³â´â¿ââââ"],
["aa41","ì°¥ì°¦ì°ªì°«ì°­ì°¯ì°±",6,"ì°ºì°¿",4,"ì±ì±ì±ì±ì±ì±ì±"],
["aa61","ì±",4,"ì±ì±",5,"ì±¡ì±¢ì±£ì±¥ì±§ì±©",6,"ì±±ì±²"],
["aa81","ì±³ì±´ì±¶",29,"ã",82],
["ab41","ì²ì²ì²ì²ì²ì²ì²ì²ì²ì²¡",6,"ì²ªì²®",5,"ì²¶ì²·ì²¹"],
["ab61","ì²ºì²»ì²½",6,"ì³ì³ì³",5,"ì³ì³ì³ì³",5],
["ab81","ì³",8,"ì³¥",6,"ì³­ì³®ì³¯ì³±",12,"ã¡",85],
["ac41","ì³¾ì³¿ì´ì´",5,"ì´ì´ì´ì´ì´ì´",6,"ì´ì´ì´ì´ì´ "],
["ac61","ì´¡ì´¢ì´£ì´¥ì´¦ì´§ì´©ì´ªì´«ì´­",11,"ì´º",4],
["ac81","ì´¿",28,"ìµìµìµÐ",5,"ÐÐ",25],
["acd1","Ð°",5,"ÑÐ¶",25],
["ad41","ìµ¡ìµ¢ìµ£ìµ¥",6,"ìµ®ìµ°ìµ²",5,"ìµ¹",7],
["ad61","ì¶",6,"ì¶",10,"ì¶ì¶ì¶ì¶ì¶ì¶ì¶ì¶"],
["ad81","ì¶ ì¶¡ì¶¢ì¶£ì¶¦ì¶¨ì¶ª",5,"ì¶±",18,"ì·"],
["ae41","ì·",5,"ì·ì·ì·ì·",16],
["ae61","ì·¢",5,"ì·©ì·ªì·«ì·­ì·®ì·¯ì·±",6,"ì·ºì·¼ì·¾",4],
["ae81","ì¸ì¸ì¸ì¸ì¸ì¸ì¸ì¸",6,"ì¸ì¸ì¸ì¸ì¸",5,"ì¸¢ì¸£ì¸¥ì¸¦ì¸§ì¸©ì¸ªì¸«"],
["af41","ì¸¬ì¸­ì¸®ì¸¯ì¸²ì¸´ì¸¶",19],
["af61","ì¹",13,"ì¹ì¹ì¹ì¹ì¹¢",5,"ì¹ªì¹¬"],
["af81","ì¹®",5,"ì¹¶ì¹·ì¹¹ì¹ºì¹»ì¹½",6,"ìºìºìº",5,"ìºìºìºìºìºìº"],
["b041","ìº",5,"ìº¢ìº¦",5,"ìº®",12],
["b061","ìº»",5,"ì»",19],
["b081","ì»",13,"ì»¦ì»§ì»©ì»ªì»­",6,"ì»¶ì»º",5,"ê°ê°ê°ê°ê°ê°ê°ê°",7,"ê°",4,"ê° ê°¤ê°¬ê°­ê°¯ê°°ê°±ê°¸ê°¹ê°¼ê±ê±ê±ê±ê±ê±ê±°ê±±ê±´ê±·ê±¸ê±ºê²ê²ê²ê²ê²ê²ê²ê²ê²ê²ê²ê²ê²ê²ê²ê² ê²¡ê²¨ê²©ê²ªê²¬ê²¯ê²°ê²¸ê²¹ê²»ê²¼ê²½ê³ê³ê³ê³ê³ê³ê³ ê³¡ê³¤ê³§ê³¨ê³ªê³¬ê³¯ê³°ê³±ê³³ê³µê³¶ê³¼ê³½ê´ê´ê´"],
["b141","ì¼ì¼ì¼ì¼ì¼ì¼",6,"ì¼ì¼ì¼",5,"ì¼ì¼ì¼ì¼¡ì¼¢ì¼£"],
["b161","ì¼¥",6,"ì¼®ì¼²",5,"ì¼¹",11],
["b181","ì½",14,"ì½ì½ì½ì½ì½ì½",6,"ì½¦ì½¨ì½ªì½«ì½¬ê´ê´ê´ê´ê´ê´ê´ ê´©ê´¬ê´­ê´´ê´µê´¸ê´¼êµêµêµêµêµêµêµêµ¡êµ£êµ¬êµ­êµ°êµ³êµ´êµµêµ¶êµ»êµ¼êµ½êµ¿ê¶ê¶ê¶ê¶ê¶ê¶ê¶ê¶ê¶¤ê¶·ê·ê·ê·ê·ê·ê·ê·ê·ê· ê·¤ê·¸ê·¹ê·¼ê·¿ê¸ê¸ê¸ê¸ê¸ê¸ê¸ê¸°ê¸±ê¸´ê¸·ê¸¸ê¸ºê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ê¹ ê¹¡ê¹¥ê¹¨ê¹©ê¹¬ê¹°ê¹¸"],
["b241","ì½­ì½®ì½¯ì½²ì½³ì½µì½¶ì½·ì½¹",6,"ì¾ì¾ì¾ì¾ì¾",5,"ì¾"],
["b261","ì¾",18,"ì¾¢",5,"ì¾©"],
["b281","ì¾ª",5,"ì¾±",18,"ì¿",6,"ê¹¹ê¹»ê¹¼ê¹½êºêºêºêº¼êº½êº¾ê»ê»ê»ê»ê»ê»ê»ê»ê»ê»ê»¨ê»«ê»­ê»´ê»¸ê»¼ê¼ê¼ê¼ê¼ê¼¬ê¼­ê¼°ê¼²ê¼´ê¼¼ê¼½ê¼¿ê½ê½ê½ê½ê½ê½ê½ê½ê½¤ê½¥ê½¹ê¾ê¾ê¾ê¾ê¾ê¾ê¾ê¾¸ê¾¹ê¾¼ê¿ê¿ê¿ê¿ê¿ê¿ê¿ê¿ê¿ê¿¨ê¿©ê¿°ê¿±ê¿´ê¿¸ëëëëëëëëë¨ëëëëëëëëëëë"],
["b341","ì¿",19,"ì¿¢ì¿£ì¿¥ì¿¦ì¿§ì¿©"],
["b361","ì¿ª",5,"ì¿²ì¿´ì¿¶",5,"ì¿½ì¿¾ì¿¿íííí",5],
["b381","í",5,"í",5,"í",19,"ëë¼ë½ëëëëëëëëëëëë ë¡ë¢ë¨ë©ë«",4,"ë±ë³ë´ëµë¸ë¼ëëëëëëëëëë ë¥ëëëëëëëëëëëëë£ë¤ë¥ë¨ë¬ë´ëµë·ë¸ë¹ëëëëëëëëëëë ë¸ë¹ë¼ëëëëëëëëëëëë¨ëëëëë"],
["b441","í®",5,"í¶í·í¹íºí»í½",6,"ííí",5],
["b461","ííííííí",6,"í¡",10,"í®í¯"],
["b481","í±í²í³íµ",6,"í¾í¿íí",18,"ëë¨ë©ë¬ë°ë¹ë»ë½ëëëëëëëëëë ë´ë¼ëëë ë¨ë©ë´ëµë¼ëëëëëëëëëë ë¡ë£ë¥ë¦ëªë¬ë°ë´ëëëëëëëëëë¢ë¤ë¥ë¦ë¨ë«",4,"ë³ë´ëµë·",4,"ë¿ëëëëëëëëëëëëëëëëëëë¤ë¥"],
["b541","í",14,"í¦í§í©íªí«í­",5],
["b561","í³í¶í¸íº",5,"íííííí",5,"íí",4],
["b581","íííí¡í¢í£í¥",6,"í®í²",5,"í¹",11,"ë§ë©ë«ë®ë°ë±ë´ë¸ëëëëëëëëë ë¡ë¨ë¬ëëëëëëëëëëëëëë ë¤ë¨ë¼ëëëë ë¨ë©ë«ë´ëëëëë ë¡ë£ë¥ë¬ëëëë¤ë¨ë¬ëµë·ë¹ëëëëëëëë ë£ë¤ë¦ë¬ë­ë¯ë±ë¸ëëëëëë¤ë¥ë§ë¨ë©ëªë°ë±ë´ë¸"],
["b641","í",7,"í",17],
["b661","í ",15,"í²í³íµí¶í·í¹í»í¼í½í¾"],
["b681","í¿íí",5,"íííííí",6,"íí í¢",5,"í©íªí«í­ëëëëëëëëëëëëëë ë¡ë ë¡ë¤ë¨ëªë«ë°ë±ë³ë´ëµë»ë¼ë½ëëëëëëëëë¬ëëëëë¥ë¬ë´ëë¤ë¨ëëë ë¤ë«ë¬ë±ëë°ë´ë¸ëëëë¨ë©ë¬ë¯ë°ë¸ë¹ë»ëëëëëë ë¤ë¨ë°ë±ë³ëµë¼ë½ëëëëëëëëëë"],
["b741","í®",13,"í½",6,"ííííí"],
["b761","í",20,"í¢í£í¥í¦í§"],
["b781","í©",6,"í²í´í¶í·í¸í¹í»í½í¾í¿í",14,"ëëëë ë¨ë©ë«ë¬ë­ë´ëµë¸ëëë¬ë­ë°ë´ë¼ë½ë¿ë ë ë ë ë ë ë ë ë ë ë ë ¤ë ¥ë ¨ë ¬ë ´ë µë ·ë ¸ë ¹ë¡ë¡ë¡ë¡ë¡ë¡ë¡ ë¡¤ë¡¬ë¡­ë¡¯ë¡±ë¡¸ë¡¼ë¢ë¢¨ë¢°ë¢´ë¢¸ë£ë£ë£ë£ë£ë£ë£ë£ë£ë£¡ë£¨ë£©ë£¬ë£°ë£¸ë£¹ë£»ë£½ë¤ë¤ë¤ ë¤¼ë¤½ë¥ë¥ë¥ë¥ë¥ë¥ë¥ë¥ë¥ ë¥¨ë¥©"],
["b841","í",7,"í",17],
["b861","í«",8,"íµí¶í·í¹",13],
["b881","íí",5,"í",24,"ë¥«ë¥­ë¥´ë¥µë¥¸ë¥¼ë¦ë¦ë¦ë¦ë¦ë¦ë¦ë¦¬ë¦­ë¦°ë¦´ë¦¼ë¦½ë¦¿ë§ë§ë§ë§ë§",4,"ë§ë§ë§ë§ë§ë§¡ë§£ë§¤ë§¥ë§¨ë§¬ë§´ë§µë§·ë§¸ë§¹ë§ºë¨ë¨ë¨ë¨ë¨¸ë¨¹ë¨¼ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©ë©¤ë©¥ë©§ë©¨ë©©ë©°ë©±ë©´ë©¸ëªëªëªëªëªëª¨ëª©ëª«ëª¬ëª°ëª²ëª¸ëª¹ëª»ëª½ë«ë«ë«ë«ë«¼"],
["b941","íªí«í®í¯í±í²í³íµ",6,"í¾íí",5,"íííí"],
["b961","í",14,"í",6,"í¥í¦í§í¨"],
["b981","í©",22,"íííííííííë¬ë¬ë¬ë¬ë¬ë¬ë¬ë¬ ë¬©ë¬«ë¬´ë¬µë¬¶ë¬¸ë¬»ë¬¼ë¬½ë¬¾ë­ë­ë­ë­ë­ë­ë­ë­ë­ë­¡ë­£ë­¬ë®ë®ë®ë®¤ë®¨ë®¬ë®´ë®·ë¯ë¯ë¯ë¯ë¯ë¯¸ë¯¹ë¯¼ë¯¿ë°ë°ë°ë°ë°ë°ë°ë°ë°ë°",4,"ë°",4,"ë°¤ë°¥ë°§ë°©ë°­ë°°ë°±ë°´ë°¸ë±ë±ë±ë±ë±ë±ë±ë±ë±ë±ë²ë²ë²ë²ë²ë²ë²ë²ë²"],
["ba41","ííííííí",5,"íííí¡í¢í£í¥",6,"í­"],
["ba61","í®í¯í°í²",5,"íºí»í½í¾íí",4,"íí",5],
["ba81","ííííííííí",6,"í¦",9,"í²í³íµí¶í·í¹íºë²ë²ë² ë²¡ë²¤ë²§ë²¨ë²°ë²±ë²³ë²´ë²µë²¼ë²½ë³ë³ë³ë³ë³ë³ë³ë³ë³ë³´ë³µë³¶ë³¸ë³¼ë´ë´ë´ë´ë´ë´ë´¤ë´¬ëµëµëµëµëµëµëµëµ¤ëµ¨ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶ë¶¤ë¶°ë¶¸ë·ë·ë·ë·ë·©ë·°ë·´ë·¸ë¸ë¸ë¸ë¸ë¸ë¸ë¸ë¸ë¸ë¸ë¹ë¹ë¹ë¹ë¹ë¹ë¹ë¹ë¹ë¹ë¹ë¹ ë¹¡ë¹¤"],
["bb41","í»",4,"ííí",5,"íííííí",4,"íí¢í£"],
["bb61","í¤í¦í§íªí«í­í®í¯í±",6,"íºí¾",5,"íííí"],
["bb81","í",31,"ë¹¨ë¹ªë¹°ë¹±ë¹³ë¹´ë¹µë¹»ë¹¼ë¹½ëºëºëºëºëºëºëºëºëºëº¨ë»ë»ë»ë»ë»ë» ë»£ë»¤ë»¥ë»¬ë¼ë¼ë¼ë¼ë¼ë¼ë¼ë¼ë½ë½ë½ë½ë½ë½ë½ë¾ë¾°ë¿ë¿ë¿ë¿ë¿ë¿ë¿ë¿¡ì¼ìììì ì¨ì©ììììì ì¡ì£ì¥ì¬ì­ì¯ì°ì³ì´ìµì¶ì¼ì½ì¿ììììììììììììì¤"],
["bc41","íª",17,"í¾í¿íííííí"],
["bc61","íííííí",5,"íííííí¡",6,"íªí¬í®"],
["bc81","í¯",4,"íµí¶í·í¹íºí»í½",6,"ííí",5,"í",5,"ì¥ì¨ì¬ì´ìµì·ì¹ìììììì",4,"ì£ì¤ì¦ì§ì¬ì­ì¯ì°ì±ì¶ì¸ì¹ì¼ììììììììììì¤ì¥ì§ì¨ì©ì°ì´ì¸ììììììììììì¡ì¥ì¨ì©ì¬ì°ì½ììììììì ì¤ì¨ì°ì±ì³ì¼ì½ììììììììììì ì¨ì©ì«ì­"],
["bd41","íí",7,"í¢í¤",7,"í®í¯í±í²í³íµí¶í·"],
["bd61","í¸í¹íºí»í¾íí",5,"í",13],
["bd81","í",5,"í",25,"ì¯ì±ì²ì´ìììììì ì¥ì¬ì­ì°ì´ì¼ì½ì¿ìììììììì¤ì¥ì¨ì¬ì­ì´ìµì·ì¹ììì ì£ì¤ì«ì¬ì­ì¯ì±ì¶ì¸ì¹ì»ì¼ììììììììììì¤ì¥ì¨ì©ìì¨ì©ì¬ì°ì²ì¸ì¹ì¼ì½ììììììììì ì¢ì¨ì©ì­ì´ìµì¸ììì¤ì¬ì°"],
["be41","í¸",7,"íííí",14],
["be61","í",7,"íííí¡í¢í£í¥",7,"í®í°í±í²"],
["be81","í³",4,"íºí»í½í¾íí",4,"ííí",5,"í",8,"ì´ì¼ì½ìì¤ì¥ì¨ì¬ì´ìµì¹ìììì¸ì¼ì©ì°ì±ì´ì¸ìºì¿ììììììì¨ì©ì¬ì°ì¸ì¹ì»ì½ììììììììììììììììì ì¡ì¤ì¨ì°ì±ì³ì´ìµì¼ì½ìììììììììììì ì©ì´ìµì¸ì¹ì»ì¼ì½ì¾ì",6,"ìì"],
["bf41","í",10,"íª",14],
["bf61","í¹",18,"ííííííí"],
["bf81","í",5,"ííí ",7,"í©íªí«í­í®í¯í±",6,"í¹íºí¼ììììì ì¡ì£ì¥ì¬ì­ì®ì°ì´ì¶ì·ì¼",5,"ììììììììììì¤ì¥ì¨ì¬ì­ì®ì°ì³ì´ìµì·ì¹ì»ìììììììììììì ì¬ì¯ì±ì¸ì¹ì¼ìììììììììì¤ì¥ì§ì©ì°ì±ì´ì¸ì¹ìºììììììììììì ì¡ì¨"],
["c041","í¾",5,"ííííííí",6,"íí",5],
["c061","í",25],
["c081","í¸í¹íºí»í¾í¿íííí",6,"ííí",5,"íííííí¡í¢í£ì©ì¬ì°ì¸ì¹ì½ììììììììì ì¡ì¤ì¨ì°ì±ì³ìµì·ì¼ì½ììììììì",7,"ìì ì¨ì«ì´ìµì¸ì¼ì½ì¾ìììììììììììììììì ì¡ì£ì¤ì¥ì¦ì¬ì­ì°ì´ì¼ì½ì¿ìììììììììì¤ì¨ì¬ì ì ì ì ì "],
["c141","í¤í¦í§íªí¬í®",5,"í¶í·í¹íºí»í½",6,"ííí"],
["c161","ííííí",19,"í¦í§"],
["c181","í¨",31,"ì ì ì ì ì ì ì ì  ì ¤ì ¬ì ­ì ¯ì ±ì ¸ì ¼ì¡ì¡ì¡ì¡ì¡ì¡ì¡°ì¡±ì¡´ì¡¸ì¡ºì¢ì¢ì¢ì¢ì¢ì¢ì¢ì¢ì¢ì¢ì¢ì¢ì¢¡ì¢¨ì¢¼ì¢½ì£ì£ì£ì£ì£ì£ì£ì£ ì£¡ì£¤ì£µì£¼ì£½ì¤ì¤ì¤ì¤ì¤ì¤ì¤ì¤ì¤ì¤¬ì¤´ì¥ì¥ì¥ì¥ì¥ ì¥¡ì¥£ì¥¬ì¥°ì¥´ì¥¼ì¦ì¦ì¦ì¦ì¦ì¦ì¦ì¦ì§ì§ì§ì§ì§ì§ì§ì§ì§"],
["c241","ííííííí",4,"ííí",5,"í¦í§í©íªí«í­í®"],
["c261","í¯",4,"í¶í¸íº",5,"íííííí",6,"í"],
["c281","í",5,"íííí¡í¢í£í¥",7,"í®",9,"íºí»ì§ì§ì§ì§ì§ì§ì§ ì§¢ì§¤ì§§ì§¬ì§­ì§¯ì§°ì§±ì§¸ì§¹ì§¼ì¨ì¨ì¨ì¨ì¨ì¨ì¨ì¨ì¨©ì©ì©ì©ì©ì©ì©ì©ì© ì©¡ì©¨ì©½ìªìªìª¼ìª½ì«ì«ì«ì«ì«ì«ì«ì«ì«ì« ì«¬ì«´ì¬ì¬ì¬ì¬ì¬ ì¬¡ì­ì­ì­ì­ì­ì­ì­ì­ì­¤ì­¸ì­¹ì®ì®¸ì¯ì¯¤ì¯§ì¯©ì°ì°ì°ì°ì°ì°ì°¡ì°¢ì°§ì°¨ì°©ì°¬ì°®ì°°ì°¸ì°¹ì°»"],
["c341","í½í¾í¿ííííííííííííííííííí",4],
["c361","í¢",4,"í¨íª",5,"í²í³íµ",11],
["c381","íííí",5,"íííííí",7,"íí í¢",5,"í©íªì°¼ì°½ì°¾ì±ì±ì±ì±ì±ì±ì±ì±ì±ì± ì±¤ì±¦ì±¨ì±°ì±µì²ì²ì²ì² ì²¨ì²©ì²«ì²¬ì²­ì²´ì²µì²¸ì²¼ì³ì³ì³ì³ì³ì³ì³¤ì³¬ì³°ì´ì´ì´ì´ì´ì´ì´ì´ì´ì´¤ì´¨ì´¬ì´¹ìµìµ ìµ¤ìµ¬ìµ­ìµ¯ìµ±ìµ¸ì¶ì¶ì¶ì¶ì¶ì¶¤ì¶¥ì¶§ì¶©ì¶°ì·ì·ì·ì·¨ì·¬ì·°ì·¸ì·¹ì·»ì·½ì¸ì¸ì¸ì¸ì¸ì¸ ì¸¡ì¸¤ì¸¨ì¸°ì¸±ì¸³ì¸µ"],
["c441","í«í­í®í¯í±",7,"íºí¼",7,"ííííí"],
["c461","íííííííííí",5,"í¡í¢í£í¥í¦í§í©",4],
["c481","í®í¯í±í²í³í´í¶",5,"í¾í¿íííí",11,"íííì¹ì¹ì¹ì¹ì¹ ì¹¡ì¹¨ì¹©ì¹«ì¹­ì¹´ì¹µì¹¸ì¹¼ìºìºìºìºìºìºìºìºìº ìº¡ìº£ìº¤ìº¥ìº¬ìº­ì»ì»¤ì»¥ì»¨ì»«ì»¬ì»´ì»µì»·ì»¸ì»¹ì¼ì¼ì¼ì¼ì¼ì¼ì¼ì¼ì¼ì¼ ì¼¤ì¼¬ì¼­ì¼¯ì¼°ì¼±ì¼¸ì½ì½ì½ì½ì½¤ì½¥ì½§ì½©ì½°ì½±ì½´ì½¸ì¾ì¾ì¾ì¾¡ì¾¨ì¾°ì¿ì¿ ì¿¡ì¿¤ì¿¨ì¿°ì¿±ì¿³ì¿µì¿¼ííííí­í´íµí¸í¼"],
["c541","ííííííííí¡",6,"íªí¬í®",5,"í¶í·í¹"],
["c561","íºí»í½",6,"íííí",5,"íííí",4],
["c581","íí¢í¤í¦í§í¨íªí«í­í®í¯í±í²í³íµ",6,"í¾í¿íí",5,"íííííííííí í¬í­í°í´í¼í½íí¤í¥í¨í¬í´íµí·í¹ííííííííííííí í¤í¬í­í¯í°í±í¸íí°í±í´í¸íºííííííííííííí¡í¨í¬í¼ííí í¡í¤í¨í°í±í³íµíºí¼ííí´í¸íííí¬í­í°í´í¼í½í¿ííí"],
["c641","íííí",6,"ííí",5],
["c6a1","í¤ííííííííí í¤í¬í±í¸í¹í¼í¿ííííííííí¤í¥í°í±í´í¸íííííííííííííí í¡í¥í¨í©í¬í°í¸í¹í»í¼í½ííí¼í½ííííííííííí í¨í©í«í­í´í¸í¼ííííííí¡í£í¬í­í°í´í¼í½í¿í"],
["c7a1","íííííí í¤í­í¯í¸í¹í¼í¿íííííííí©íííííí¨í¬í°í¸í»í½ííííííí¼í½íííííííííí í¥í¨í©í«í­í´íµí¸í¼ííííííí¥íííííííííí¤í¥í¨í¬í´íµí·í¹ííííííííííí "],
["c8a1","í¤í­í¸í¹í¼íííííííííííí§í©í°í±í´ííííííííí¡í¨í¬í°í¹í»ííííííííí í¤í¨í°íµí¼í½ííííííí í¨í©í«í­í´íµí¸í¼ííííííííííí í¡í£í¥í©í¬í°í´í¼í½ííííííííí"],
["caa1","ä¼½ä½³åå¹å å¯åµå¥åå«å®¶ææ¶æ·æ¯æ­ççç¨¼èèè¡è¢è¨¶è³è·è»»è¿¦é§å»å´åæªæ¤æ®¼çèè¦ºè§é£ä¾åå¢¾å¥¸å§¦å¹²å¹¹ææææ¬æ¡¿æ¾ççç£µç¨ç«¿ç°¡èè®è±è««éä¹«åæ·æ¸´ç¢£ç«­èè¤èé¨ååå ªåµææ¾æ¡æ¢ææ©æ¸çç³ç£ç°ç´ºé¯ééé¾"],
["cba1","å£å²¬ç²èééåå å§å²¡å´åº·å¼ºå½æ·æ±çºçç³ çµ³ç¶±ç¾èè¡èè¥è¬é¼éé±ä»ä»·åå±å¡æ·æ¾æ¨æ¹æ§ªæ¼ç¥ççç®è¥èï¤é§éåå®¢åï¤ç²³ç¾¹éµå¨å»å±å·¨ææ®ææ§æ¸ ç¬ç¥è·è¸ï¤é½éé¸ä¹¾ä»¶å¥å·¾å»ºææ¥è±èè¹éµé¨«ä¹åæ°æ¡åååæª¢"],
["cca1","ç¼éé»å«æ¯è¿²åæ©æ­ææ ¼æªæ¿èè¦¡éå ç½ç¬ççµ¹ç¹­è©è¦è­´é£éµææ±ºæ½çµç¼ºè¨£å¼æç®è¬ééäº¬ä¿åå¾åååå¿å°å¢åºå¾æ¶æ¬ææ¬æ¯æ»æ´æ¢æ¶çç±çç¥ççç¡¬ç£¬ç«ç«¶çµç¶èè¿èèè­¦è¼éé¡é é ¸é©é¯¨ä¿åå ºå¥å­£å±æ¸ææ¡æ¢°"],
["cda1","æ£¨æºªçç¸ç£ç¨½ç³»ç¹«ç¹¼è¨èª¡è°¿éé·å¤å©åå±åºå§å­¤å°»åº«æ·æ·ææ²æ æ¯æ§æ²½ç¼çç¾ç¨¿ç¾èè¡èè¦è½è°èè ±è¢´èª¥ï¤è¾é®éé¡§é«é¼å­ææ²æ¢ç©è°·éµ å°å¤å´ææ¢±æ£æ»¾ç¨è¢é¯¤æ±¨ï¤éª¨ä¾å¬å±åå­å·¥ææ­æ±æ§æ»çç©ºè£è²¢éä¸²å¯¡ææç"],
["cea1","ç§èèªèª²è·¨ééé¡å»æ§¨è¿é­ï¤å å®å¯¬æ£æ£ºæ¬¾çç¯çç®¡ç½èè§è²«éé¤¨å®ææ¬éä¾åå¡å£å»£æ æ´¸çççç­è±éå¦æç½«ä¹åå¡å£æªæ§ææ§é­å®ç´è±è½äº¤åå¬å¬å¬å¶ å·§æªææ ¡æ©ç¡çç¯çµç¿¹è èèè¼è½éé¤é©é®«ä¸ä¹ä¹ä»ä¿±å·å¾"],
["cfa1","åå£å¥åååµå¢å¯å¶å»æ¼æææ¸æ©æ§æ­æ¯æ¯¬æ±æºç¸çççç¿ç©ç©¶çµ¿èè¼èèèè¡¢è¬³è³¼è»éé±é¤é¶é§é©é³©é·é¾åå±èé é«éº´åçªç¾¤è£è»é¡å å±æçªå®®å¼ç©¹çª®èèº¬å¦å¸å¸å·åæ³æ²æ¬æ·ç·å¥çè¨è¹¶éæºæ«æ½°è©­è»é¥ï¤æ·æ­¸è²´"],
["d0a1","é¬¼ï¤å«å­å¥ææ§»çªç¡çªºç«ç³¾èµè¦èµ³éµé¨å»åçç­ èéï¤æ©åååææ£æ¥µéåå¤å¤ææ¤æ ¹æ§¿ç¾ç­è¹è«è¦²è¬¹è¿é¥ï¤ä»å¦æææªç´ç¦ç¦½è©è¡¾è¡¿è¥ï¤é¦ä¼åæ¥æ±æ±²ç´çµ¦äºå¢çè¯ä¼ä¼å¶ååå¨å»åºå¼å¤å¥å¦å¯å²å´å·±å¹¾å¿æææ£"],
["d1a1","ææææ£æ£æ©æ¬ºæ°£æ±½æ²æ·çç¦çªçç£ç¸ç¿ç¢ç£¯ç¥ç¥ç¥ç¥ºç®ç´ç¶ºç¾èè­èè¨è­è±èµ·é¡é¤é£¢é¥é¨é¨é©¥éºç·ä½¶åæ®æ¡éå«åºï¤ï¤å¨æ¦ï¤ææ¿ï¤",5,"é£ï¤",4,"è«¾ï¤ï¤ï¤ï¤æï¤çï¤ï¤é£ï¤ ææºåï¤¡ææ¥ æ¹³ï¤¢ç·ï¤£ï¤¤ï¤¥"],
["d2a1","ç´ï¤¦ï¤§è¡²åå¨ï¤¨",4,"ä¹ï¤­å§å¥æ°èï¤®å¥³å¹´æç§å¿µæ¬ææ»å¯§å¯åªï¤¯å¥´å¼©æï¤°ï¤±ï¤²çï¤³",5,"é§ï¤¹",10,"æ¿ï¥ï¥è¿è¾²æ±ï¥ï¥è¦ï¥ï¥å°¿ï¥",7,"å«©è¨¥æ»ç´ï¥",5,"è½ï¥ï¥å°¼æ³¥å¿æººå¤è¶"],
["d3a1","ä¸¹äº¶ä½å®åå£å½æ·æ¦æªæ®µæ¹ç­ç«¯ç°ç·èè¢é²éæ»æ¾¾çºç¸éååæºæææ·¡æ¹æ½­æ¾¹ç°èè½èè¦è«è­éæ²çç­è¸éåå å¡å¹¢æææ£ ç¶ç³è³é»¨ä»£åå®å¤§å°å²±å¸¶å¾æ´æ¡ç³èºè¢è²¸éé»å®å¾·æ³ååå°åå µå¡å°å± å³¶å¶åº¦å¾æ¼ææææ¡"],
["d4a1","æ£¹æ«æ·æ¸¡æ»æ¿¤ç¾çç¹ç¦±ç¨»èè¦©è³­è·³è¹éééé½éé¶éæ¯ççç¢ç¨ç£ç¦¿ç¯¤çºè®å¢©ææ¦æ½æ¾æ²ççè±é ä¹­çªä»å¬åååæ§æ±æ¡æ£æ´æ½¼ç¼ç³ç«¥è´è£éåæææçç«è³ï¥è±éé ­å±¯èèéé¯éå¾å¶æ©çç»ç­è¤è¬é§é¨°åæ¶ï¥ç©ç¾"],
["d5a1","è¿èºè£¸éï¥æ´çççµ¡è½ï¥éªé§±ï¥äºåµæ¬æ¬ç¾çè­é¸åè¾£åµæ¥æ¬æ¬æ¿«ç±çºèè¥¤è¦½æèè å»ææµªç¼çç¯èéä¾å´å¾ èå·æ ç¥äº®åå©åæ¢æ¨ç²®ç²±ç³§è¯è«è¼éä¾¶å·åµåå»¬æ®æ¾ææ«æ¿¾ç¤ªèè £é­é©¢é©ªéºé»åææ­·çç¤«è½¢éæææ£æ¼£"],
["d6a1","ççç·´è¯è®è¼¦é£éå½åå£æ´çè£å»ææ®®æ¿ç°¾çµä»¤ä¼¶å¹ï¥å²ºå¶ºæç²ç¬­ç¾ç¿èéé´é¶éé é½¡ä¾æ¾§ç¦®é´é·åï¥ æææ«æ½ççç§èèèè·¯è¼é²é­¯é·ºé¹µç¢ç¥¿ç¶ èéé¹¿éºè«å£å¼æ§ç§çç± è¾å¡ç¨ç¢ç£è³è³è³´é·äºåå¯®å»æççç­èè¼"],
["d7a1","é¼é¬§é¾å£å©å±¢æ¨æ·æ¼ç»ç´¯ç¸·èè¤¸é¤éåææ³æ¦´æµæºççç çç¤ç¡«è¬¬é¡å­æ®é¸ä¾å«å´æ·ªç¶¸è¼ªå¾ææ ï¥¡éåèååæ¥ç¨ç¶¾è±éµä¿å©åååå±¥æ§ææ¢¨æµ¬çç¸ççï¥¢ç¢ç±¬ç½¹ç¾¸èè£è£¡ééé¢é¯åæ½¾ççèºèºªé£é±éºææ·ç³è¨éç ¬"],
["d8a1","ç«ç¬ ç²æ©çªç²ç¢¼ç£¨é¦¬é­éº»å¯å¹æ¼ èè«éä¸åå¨©å·å½æ¢æ½æ©æ¼æ»¿æ¼«ç£çè¬èè »è¼é¥é°»åæ¹æ«æ²«èè¥ªéºäº¡å¦å¿å¿æç¶²ç½èè«è½è¼éåå¦¹åªå¯æ§ææ¢æ¯ç¤ç½µè²·è³£éé­èè²éé©éº¥å­æ°çç²çèåªè¦åååæ£æ²çç ç¶¿ç·¬é¢éºµæ»"],
["d9a1","èå¥åå½æææ¤§æºç¿çèèèé©éé³´è¢ä¾®ååå§å¸½ææ¸æ¹æ®ææ¨¡æ¯æ¯çç¡çç¸çèè¼èè¬è¬¨è²æ¨æ²ç§ç®ç¦ç©é¶©æ­¿æ²å¤¢æ¦èå¯å¢å¦å»ææ´æ³æ¸ºç«ç«èé¨åå·«æ®ææææ«æ æ¥æ­¦æ¯ç¡ç·çç¹èèèªèª£è²¿é§éµ¡å¢¨é»ååå»åæ"],
["daa1","æ±¶ç´ç´èèéé¯å¿æ²ç©å³åªå°¾åµå½å¾®æªæ¢¶æ¥£æ¸¼æ¹çç±³ç¾èè¬è¿·é¡é»´å²·æ¶ææ«ææ»æ¼æ°æ³¯ççç·¡éå¯èè¬ååæææ²æ´æ¨¸æ³ççç®ç²ç¸èè¶èè¿«é¹é§ä¼´åååææ¬æææ§æ³®æ½ç­çç¢ç¤ç¼ç£ç£»ç¤¬çµè¬è è¿é é£¯åææ¥æ¸¤æ½"],
["dba1","ç¼è·é±é¢é«®é­å£ååå¦¨å°¨å¹å½·æ¿æ¾æ¹ææææ¦æ»ç£ç´¡èªèè«è³è¡èè¨ªè¬é¦é²é¾åä¿³ï¥£å¹å¾æææ¯æ¹ççèèè£´è£µè¤è³ è¼©ééªä¼¯ä½°å¸ææ ¢ç½ç¾é­å¹¡æ¨ç©ççªï¥¤ç¹èè©é£ä¼ç­ç½°é¥å¡å¸æ¢µæ°¾æ±æ³ç¯ç¯èæ³çºå»åå£ææªç§ç"],
["dca1","ç¢§èé¢é¹ï¥¥åå¼è®è¾¨è¾¯éå¥ç¥é±é¼ä¸ååµå±å¹·ææºææ£ç³ççç§ç«è¼§é¤ é¨ä¿å ¡å ±å¯¶æ®æ­¥æ´æ¹ºæ½½ç¤ç«è©è£è¤è­è¼ä¼åååå®å¾©æç¦è¹è¯èè¤è¦è¼¹è¼»é¦¥é°æ¬ä¹¶ä¿¸å¥å°å³¯å³°æ§æ£ç½ç¢ç«ç¸«è¬èé¢éé³³ä¸ä»ä¿¯ååå¯å¦åå å¤«å©¦"],
["dda1","å­å­µå¯åºï¥¦æ¶æ·æ§æµ®æº¥ç¶ç¬¦ç°¿ç¼¶èèèèèè©è¨è² è³¦è³»èµ´è¶ºé¨éééé§é³§ååå©å´å¢³å¥å¥®å¿¿æ¤æ®ææ±¾ççç²ç³ç´è¬è³é°ï¥§ä½å¼å½¿æå´©ææ£ç¡¼ç¹éµ¬ä¸åååªåå¦å©¢åºæ²æææ¹æææ¦§æ¯æ¯æ¯æ¯æ²¸ï¥¨çµçºç ç¢ç§ç§ç²ç·ç¿¡è¥"],
["dea1","è¾èè²èè£¨èª¹è­¬è²»ééé£é¼»å¬å¬ªå½¬ææª³æ®¯æµæ¿±ççç­è²§è³é »ææ°·èé¨ä¹äºäºä»ä¼ºä¼¼ä½¿ä¿å¿å²å¸åå£åå£«å¥¢å¨å¯«å¯ºå°å·³å¸«å¾ææ¨ææ¯æ¶æ»æ¢­æ­»æ²æ³æ¸£ççç ç¤¾ç¥ç¥ ç§ç¯©ç´çµ²èèèèèè£è©è©è¬è³èµ¦è¾­éªé£¼é§éºåï¥©æï¥ª"],
["dfa1","ååªå±±æ£æ±çç£çç®èé¸é°ä¹·ææ®ºçè©ä¸ï¥«ææ£®æ¸èèè¡«æ·æ¾éé¢¯ä¸å·ååååªåå­å°å³ å¸¸åºåº å»æ³æ¡æ©¡æ¹ç½ççç¸ç¥¥ç®±ç¿è£³è§´è©³è±¡è³éå¡ç½è³½åï¥¬ç©¡ç´¢è²ç²çç¥ï¥­ç¬å¢å£»å¶¼åºåº¶å¾æææ¿ææææ¸æ æ£²ççç­®çµ®ç·ç½²"],
["e0a1","è¥èè¯è¥¿èªéé¤é»é¼ å¤å¥­å¸­æææ³ææ±æ·æ½ç³ç¢©èéé«ä»åååå¬å®£ææ¾ææ¸²ç½çççç¿ç¬ç¦ªç·ç¹ç¾¨èºè³è¹èè¬è©µè·£é¸éé¥é¥é®®å¨å±æ¥æ³æ´©æ¸«èèè¤»è¨­èªªéªé½§å¡æ¹æ®²çºè¾è´ééææ¶ç®ï¥®åå§å®¬æ§æºæææç©ç¹ççç­¬"],
["e1a1","èè²è¥èª éä¸å¢æ­²æ´ç¨ç¬¹ç´°ï¥¯è²°å¬å¯å¡å®µå°å°å·¢ææææ­æ¢³æ²¼æ¶æº¯çç¤çç¦çççç¬ç¯ ç°«ç´ ç´¹è¬è­èè¨´éé¡éµé·é¶é¨·ä¿å±¬ææ¶ç²çºè¬è´éå­«å·½æèéé£¡çå®ææ¾æ·è¨èª¦éé å·ï¥°çç¢éè¡°éä¿®åå½ååå£½å«å®å²«å³å¸¥æ"],
["e2a1","æææææ¶æ¸æ¨¹æ®æ°´æ´æ¼±ç§ç©ç¸çç²ç¦ç¡ç§ç©ç«ªç²¹ç¶ç¶¬ç¹¡ç¾è©è±èèèªè¢èª°è®è¼¸ééé¬éé¹éé§é¨ééé é¦é«é¬åå¡¾å¤å­°å®¿æ·æ½çç¡ç¹èè½å·¡å¾å¾ªææ¬æ æ¥¯æ©æ®æ´µæ·³ç£ç¾ç¬ç­ç´è£èèè´è£è©¢è«ééé é¦´æè¡è¿°é¥å´å´§"],
["e3a1","åµ©çèè¨æ¿æ¾ç¿è¤¶è¥²ä¸ä¹å§ååæ¿æç¹©è éä¾åå¶å§åª¤å°¸å±å±å¸å¼ææ½æ¯ææ¾æ´çç¢ç¤ºç¿èèè¦è©¦è©©è«¡è±è±ºå´å¯å¼æ¯æ­æ¤æ®æ¹çç¯èè­è»¾é£é£¾ä¼¸ä¾ä¿¡å»å¨ å®¸æ¼æ°æ¨ç¼ç³ç¥ç´³èè£èèªèèè¨èº«è¾ï¥±è¿å¤±å®¤å¯¦æå¯©å°å¿æ²"],
["e4a1","ï¥²æ·±ççè¯è«¶ä»åï¥³éæ°äºä¿ååå¨¥å³¨æçè½èªè¾è¡è¨é¿éé¤é´éµå å²³å¶½å¹æ¡ææ¡æ¨æ¸¥ééé¡é°é½·å®å²¸æææ¡ç¼ééé¡é®æ¡è¬è»é¼åµå²©å·åºµæçè´éå£æ¼çé´¨ä»°å¤®ææ»æ®ç§§é´¦åååå´æææ¶¯ç¢è¾ééåæ¼ææ¶²ç¸èé¡"],
["e5a1","æ«»ç½é¶¯é¸ä¹å»å¶å¤æ¹æ¶æ¤°çºè¶ï¥´éå¼±ï¥µï¥¶ç´è¥è¯è»è¥èºï¥·ä½¯ï¥¸ï¥¹å£¤å­ææææ­æï¥ºæ¥æ¨£æ´çç¬ççç¦³ç©°ï¥»ç¾ï¥¼è¥ï¥½è®éé½ï¥¾é¤åå¾¡æ¼æ¼çç¦¦èªé¦­é­é½¬åæ¶ææªèåå °å½¦çè¨è«ºå­¼èä¿ºå¼å´å¥æ©æ·¹å¶ªæ¥­åäºä½ï¥¿ï¦ï¦å¦ï¦"],
["e6a1","ï¦æ­æ±ï¦çµç¤ï¦èèè¹è¼¿è½ï¦é¤ï¦ï¦ï¦äº¦ï¦åå½¹æï¦ï¦ç«ç¹¹è­¯ï¦éé©å¥å §å§¸å¨å®´ï¦å»¶ï¦ï¦ææ»ï¦æ¤½æ²æ²¿æ¶æ¶æ·µæ¼ï¦çç¶çï¦ççï¦ç¡ç¡¯ï¦ç­µç·£ï¦ç¸¯ï¦è¡è»ï¦ï¦ï¦éï¦é³¶ï¦ï¦ï¦ææ¶ï¦ç±ï¦ ï¦¡é±å­ï¦¢ï¦£ï¦¤æï¦¥çç°ç°è¶è"],
["e7a1","ï¦¦é»é«¥é¹½æï¦§çèï¦¨ï¦©å¡ï¦ªï¦«å¶¸å½±ï¦¬æ ææ¥¹æ¦®æ°¸æ³³æ¸¶æ½æ¿çç¯ççç°ï¦­çï¦®ççç©çºï¦¯ï¦°è±è© è¿ï¦±éï¦²éï¦³ï¦´ä¹åªï¦µåå¡æ³æ±­æ¿çç¿ç©¢è®èèï¦¶è£è©£è­½è±«ï¦·é³ï¦¸éé äºä¼ä¿å²åå¾å³åå¡¢å¢ºå¥§å¨å¯¤æï¦¹æææ¿æ¤æ¢§æ±æ¾³"],
["e8a1","çç¬çç­½èèª¤é°²é¼å±æ²ççéºæº«ç¥çç©©ç¸èåå£æç®çç°ç¿ééé¥æ¸¦ç¦çª©çªªè¥èè¸è¨å©å®å®æ¢¡æ¤æµ£ç©çç¬ç¢ç·©ç¿«èèèè±é®é æ°å¾æºææ±ªçå­å¨æ­ªç®å¤åµ¬å·ç¥çï¦ºï¦»å¥å¹å ¯å¤­å¦å§å¯¥ï¦¼ï¦½å¶¢ææææ¾ï¦¾æï¦¿æ©ï§ç¿ç¤ï§"],
["e9a1","çªçª¯ç¹ç¹èè°ï§è¯è¦è¬ éï§éé¥æ¾æ¬²æµ´ç¸è¤¥è¾±ä¿å­åååå¢å®¹åº¸ææ¦æ¶æ¹§æº¶çç¢ç¨ç¬è³è¸èè¸ééï§äºä½å¶åªååå³å®å¯å°¤æææ´ççççç¥ç¦ç¦¹ç´ç¾½èèèè¿ééµéªéé¨é©åå½§æ­æ±æ ¯çç¨¶éé äºï§æ©æ®æ¾çèè¸è"],
["eaa1","ééé²é»èé¬±äºçéååå¡ååå£åªå«å¯æ¨æ¿æ´æ²æ´¹æ¹²æºç°ç¿çèè¢è½é ï§é¢é¡é´æè¶éä½ååå±åå§å¨å°æ°ææ¸­ç²çç·¯èèè¦è¿èè¡è¤è¬ééé­ä¹³ä¾ååªï§å¯å©å­ºå®¥å¹¼å¹½åº¾æ æææææ¸æï§ææï§æ¥¡æ¥¢æ²¹æ´§ï§æ¸¸ï§"],
["eba1","æ¿¡ç¶ç·ï§çç±ï§çï§ï§ç¶­è¾è¸è£èªè«è«­è¸°è¹éé¾éºééé®ï§ï§å ï§æ¯èè²ï§ï§åå¥«å°¹ï§ï§æ½¤ç§è¤è´ï§ééï§ï§ï§ï§è¿æççµ¨èï§å æ©ææ®·èª¾éé±ä¹åæ·«è­é°é³é£®ææ³£éåæèºé·¹ä¾ååå®ææ¿æ¬æ¤æ¯çç£ç¾©è¤èè»è¡£èª¼"],
["eca1","è­°é«äºä»¥ä¼ï§ï§å¤·å§¨ï§å·²å¼å½æ¡ï§ ï§¡ï§¢ï§£ç¾ç¥ï§¤ç°çï§¥ç§»ï§¦èè³èè¡èï§§ï§¨è²½è²³éï§©ï§ªé£´é¤ï§«ï§¬ç·çç¿ç¿ç¿¼è¬äººä»åå°ï§­å½å å§»å¯å¼å¿æ¹®ï§®ï§¯çµªèµï§°èèªï§±é­é·ï§²ï§³ä¸ä½ä½¾å£¹æ¥æº¢é¸é°é¦¹ä»»å£¬å¦å§æï§´ï§µç¨ï§¶èè³å¥å"],
["eda1","ï§·ï§¸ï§¹ä»å©å­è¿ä»åºå¨å§å§¿å­å­å­æ£ææ»çç®çç·çµç£ç´«èèªè¨èèè«®è³éä½åºå¼æ«æ¨ç¼ç¸çµç¶½èéééµ²å­±æ£§æ®æ½ºçå²æ«æ½ç®´ç°ªè ¶éä¸ä»å å ´å¢»å£¯å¥¬å°å¸³åºå¼µææ²ææ¨æª£æ¬æ¼¿çï§ºççç« ç²§è¸èè§èè¬è£èèè£è´é¬é·"],
["eea1","éååå¨å®°æææ ½æ¢æ¸½æ»ç½ç¸¡è£è²¡è¼é½é½ç­ç®è«éä½ä½å²åå§åºæµæµæ¥®æ¨æ²®æ¸ççªç½ç®¸ç´µè§è¹èè·è©è²¯èºéé¸éé½å£åå«¡å¯ææµæ»´çï§»çç©ç¬ç±ç¸¾ç¿è»è¬«è³èµ¤è·¡è¹è¿ªè¿¹é©éä½ä½ºå³å¨å¸ååªå¡¡å¡¼å¥ å°å±å»ææ°æ æ®¿æ°æ¾±"],
["efa1","çç ç°ç¸çç²ç­ç®ç®­ç¯çºè©®è¼¾è½é¿éé¢é«é»é¡é¡«é¤åæªææµç¤ç«ç¯çµ¶å å²¾åºæ¼¸ç¹ç²éé®é»æ¥æºè¶ä¸äºäº­ååµåå§å®å¹åº­å»·å¾ææºæ¿æ´ææ¶æ¸æ¾æ¥¨æªæ­£æ±æ·æ·¨æ¸æ¹çç¡çç½çºçç¢ç¦ç¨ç©½ç²¾ç¶èè¨è«ªè²é­ééé¦éé éé"],
["f0a1","éé é¼å¶åå¼å ¤å¸å¼æææ¢¯æ¿ç¥­ç¬¬èèºè£½è«¸è¹éé¤éé½é¡é½ä¿ååå©å²å¼å½«æªææ©ææºæ¹ææ¢æ£æ§½æ¼æ½®ç§ç¥çªçªçºç¥ç¥ç§ç¨ çªç²ç³çµç¹°èè»è¤è©èª¿è¶èºé é­é£é»éé³¥æç°è¶³éå­å°åæçå§å®å¾æ°æ«æ£æ·ç®ç¨®çµç¶ç¸±è«"],
["f1a1","è¸ªè¸µé¾éä½åå·¦åº§æ«ç½ªä¸»ä½ä¾åå§èåªå¨å¾å¥å®å·å»ææ±æ±æ ªæ³¨æ´²æ¹æ¾ç·ç çç±ç´ç´¬ç¶¢èèè¨»èªèµ°èºè¼³é±éééé§ç«¹ç²¥ä¿åååå¯¯å³»ææ¨½æµæºæ¿¬çç¯ç«£è ¢é¡éµéé§¿èä¸­ä»²è¡éå½æ«æ¥«æ±èºå¢ææ¾æ¯çççç¹è¸è­è´ä¹åª"],
["f2a1","å«å°åå¿æææ¯æ¯æ¨æºææ³æ­¢æ± æ²æ¼¬ç¥ç ¥ç¥ç¥ç´è¢èè³èè·èèªï§¼è´è¶¾é²ç´ç¨ç¨·ç¹è·ååå¡µæ¯æ¢æææ¡­æ¦æ®æ´¥æº±çç¨ç¡çç¹ç¡ççç§¦ç¸ç¸è»è¯è¢è¨ºè³è»«è¾°é²é­é£é³éä¾å±å§ªå«å¸æ¡çç¾ç§©çªè£è­è³ªè·è¿­ææï§½å·æ½ç·è¼¯"],
["f3a1","é¶éå¾µæ²æ¾ä¸ä¾ååååµ¯å·®æ¬¡æ­¤ç£ç®ï§¾è¹è»é®ææ¾ççªé¯é¿é½ªæ°æ¾¯ç¦ç¨çç«ç°çºç²²çºè®è´é½é¤é¥å¹å¯æ¦æ­ç´®å­åå¡¹æææºæ¬ç«è®è®åå¡åµå±å¨¼å» å½°æ´æææ¶æ¢æ§æ»æ¼²çç¡çªè¹èèè¼åµå°å¯å¯¨å½©æ¡ç ¦ç¶µèè¡ééµåæµç­"],
["f4a1","è²¬åå¦»æ½èåï§¿åå°ºæ½æææ²æ¥æ»ç èè¹ éé»ä»ååå¤©å·ææ³æ·ºçç©¿èè¦è³¤è¸é·é§é¡é¡éå¸å²åå¾¹æ¤æ¾ç¶´è¼è½éµåå°æ²¾æ·»çç»ç°½ç±¤è©¹è«å å¦¾å¸æ·ççç«è«è²¼è¼å»³æ´æ·¸è½èè«éé¯ï¨åæ¿æ¶æ»¯ç· è«¦é®éé«åå¿å¨ææææ¢¢"],
["f5a1","æ¤æ¥æ¨µçç¦ç¡ç¤ç¤ç§ç¨èè¸èèèè²è¶é¢éé®ä¿åç­çèè§¸å¯¸å¿æé¨å¢å¡å¯µæ¤ææ ç¸½è°è¥éæ®å¬å´æå¢æ½æ¨æ¤æ¥¸æ¨æ¹«çºç§è»è©è«è¶¨è¿½éééééééé¨¶é°ä¸çç¥ç«ºç­ç¯ç¸®èè¹è¹´è»¸éæ¥æ¤¿çåºæ®é»åå¿ æ²è²è¡è¡·æ´èµè"],
["f6a1","è´åå¹å´å¨¶å°±çç¿ èèè­è¶£éé©é·²å´ä»å æ»æ¸¬å±¤ä¾å¤å¤å³å¹æ¥æ¢æ²»æ·ç¾çç´ç¡ç¨ç©ç·ç·»ç½®è´è©è¼éé¦³é½ååé£­è¦ªä¸ææ¼ä¾µå¯¢ææ²æµ¸çç §éé¼èç§¤ç¨±å¿«ä»å¤å¾å¢®å¦¥æ°æææ¶æ¥èµéé¦±é§å¬ååå¼ï¨æï¨æ¢æ«ææ¿æ¿¯ç¢ç¸è¨"],
["f7a1","é¸ååå¦å½ææ­çç­ç¶»èªå¥ªè«æ¢çè½è²ªå¡æ­æ¦»å®å¸æ¹¯ï¨è©åå°å¤ªæ ææ®æ±°æ³°ç¬èèè·é°é¢±ï¨ææ¾¤ææåååè¨ææ¡¶ï¨çç­çµ±éå æ§è¿è¤ªéé ¹å¸å¥å¦¬æéé¬ªæç¹éå¡å©å·´ææ­æºæ·æ³¢æ´¾ç¬ç¶ç ´ç½·è­è·é å¤åæ¿çç£è²©è¾¦é"],
["f8a1","éªå«å­æä½©åæææ²æµ¿çç½ç¨è¦è²å½­æ¾ç¹è¨æä¾¿åæçç¯ç·¨ç¿©éé­é¨è²¶åªå¹³æ°èè©å å¬å¹£å»¢å¼æèºè½ééä½åååååºåå¸æææ±æï¨æ³¡æµ¦ç±ç ²èè¯èè¡è²è¢è¤ééªé£½é®å¹æ´æççï¨ä¿µå½å½ªæææ¨æ¼ç¢ç¥¨è¡¨è±¹é£é£é©"],
["f9a1","åç¨æ¥è«·è±é¢¨é¦®å½¼æ«ç²ç®è¢«é¿éå¹å¼¼å¿æ³çç¢çç­è¾é¦ä¹é¼ä¸ä½å¦å¤å»æ°æ²³çè·è¦è³ééé°å£å­¸èè¬é¶´å¯æ¨ææ±æ±æ¼¢æ¾£çç½ç¿°ééééå²è½å½å«å¸å£åæª»æ¶µç·è¦éé·é¹¹ååçè¤é¤ééäº¢ä¼å§®å«¦å··æææ­æ¡æ²æ¸¯ç¼¸èèª"],
["faa1","ï¨ï¨é äº¥åå³åå¥å­©å®³ææ¥·æµ·ç£è¹è§£è©²è«§éé§­éª¸å¾æ ¸åå¹¸æèè¡äº«åå®ç¦éé¿é¤é¥é¦åå¢èè¨±æ²æ«¶ç»è»æ­éªé©å¥çèµ«é©ä¿å³´å¼¦æ¸ææ³«ç«çç¹ç¾ç©ççµçµ¢ç¸£è·è¡ï¨è³¢éé¡¯å­ç©´è¡é å«ä¿ åå¤¾å³½æ¾æµ¹ç¹èèè¢éé °äº¨ååå"],
["fba1","å½¢æ³æ»ççç¯çç©ç©èè¢è¡¡éé¢é£é¦¨å®å½æ æ§æ³èè¹é¯éä¹äºå¼å£å£ºå¥½å²µå¼§æ¶æææ§æ¯«æµ©æ·æ¹æ»¸æ¾æ¿ æ¿©ççç¥çç çç¥ç³ç¸è¡è¦è«è¿èèè´è­·è±ªé¬é é¡¥ææé·å©ææ··æ¸¾ç¿é­å¿½æç¬åå¼æ±æ³æ´ªçç´è¹è¨é´»ååå¬æ¨ºç«çµ"],
["fca1","ç¦ç¦¾è±è¯è©±è­è²¨é´ï¨æ´æ«ç¢ºç¢»ç©«ä¸¸åå¥å®¦å¹»æ£ææ­¡æ¥æ¡æ¸ç¥ç°ç´éé©©é°¥æ´»æ»ç¾è±éå°å¹å¾¨ææ¶æ°ææææ¦¥æ³æ¹æ»æ½¢çççç¯ç°§èèééé»å¯åå»»å¾æ¢ææ·æ¦ææªæ·®æ¾®ç°çªç¹ªè¾è´èèª¨è³åç²å®æ©«éå®åå­ææææ¢æ¶æ·"],
["fda1","ç»è´éµé©ä¾¯åååå¼ååå¸¿å¾æ½ç¦çéåå³å¡¤å£ççç»è°è¨æè¨å§æçè±ååæ¯å½å¾½æ®æçè«±è¼éº¾ä¼æºçç¦è§æ¤è­é·¸åå¶åæ´¶è¸é»ææ¬£ççåå±¹ç´è¨æ¬ æ¬½æ­å¸æ°æ´½ç¿èåååå«åå§¬å¬å¸æææ±ææ¦çç¹çºç§ç¦§ç¨ç¾²è©°"]
]
