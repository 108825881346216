// Generated by CoffeeScript 1.12.6
(function() {
  module.exports = {
    annotate: function(x, y, w, h, options) {
      var key, ref, val;
      options.Type = 'Annot';
      options.Rect = this._convertRect(x, y, w, h);
      options.Border = [0, 0, 0];
      if (options.Subtype !== 'Link') {
        if (options.C == null) {
          options.C = this._normalizeColor(options.color || [0, 0, 0]);
        }
      }
      delete options.color;
      if (typeof options.Dest === 'string') {
        options.Dest = new String(options.Dest);
      }
      for (key in options) {
        val = options[key];
        options[key[0].toUpperCase() + key.slice(1)] = val;
      }
      ref = this.ref(options);
      this.page.annotations.push(ref);
      ref.end();
      return this;
    },
    note: function(x, y, w, h, contents, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Text';
      options.Contents = new String(contents);
      options.Name = 'Comment';
      if (options.color == null) {
        options.color = [243, 223, 92];
      }
      return this.annotate(x, y, w, h, options);
    },
    link: function(x, y, w, h, url, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Link';
      options.A = this.ref({
        S: 'URI',
        URI: new String(url)
      });
      options.A.end();
      return this.annotate(x, y, w, h, options);
    },
    _markup: function(x, y, w, h, options) {
      var ref1, x1, x2, y1, y2;
      if (options == null) {
        options = {};
      }
      ref1 = this._convertRect(x, y, w, h), x1 = ref1[0], y1 = ref1[1], x2 = ref1[2], y2 = ref1[3];
      options.QuadPoints = [x1, y2, x2, y2, x1, y1, x2, y1];
      options.Contents = new String;
      return this.annotate(x, y, w, h, options);
    },
    highlight: function(x, y, w, h, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Highlight';
      if (options.color == null) {
        options.color = [241, 238, 148];
      }
      return this._markup(x, y, w, h, options);
    },
    underline: function(x, y, w, h, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Underline';
      return this._markup(x, y, w, h, options);
    },
    strike: function(x, y, w, h, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'StrikeOut';
      return this._markup(x, y, w, h, options);
    },
    lineAnnotation: function(x1, y1, x2, y2, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Line';
      options.Contents = new String;
      options.L = [x1, this.page.height - y1, x2, this.page.height - y2];
      return this.annotate(x1, y1, x2, y2, options);
    },
    rectAnnotation: function(x, y, w, h, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Square';
      options.Contents = new String;
      return this.annotate(x, y, w, h, options);
    },
    ellipseAnnotation: function(x, y, w, h, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'Circle';
      options.Contents = new String;
      return this.annotate(x, y, w, h, options);
    },
    textAnnotation: function(x, y, w, h, text, options) {
      if (options == null) {
        options = {};
      }
      options.Subtype = 'FreeText';
      options.Contents = new String(text);
      options.DA = new String;
      return this.annotate(x, y, w, h, options);
    },
    _convertRect: function(x1, y1, w, h) {
      var m0, m1, m2, m3, m4, m5, ref1, x2, y2;
      y2 = y1;
      y1 += h;
      x2 = x1 + w;
      ref1 = this._ctm, m0 = ref1[0], m1 = ref1[1], m2 = ref1[2], m3 = ref1[3], m4 = ref1[4], m5 = ref1[5];
      x1 = m0 * x1 + m2 * y1 + m4;
      y1 = m1 * x1 + m3 * y1 + m5;
      x2 = m0 * x2 + m2 * y2 + m4;
      y2 = m1 * x2 + m3 * y2 + m5;
      return [x1, y1, x2, y2];
    }
  };

}).call(this);
