const choo = require('choo')
const app = choo()

app.use(require('./stores/templates.js'))

app.use(require('./stores/export-img.js'))
app.use(require('./stores/export-pdf.js'))

const main = require('./templates/main.js')

app.route('/', main)
app.route('/:label', main)
app.mount('body')