module.exports=[
["a140","î",62],
["a180","î",32],
["a240","î¦",62],
["a280","î¥",32],
["a2ab","î¦",5],
["a2e3","â¬î­"],
["a2ef","î®î¯"],
["a2fd","î°î±"],
["a340","î",62],
["a380","î",31,"ã"],
["a440","î¦",62],
["a480","î¥",32],
["a4f4","î²",10],
["a540","î",62],
["a580","î",32],
["a5f7","î½",7],
["a640","î¦",62],
["a680","î¥",32],
["a6b9","î",7],
["a6d9","î",6],
["a6ec","îî"],
["a6f3","î"],
["a6f6","î",8],
["a740","î",62],
["a780","î",32],
["a7c2","î ",14],
["a7f2","î¯",12],
["a896","î¼",10],
["a8bc","î"],
["a8bf","Ç¹"],
["a8c1","îîîî"],
["a8ea","î",20],
["a958","î¢"],
["a95b","î£"],
["a95d","î¤î¥î¦"],
["a989","ã¾â¿°",11],
["a997","î´",12],
["a9f0","î ",14],
["aaa1","î",93],
["aba1","î",93],
["aca1","î¼",93],
["ada1","î",93],
["aea1","î¸",93],
["afa1","î",93],
["d7fa","î ",4],
["f8a1","î´",93],
["f9a1","î",93],
["faa1","î°",93],
["fba1","î",93],
["fca1","î¬",93],
["fda1","î",93],
["fe50","âºî î î âºã³ãâºâºî ãããâºâºã¥®ã¤î ¦ã§ã§ã©³ã§î «î ¬ã­ã±®ã³ âº§î ±î ²âºªääâº®ä·âº³âº¶âº·î »ä±ä¬âº»äää¡äî¡"],
["fe80","ä£ä©ä¼äâ»ä¥ä¥ºä¥½ä¦ä¦ä¦ä¦ä¦ä¦ä¦·ä¦¶î¡î¡ä²£ä²ä² ä²¡ä±·ä²¢ä´",6,"ä¶®î¡¤î¨",93]
]
