const Nanocomponent = require('nanocomponent')
const html = require('choo/html')
const paper = require('paper')
const path = require('path')

// PDF Generator
const printer = require('../assets/printer.js')

class Canvas extends Nanocomponent {
  constructor () {
    super()
    this.template = null
    this.isRetina = null
    this.background = null
    this.graphics = null
  }

  createElement (template) {
    this.template = template
    return html`
      <canvas hidpi="on" style="margin: auto"></canvas>
    `
  }

  load (el) {
    window.addEventListener('resize', () => {
      this.update(this.template, false)
    })

    this.isRetina = window.matchMedia('screen and (min-resolution: 2dppx)').matches

    window.matchMedia('screen and (min-resolution: 2dppx)').addListener((e) => {
      this.isRetina = e.matches
      this.update(this.template, false)
    })

    console.log('Canvas Ã¢ÂÂ')
    paper.setup(this.element)

    this.update(this.template, false)
  }

  update (template, requestPDF, requestIMG) {
    if (requestPDF) {
      this.background.removeChildren()
      paper.project.view.update()
      this.print()
      return false
    }

    if (requestIMG) {
      this.saveImage()
      return false
    }

    if (template && template !== this.template) this.template = template

    console.log('Drawing: ', this.template)
    console.log('Retina: ', this.isRetina)

    this.graphics = new paper.Group()
    this.graphics.removeChildren()

    paper.project.activeLayer.removeChildren()

    var size = Math.min(window.innerWidth - 300, window.innerHeight)
    paper.view.viewSize = new paper.Size(size, size)
    
    let box = new paper.Shape.Circle(paper.view.center, Math.min(paper.view.viewSize.width, paper.view.viewSize.height)/2 * 0.9)
    box.fillColor = 'white'

    this.background = new paper.Group()
    this.background.removeChildren()
    this.background.addChild(box)

    let rendered = null
    switch (this.template.slug) {
      case 'front': 
        rendered = require('../assets/front/index.js')(this.template, box)
        break
      case 'back': 
        rendered = require('../assets/back/index.js')(this.template, box)
        break
    }
    this.graphics.addChild(rendered)

    console.log('Loading plate for:', this.template.slug)
    paper.project.importSVG('/assets/' + this.template.slug + '/' + this.template.slug + '.svg', function(plate) {
      plate.position = paper.view.center
      plate.fitBounds(box.bounds)
      this.graphics.addChild(plate.clone())
      console.log('Plate loaded')
    }.bind(this))

    return false
  }

  print () {

    var image = this.element.toDataURL("image/png")
    printer(image)
    this.update(null, false)

  }

  saveImage () {

    var image = this.element.toDataURL("image/png").replace("image/png", "image/octet-stream")
    window.location.href = image
    this.update(null, false)

  }
}

module.exports = Canvas