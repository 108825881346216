module.exports=[
["0","\u0000",127],
["8ea1","ï½¡",62],
["a1a1","ãããï¼ï¼ã»ï¼ï¼ï¼ï¼ããÂ´ï½Â¨ï¼¾ï¿£ï¼¿ã½ã¾ãããä»ãããã¼ââï¼ï¼¼ï½â¥ï½â¦â¥ââââï¼ï¼ããï¼»ï¼½ï½ï½ã",9,"ï¼ï¼Â±ÃÃ·ï¼â ï¼ï¼â¦â§ââ´ââÂ°â²â³âï¿¥ï¼ï¿ ï¿¡ï¼ï¼ï¼ï¼ï¼ Â§ââââââ"],
["a2a1","ââ¡â â³â²â½â¼â»ãââââã"],
["a2ba","âââââââªâ©"],
["a2ca","â§â¨ï¿¢ââââ"],
["a2dc","â â¥ââââ¡ââªâ«ââ½ââµâ«â¬"],
["a2f2","â«â°â¯â­âªâ â¡Â¶"],
["a2fe","â¯"],
["a3b0","ï¼",9],
["a3c1","ï¼¡",25],
["a3e1","ï½",25],
["a4a1","ã",82],
["a5a1","ã¡",85],
["a6a1","Î",16,"Î£",6],
["a6c1","Î±",16,"Ï",6],
["a7a1","Ð",5,"ÐÐ",25],
["a7d1","Ð°",5,"ÑÐ¶",25],
["a8a1","ââââââââ¬â¤â´â¼âââââââ£â³â«â»ââ â¯â¨â·â¿ââ°â¥â¸â"],
["ada1","â ",19,"â ",9],
["adc0","ããã¢ããã§ãã¶ãããã¦ã£ã«ãã»ããããããã¡"],
["addf","ã»ããâãâ¡ã¤",4,"ã±ã²ã¹ã¾ã½ã¼ââ¡â«â®âââ¥â ââ¿âµâ©âª"],
["b0a1","äºåå¨é¿åææ¨å§¶é¢èµèç©æªæ¡æ¸¥æ­è¦è¦é¯µæ¢å§æ¡æ±å®å§è»é£´çµ¢ç¶¾é®æç²è¢·å®åºµæææ¡ééæä»¥ä¼ä½ä¾åå²å¤·å§å¨å°æææ°ææ¤çºçç°ç§»ç¶­ç·¯èèè¡£è¬ééºå»äºäº¥åè²éç£¯ä¸å£±æº¢é¸ç¨²è¨èé°¯åå°å½å¡å å§»å¼é£²æ·«è¤è­"],
["b1a1","é¢é°é é»åå³å®çç¾½è¿é¨å¯éµçªºä¸ç¢è¼æ¸¦ååæ¬èé°»å§¥å©æµ¦çéåäºéé²èé¤å¡å¶å¬°å½±æ æ³æ æ°¸æ³³æ´©ççç©é ´è±è¡è© é­æ¶²ç«çé§æ¦è¬è¶é²æ¦å­ååå °å¥å®´å»¶æ¨æ©æ´æ²¿æ¼ççççç¿ç¸è¶èèé éé´å¡©æ¼æ±ç¥å¹å¤®å¥¥å¾å¿"],
["b2a1","æ¼æºæ¨ªæ¬§æ®´çç¿è¥é´¬é´é»å²¡æ²è»åå±æ¶èæ¡¶ç¡ä¹ä¿ºå¸æ©æ¸©ç©é³ä¸åä»®ä½ä¼½ä¾¡ä½³å å¯åå¤å«å®¶å¯¡ç§æææ¶æ­æ²³ç«çç¦ç¦¾ç¨¼ç®è±èèè·è¯èè¦èª²å©è²¨è¿¦ééèä¿å³¨æçç»è¥è½è¾è³éé¤é§ä»ä¼è§£åå¡å£å»»å¿«æªææ¢ææææ¹"],
["b3a1","é­æ¦æ¢°æµ·ç°çççµµè¥è¹ééè²å±å¾å¤å³å®³å´æ¨æ¦æ¶¯ç¢èè¡è©²é§éª¸æµ¬é¦¨èå£æ¿èéåååå»æ¡æ¹æ ¼æ ¸æ®»ç²ç¢ºç©«è¦è§èµ«è¼é­é£éé©å­¦å²³æ¥½é¡é¡æç¬ æ¨«æ©¿æ¢¶é°æ½å²åæ°æ¬æ´»æ¸æ»èè¤è½ä¸é°¹å¶æ¤æ¨ºéæ ªåç«è²ééåé´¨æ ¢èè±"],
["b4a1","ç²¥åèç¦ä¹¾ä¾å å¯ååå§å·»åå ªå§¦å®å®å¯å¹²å¹¹æ£ææ£æ¾ææ¢ææ¡æ£ºæ¬¾æ­æ±æ¼¢æ¾æ½ç°çç£çç«¿ç®¡ç°¡ç·©ç¼¶ç¿°èè¦èè¦³è«è²«ééééé¢é¥éé¤¨èä¸¸å«å²¸å·ç©çç¼å²©ç¿«è´éé é¡é¡ä¼ä¼å±åå¨åºå¥å¬å¯å²å¸å¹¾å¿æ®æºææ¢ææ£æ£"],
["b5a1","æ©å¸°æ¯æ°æ±½ç¿ç¥å­£ç¨ç´å¾½è¦è¨è²´èµ·è»è¼é£¢é¨é¬¼äºå½åå¦å®æ¯ææ¬æ¬ºç çç¥ç¾©è»èª¼è­°æ¬èé ååå«æ¡æ©è©°ç §æµé»å´å®¢èèéä¸ä¹ä»ä¼åå¸å®®å¼æ¥ææ½æ±æ±²æ³£ç¸çç©¶çª®ç¬ç´ç³¾çµ¦æ§çå»å±å·¨ææ ææ¸ èè¨±è·é¸æ¼ç¦¦é­äº¨äº«äº¬"],
["b6a1","ä¾ä¾ ååç«¶å±å¶åå¡å¿å«å¬å¢å³¡å¼·å½æ¯ææ­æææ©æ³çç­ç¯è¸èèèé·é¡é¿é¥é©ä»°åå°­ææ¥­å±æ²æ¥µçæ¡ç²åå¤åå·¾é¦æ¤æ¬£æ¬½ç´ç¦ç¦½ç­ç·è¹èè¡¿è¥è¬¹è¿éåéä¹å¶å¥åºççç©è¦èº¯é§é§é§å·æèå°ç©ºå¶å¯ééä¸²æ«é§å±å±"],
["b7a1","æçªæ²é´è½¡çªªçéç²æ ç¹°æ¡é¬å²åè«è¨ç¾¤è»é¡å¦è¢ç¥ä¿å¾åååå­çªåå¥å½¢å¾æµæ¶æ§æ©æ²æºæ¬æ¯æ¡æ¸ç¦ç¨½ç³»çµç¶ç¹ç½«èèèè¨è©£è­¦è»½é é¶è¸è¿é¯¨åæææ¿éæ¡åæ¬ æ±ºæ½ç©´çµè¡è¨£æä»¶å¹å¦å¥å¼å¸å£å§åå å«å»ºæ²æ¸æ³æ²"],
["b8a1","æ¤æ¨©ç½ç¬ç®ç ç¡¯çµ¹çè©è¦è¬è³¢è»é£éµéºé¡é¨é¹¸ååå³å¹»å¼¦æ¸æºçç¾çµè·è¨è«ºéä¹åå¤å¼åºå§å­¤å·±åº«å¼§æ¸ææ¯æ¹çç³è¢´è¡è¡è°èèªè·¨é·éé¡§é¼äºäºä¼ååå¾å¨¯å¾å¾¡ææ¢§æªçç¢èªèª¤è­·éä¹é¯äº¤ä½¼ä¾¯åååå¬åå¹å¾åå£å"],
["b9a1","åååå¢å¥½å­å­å®å·¥å·§å··å¹¸åºåºåº·å¼æææææ§æ»æææ´æ­æ ¡æ¢æ§æ±æ´ªæµ©æ¸¯æºç²çç¡¬ç¨¿ç³ ç´ç´çµç¶±èèè¯è±èèèªèè¡è¡¡è¬è²¢è³¼ééµé±ç ¿é¼é¤éé é¦é«é´»åå«å·åå£æ·æ¿ è±ªè½éº¹åå»åå½ç©é·éµ é»çæ¼è°çå¿½æéª¨çè¾¼"],
["baa1","æ­¤é ä»å°å¤å¢¾å©æ¨ææææ ¹æ¢±æ··çç´ºè®é­äºä½åååµ¯å·¦å·®æ»æ²ç³ç è©éè£ååº§æ«åµå¬åæåå¡å¦»å®°å½©ææ¡æ ½æ­³æ¸ç½éçç ç ¦ç¥­æç´°èè£è¼éå¤å¨æç½ªè²¡å´åéªå ºæ¦è´å²å´å¼ç¢é·ºä½ååæ¾æ¨ææµçªç­ç´¢é¯æ¡é®­ç¬¹ååå·"],
["bba1","å¯æ¶æ®æ¦æ­æ®ºè©éçé¯æéé®«ç¿æä¸ååå±±æ¨ææ£æ¡ç¦çç£ç®çºèè®è³é¸é¤æ¬æ«æ®ä»ä»ä¼ºä½¿åºå¸å²å£åå£«å§å§å§¿å­å±å¸å¸«å¿æææ¯å­æ¯æ½æ¨ææ­¢æ­»æ°çç¥ç§ç³¸ç´ç´«è¢èè³è¦è©è©©è©¦èªè«®è³è³éé£¼æ­¯äºä¼¼ä¾åå­å¯ºæææ"],
["bca1","æ¬¡æ»æ²»ç¾ç½çç£ç¤ºèè³èªèè¾æ±é¹¿å¼è­é´«ç«ºè»¸å®é«ä¸å±å·å¤±å«å®¤ææ¹¿æ¼ç¾è³ªå®èç¯ å²æ´èå±¡èç¸èåå°æ¨èµ¦æç®ç¤¾ç´èè¬è»é®èéªååºå°ºæç¼çµééé«è¥å¯å¼±æ¹ä¸»åå®ææ±æ®ç©ç ç¨®è«è¶£éé¦åååªå¯¿ææ¨¹ç¶¬éååå¨"],
["bda1","å®å°±å·ä¿®ææ¾æ´²ç§ç§çµç¹ç¿è­èèè¡è¥²è®è¹´è¼¯é±éé¬ééä»ä½ååå¾æææ±æ¸ç£ç¸¦ééåå¤å®¿æ·ç¥ç¸®ç²å¡¾çåºè¡è¿°ä¿å³»æ¥ç¬ç«£èé§¿åå¾ªæ¬æ¥¯æ®æ·³æºæ½¤ç¾ç´å·¡éµéé å¦åææææ¸åº¶ç·ç½²æ¸è¯è·è«¸å©åå¥³åºå¾æé¤é¤å·å"],
["bea1","åå åå¬å¨åå±åå¥¨å¦¾å¨¼å®µå°å°å°å°åºåºå» å½°æ¿ææææ·æææ­æ¶æ¾æ¢¢æ¨æ¨µæ²¼æ¶æ¸æ¹ç¼ç¦ç§ççç¡ç¤ç¥¥ç§°ç« ç¬ç²§ç´¹èèèèè¡è£³è¨è¨¼è©è©³è±¡è³é¤é¦é¾éééä¸ä¸ä¸ä¹åå°åå ´å£å¬¢å¸¸ææ¾æ¡ææµç¶ç³ç©£è¸è­²é¸é å±å´é£¾"],
["bfa1","æ­æ¤æ®ç­ç¹è·è²è§¦é£èè¾±å°»ä¼¸ä¿¡ä¾µåå¨ å¯å¯©å¿ææ¯æ°ææ£®æ¦æµ¸æ·±ç³ç¹çç¥ç§¦ç´³è£è¯èªè¦ªè¨ºèº«è¾é²ééäººä»åå¡µå£¬å°çå°½èè¨è¿é£é­ç¬¥è«é é¢å³å¨éå¹åå¸¥æ¨æ°´çç¡ç²ç¿ è¡°éééééçé«å´åµ©æ°æ¢è¶¨éæ®ææ¤èé éè£¾"],
["c0a1","æ¾æºå¯¸ä¸ç¬çæ¯åå¶å¢å§å¾æ§ææ¿æ´ææ´æ£²æ æ­£æ¸ç²ççç²¾èå£°è£½è¥¿èª èªè«ééééæç¨èé»å¸­æææ¥ææç³ç©ç±ç¸¾èè²¬èµ¤è·¡è¹ç¢©åææ¥ææè¨­çªç¯èª¬éªçµ¶èèä»ååå å®£å°å°å·æ¦ææ°æ æ ´æ³æµæ´ææ½çç½æç©¿ç®­ç·"],
["c1a1","ç¹ç¾¨èºèè¹è¦è©®è³è·µé¸é·é­ééé®®ååæ¼¸ç¶å¨ç¦ç¹è³ç³åå¡å²¨æªæ¾æ½æ¥çççç¤ç¥ç§ç²ç´ çµèè¨´é»é¡é¼ å§åµåå¢ååªå£®å¥ç½å®å±¤åæ£æ³æææ¿æ»ææ©æ¹å·£æ§æ§½æ¼ç¥äºç©ç¸çªç³ç·ç¶è¡èèè¬è¼è»è£èµ°éé­ééé¨åå¢æ"],
["c2a1","èèµè´é ä¿å´åå³æ¯æææ¸¬è¶³éä¿å±è³æç¶åè¢å¶æå­å­«å°ææéä»å¤å¤ªæ±°è©å¾å å¦¥æ°ææèµæ¥éé§é¨¨ä½å å¯¾èå²±å¸¯å¾æ ææ´æ¿æ³°æ»èè¿èè¢è²¸éé®éé»é¯ä»£å°å¤§ç¬¬éé¡é·¹æ»ç§ååå®ææææ²¢æ¿¯ç¢è¨é¸æ¿è«¾è¸å§è¸åª"],
["c3a1","å©ä½éè¾°å¥ªè±å·½ç«ªè¾¿æ£è°·ç¸é±æ¨½èª°ä¸¹ååå¦ææ¢æ¦æ­æ·¡æ¹ç­ç­ç«¯ç®ªç¶»è½èèèªéå£å£å¼¾æ­ææªæ®µç·è«å¤ç¥å°å¼æ¥æºæ± ç´ç¨ç½®è´èéé¦³ç¯çç«¹ç­èéç§©çªè¶å«¡çä¸­ä»²å®å¿ æ½æ¼æ±æ³¨è«è¡·è¨»éé³é§æ¨ç¦çªè§èè²¯ä¸åååå¯µ"],
["c4a1","å¸å¸³åºå¼å¼µå½«å¾´æ²ææ¢ææ½®ççºçºè´è¹è¸è¶èª¿è«è¶è·³éé·é é³¥åæç´ææ²çè³é®é³æ´¥å¢æ¤æ§è¿½éçéå¡æ æ´æ§»ä½æ¼¬æè¾»è¦ç¶´éæ¤¿æ½°åªå£·å¬¬ç´¬çªåé£é¶´äº­ä½ååµåè²åå ¤å®å¸åºåº­å»·å¼ææµæºææ¢¯æ±ç¢ç¦ç¨ç· èè¨è«¦è¹é"],
["c5a1","é¸é­éé¼æ³¥ææ¢æµæ»´çç¬é©éæººå²å¾¹æ¤è½è¿­éå¸å¡«å¤©å±åºæ·»çºçè²¼è»¢é¡ç¹ä¼æ®¿æ¾±ç°é»ååå µå¡å¦¬å± å¾æææ¸¡ç»èè³­éé½éç ¥ç ºåªåº¦åå¥´æååå¬åååå¡å¡å¥å®å³¶å¶æ¼ææ­æ±æ¡æ¢¼æ£çæ·æ¹¯æ¶ç¯çå½çç¥·ç­ç­ç­ç³çµ±å°"],
["c6a1","è£è©è¤è¨è¬è±è¸éééé¶é ­é¨°éåååå å°æ§ææ´ç³ç«¥è´èééå³ é´å¿å¾å¾³æ¶ç¹ç£ç¦¿ç¯¤æ¯ç¬èª­æ æ©¡å¸çªæ¤´å±é³¶è«å¯éçå¸å±¯ææ¦æ²è±éé åæéå¥é£åä¹åªèè¬çæºéæ¥¢é¦´ç¸ç·åæ¥ è»é£æ±äºå°¼å¼è¿©åè³èè¹å»¿æ¥ä¹³å¥"],
["c7a1","å¦å°¿é®ä»»å¦å¿èªæ¿¡ç¦°ç¥¢å¯§è±ç«ç±å¹´å¿µæ»æçç²ä¹å»¼ä¹åå¢æ©æ¿ç´è½è³è¿è¾²è¦è¤å·´ææ­è¦æ·æ³¢æ´¾ç¶ç ´å©ç½µè­é¦¬ä¿³å»ææææ¯ççèèºè¼©éåå¹åªæ¢æ¥³ç¤ç½è²·å£²è³ éªéè¿ç§¤ç§è©ä¼¯å¥åæææ³ç½ç®ç²è¶èè¿«ææ¼ çç¸è«é§éº¦"],
["c8a1","å½ç®±ç¡²ç®¸èç­æ«¨å¹¡èçç å«é¢æºçºéé«ªä¼ç½°æç­é¥é³©åºå¡è¤é¼ä¼´å¤åååå¸æ¬ææ¿æ°¾æ±çç¯ç­çç¹è¬è©è²©ç¯éç©é é£¯æ½æ©çªç¤ç£èè®åªåå¦å¦åºå½¼æ²ææ¹æ«ææ¯æ³ç²ç®ç¢ç§ç·ç½·è¥è¢«èª¹è²»é¿éé£æ¨ç°¸åå°¾å¾®ææ¯çµçç¾"],
["c9a1","é¼»æç¨å¹çé«­å½¦èè±èå¼¼å¿ç¢ç­é¼æ¡§å§«åªç´ç¾è¬¬ä¿µå½ªæ¨æ°·æ¼ç¢ç¥¨è¡¨è©è±¹å»æçç§èé¨é²èè­é°­åå½¬ææµçè²§è³é »æç¶ä¸ä»å å¤«å©¦å¯å¨å¸åºææ¶æ·æ§æ®æµ®ç¶ç¬¦èèèè­è² è³¦èµ´ééä¾®æ«æ­¦èè¡èªé¨å°æ¥é¢¨èºèä¼å¯å¾©å¹æ"],
["caa1","ç¦è¹è¤è¦æ·µå¼ææ²¸ä»ç©é®åå»å´å¢³æ¤æ®çå¥®ç²ç³ç´é°æèä¸ä½µåµå¡å¹£å¹³å¼æä¸¦è½ééç±³é å»å£çç¢§å¥ç¥èç®åå¤çç¯ç·¨è¾ºè¿éä¾¿åå¨©å¼é­ä¿èéªåææ­©ç«è£è¼ç©åå¢æææ®æ¯ç°¿è©å£ä¿¸ååå ±å¥å®å³°å³¯å´©åºæ±æ§æ¾æ¹æ"],
["cba1","æ³æ³¡ç¹ç ²ç¸«èè³èè¬èè¤è¨ªè±é¦éé£½é³³éµ¬ä¹äº¡åååå¦¨å¸½å¿å¿æ¿æ´æææ£åç´¡èªè¨è¬è²è²¿é¾é²å é ¬åååå¢¨æ²æ´ç§ç¦ç©é¦åæ²¡æ®å å¹å¥æ¬ç¿»å¡çæ©ç£¨é­éº»åå¦¹æ§ææ¯å©æ§å¹èæé®ªæ¾é±æ¡äº¦ä¿£åæ¹æ«æ²«è¿ä¾­ç¹­éº¿ä¸æ¢æº"],
["cca1","æ¼«èå³æªé­å·³ç®å²¬å¯èæ¹èç¨èå¦ç²æ°ç åå¤¢ç¡ççé§éµ¡æ¤å©¿å¨å¥åå½æçè¿·éé³´å§ªçæ»åæ£ç¶¿ç·¬é¢éººæ¸æ¨¡èå¦å­æ¯çç²ç¶²èèå²æ¨é»ç®æ¢å¿é¤å°¤æ»ç±¾è²°åæ¶ç´éåä¹å¶å¤çºè¶éå¼¥ç¢åå½¹ç´è¬è¨³èºéæ³è®éæææ²¹ç"],
["cda1","è«­è¼¸å¯ä½åªååå®¥å¹½æ æææææ¹§æ¶ç¶ç·ç±ç¥è£èªéééµéèå¤äºä½ä¸èªè¼¿é å­å¹¼å¦å®¹åº¸ææºæææ¥æ§æ´æº¶çç¨çª¯ç¾èèèè¦è¬¡è¸é¥é½é¤æ¾ææ¬²æ²æµ´ç¿ç¿¼æ·ç¾èºè£¸æ¥è±é ¼é·æ´çµ¡è½éªä¹±åµåµæ¬æ¿«èè­è¦§å©åå±¥ææ¢¨çç"],
["cea1","ç¢è£è£¡éé¢é¸å¾çç«èæ ç¥åæµæºççç¡«ç²éç«é¾ä¾¶æ®æèäºäº®åä¸¡åå¯®ææ¢æ¶¼ççç­ç¨ç³§è¯è«é¼ééµé åç·å«åææ·çç³è¨è¼ªé£é±éºç å¡æ¶ç´¯é¡ä»¤ä¼¶ä¾å·å±å¶ºæç²ç¤¼èé´é·é¶ééºé½¢æ¦æ­´åå£çè£å»æææ¼£çç°¾ç·´è¯"],
["cfa1","è®é£é¬åé­¯æ«çè³è·¯é²å´å©å»å¼ææ¥¼æ¦æµªæ¼ç¢ç¼ç¯­èè¾èéå­éºç¦èé²è«å­åè©±æ­ªè³èææ é·²äºäºé°è©«èè¨æ¤æ¹¾ç¢è"],
["d0a1","å¼ä¸ä¸ä¸ªä¸±ä¸¶ä¸¼ä¸¿ä¹ä¹ä¹äºäºè±«äºèå¼äºäºäºäº äº¢äº°äº³äº¶ä»ä»ä»ä»ä»ä»ä»ä»­ä»ä»·ä¼ä½ä¼°ä½ä½ä½ä½ä½¶ä¾ä¾ä¾ä½»ä½©ä½°ä¾ä½¯ä¾ä¾åä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿¤ä¿¥åå¨ååªå¥åä¼ä¿¶å¡å©å¬ä¿¾ä¿¯ååååæåååååå¬å¸åååå´å²"],
["d1a1","ååå³åååå¥å­å£å®å¹åµåååååååå¡åºå·å¼å»å¿ååååå¢ç«¸å©åªå®ååååååååååå¤å¦å¢å©åªå«å³å±å²å°åµå½åååå èå©å­å°åµå¾ååååå§åªå®å³å¹åååååååªå´å©å³å¿å½åååå±ååè¾¨"],
["d2a1","è¾§å¬å­å¼åµååååå£å¦é£­å å³åµå¸å¹ååç¸åååååå£å¯å±å³å¸åååä¸ååååå©å®å¤å»å·ååå å¦å¥å®å°å¶åç°éåæ¼ç®å®å¨å­åºåå½åå¬å­å¼å®å¶å©ååååµååå±å·å°åå»åå¶ååååå¢å¸å¥å¬ååå¨"],
["d3a1","å«åå¤å¾å¼åå¥å¦ååå½å®å­åºå¢å¹åå£åå®ååååå¸å³åååå¯ååå»å¾ååå®å¼åå©åå¨åååååå¤ååå·åå¾å½åå¹ååçå´å¶å²å¸å«å¤å¯å¬åªåååå ååå¥å®å¶å´åå¼ååååååååå®å¹åå¿åå"],
["d4a1","ååååååååå¦å·å¸åå»ååå©ååå¡å¿ååå å³å¤åªå°åååååå åå£å å å å¡²å ¡å¡¢å¡å¡°æ¯å¡å ½å¡¹å¢å¢¹å¢å¢«å¢ºå£å¢»å¢¸å¢®å£å£å£å£å£å£å£¥å£å£¤å£å£¯å£ºå£¹å£»å£¼å£½å¤å¤å¤å¤æ¢¦å¤¥å¤¬å¤­å¤²å¤¸å¤¾ç«å¥å¥å¥å¥å¥å¥¢å¥ å¥§å¥¬å¥©"],
["d5a1","å¥¸å¦å¦ä½ä¾«å¦£å¦²å§å§¨å§å¦å§å§å¨¥å¨å¨å¨å¨å¨å©å©¬å©å¨µå¨¶å©¢å©ªåªåª¼åª¾å«å«åª½å«£å«å«¦å«©å«å«ºå«»å¬å¬å¬å¬²å«å¬ªå¬¶å¬¾å­å­å­å­å­å­å­å­¥å­©å­°å­³å­µå­¸æå­ºå®å®å®¦å®¸å¯å¯å¯å¯å¯å¯¤å¯¦å¯¢å¯å¯¥å¯«å¯°å¯¶å¯³å°å°å°å°å°å° å°¢å°¨å°¸å°¹å±å±å±å±"],
["d6a1","å±å±å­±å±¬å±®ä¹¢å±¶å±¹å²å²å²å¦å²«å²»å²¶å²¼å²·å³å²¾å³å³å³©å³½å³ºå³­å¶å³ªå´å´å´åµå´å´å´å´å´¢å´å´å´åµåµåµåµåµ¬åµ³åµ¶å¶å¶å¶å¶¢å¶å¶¬å¶®å¶½å¶å¶·å¶¼å·å·å·å·å·å·å·«å·²å·µå¸å¸å¸å¸å¸å¸¶å¸·å¹å¹å¹å¹å¹å¹å¹å¹¢å¹¤å¹å¹µå¹¶å¹ºéº¼å¹¿åº å»å»å»å»å»"],
["d7a1","å»å»£å»å»å»å»¢å»¡å»¨å»©å»¬å»±å»³å»°å»´å»¸å»¾å¼å¼å½å½å¼å¼å¼å¼©å¼­å¼¸å½å½å½å½å¼¯å½å½å½å½å½¡å½­å½³å½·å¾å¾å½¿å¾å¾å¾å¾å¾å¾å¾å¾ å¾¨å¾­å¾¼å¿å¿»å¿¤å¿¸å¿±å¿æ³å¿¿æ¡æ æææ©ææ±æææ«æ¦ææºæææªæ·æææææ£ææ¤ææ¬æ«ææææ§ææ"],
["d8a1","ææææææ§ææ¡æ¸æ ææ´å¿°æ½ææµæææææ¶æ·ææ´æºææ¡æ»æ±ææææ¾æ¨æ§ææ¿æ¼æ¬æ´æ½æææ³æ·ææææ«æ´æ¯æ¥æ±ææææµææææ¬æææææ«æ®ææææ·ææææºæç½¹ææ¦æ£æ¶æºæ´æ¿æ½æ¼æ¾æææææææ"],
["d9a1","ææ¡æªæ®æ°æ²æ³ææææ£ææ æ¨æ¼æææ¾æææææææææ»ææ¿ææææææææææææ®æ±æ§æææ¯æµææ¾æææææææ«æ¶æ£ææææµæ«æ©æ¾æ©æææ£æææ¶æææ´æææ¦æ¶æææ¨ææ§æ¯æ¶ææªæææ¥æ©ææ¼"],
["daa1","æææææ»æææ±æ§èæ æ¡æ¬æ£æ¯æ¬æ¶æ´æ²æºææ½ææææ¤æ£æ«æ´æµæ·æ¶æ¸çææææææææ²æ¸ææè®æææ«æ·æææææææææ æ¡æ±æ²æææ»æ³æµæ¶æ´ææææææææ¤æ§æ¨ææ¢æ°æææææææææ¹ææ¾æ¼"],
["dba1","ææ¸æææ æ¿æ¦æ©æ°æµæ·ææææ¦æ§é¸æ®æ¿æ¶ææ¸æ·æææ ææ£æ¤ææ°æ©æ¼æªæææ¦æ¡ææ·æ¯æ´æ¬æ³æ©æ¸æ¤æææ¢æ®æ¹æææ§æªæ æ¡æ ©æ¡æ¡æ ²æ¡æ¢³æ «æ¡æ¡£æ¡·æ¡¿æ¢æ¢æ¢­æ¢æ¢æ¢æ¢æª®æ¢¹æ¡´æ¢µæ¢ æ¢ºæ¤æ¢æ¡¾æ¤æ£æ¤æ£æ¤¢æ¤¦æ£¡æ¤æ£"],
["dca1","æ£æ£§æ£æ¤¶æ¤æ¤æ£æ££æ¤¥æ£¹æ£ æ£¯æ¤¨æ¤ªæ¤æ¤£æ¤¡æ£æ¥¹æ¥·æ¥æ¥¸æ¥«æ¥æ¥¾æ¥®æ¤¹æ¥´æ¤½æ¥æ¤°æ¥¡æ¥æ¥æ¦æ¥ªæ¦²æ¦®æ§æ¦¿æ§æ§æ¦¾æ§å¯¨æ§æ§æ¦»æ§æ¦§æ¨®æ¦æ¦ æ¦æ¦æ¦´æ§æ§¨æ¨æ¨æ§¿æ¬æ§¹æ§²æ§§æ¨æ¦±æ¨æ§­æ¨æ§«æ¨æ¨æ«æ¨£æ¨æ©æ¨æ©²æ¨¶æ©¸æ©æ©¢æ©æ©¦æ©æ¨¸æ¨¢æªæªæª æªæª¢æª£"],
["dda1","æªèæª»æ«æ«æª¸æª³æª¬æ«æ«æ«æªªæ«æ«ªæ«»æ¬èæ«ºæ¬æ¬é¬±æ¬æ¬¸æ¬·çæ¬¹é£®æ­æ­æ­æ­æ­æ­æ­æ­æ­¡æ­¸æ­¹æ­¿æ®æ®æ®æ®æ®æ®æ®æ®¤æ®ªæ®«æ®¯æ®²æ®±æ®³æ®·æ®¼æ¯æ¯æ¯æ¯æ¯¬æ¯«æ¯³æ¯¯éº¾æ°æ°æ°æ°æ°¤æ°£æ±æ±æ±¢æ±ªæ²æ²æ²æ²æ²æ±¾æ±¨æ±³æ²æ²æ³æ³±æ³æ²½æ³æ³æ³æ²®æ²±æ²¾"],
["dea1","æ²ºæ³æ³¯æ³æ³ªæ´è¡æ´¶æ´«æ´½æ´¸æ´æ´µæ´³æ´æ´æµ£æ¶æµ¤æµæµ¹æµæ¶æ¶æ¿¤æ¶æ·¹æ¸æ¸æ¶µæ·æ·¦æ¶¸æ·æ·¬æ·æ·æ·¨æ·æ·æ·ºæ·æ·¤æ·æ·ªæ·®æ¸­æ¹®æ¸®æ¸æ¹²æ¹æ¸¾æ¸£æ¹«æ¸«æ¹¶æ¹æ¸æ¹æ¸ºæ¹æ¸¤æ»¿æ¸æ¸¸æºæºªæºæ»æº·æ»æº½æº¯æ»æº²æ»æ»æºæº¥æ»æºæ½æ¼çæ»¬æ»¸æ»¾æ¼¿æ»²æ¼±æ»¯æ¼²æ»"],
["dfa1","æ¼¾æ¼æ»·æ¾æ½ºæ½¸æ¾æ¾æ½¯æ½æ¿³æ½­æ¾æ½¼æ½æ¾æ¾æ¿æ½¦æ¾³æ¾£æ¾¡æ¾¤æ¾¹æ¿æ¾ªæ¿æ¿æ¿¬æ¿æ¿æ¿±æ¿®æ¿ççæ¿ºçççæ¿¾ççæ½´çççç°ç¾ç²çç£ççç¯ç±ç¬ç¸ç³ç®çççççç½ççç¥ççç¦ç¢ççç¬çç»ççç¨ç¬çç¹ç¾ççççç ç¬ç§çµç¼"],
["e0a1","ç¹ç¿çççç¨ç­ç¬ç°ç²ç»ç¼ç¿ççççç´ç¾çççççç¢ç§ç¹ç²çççççç¢ç ç¡ç¹ç·åçççççç´ç¯ç©ç¥ç¾ççé»ççªç¨ç°ç¸çµç»çºçç³çç»çç¥ç®çç¢çç¯ç¥ç¸ç²çºçç¿ççççç©ç°ç£çªç¶ç¾ççç§çççç±"],
["e1a1","ç ç£ç§ç©ç®ç²ç°ç±ç¸ç·çççççççççç¦ç¬ç¼çççççççç©ç¤ç§ç«ç­ç¸ç¶ççç´ççççççç¥ç£çç³ççµç½ç¸ç¼ç±ççççç£çç¾ç¿ç¼çç°çºç²ç³ççççç§ç ç¡ç¢ç¤ç´ç°ç»çççççç¡ç¢ç¨ç©çªç§ç¬ç°"],
["e2a1","ç²ç¶ç¸ç¼çççççççççç°ç´ç¸ç¹çºçççççç¡ç¥ç§çªè¯ç»çççç©ç¤çç¥ç¦çç·ç¸ççç¨ç«çç¥ç¿ç¾ç¹çççç çç°ç¶ç¹ç¿ç¼ç½ç»çççççç£ç®ç¼ç ç ç¤¦ç  ç¤ªç¡ç¢ç¡´ç¢ç¡¼ç¢ç¢ç¢£ç¢µç¢ªç¢¯ç£ç£ç£ç£ç¢¾ç¢¼ç£ç£ç£¬"],
["e3a1","ç£§ç£ç£½ç£´ç¤ç¤ç¤ç¤ç¤¬ç¤«ç¥ç¥ ç¥ç¥ç¥ç¥ç¥ç¥ºç¥¿ç¦ç¦ç¦§é½ç¦ªç¦®ç¦³ç¦¹ç¦ºç§ç§ç§§ç§¬ç§¡ç§£ç¨ç¨ç¨ç¨ç¨ ç¨ç¦ç¨±ç¨»ç¨¾ç¨·ç©ç©ç©ç©¡ç©¢ç©©é¾ç©°ç©¹ç©½çªçªçªçªçªçª©ç«çª°çª¶ç«ç«çª¿éç«ç«ç«ç«ç«ç«ç«ç«ç«ç«¡ç«¢ç«¦ç«­ç«°ç¬ç¬ç¬ç¬ç¬³ç¬ç¬ç¬ç¬µç¬¨ç¬¶ç­"],
["e4a1","ç­ºç¬ç­ç¬ç­ç­ç­µç­¥ç­´ç­§ç­°ç­±ç­¬ç­®ç®ç®ç®ç®ç®ç®ç®ç®ç®ç­ç®ç¯ç¯ç¯ç¯ç®´ç¯ç¯ç¯©ç°ç°ç¯¦ç¯¥ç± ç°ç°ç°ç¯³ç¯·ç°ç°ç¯¶ç°£ç°§ç°ªç°ç°·ç°«ç°½ç±ç±ç±ç±ç±ç±ç±ç±ç±¤ç±ç±¥ç±¬ç±µç²ç²ç²¤ç²­ç²¢ç²«ç²¡ç²¨ç²³ç²²ç²±ç²®ç²¹ç²½ç³ç³ç³ç³ç³ç³ç³¢é¬»ç³¯ç³²ç³´ç³¶ç³ºç´"],
["e5a1","ç´ç´ç´ç´çµçµç´®ç´²ç´¿ç´µçµçµ³çµçµçµ²çµ¨çµ®çµçµ£ç¶ç¶çµç¶çµ½ç¶ç¶ºç¶®ç¶£ç¶µç·ç¶½ç¶«ç¸½ç¶¢ç¶¯ç·ç¶¸ç¶ç¶°ç·ç·ç·¤ç·ç·»ç·²ç·¡ç¸ç¸ç¸£ç¸¡ç¸ç¸±ç¸ç¸ç¸ç¸¢ç¹ç¹¦ç¸»ç¸µç¸¹ç¹ç¸·ç¸²ç¸ºç¹§ç¹ç¹ç¹ç¹ç¹ç¹¹ç¹ªç¹©ç¹¼ç¹»çºç·ç¹½è¾®ç¹¿çºçºçºçºçºçºçºçºçºçºçºç¼¸ç¼º"],
["e6a1","ç½ç½ç½ç½ç½ç½ç½ç½ç½ç½ç½ ç½¨ç½©ç½§ç½¸ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾£ç¾¯ç¾²ç¾¹ç¾®ç¾¶ç¾¸è­±ç¿ç¿ç¿ç¿ç¿ç¿¡ç¿¦ç¿©ç¿³ç¿¹é£èèèèèèèè¡è¨è¿è»èèèèèèè¢è¨è³è²è°è¶è¹è½è¿èèèèèèè­åè¬èè¥èèèèèèè¯è±èè©è£è¯è"],
["e7a1","éèè¾èèè¼è±è®è¥è¦è´èèèèèè èè¤è£èèè©è°èµè¾è¸è½èèèºèèèèèèèèè è§èºè»è¾èèèèèèèèè©è«è¸è³èèèèèèè¤è¢è¨èªè«è®è±è·è¸è¾èèè«èè»è¬è¡è£èèè´è³èºèèè»è¹èèèèè"],
["e8a1","èµè´èè²è±èè¹èèè¯è«èèèèèªèè¢èè£èèèè¼èµè³èµè èè¨è´èè«èè½èèèèè·èè è²èè¢è è½è¸èè»è­èªè¼èèè·è«è­è®èè©èè¬è¯è¹èµèè¢è¹è¿èèèè»èèèèèè¡è¡è¿è´èèè¬èèèè¼èè£èè"],
["e9a1","èèèèèè¤èèèè¨è­èèèªèèè·è¾èèèºèè¹èèèè¥èè¹èèèè¾èºèè¢èè°è¿èä¹èèè§è±èè£è©èªèèè¶è¯èèè°èè £è«èèè©è¬èèè¯èèèèèè»èèèè¹èè´è¿è·è»è¥è©èè èè¸èèè´èè¨è®è"],
["eaa1","èè£èªè è¢èèè¯èè½èèéè«èè³èèè»è¯è²è è è è¾è¶è·è èè è è è ¢è ¡è ±è ¶è ¹è §è »è¡è¡è¡è¡è¡è¡¢è¡«è¢è¡¾è¢è¡µè¡½è¢µè¡²è¢è¢è¢è¢®è¢è¢¢è¢è¢¤è¢°è¢¿è¢±è£è£è£è£è£è£è£¹è¤è£¼è£´è£¨è£²è¤è¤è¤è¤è¥è¤è¤¥è¤ªè¤«è¥è¥è¤»è¤¶è¤¸è¥è¤è¥ è¥"],
["eba1","è¥¦è¥¤è¥­è¥ªè¥¯è¥´è¥·è¥¾è¦è¦è¦è¦è¦è¦¡è¦©è¦¦è¦¬è¦¯è¦²è¦ºè¦½è¦¿è§è§è§è§è§§è§´è§¸è¨è¨è¨è¨è¨è¨è¨¥è¨¶è©è©è©è©è©è©¼è©­è©¬è©¢èªèªèªèª¨èª¡èªèª¥èª¦èªèª£è«è«è«è«è««è«³è«§è«¤è«±è¬è« è«¢è«·è«è«è¬è¬è¬è«¡è¬è¬è¬è¬ è¬³é«è¬¦è¬«è¬¾è¬¨è­è­è­è­è­è­è­è­è­«"],
["eca1","è­è­¬è­¯è­´è­½è®è®è®è®è®è®è®è®è°ºè±è°¿è±è±è±è±è±è±¢è±¬è±¸è±ºè²è²è²è²è²è²è²è±¼è²æè²­è²ªè²½è²²è²³è²®è²¶è³è³è³¤è³£è³è³½è³ºè³»è´è´è´è´è´è´è´é½è´è³è´è´èµ§èµ­èµ±èµ³è¶è¶è·è¶¾è¶ºè·è·è·è·è·è·è·ªè·«è·è·£è·¼è¸è¸è·¿è¸è¸è¸è¸è¹è¸µè¸°è¸´è¹"],
["eda1","è¹è¹è¹è¹è¹è¹è¹¤è¹ è¸ªè¹£è¹è¹¶è¹²è¹¼èºèºèºèºèºèºèºèºèºèºèºªèº¡èº¬èº°è»èº±èº¾è»è»è»è»è»£è»¼è»»è»«è»¾è¼è¼è¼è¼è¼è¼è¼è¼è¼è¼è¼¦è¼³è¼»è¼¹è½è½è¼¾è½è½è½è½è½è½è½¢è½£è½¤è¾è¾è¾£è¾­è¾¯è¾·è¿è¿¥è¿¢è¿ªè¿¯éè¿´éè¿¹è¿ºééé¡ééééé§é¶éµé¹è¿¸"],
["eea1","ééééééé¾éééé¨é¯é¶é¨é²éé½éééééé¨é¯é±éµé¢é¤æééééé²é°éééé£é¥é©é³é²éééé¢é«é¯éªéµé´éºéééééééé¡éé¼éµé¶éé¿éé¬ééééééé¤ééé¿ééééééééé¹é·é©ééºéé®"],
["efa1","éé¢éé£éºéµé»éé é¼é®éé°é¬é­éé¹ééé¨é¥éééééé¤ééééééé¶é«éµé¡éºééééé é¢ééªé©é°éµé·é½éé¼é¾éé¿éééééééé é¨é§é­é¼é»é¹é¾éæ¿¶éééééééé¡é¥é¢é¡é¨é®é¯ééééé·éé"],
["f0a1","ééé¦é²é¬éééééªé§é±é²é°é´é¶é¸é¹ééééè¥éééé¹éééééééééé¤éªé°é¹é½é¾ééééééé é¤é¦é¨åé«é±é¹éé¼ééºéééééé¨é¦é£é³é´éééééé­é½é²ç«é¶éµé é é ¸é ¤é ¡é ·é ½é¡é¡é¡é¡«é¡¯é¡°"],
["f1a1","é¡±é¡´é¡³é¢ªé¢¯é¢±é¢¶é£é£é£é£©é£«é¤é¤é¤é¤é¤é¤¡é¤é¤é¤¤é¤ é¤¬é¤®é¤½é¤¾é¥é¥é¥é¥é¥é¥é¥é¥é¥é¦é¦é¦¥é¦­é¦®é¦¼é§é§é§é§é§é§­é§®é§±é§²é§»é§¸é¨é¨é¨é§¢é¨é¨«é¨·é©é©é©é©é¨¾é©é©é©é©é©é©¢é©¥é©¤é©©é©«é©ªéª­éª°éª¼é«é«é«é«é«é«é«é«¢é«£é«¦é«¯é««é«®é«´é«±é«·"],
["f2a1","é«»é¬é¬é¬é¬é¬¢é¬£é¬¥é¬§é¬¨é¬©é¬ªé¬®é¬¯é¬²é­é­é­é­é­é­é­é­´é®é®é®é®é®é®é® é®¨é®´é¯é¯é®¹é¯é¯é¯é¯é¯£é¯¢é¯¤é¯é¯¡é°ºé¯²é¯±é¯°é°é°é°é°é°é°é°é°é°é°é°®é°é°¥é°¤é°¡é°°é±é°²é±é°¾é±é± é±§é±¶é±¸é³§é³¬é³°é´é´é³«é´é´é´ªé´¦é¶¯é´£é´éµé´é´éµé´¿é´¾éµéµ"],
["f3a1","éµéµéµ¤éµéµéµéµ²é¶é¶é¶«éµ¯éµºé¶é¶¤é¶©é¶²é·é·é¶»é¶¸é¶ºé·é·é·é·é·é·¸é·¦é·­é·¯é·½é¸é¸é¸é¹µé¹¹é¹½éºéºéºéºéºéºéºéºéº¥éº©éº¸éºªéº­é¡é»é»é»é»é»é»é»é»é» é»¥é»¨é»¯é»´é»¶é»·é»¹é»»é»¼é»½é¼é¼ç·é¼é¼¡é¼¬é¼¾é½é½é½é½£é½é½ é½¡é½¦é½§é½¬é½ªé½·é½²é½¶é¾é¾é¾ "],
["f4a1","å ¯æ§éç¤åç"],
["f9a1","çºè¤ééèä¿ç»æ±æ£é¹æ»å½ä¸¨ä»¡ä»¼ä¼ä¼ä¼¹ä½ä¾ä¾ä¾ä¾ä¿åå¢ä¿¿ååå°ååå´ååå¤åå¾å¬ååå¦ååååå¤å²åå²åï¨ååå©å¿ååå¥å¬ååï¨ï¨å¢å¢²å¤å¥å¥å¥å¥£å¦¤å¦ºå­å¯ç¯å¯å¯¬å°å²¦å²ºå³µå´§åµï¨åµåµ­å¶¸å¶¹å·å¼¡å¼´å½§å¾·"],
["faa1","å¿ææææææ æ²ææ·æ°æææ¦æµæ ææææææ»ææ®ææ¤æ¥ææï¨æ³ææ æ²æ¿æºæï¤©æ¦æ»æ¡ææ æ¡æ£ï¨æ¥¨ï¨æ¦æ§¢æ¨°æ©«æ©æ©³æ©¾æ«¢æ«¤æ¯æ°¿æ±æ²æ±¯æ³æ´æ¶æµ¯æ¶æ¶¬æ·æ·¸æ·²æ·¼æ¸¹æ¹æ¸§æ¸¼æº¿æ¾æ¾µæ¿µççç¨çç«çççççï¨çç¾ç±"],
["fba1","ç¾ç¤ï¨ç·ç½ççç£çççµç¦çªç©ç®ç¢çççç¯ççççç¦ï¨çå¯ç ¡ç¡ç¡¤ç¡ºç¤°ï¨ï¨ï¨ç¦ï¨ç¦ç«ç«§ï¨ç««ç®ï¨çµçµç¶·ç¶ ç·ç¹ç½ç¾¡ï¨èè¢è¿èè¶èè´èèè«ï¨è°ï¨ ï¨¡è è£µè¨è¨·è©¹èª§èª¾è«ï¨¢è«¶è­è­¿è³°è³´è´èµ¶ï¨£è»ï¨¤ï¨¥é§éï¨¦éé§é"],
["fca1","ééé­é®é¤é¥ééééºéé¼éééé¹é§é§é·é¸é§éééï¨§éé éé¥é¡é»ï¨¨éé¿ééé°éé¤ééé¸é±éééï§ï¨©éé¯é³é»éééééé¡é¡¥ï¨ªï¨«é¤§ï¨¬é¦é©é«é«é­µé­²é®é®±é®»é°éµ°éµ«ï¨­é¸é»"],
["fcf1","â°",9,"ï¿¢ï¿¤ï¼ï¼"],
["8fa2af","ËËÂ¸ËËÂ¯ËËï½ÎÎ"],
["8fa2c2","Â¡Â¦Â¿"],
["8fa2eb","ÂºÂªÂ©Â®â¢Â¤â"],
["8fa6e1","ÎÎÎÎÎª"],
["8fa6e7","Î"],
["8fa6e9","ÎÎ«"],
["8fa6ec","Î"],
["8fa6f1","Î¬Î­Î®Î¯ÏÎÏÏÏÏÎ°Ï"],
["8fa7c2","Ð",10,"ÐÐ"],
["8fa7f2","Ñ",10,"ÑÑ"],
["8fa9a1","ÃÄ"],
["8fa9a4","Ä¦"],
["8fa9a6","Ä²"],
["8fa9a8","ÅÄ¿"],
["8fa9ab","ÅÃÅ"],
["8fa9af","Å¦Ã"],
["8fa9c1","Ã¦ÄÃ°Ä§Ä±Ä³Ä¸ÅÅÅÅÃ¸ÅÃÅ§Ã¾"],
["8faaa1","ÃÃÃÃÄÇÄÄÃÃÄÄÄÃÄÄÃÃÃÃÄÄÄÄ"],
["8faaba","ÄÄÄ¢Ä Ä¤ÃÃÃÃÇÄ°ÄªÄ®Ä¨Ä´Ä¶Ä¹Ä½Ä»ÅÅÅÃÃÃÃÃÇÅÅÃÅÅÅÅÅÅ ÅÅ¤Å¢ÃÃÃÃÅ¬ÇÅ°ÅªÅ²Å®Å¨ÇÇÇÇÅ´ÃÅ¸Å¶Å¹Å½Å»"],
["8faba1","Ã¡Ã Ã¤Ã¢ÄÇÄÄÃ¥Ã£ÄÄÄÃ§ÄÄÃ©Ã¨Ã«ÃªÄÄÄÄÇµÄÄ"],
["8fabbd","Ä¡Ä¥Ã­Ã¬Ã¯Ã®Ç"],
["8fabc5","Ä«Ä¯Ä©ÄµÄ·ÄºÄ¾Ä¼ÅÅÅÃ±Ã³Ã²Ã¶Ã´ÇÅÅÃµÅÅÅÅÅÅ¡ÅÅ¥Å£ÃºÃ¹Ã¼Ã»Å­ÇÅ±Å«Å³Å¯Å©ÇÇÇÇÅµÃ½Ã¿Å·ÅºÅ¾Å¼"],
["8fb0a1","ä¸ä¸ä¸ä¸ä¸ä¸ä¸£ä¸¤ä¸¨ä¸«ä¸®ä¸¯ä¸°ä¸µä¹ä¹ä¹ä¹ä¹ä¹ä¹ä¹£ä¹¨ä¹©ä¹´ä¹µä¹¹ä¹¿äºäºäºäºäº¯äº¹ä»ä»ä»ä»ä» ä»¡ä»¢ä»¨ä»¯ä»±ä»³ä»µä»½ä»¾ä»¿ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä¼®ä¼±ä½ ä¼³ä¼µä¼·ä¼¹ä¼»ä¼¾ä½ä½ä½ä½ä½ä½ä½ä½ä½ä½ä½ä½£ä½ªä½¬ä½®ä½±ä½·ä½¸ä½¹ä½ºä½½ä½¾ä¾ä¾ä¾"],
["8fb1a1","ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä¾²ä¾·ä¾¹ä¾»ä¾¼ä¾½ä¾¾ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ä¿ ä¿¢ä¿°ä¿²ä¿¼ä¿½ä¿¿åååååååååååååååå¢å§å®å°å²å³åµååååååååååååååå å¢å£å¦å§åªå­å°å±å»åååååååå"],
["8fb2a1","ååååååå",4,"åªå¯å°å¹åºå½åååååååååååååå¢å¤å¦å¨å©å¯å±å¶åºå¾ååååååååå²åååååååå£å§å¨å¬å­å¯å±å³å´åµå¸å¹ååååååååå¤å¦å¾ååååååå¡å£å­å¸åºå¼å¾å¿å"],
["8fb3a1","ååååååååå¢å¥å®å²å³å´å·ååååååååå¢å¨å±å²åµå¼ååååååååå å¡å¦å®å·å¸å¹ååååååååååååå¤å¥å¦å§å¯å°å¶å·å¸åºå»å½åååååååååååå¡å¥å¨å©åªå¬å°å±å´å¶å·åååå"],
["8fb4a1","ååååååååå¥å§å¨å©å«å¬å­å°å²åµå¼å½å¾åååååå¡å£å¥å¬å­å²å¹å¾ååååååååå¡å¤åªå«å¯å²å´åµå·å¸åºå½åååååååååå å¦å§åµåååå¡å§å¨åªå¯å±å´åµååååååå¢å¤å¦å§å©å«å­å®å´å¿"],
["8fb5a1","åååååååååååå¡å¦å§å©åªå­å®å±å·å¹åºå»å¿ååååå åªå¬å¯å¶å¼å¾å¿ååååååååååªå«å²åµå¶å»å¼å½åååååååååååå å¡å¤å¦å¿åååååååååååå£å¤å­å²å¿ååååååååå"],
["8fb6a1","åååååå¢å©å¶å¿åååå",5,"åå¬å°å³åµå·å¹å»å¼å½å¿åååååååååååå å¡å¢å£å¦å©å­å¯å±å²åµåååååååååååå¦å§å¨å©å«å¬å­å±å³å·å¾ååååååååååååå¡å¤",4,"å±å«å­"],
["8fb7a1","å¶å·ååååååååååå å¢å£å¤å¥å©åªå¬å®å¯å³å´å½å¾å¿åååååå¢å¥å§å¨å«å­",4,"å³å´åµå·å¹åºå»å¼å¾åååååååååååå¡åå§å¨å©å¬å¸å½åååååååå¤å¦å§å©å­å°åµå¶å¸å½å¾å¿å å å å å¡"],
["8fb8a1","å å å å å å  å ¦å §å ­å ²å ¹å ¿å¡å¡å¡å¡å¡å¡å¡å¡¡å¡¤å¡§å¡¨å¡¸å¡¼å¡¿å¢å¢å¢å¢å¢å¢å¢å¢å¢å¢å¢å¢å¢å¢ å¢¡å¢¢å¢¦å¢©å¢±å¢²å£å¢¼å£å£å£å£å£å£å£å£å£å£å£¡å£¢å£©å£³å¤å¤å¤å¤å¤å¤å¤èå¤å¤¡å¤£å¤¤å¤¨å¤¯å¤°å¤³å¤µå¤¶å¤¿å¥å¥å¥å¥å¥å¥å¥å¥å¥å¥¡å¥£å¥«å¥­"],
["8fb9a1","å¥¯å¥²å¥µå¥¶å¥¹å¥»å¥¼å¦å¦å¦å¦å¦å¦å¦å¦¤å¦§å¦­å¦®å¦¯å¦°å¦³å¦·å¦ºå¦¼å§å§å§å§å§å§å§å§å§å§å§£å§¤å§§å§®å§¯å§±å§²å§´å§·å¨å¨å¨å¨å¨å¨å¨å¨å¨£å¨¤å¨§å¨¨å¨ªå¨­å¨°å©å©å©å©å©å©å©å©å©£å©¥å©§å©­å©·å©ºå©»å©¾åªåªåªåªåªåªåªåªåª åª¢åª§åª¬åª±åª²åª³åªµåª¸åªºåª»åª¿"],
["8fbaa1","å«å«å«å«å«å«å« å«¥å«ªå«®å«µå«¶å«½å¬å¬å¬å¬å¬´å¬å¬å¬å¬¡å¬¥å¬­å¬¸å­å­å­å­å­å­å­¨å­®å­¯å­¼å­½å­¾å­¿å®å®å®å®å®å®å®å®å®å®å®¨å®©å®¬å®­å®¯å®±å®²å®·å®ºå®¼å¯å¯å¯å¯å¯",4,"å¯ å¯¯å¯±å¯´å¯½å°å°å°å°å°£å°¦å°©å°«å°¬å°®å°°å°²å°µå°¶å±å±å±å±¢å±£å±§å±¨å±©"],
["8fbba1","å±­å±°å±´å±µå±ºå±»å±¼å±½å²å²å²å²å²å²å²å² å²¢å²£å²¦å²ªå²²å²´å²µå²ºå³å³å³å³å³å³®å³±å³²å³´å´å´å´å´å´«å´£å´¤å´¦å´§å´±å´´å´¹å´½å´¿åµåµåµåµåµåµåµåµåµåµ åµ¡åµ¢åµ¤åµªåµ­åµ°åµ¹åµºåµ¾åµ¿å¶å¶å¶å¶å¶å¶å¶å¶å¶å¶å¶å¶ å¶§å¶«å¶°å¶´å¶¸å¶¹å·å·å·å·å·å·å·å· å·¤"],
["8fbca1","å·©å·¸å·¹å¸å¸å¸å¸å¸å¸å¸å¸å¸ å¸®å¸¨å¸²å¸µå¸¾å¹å¹å¹å¹å¹å¹å¹å¹å¹å¹¨å¹ª",4,"å¹°åºåºåºåº¢åº¤åº¥åº¨åºªåº¬åº±åº³åº½åº¾åº¿å»å»å»å»å»å»å»å»å»å»å»¥å»«å¼å¼å¼å¼å¼å¼å¼å¼å¼¡å¼¢å¼£å¼¤å¼¨å¼«å¼¬å¼®å¼°å¼´å¼¶å¼»å¼½å¼¿å½å½å½å½å½å½å½å½å½å½ å½£å½¤å½§"],
["8fbda1","å½¯å½²å½´å½µå½¸å½ºå½½å½¾å¾å¾å¾å¾å¾å¾å¾¢å¾§å¾«å¾¤å¾¬å¾¯å¾°å¾±å¾¸å¿å¿å¿å¿å¿å¿",4,"å¿å¿¡å¿¢å¿¨å¿©å¿ªå¿¬å¿­å¿®å¿¯å¿²å¿³å¿¶å¿ºå¿¼ææææææææææ¤æ­æ³æµæææææææææææ¡æ§æ±æ¾æ¿ææææææææææææ¢æ¤æ¥æ¨æ°æ±æ·"],
["8fbea1","æ»æ¾æææææææææææææææ¢æ¥æ²æµæ¸æ¼æ½æææææ",4,"ææææææ¢æªæ«æ°æ±æµæ¶æ·æ¹ææææææ æ¬æ²æ¸æ»æ¼æ¿ææææææææææææææ æ¥æ¨æªæ­æ¸æ¹æ¼æææææææææææ¡æ¢æ§æ©æ¥"],
["8fbfa1","æ¬æ­æ¯ææææææææ æ¢æ£æ§æ©æ«æ¹æ½æææææææææææææ¤æ­æ¯æ³æºæ½æææææ¦æ¨æ³æ¶æ·æºæ¾æ¿ææææææªæ²æ´æ¼æ½ææææææææææ©æªæ­æµæ¶æ¹æ¼ææææææææææææææ¥æ¦æ¬æ­æ±æ´æµ"],
["8fc0a1","æ¸æ¼æ½æ¿æææææææææææ¤æ¦æ­æ®æ¯æ½ææææææææææ æ¥æªæ¬æ²æ³æµæ¸æ¹ææææææææ æ¢æ¤æ¥æ©æªæ¯æ°æµæ½æ¿ææææææææææææ æ¡æ£æ­æ³æ´æ»æ½æææææææææææ¡æ£æ¦æ¨æ¬æ³æ½æ¾æ¿"],
["8fc1a1","æææææææææææ¤æ¥æ©æªæ­æ°æµæ·æ»æ¿ææææææææææææææ¢æ¦æ©æ®æ±æºæ¼æ½ææææææææ æ§æ«æºæ½ææææææææ æ£æ¦æ®æ²æ³æ´æ¿ææææææææææ°æ²æ´æµæ¹æ¾æ¿ææææææææææ"],
["8fc2a1","ææ¡æ¢æ£æ¤æ¦æ©æªæ«æ¬æ®æ°æ±æ³æ¹æ·æææææææææææææ æ¡æ»æªæ«æ¬æ¾æ³æµæ¿æ·æ¸æ¹æ»ææ¼æææææææææææ æ¤æ­æ±æ²æµæ»æ¿æææææææææææ¨æ«æ¬æ®æºæææææææ æ¢æ³æ¾æææææææ"],
["8fc3a1","æ¦æ¬æ®æ´æ¶æ»æææææææææææ°æ±æ²æµæ»æ¼æ½æ¹æææææææææææ¡æ¦æ°æ²æ¶æ·æ¡æ æ æ æ æ ¨æ §æ ¬æ ­æ ¯æ °æ ±æ ³æ »æ ¿æ¡æ¡æ¡æ¡æ¡æ¡æ¡æ¡æ¡«æ¡®",4,"æ¡µæ¡¹æ¡ºæ¡»æ¡¼æ¢æ¢æ¢æ¢æ¢æ¢æ¢æ¢æ¢¡æ¢£æ¢¥æ¢©æ¢ªæ¢®æ¢²æ¢»æ£æ£æ£æ£"],
["8fc4a1","æ£æ£æ£æ£æ£æ£æ£æ£¥æ£¨æ£ªæ£«æ£¬æ£­æ£°æ£±æ£µæ£¶æ£»æ£¼æ£½æ¤æ¤æ¤æ¤æ¤æ¤æ¤æ¤æ¤±æ¤³æ¤µæ¤¸æ¤»æ¥æ¥æ¥æ¥æ¥æ¥æ¥£æ¥¤æ¥¥æ¥¦æ¥¨æ¥©æ¥¬æ¥°æ¥±æ¥²æ¥ºæ¥»æ¥¿æ¦æ¦æ¦æ¦æ¦æ¦¡æ¦¥æ¦¦æ¦¨æ¦«æ¦­æ¦¯æ¦·æ¦¸æ¦ºæ¦¼æ§æ§æ§æ§æ§æ§¢æ§¥æ§®æ§¯æ§±æ§³æ§µæ§¾æ¨æ¨æ¨æ¨æ¨æ¨æ¨æ¨æ¨ æ¨¤æ¨¨æ¨°æ¨²"],
["8fc5a1","æ¨´æ¨·æ¨»æ¨¾æ¨¿æ©æ©æ©æ©æ©æ©æ©æ©æ©æ©æ©æ©¤æ©§æ©ªæ©±æ©³æ©¾æªæªæªæªæªæªæªæªæªæªæªæª¥æª«æª¯æª°æª±æª´æª½æª¾æª¿æ«æ«æ«æ«æ«æ«æ«æ«æ«æ«æ«¤æ«§æ«¬æ«°æ«±æ«²æ«¼æ«½æ¬æ¬æ¬æ¬æ¬æ¬æ¬æ¬æ¬æ¬æ¬æ¬¤æ¬¨æ¬«æ¬¬æ¬¯æ¬µæ¬¶æ¬»æ¬¿æ­æ­æ­æ­æ­æ­æ­æ­ æ­§æ­«æ­®æ­°æ­µæ­½"],
["8fc6a1","æ­¾æ®æ®æ®æ®æ®æ® æ®¢æ®£æ®¨æ®©æ®¬æ®­æ®®æ®°æ®¸æ®¹æ®½æ®¾æ¯æ¯æ¯æ¯æ¯æ¯æ¯¡æ¯£æ¯¦æ¯§æ¯®æ¯±æ¯·æ¯¹æ¯¿æ°æ°æ°æ°æ°æ°æ°æ°æ°æ°æ°¦æ°§æ°¨æ°¬æ°®æ°³æ°µæ°¶æ°ºæ°»æ°¿æ±æ±æ±æ±æ±æ±æ±æ±æ±æ±«æ±­æ±¯æ±´æ±¶æ±¸æ±¹æ±»æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²æ²°æ²²æ²´æ³æ³æ³æ³æ³æ³æ³æ³æ³"],
["8fc7a1","æ³æ³æ³ æ³§æ³©æ³«æ³¬æ³®æ³²æ³´æ´æ´æ´æ´æ´æ´æ´æ´æ´¦æ´§æ´¨æ±§æ´®æ´¯æ´±æ´¹æ´¼æ´¿æµæµæµæµ¡æµ¥æµ§æµ¯æµ°æµ¼æ¶æ¶æ¶æ¶æ¶æ¶æ¶æ¶æ¶ªæ¶¬æ¶´æ¶·æ¶¹æ¶½æ¶¿æ·æ·æ·æ·æ·æ·æ·æ·æ·æ· æ·¢æ·¥æ·©æ·¯æ·°æ·´æ·¶æ·¼æ¸æ¸æ¸æ¸¢æ¸§æ¸²æ¸¶æ¸¹æ¸»æ¸¼æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹æ¹"],
["8fc8a1","æ¹¢æ¹£æ¹¨æ¹³æ¹»æ¹½æºæºæºæº æº§æº­æº®æº±æº³æº»æº¿æ»æ»æ»æ»æ»æ»æ»æ»æ»æ»«æ»­æ»®æ»¹æ»»æ»½æ¼æ¼æ¼æ¼æ¼æ¼æ¼æ¼æ¼æ¼¦æ¼©æ¼ªæ¼¯æ¼°æ¼³æ¼¶æ¼»æ¼¼æ¼­æ½æ½æ½æ½æ½æ½æ½æ½æ½æ½¡æ½¢æ½¨æ½¬æ½½æ½¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾æ¾ æ¾¥æ¾¦æ¾§æ¾¨æ¾®æ¾¯æ¾°æ¾µæ¾¶æ¾¼æ¿æ¿æ¿æ¿"],
["8fc9a1","æ¿æ¿æ¿¨æ¿©æ¿°æ¿µæ¿¹æ¿¼æ¿½ççççççç ç£ç¯ç´ç·ç¹ç¼çççççççççççç¤ç¥ç¬ç®çµç¶ç¾çççç",4,"çç¤ç«ç°ç±ç´ç·ççççççççç¤çºç",4,"ççççç ç«ç­ç¯ç°ç±ç¸ççççççççççççç "],
["8fcaa1","ç¨ç¹çççççççç ç¢ç¯ç°ç²ç³çºç¿ççççççççççç¸ç¾çççççççççç¤ç«ç¯ç´ç¸ç¹çççççççççççççç ç£ç¨ç«ç®ç¯ç±ç·ç¸ç»ç¼ç¿ççççççç¨ç­ç®ç±ç´ç¾ççççççççç¥ç³ç´çºç»"],
["8fcba1","ç¾çççççççççççç¢ç¤ç§ç¨ç¬ç±ç²çµçºç»ç½çççççççççç ç¦ç§ç©ç«ç¬ç®ç¯ç±ç·ç¹ç¼ççççççççççççççç ç¢ç¥ç¦çªç«ç­çµç·ç¹ç¼ç½ç¿çççççççççççç¡ç£ç¦ç§ç©ç´çµç·ç¹çºç»ç½"],
["8fcca1","ç¿ççççççççç¤ç¦ç¨",9,"ç¹çççççççççççç¢ç¦ç§ç¨ç«ç­ç®ç±ç²çççççççççççççççç ç¡ç£ç¦ç¨ç©çªç«ç®ç¯ç±ç²çµç¹ç»ç¿ççççççççççç¤ç¨çªç«ç¯ç´çºç»ç¼ç¿ç"],
["8fcda1","çççç ç¡ç¤ç§ç©çªç¯ç¶ç¹ç½ç¾ç¿ççççççççççç¡ç¯ç±ç¹",5,"ççççççççç¢ç¤ç´çºç¿ççççççççççç ç¡ç¤ç§ç¬ç®ç¯ç±ç¹ççççççççççççççççççç£ç¥ç¦ç©ç­ç²ç³çµç¸ç¹"],
["8fcea1","çºç¼çççççççççççç¤ç¥ç­ç®ç¯ç±ç´çççççççççç ç¢",6,"çªç­ç½ççççççççç ç¦ç¨ç¬ç°ç±ç¶ç¹ç¼çççççççççççç¢ç¨ç­ç®ç¯ç´çµç¶ç¹ç½ç¾çççççççççççççç ç¢"],
["8fcfa1","ç¤ç§çªç¬ç°ç²ç³ç´çºç½çççççççççç¢ç§çªç®ç¯ç±çµç¾ççççççççç ç¤ç¦çªç¬ç°ç±ç´ç¸ç»ç ç ç ç ç ç ç ç ¡ç ¢ç £ç ­ç ®ç °ç µç ·ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ç¡ ç¡¡ç¡£ç¡¤ç¡¨ç¡ªç¡®ç¡ºç¡¾ç¢ç¢ç¢ç¢ç¢¡ç¢ç¢ç¢ç¢¤ç¢¨ç¢¬ç¢­ç¢°ç¢±ç¢²ç¢³"],
["8fd0a1","ç¢»ç¢½ç¢¿ç£ç£ç£ç£ç£ç£ç£ç£ç£ç£¤ç£ç£ç£ ç£¡ç£¦ç£ªç£²ç£³ç¤ç£¶ç£·ç£ºç£»ç£¿ç¤ç¤ç¤ç¤ç¤ç¤ç¤ç¤ ç¤¥ç¤§ç¤©ç¤­ç¤±ç¤´ç¤µç¤»ç¤½ç¤¿ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç¥§ç¥©ç¥«ç¥²ç¥¹ç¥»ç¥¼ç¥¾ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦ç¦¡ç¦¨ç¦©ç¦«ç¦¯ç¦±ç¦´ç¦¸ç¦»ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§ç§"],
["8fd1a1","ç§ ç§¢ç§¥ç§ªç§«ç§­ç§±ç§¸ç§¼ç¨ç¨ç¨ç¨ç¨ç¨ç¨ç¨ç¨ç¨ç¨¡ç¨§ç¨«ç¨­ç¨¯ç¨°ç¨´ç¨µç¨¸ç¨¹ç¨ºç©ç©ç©ç©ç©ç©ç©ç©ç©ç©ç©ç© ç©¥ç©§ç©ªç©­ç©µç©¸ç©¾çªçªçªçªçªçªçªçªçªçªçª çª£çª¬çª³çªµçª¹çª»çª¼ç«ç«ç«ç«ç«ç«ç«¨ç«©ç««ç«¬ç«±ç«´ç«»ç«½ç«¾ç¬ç¬ç¬ç¬£ç¬§ç¬©ç¬ªç¬«ç¬­ç¬®ç¬¯ç¬°"],
["8fd2a1","ç¬±ç¬´ç¬½ç¬¿ç­ç­ç­ç­ç­ç­ ç­¤ç­¦ç­©ç­ªç­­ç­¯ç­²ç­³ç­·ç®ç®ç®ç®ç®ç®ç®ç®ç® ç®¥ç®¬ç®¯ç®°ç®²ç®µç®¶ç®ºç®»ç®¼ç®½ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯ç¯¨ç¯ªç¯²ç¯´ç¯µç¯¸ç¯¹ç¯ºç¯¼ç¯¾ç°ç°ç°ç°ç°ç°ç°ç°ç°ç°ç°ç°ç° ç°¥ç°¦ç°¨ç°¬ç°±ç°³ç°´ç°¶ç°¹ç°ºç±ç±ç±ç±ç±ç±ç±",5],
["8fd3a1","ç±¡ç±£ç±§ç±©ç±­ç±®ç±°ç±²ç±¹ç±¼ç±½ç²ç²ç²ç²ç²ç² ç²¦ç²°ç²¶ç²·ç²ºç²»ç²¼ç²¿ç³ç³ç³ç³ç³ç³ç³ç³ç³ç³ç³ç³ç³ç³¦ç³©ç³«ç³µç´ç´ç´ç´ç´ç´ç´ç´ç´ç´ç´ç´£ç´¦ç´ªç´­ç´±ç´¼ç´½ç´¾çµçµçµçµçµçµçµçµçµçµçµçµçµ¥çµ§çµªçµ°çµ¸çµºçµ»çµ¿ç¶ç¶ç¶ç¶ç¶ç¶ç¶ç¶ç¶ç¶ç¶ç¶ç¶"],
["8fd4a1","ç¶ç¶¦ç¶§ç¶ªç¶³ç¶¶ç¶·ç¶¹ç·",4,"ç·ç·ç·ç·ç·ç¸ç·¢ç·¥ç·¦ç·ªç·«ç·­ç·±ç·µç·¶ç·¹ç·ºç¸ç¸ç¸ç¸ç¸ç¸ç¸ç¸ ç¸§ç¸¨ç¸¬ç¸­ç¸¯ç¸³ç¸¶ç¸¿ç¹ç¹ç¹ç¹ç¹ç¹ç¹ç¹ç¹¡ç¹¢ç¹¥ç¹«ç¹®ç¹¯ç¹³ç¹¸ç¹¾çºçºçºçºçºçºçºçºçºçºçºç¼¼ç¼»ç¼½ç¼¾ç¼¿ç½ç½ç½ç½ç½ç½ç½ç½ç½ç½¡ç½£ç½¤ç½¥ç½¦ç½­"],
["8fd5a1","ç½±ç½½ç½¾ç½¿ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾ç¾¡ç¾¢ç¾¦ç¾ªç¾­ç¾´ç¾¼ç¾¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿£ç¿¥ç¿¨ç¿¬ç¿®ç¿¯ç¿²ç¿ºç¿½ç¿¾ç¿¿èèèèèèèèèèèèèè è¤è¦è¬è®è°è´èµè·è¹èºè¼è¾èèè è¤è¦è­è±èµèèèèèè¦è§è«è¸è¹èèèèèèèèè è­è®"],
["8fd6a1","è°è²è³è¶è¹èºè¾èèèèèèèè è¤è§è¬è°èµèºè¼èèèèèèè è¡è§è¨è©è­è¯è·èèèèèèèèèèèè¢è®è²è´è»èèèèèèèèèèèè¡è¤è«è¬è°è±è²èµè¶è¸è¹è½è¿èèèèèèèèè¡è¢è¨è²è´èºèèèè"],
["8fd7a1","èèèèèèè è£è§è­è´è»è½è¿èèèèèèèèèèèèèèè è¡è£è¤è§è¨è©èªè®è°è²è´è·èºè¼è¾è¿èèèèè è¢è¤è¨èªè­è¯è¶è·è½è¾èèèèèèèèèèèè¡è¢è¬è­è®è°è³è·èºè¼è½èèèèèèèèèèè°è¸"],
["8fd8a1","è½è¿èèèèèèèèèèèèèè¦è§è©è¬è¾è¿èèèèèèèèèè¨èªè¶è¸è¹è¼èèèèèèèè­è¯è¹èèèèèèèèèèèèèè è¤è¥è§èªè°è³è´è¶è¸è¼è½èèèèèèè¦è¨è©èªè¯è±è´èºè½è¾èèèèèèè"],
["8fd9a1","èè§èªè¯è°è±è²è·è²èºè»è½èèèèèèèèè¢è£è¤è¥è§èªè«è¯è³è´è¶è¿èè",4,"èèè",6,"è¤è«è¯è¹èºè»è½è¿èèèèèèèèèèèè è¢è¥è§è´è¶è·è¸è¼è½è¾è¿èèèèèè­èèèè è¦è¨è­è³è¶è¼"],
["8fdaa1","è¿èèèèèèèèèèèèè¡è§è©è¶è¸èºè¼è½èèèèèèèèèèè ",4,"è©è¬è¯èµè¶è·èºèèèèèèè¡è¦è§è¨è­è±è³è´èµè·è¸è¹è¿èèèèèèèèèèè è£è¥è§èèºè¼è½èèèèèèèèèèèèè¡è£"],
["8fdba1","è¨è®è¯è±è²è¹èºè¼è½è¾èèèèèèè¡è¤è¥è¯è±è²è»è",6,"èèèèèèèèèè è£è§è¬è­è®è±èµè¾è¿èèèèèèèèèèèè¢è£è¤èªè«è­è±è³è¸èºè¿è è è è è è è è è è è è è è è è è è ¨è ­è ®è °è ²è µ"],
["8fdca1","è ºè ¼è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡è¡ è¡¤è¡©è¡±è¡¹è¡»è¢è¢è¢è¢è¢è¢è¢ è¢¨è¢ªè¢ºè¢½è¢¾è£è£",4,"è£è£è£è£è£è£§è£¯è£°è£±è£µè£·è¤è¤è¤è¤è¤è¤è¤è¤è¤è¤è¤è¤ è¤¦è¤§è¤¨è¤°è¤±è¤²è¤µè¤¹è¤ºè¤¾è¥è¥è¥è¥è¥è¥è¥è¥è¥è¥è¥è¥¡è¥¢è¥£è¥«è¥®è¥°è¥³è¥µè¥º"],
["8fdda1","è¥»è¥¼è¥½è¦è¦è¦è¦è¦è¦è¦è¦è¦ è¦¥è¦°è¦´è¦µè¦¶è¦·è¦¼è§",4,"è§¥è§©è§«è§­è§±è§³è§¶è§¹è§½è§¿è¨è¨è¨è¨è¨è¨è¨è¨è¨è¨ è¨¢è¨¤è¨¦è¨«è¨¬è¨¯è¨µè¨·è¨½è¨¾è©è©è©è©è©è©è©è©è©è©è©è©è©è©¡è©¥è©§è©µè©¶è©·è©¹è©ºè©»è©¾è©¿èªèªèªèªèªèªèªèªèªèªèªèª§èª©èª®èª¯èª³"],
["8fdea1","èª¶èª·èª»èª¾è«è«è«è«è«è«è«è«è«è«è«è«è«¬è«°è«´è«µè«¶è«¼è«¿è¬è¬è¬è¬è¬è¬è¬è¬è¬­è¬°è¬·è¬¼è­",4,"è­è­è­è­è­è­è­è­£è­­è­¶è­¸è­¹è­¼è­¾è®è®è®è®è®è®è®è®è®è®è®è°¸è°¹è°½è°¾è±è±è±è±è±è±è±è±è±è±è±è±è±è±£è±¤è±¦è±¨è±©è±­è±³è±µè±¶è±»è±¾è²"],
["8fdfa1","è²è²è²è²è²è²è²è²è²¤è²¹è²ºè³è³è³è³è³è³è³è³è³è³¡è³¨è³¬è³¯è³°è³²è³µè³·è³¸è³¾è³¿è´è´è´è´è´è´èµ¥èµ©èµ¬èµ®èµ¿è¶è¶è¶è¶è¶è¶è¶è¶è¶è¶ è¶¦è¶«è¶¬è¶¯è¶²è¶µè¶·è¶¹è¶»è·è·è·è·è·è·è·è·è·è·è·è·è·¤è·¥è·§è·¬è·°è¶¼è·±è·²è·´è·½è¸è¸è¸è¸è¸è¸è¸è¸è¸ è¸¡è¸¢"],
["8fe0a1","è¸£è¸¦è¸§è¸±è¸³è¸¶è¸·è¸¸è¸¹è¸½è¹è¹è¹è¹è¹è¹è¹è¹è¹è¹è¹è¹¡è¹¢è¹©è¹¬è¹­è¹¯è¹°è¹±è¹¹è¹ºè¹»èºèºèºèºèºèºèºèºèºèºèº¢èº§èº©èº­èº®èº³èºµèººèº»è»è»è»è»è»è»è»è»è»è»¨è»®è»°è»±è»·è»¹è»ºè»­è¼è¼è¼è¼è¼è¼è¼è¼è¼è¼è¼ è¼¡è¼£è¼¥è¼§è¼¨è¼¬è¼­è¼®è¼´è¼µè¼¶è¼·è¼ºè½è½"],
["8fe1a1","è½è½è½è½",4,"è½è½è½è½¥è¾è¾ è¾¡è¾¤è¾¥è¾¦è¾µè¾¶è¾¸è¾¾è¿è¿è¿è¿è¿è¿è¿è¿è¿è¿è¿ è¿£è¿¤è¿¨è¿®è¿±è¿µè¿¶è¿»è¿¾ééééééé¨é©é¯éªé¬é­é³é´é·é¿éééééé¢é¦é§é¬é°é´é¹éééééééééééé é¡é¢é¥é°é²é³é´é¶é½éé¾é"],
["8fe2a1","éééééééééééé¥éé¶é«é¯é°é´é¾é¿ééééééééééééééé é¥é¢é£é§é©é®é¯é±é´é¶é·é¹éºé¼é½éééééééééé¡é¤é§é­é´é¹éºé»ééééééééééééé¡é¦é¨é¬é­é®é°é±é²é³é¶é»é¼é½é¿"],
["8fe3a1","éééééééééé¤é¥é©éªé¬",5,"é·é¹é»é½ééééééééééééééééé£é¤é¥é¦é¨é®é¯é°é³éµé¶é¸é¹éºé¼é¾ééééééééééééééé é¡é¥é§é¨é©é®é¯é°éµ",4,"é»é¼é½é¿ééééééé"],
["8fe4a1","ééé é¤é¥é§é¨é«é¯é²é¶é¸éºé»é¼é½é¿",4,"éééééééééééééééééé é¡é£é¥é§é¨é¬é®é°é¹é»é¿éééééééééééé¡é¤é¥é§é©éªé³é´é¶é·éééééééééééé¤é¥é§é©éªé­é¯é°é±é³é´é¶"],
["8fe5a1","éºé½é¿éééééééééééééé¡é£é¤é¦é¨é«é´éµé¶éºé©éééééé",4,"éééééé¢é¦é§é¹é·é¸éºé»é½ééééééééééééé®é¯é±é²é³é´é»é¿é½éééééééééé¡é£é¨é«é­é®é¯é±é²ééé¸é¹"],
["8fe6a1","é¾ééééééééé¡é¦é©é«é¬é´é¶éºé½é¿éééééééééééééé é¤é¦ééé¢é¤é¥é¦é¬é±é³é·é¸é¹éºé¼é½ééééééé¡é®é´é»é¼é¾é¿ééééééééééé¤é¥é¦é©é®é¯é³éºééå¶²éééééé©é¯é±éºé"],
["8fe7a1","ééééééé¡é¢é£é¨é±é³ééééééééééé£é§éªé®é³é¶é·é¸é»é½é¿éééééééééé¢é¬é®é±é²éµé¶é¸é¹éºé¼é¾é¿éééééééééééééééééé éé¡é¤é¯é±é´é·é¸éºé é é é é é é é é é  é £é ¦"],
["8fe8a1","é «é ®é ¯é °é ²é ³é µé ¥é ¾é¡é¡é¡é¡é¡é¡é¡é¡é¡é¡é¡¢é¡£é¡¥é¡¦é¡ªé¡¬é¢«é¢­é¢®é¢°é¢´é¢·é¢¸é¢ºé¢»é¢¿é£é£é£é£é£¡é££é£¥é£¦é£§é£ªé£³é£¶é¤é¤é¤é¤é¤é¤é¤é¤é¤é¤é¤é¤¢é¤¦é¤§é¤«é¤±",4,"é¤¹é¤ºé¤»é¤¼é¥é¥é¥é¥é¥é¥é¥é¥é¥é¥é¥é¥é¥é¥é¥ é¦é¦é¦é¦¦é¦°é¦±é¦²é¦µ"],
["8fe9a1","é¦¹é¦ºé¦½é¦¿é§é§é§é§é§é§é§é§é§§é§ªé§«é§¬é§°é§´é§µé§¹é§½é§¾é¨é¨é¨é¨é¨é¨é¨é¨é¨é¨ é¨¢é¨£é¨¤é¨§é¨­é¨®é¨³é¨µé¨¶é¨¸é©é©é©é©é©é©é©é©é©é©é©éªªéª¬éª®éª¯éª²éª´éªµéª¶éª¹éª»éª¾éª¿é«é«é«é«é«é«é«é«é«é«é«é«é« é«¤é«¥é«§é«©é«¬é«²é«³é«µé«¹é«ºé«½é«¿",4],
["8feaa1","é¬é¬é¬é¬é¬é¬é¬é¬é¬é¬é¬é¬é¬é¬é¬ é¬¦é¬«é¬­é¬³é¬´é¬µé¬·é¬¹é¬ºé¬½é­é­é­é­é­é­é­é­é­¡é­£é­¥é­¦é­¨é­ª",4,"é­³é­µé­·é­¸é­¹é­¿é®é®é®é®é®é®é®é®é®é®é®é®é®é®é®é®¦é®§é®©é®¬é®°é®±é®²é®·é®¸é®»é®¼é®¾é®¿é¯é¯é¯é¯é¯é¯é¯é¯é¯é¯¥é¯§é¯ªé¯«é¯¯é¯³é¯·é¯¸"],
["8feba1","é¯¹é¯ºé¯½é¯¿é°é°é°é°é°é°é°é°é°é°é°é°¢é°£é°¦",4,"é°±é°µé°¶é°·é°½é±é±é±é±é±é±é±é±é±é±é±é±é±é±é±é±é±é±£é±©é±ªé±é±«é±¨é±®é±°é±²é±µé±·é±»é³¦é³²é³·é³¹é´é´é´é´é´é´é´é´é´¯é´°é´²é´³é´´é´ºé´¼éµé´½éµéµéµéµéµéµéµéµ£éµ¢éµ¥éµ©éµªéµ«éµ°éµ¶éµ·éµ»"],
["8feca1","éµ¼éµ¾é¶é¶é¶é¶é¶é¶é¶é¶é¶é¶é¶é¶é¶¡é¶ªé¶¬é¶®é¶±é¶µé¶¹é¶¼é¶¿é·é·é·é·é·é·é·é·é·é·é·é· é·¥é·§é·©é·«é·®é·°é·³é·´é·¾é¸é¸é¸é¸é¸é¸é¸é¸é¸é¸é¸é¸é¹ºé¹»é¹¼éºéºéºéºéºéºéºéºéºéºéºéºéº¤éº¨éº¬éº®éº¯éº°éº³éº´éºµé»é»é»é»é»é»¤é»§é»¬é»­é»®é»°é»±é»²é»µ"],
["8feda1","é»¸é»¿é¼é¼é¼é¼é¼é¼é¼é¼é¼é¼é¼é¼é¼é¼é¼¢é¼¦é¼ªé¼«é¼¯é¼±é¼²é¼´é¼·é¼¹é¼ºé¼¼é¼½é¼¿é½é½",4,"é½é½é½é½é½é½é½é½é½¨é½©é½­",4,"é½³é½µé½ºé½½é¾é¾é¾é¾é¾é¾é¾é¾é¾¡é¾¢é¾£é¾¥"]
]
