module.exports=[
["0","\u0000",127],
["a140","ãï¼ããï¼â§ï¼ï¼ï¼ï¼ï¸°â¦â¥ï¹ï¹ï¹Â·ï¹ï¹ï¹ï¹ï½âï¸±âï¸³â´ï¸´ï¹ï¼ï¼ï¸µï¸¶ï½ï½ï¸·ï¸¸ããï¸¹ï¸ºããï¸»ï¸¼ããï¸½ï¸¾ããï¸¿ï¹ããï¹ï¹ããï¹ï¹ï¹ï¹"],
["a1a1","ï¹ï¹ï¹ï¹ââââããâµâ²ï¼ï¼ï¼â»Â§ãâââ³â²ââââââ¡â â½â¼ã£âÂ¯ï¿£ï¼¿Ëï¹ï¹ï¹ï¹ï¹ï¹ï¹ï¹ ï¹¡ï¼ï¼ÃÃ·Â±âï¼ï¼ï¼â¦â§â âââ¡ï¹¢",4,"ï½â©âªâ¥â ââ¿ããâ«â®âµâ´âââââââââââââ¥â£ï¼"],
["a240","ï¼¼âï¹¨ï¼ï¿¥ãï¿ ï¿¡ï¼ï¼ ââï¹©ï¹ªï¹«ãããããã¡ãããÂ°ååååå¡å£å§ç©ç³â",7,"ââââââââ¼â´â¬â¤ââââââââââ­"],
["a2a1","â®â°â¯âââªâ¡â¢â£â¥â¤â±â²â³ï¼",9,"â ",9,"ã¡",8,"åååï¼¡",25,"ï½",21],
["a340","ï½ï½ï½ï½Î",16,"Î£",6,"Î±",16,"Ï",6,"ã",10],
["a3a1","ã",25,"ËËËËË"],
["a3e1","â¬"],
["a440","ä¸ä¹ä¸ä¸ä¹ä¹äºäºäººå¿å¥å«å åååååååä¸ä¸ä¸ä¸ä¸«ä¸¸å¡ä¹ä¹ä¹ä¹äºäº¡åååºååå£åå£«å¤å¤§å¥³å­å­å­å¯¸å°å°¢å°¸å±±å·å·¥å·±å·²å·³å·¾å¹²å»¾å¼å¼æ"],
["a4a1","ä¸ä¸ä¸ä¸­ä¸°ä¸¹ä¹å°¹äºäºäºäºäºäº¢ä»ä»ä»ä»ä»ä»ä»ä»ä»ååå§å­å®å¬åå¶åååå»å¾å¿åå¹ååååååååå£¬å¤©å¤«å¤ªå¤­å­å°å°¤å°ºå±¯å·´å¹»å»¿å¼å¼å¿ææ¶æææ¯æææ¤æ¹æ¥æ°ææ¨æ¬ æ­¢æ­¹æ¯æ¯æ¯æ°æ°´ç«çªç¶ç»çççç¬çä¸"],
["a540","ä¸ä¸ä¸ä¸ä¸»ä¹ä¹ä¹ä»¥ä»ä»ä»ä»ä»ä»£ä»¤ä»ä»ååååå¬å¹åºå¸åå åååååä»ååå¡å å¯å®å»å¯å¤å³å¬å®å©å¨å¼å¸åµå«å¦åªå²å±å°å¥å­å»ååå¤"],
["a5a1","å¤®å¤±å¥´å¥¶å­å®å°¼å·¨å·§å·¦å¸å¸å¹³å¹¼å¼å¼å¼å¿ææææææ¥æ¦æ®æ¬æªæ«æ­æ­£æ¯æ°æ°æ°¸æ±æ±æ°¾ç¯çççç¦ççç¨ç©ç°ç±ç²ç³çç½ç®ç¿ç®çç¢ç³ç¤ºç¦¾ç©´ç«ä¸ä¸ä¹ä¹ä¹©äºäº¤äº¦äº¥ä»¿ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä¼ä»²ä»¶ä»»ä»°ä»³ä»½ä¼ä¼ååååå¨"],
["a640","å±åå°åååååå£åå¡å å°å±åååååååååååååååå ååå³å°å¨å­å¬å¯å©å¤å¤å¤·å¤¸å¦å¥¸å¦å¥½å¥¹å¦å¦å­å­å®å®å®å®å¯ºå°å±¹å·å¸å¹¶å¹´"],
["a6a1","å¼å¼å¿å¿æææææ£æææ¶æ©æ¨æ¬æ­æ²æ³ææ½æ´æ±æµæ¬¡æ­¤æ­»æ°æ±æ±æ±æ±æ± æ±æ±æ±¡æ±æ±æ±ç°ççç¾ç«¹ç±³ç³¸ç¼¶ç¾ç¾½èèèèè³è¿èèèè£èªè³è¼èèèè®è²è¾è«è¡è¡è¡£è¥¿é¡ä¸²äº¨ä½ä½ä½ä½ä½ä¼´ä½ä½ä¼°ä½ä½ä¼½ä¼ºä¼¸ä½ä½ä¼¼ä½ä½£"],
["a740","ä½ä½ ä¼¯ä½ä¼¶ä½ä½ä½ä½ååååµå¶å·å¥å¤å©åªå¨å«å©åªå¬å£å³åµåå­åå¾å¦åå§ååå³åååå©åå¹å»å¸å®åµå¶å å¼åå±å«åå¬åªå°å¤å«åååå"],
["a7a1","ååå¾ååå»å£¯å¤¾å¦å¦å¦¨å¦å¦£å¦å¦å¦å¦¤å¦å¦å¦¥å­å­å­å­å®å®å®å°¬å±å±å°¿å°¾å²å²å²å²å·«å¸åºåºåºå»·å¼å¼å½¤å½¢å½·å½¹å¿å¿å¿å¿å¿±å¿«å¿¸å¿ªæææææææ¶ææ­ææ¼æ¾æ¹æ³ææ¯ææ®æææææ¹æ»æ¸æ±æ´æææææææææææ "],
["a840","æææ­¥æ¯æ±æ±æ²æ²æ²æ²æ²æ²æ±ªæ±ºæ²æ±°æ²æ±¨æ²æ²æ±½æ²æ±²æ±¾æ±´æ²æ±¶æ²æ²æ²æ²ç¶ç¼ç½ç¸ç¢ç¡ç çççç¬ç«ç·ç¸çç¯ç£ç§ç§ç¦¿ç©¶ç³»ç½èèèèèèè²è¯è"],
["a8a1","èèè¦è§è¨è°·è±è±è²èµ¤èµ°è¶³èº«è»è¾è¾°è¿è¿è¿è¿å·¡éé¢éªé¦é£éééé²é®é±éªé¬ä¸¦ä¹ä¹³äºäºäºäº«äº¬ä½¯ä¾ä¾ä½³ä½¿ä½¬ä¾ä¾ä¾ä¾ä½°ä½µä¾ä½©ä½»ä¾ä½¾ä¾ä¾ä½ºåååå©å·å¶å¸å½å½å»å¸å·åºå°å®å¶åå¾å»ååååå¦å·å¸å¹åååå³åµ"],
["a940","åå¸ååå»å·åååå¼åå±å¶ååå¢å¨åå½ååºåå·åªå©å¡å¦å¤å¼å¤å¥å¥å¥å¥å¥å¦¾å¦»å§å¦¹å¦®å§å§å§å§å§å§å§å¦¯å¦³å§å§å­å­¤å­£å®å®å®å®å®å®å°å±å±"],
["a9a1","å±å²·å²¡å²¸å²©å²«å²±å²³å¸å¸å¸å¸å¸å¸å¹¸åºåºåºåºåºå»¶å¼¦å¼§å¼©å¾å¾å½¿å½¼å¿å¿ å¿½å¿µå¿¿æææ¯æµææªææ¡æ§æ©æ«ææææ¿æ¾ææ¿ææææ¿ææ¹æææ«æææææ¨æ½æ¼æææææµææ±æææææ¬ææ¾æ§æ¼æºææææææææææ"],
["aa40","ææææ­æææ±ææ³æ·ææææ¯æ°æ¿ææ¾ææµæææ¼æªæ²æ¬£æ­¦æ­§æ­¿æ°æ°æ³£æ³¨æ³³æ²±æ³æ³¥æ²³æ²½æ²¾æ²¼æ³¢æ²«æ³æ³æ²¸æ³æ²¹æ³æ²®æ³æ³æ³±æ²¿æ²»æ³¡æ³æ³æ²¬æ³¯æ³æ³æ³ "],
["aaa1","çççççç¬ç­ç¸çç§ç©çççççç©ç¨çç«ç¥ç½çççççç²ç´ç¥ç½ç¤¾ç¥ç¥ç§ç§ç©ºç©¹ç«ºç³¾ç½ç¾ç¾èèºè¥è¢è±è¡è«è©è´èªè¯è¥è¾èè³èèè­è½èè¹è±è¬è¥è¯è¸è£è°è¾è·èè±åè¡¨è»è¿è¿è¿éµé¸é±é¶ééé·éééé¿é»é"],
["ab40","éé¹é¨ééäºäº­äº®ä¿¡ä¾µä¾¯ä¾¿ä¿ ä¿ä¿ä¿ä¿ä¾¶ä¿ä¿ä¿ä¿ä¾®ä¿ä¿ä¿ä¿ä¿ä¿ä¾·åååå åååååååååååååå»ååå¬åå¨ååå¸å¦å³ååå½åªå"],
["aba1","ååå¯å«å±å»å©å§å¿å¿ååå å£å¢åå®åå¥å¥å¥å¥å¥å§å§å§¿å§£å§¨å¨å§¥å§ªå§å§¦å¨å§»å­©å®£å®¦å®¤å®¢å®¥å°å±å±å±å±å³å³å··å¸å¸¥å¸å¹½åº åº¦å»ºå¼å¼­å½¥å¾å¾å¾å¾å¾å¾å¾æææ æ¥ææ¨ææ°æ¨æ¢æææ¬æ«æªæ¤æææææ¼æ­ææ®æ½ææ±æ·"],
["ac40","æ¯æ¬æ¾æ´æææ¿ææ«æ½æ¢æ¥æ­æ æ§æ¯ææ¨æ±æ¤æ·æ¿ææ±æææ¬æ¶æ¯æµæ©æ¯æææ´ææ¥æ¸æææ³æ°ææ¢æææ­ªæ®æ®æ®µæ¯æ¯æ°æ³æ´æ´²æ´ªæµæ´¥æ´æ´±æ´æ´"],
["aca1","æ´»æ´½æ´¾æ´¶æ´æ³µæ´¹æ´§æ´¸æ´©æ´®æ´µæ´æ´«ç«çºç³ç¬ç¯ç­ç¸ç®ç¤ç°ç²ç¯ç´ç©ç ç¡ç·çç»ç²ççç³çç­ççççç«ç¤ç¥ç¢ç£ç¸ççççççççç¹ç¸ççç¾ç¼ççç ç ç ç ç¥ç¥ç¥ç¥ç¦¹ç¦ºç§ç§ç§ç©¿çªç«¿ç«½ç±½ç´ç´ç´ç´ç´ç´ç´ç¼¸ç¾ç¾¿è"],
["ad40","èèèè¶èè¥èèèèè¡èèèè¤èè´è¢è§èèè£èè¦èè¥èèèèè±èèèèèèèè¯èèè¹è»èºè¡è¡«è¦è§è¨è¨è¨è²è² èµ´èµ³è¶´è»è»è¿°è¿¦è¿¢è¿ªè¿¥"],
["ada1","è¿­è¿«è¿¤è¿¨ééééééééééééé¢é©éé­é³é é¢¨é£é£é¦é¦ä¹äº³ååå£ä¿¯å¦å¥ä¿¸å©ååå¼ååååä¿ºååå¨ä¿±å¡åååä¿³ä¿®å­åªä¿¾å«åå¼å¤å¥å¢ååååååååååªå¿åååå¨ååå·å¼å¥å²ååºåå©å­å¡åå®åª"],
["ae40","å¦å§åå½åååååååå å¤å¥å¥å¥å¨å¨å¨å¨å¨å¨å§¬å¨ å¨£å¨©å¨¥å¨å¨å­«å±å®°å®³å®¶å®´å®®å®µå®¹å®¸å°å±å±å±å³­å³½å³»å³ªå³¨å³°å³¶å´å³´å·®å¸­å¸«åº«åº­åº§å¼±å¾å¾å¾æ"],
["aea1","æ£æ¥æææ­æ©æ¯ææææææææææ³ææ¿ææ¾æ¯ææææææºææ½æªæ«æ¨ææææææææææææææææ¸ææææ ¡æ ¸æ¡æ¡æ¡æ ¹æ¡æ¡æ ©æ¢³æ æ¡æ¡æ ½æ´æ¡æ¡æ ¼æ¡æ ªæ¡æ æ æ¡æ®æ®æ®·æ°£æ°§æ°¨æ°¦æ°¤æ³°æµªæ¶æ¶æ¶æµ¦æµ¸æµ·æµæ¶"],
["af40","æµ¬æ¶æµ®æµæµ´æµ©æ¶æ¶æµ¹æ¶æµ¥æ¶ççç¤çççç¹ç¹ç¼ç¹ç½ç¸ç·çç­çç®ç çªççççççç¾ççç²ç³ç½ç¼ç¹çç¸çç°çççç©çç ç¨ç©ç °ç §ç ¸ç ç ´ç ·"],
["afa1","ç ¥ç ­ç  ç ç ²ç¥ç¥ç¥ ç¥ç¥ç¥ç¥ç¥ç¥ç§¤ç§£ç§§ç§ç§¦ç§©ç§çªçªç«ç¬ç¬ç²ç´¡ç´ç´ç´ç´ ç´¢ç´ç´ç´ç´ç´ç´ç´ç´ç¼ºç½ç¾ç¿ç¿èèèèèè½è¿è±èè°èè­è´èè¸è³èè½èè¼è¯è­è¬èèèªè«è¨è¬è»è«èèèè¸èèèµè´èè²è¹è¶èèè±è¨è"],
["b040","èèèªèè¤è©èè£èè¡°è¡·è¢è¢è¡½è¡¹è¨è¨è¨è¨è¨è¨è¨è¨è¨è¨è¨è±è±ºè±¹è²¡è²¢èµ·èº¬è»è»è»è¾±ééè¿·éè¿ºè¿´éè¿½éè¿¸éé¡éé¢éééééééééé¢é£é¡"],
["b0a1","ééé¤ééé»é£¢é¦¬éª¨é«é¬¥é¬²é¬¼ä¹¾åºå½ååååååå¥å¶åååµå´å·ååå¯å­ååå°åªå¯åååååååå¿åå¾åæ¼ååªå¦ååå¡ååå±åååå¯å¤å¸å®åå¬å£å³ååååååå å å å å¤åºå å µå·å¹å¤ å¥¢å¨¶å©å©å©¦å©ªå©"],
["b140","å¨¼å©¢å©å©å©å­°å¯å¯å¯å¯å®¿å¯å°å°å°å± å±å±å´å´å´å´å´å´¢å´å´©å´å´å´¤å´§å´å·¢å¸¸å¸¶å¸³å¸·åº·åº¸åº¶åºµåº¾å¼µå¼·å½å½¬å½©å½«å¾å¾å¾å¾å¾¡å¾ å¾æ¿æ£ææ æ¨ææ´æ¦æ½"],
["b1a1","ææ»æµææ¼æææææ¸ææææææ æ§æ²ææ¢æ¥æ·æ§ææªæ±æ©ææææ«æ¨ææææ¡æ¬ææææ»æ©æ¨æºæææææåæææææææ¬æææææææ¤æ¨æ¦ææ¹åææ¢æ¢¯æ¢¢æ¢æ¢µæ¡¿æ¡¶æ¢±æ¢§æ¢æ¢°æ¢æ£æ¢­æ¢æ¢æ¢æ¢æ¢¨æ¢æ¢¡æ¢æ¬²æ®º"],
["b240","æ¯«æ¯¬æ°«æ¶æ¶¼æ·³æ·æ¶²æ·¡æ·æ·¤æ·»æ·ºæ¸æ·æ·æ¶¯æ·æ¶®æ·æ·¹æ¶¸æ··æ·µæ·æ·æ¸æ¶µæ·æ·«æ·æ·ªæ·±æ·®æ·¨æ·æ·æ¶ªæ·¬æ¶¿æ·¦ç¹ççç½ç¯ç½ç½çççççççççççç¾çç ç¶"],
["b2a1","ç·çç¢ç¥ç¦ç¢ç°ççççµççççççç·ç¾ç¼ç¶ç¸çºç¡«ç¡ç¡ç¥¥ç¥¨ç¥­ç§»çªçªç¬ ç¬¨ç¬ç¬¬ç¬¦ç¬ç¬ç¬®ç²ç²ç²çµçµçµ±ç´®ç´¹ç´¼çµç´°ç´³çµç´¯çµç´²ç´±ç¼½ç¾ç¾ç¿ç¿ç¿èèèè¯èè£è«è©è°è¤èèµè·è¶è¹èèèè¸è¢èè½è«èèèèè è·è»è¼"],
["b340","èè§èå½ªèèè¶èèµèèè±è¯èè¡è¢è¢è¢«è¢è¢è¢è¢è¦è¦è¨ªè¨è¨£è¨¥è¨±è¨­è¨è¨è¨¢è±è±è²©è²¬è²«è²¨è²ªè²§èµ§èµ¦è¶¾è¶ºè»è»ééééé£éééééé éé¢ééé"],
["b3a1","é¨é­é½éééµé¦é£é§é­é©ééªéµé³é¸é°é´é¶é·é¬ééªé©ç« ç«é é é­é³¥é¹µé¹¿éº¥éº»å¢ååååååååæå±å²å´åµå©ååååå¥å»åå§å¼ååååååªååååå³å®åå¾å²åå»å¬å±å¾åå«ååå ¯å ªå ´å ¤å °å ±å ¡å å  å£¹å£ºå¥ "],
["b440","å©·åªå©¿åªåªåª§å­³å­±å¯å¯å¯å¯å°å°å°±åµåµå´´åµå·½å¹å¸½å¹å¹å¹¾å»å»å»å»å¼¼å½­å¾©å¾ªå¾¨ææ¡æ²æ¶æ ææ£æºææ°æ»æ´æ¨æ±ææ¶ææææææ£ææææ©æææ"],
["b4a1","ææ£ææ¡ææ­æ®æ¶æ´æªææææ¹ææ¦æ¢æ£æææ¯æ®æ°æ´æ¶æ¯ææºæ¾æ·æ¾æ¿æææ£ºæ£æ£ æ£æ£æ¤æ£æ£µæ£®æ£§æ£¹æ£æ£²æ££æ£æ£æ¤æ¤æ¤æ£æ£æ¥®æ£»æ¬¾æ¬ºæ¬½æ®æ®æ®¼æ¯¯æ°®æ°¯æ°¬æ¸¯æ¸¸æ¹æ¸¡æ¸²æ¹§æ¹æ¸ æ¸¥æ¸£æ¸æ¹æ¹æ¸¤æ¹æ¹®æ¸­æ¸¦æ¹¯æ¸´æ¹æ¸ºæ¸¬æ¹æ¸æ¸¾æ»"],
["b540","æºæ¸æ¹æ¹£æ¹æ¹²æ¹©æ¹ççç¦ç°ç¡ç¶ç®ççççç¶ç¥ç´ç©çºçªç³ç¢ç¥çµç¶ç´ç¯çç¦ç¨ç¥ç¦ç«çªç¢çç£çççç ç»ç¼ççç´ççç­ç¡ç¡¬ç¡¯ç¨ç¨ç¨ç¨ç¨çª"],
["b5a1","çªçªç«¥ç«£ç­ç­ç­ç­ç­ç­ç­ç­ç­ç­ç²ç²¥çµçµçµ¨çµç´«çµ®çµ²çµ¡çµ¦çµ¢çµ°çµ³åç¿ç¿èèèèèèèèè¹èè¾èèè´èèè©èè¸èè èèèè¯è±è´èèè°èèè½è²èè¸èèèèèèèèèè­èèè¤èèè¡è£è£è¢±è¦è¦è¨»è© è©è©è¨¼è©"],
["b640","è©è©è©è©è¨´è¨ºè¨¶è©è±¡è²è²¯è²¼è²³è²½è³è²»è³è²´è²·è²¶è²¿è²¸è¶è¶è¶è·è·è·è·è·è·è·è·è»»è»¸è»¼è¾é®éµé±é¸é²é¶ééµéé¾é£é¥éééé£éééééééééé"],
["b6a1","ééééééé½éééé²ééééééé¯é²éé é é é£§é£ªé£¯é£©é£²é£­é¦®é¦­é»é»é»äºå­åµå²å³åå¾å¬å·å»å¯åå¿å·å½åå¦å¤å¢å£å¯åå¨åå¦ååååå£å¤å¯åå¡ååå¥åååå¡å¡å¡å¡å¡å¡å¡«å¡å¡­å¡å¡¢å¡å¡å¥§å«å«å«åª¾åª½åª¼"],
["b740","åª³å«åª²åµ©åµ¯å¹å¹¹å»å»å¼å½å¾¬å¾®æææææ³ææ¹æææææææ¾æ´æ§æææ·æ¡æ¢ææ¾ææªæ­æ½æ¬æææææ¶ææææ¬ææ°ææææææææææ¦æ¥­"],
["b7a1","æ¥æ¥·æ¥ æ¥æ¥µæ¤°æ¦æ¥æ¥¨æ¥«æ¥æ¥æ¥¹æ¦æ¥æ¥£æ¥æ­æ­²æ¯æ®¿æ¯æ¯½æº¢æº¯æ»æº¶æ»æºæºæ»æ»æº¥æºæº¼æººæº«æ»æºæºæ»æ»æºªæº§æº´ççç©ç¤çç§çç¬ç¦çç¥ççç¨ççºçç·çç¿ç¾ç¯çççççç¿çççç¶ç¸çç°çç²ç±çºç¿ç´ç³çççç«ç¦çç£"],
["b840","ç¹çªç¬çç¥ç¨ç¢ç®ç¢ç¢°ç¢ç¢ç¢ç¢ç¡¼ç¢ç¢ç¡¿ç¥ºç¥¿ç¦è¬ç¦½ç¨ç¨ç¨ ç¨ç¨ç¨çªçª ç­·ç¯ç­ ç­®ç­§ç²±ç²³ç²µç¶çµ¹ç¶ç¶ç¶çµç½®ç½©ç½ªç½²ç¾©ç¾¨ç¾¤èèèèè±è°è¸è¥è®è³è«"],
["b8a1","è¹èºè¦èèèè·è½è±èµè¦è«èè¬èè¼èµè¡è£è©è­èèèèè¹èèèèè¾è»èèèèè¡è£è£è£è£è£è£è£¡è£è£è£è¦è§£è©«è©²è©³è©¦è©©è©°èªè©¼è©£èª è©±èªè©­è©¢è©®è©¬è©¹è©»è¨¾è©¨è±¢è²è²è³è³è³è³è²²è³è³è³è·¡è·è·¨è·¯è·³è·ºè·ªè·¤è·¦èº²è¼è¼è»¾è¼"],
["b940","è¾è¾²éééééé¼éééééééé¾éééé¬éªé©éé·éé¸é½éé¾ééé¤éé´éééé¹é¿éééééééééé·é»é¹é¶éé´é¶é é é é é é é£¼é£´"],
["b9a1","é£½é£¾é¦³é¦±é¦´é«¡é³©éºé¼é¼é¼ å§å®å¥åå­ååååå±åå©å¢å³ååå±å­å¾åååå½åååååå·ååååå¶ååå¡µå¡¾å¢å¢å¢å¡¹å¢å¡½å£½å¤¥å¤¢å¤¤å¥ªå¥©å«¡å«¦å«©å«å«å«å«£å­µå¯å¯§å¯¡å¯¥å¯¦å¯¨å¯¢å¯¤å¯å°å±¢å¶å¶å¹å¹£å¹å¹å¹å»å»å¼å½å½°å¾¹æ"],
["ba40","æ¿ææ·æ¢æ£ææææµæªææææ¤æ¸ææºææ§æ´æ­æ»æ²æ¡æææ¢æ¨ææ¦æ¦¨æ¦æ§æ¦®æ§æ§æ¦æ¦·æ¦»æ¦«æ¦´æ§æ§æ¦­æ§æ¦¦æ§æ¦£æ­æ­æ°³æ¼³æ¼æ»¾æ¼æ»´æ¼©æ¼¾æ¼ æ¼¬æ¼æ¼æ¼¢"],
["baa1","æ»¿æ»¯æ¼æ¼±æ¼¸æ¼²æ¼£æ¼æ¼«æ¼¯æ¾æ¼ªæ»¬æ¼æ»²æ»æ»·ççç½çççç¾ççççç¤ç£çªç°ç­ççç§ççççç¡ç£çç½ç¿ç¡ç£ç¢ç¢§ç¢³ç¢©ç¢£ç¦ç¦ç¦ç¨®ç¨±çªªçª©ç«­ç«¯ç®¡ç®ç®ç­µç®ç®ç®ç®ç®¸ç®ç®ç²¹ç²½ç²¾ç¶»ç¶°ç¶ç¶½ç¶¾ç¶ ç·ç¶´ç¶²ç¶±ç¶ºç¶¢ç¶¿ç¶µç¶¸ç¶­ç·ç·ç¶¬"],
["bb40","ç½°ç¿ ç¿¡ç¿èèèèèèèèè¿èè§èºèèèèèè¿èèèèè²èèè¸èèèè¼èèè¿èè»è¢è¥è´èèè·è©è£³è¤è£´è£¹è£¸è£½è£¨è¤è£¯èª¦èªèªèª£èªèª¡èªèª¤"],
["bba1","èªªèª¥èª¨èªèªèªèª§è±ªè²è²è³è³è³èµ«è¶è¶è·¼è¼è¼è¼è¼è¾£é ééé£ééé¢ééééééµé¸é·é´é¸ééééé»ééé¨é¼éé¡é¨é©é£é¥é¤ééééééé¼éé¶é é é¢¯é¢±é¤é¤é¤é¤é§éª¯éª°é«¦é­é­é³´é³¶é³³éº¼é¼»é½ååå»åµå¹ååååå"],
["bc40","åååååå°å²å®å»å¹å²å¿å´å©åååå´å¶å¯å°å¢å¢å¢å¢³å¢å¢®å¢©å¢¦å¥­å¬å«»å¬å«µå¬å¬å¯®å¯¬å¯©å¯«å±¤å±¥å¶å¶å¹¢å¹å¹¡å»¢å»å»å»å»£å» å½å½±å¾·å¾µæ¶æ§æ®æææ"],
["bca1","æ¼æ°æ«æ¾æ§ææ«ææ¬ææ¤ææ®æ®æ©æ¯æ¹ææ²æææ°æ¥æææ©ææ®æ­æ«ææ¬ææ¢æ³æµæ·æ¸æ®æ«æ´æ±æ¨£æ¨æ§¨æ¨æ¨æ¨æ§½æ¨¡æ¨æ¨æ§³æ¨æ¨æ§­æ¨æ­æ­æ®¤æ¯æ¯æ¼¿æ½¼æ¾æ½æ½¦æ½æ¾æ½­æ½æ½¸æ½®æ¾æ½ºæ½°æ½¤æ¾æ½æ»æ½¯æ½ æ½çç¬ç±ç¨ççççç©çç"],
["bd40","ç¾çç¿ç ç©çç¤ç¦ç¡ç¢ççºç¤çççççç£ç£ç¢ºç£ç¢¾ç£ç¢¼ç£ç¨¿ç¨¼ç©ç¨½ç¨·ç¨»çª¯çª®ç®­ç®±ç¯ç®´ç¯ç¯ç¯ç® ç¯ç³ç· ç·´ç·¯ç·»ç·ç·¬ç·ç·¨ç·£ç·ç·ç·©ç¶ç·ç·²ç·¹ç½µç½·ç¾¯"],
["bda1","ç¿©è¦èèèè èèèè½èè®è¬è­èèè£è¡èè¬è¥è¿èèè´è¶è è¦è¸è¨èèèèè¡è¡è¤è¤è¤è¤è¤è¤èª¼è«è«è«èªè«è«¸èª²è«è«èª¿èª°è«è«èª¶èª¹è«è±è±è±¬è³ è³è³¦è³¤è³¬è³­è³¢è³£è³è³ªè³¡èµ­è¶è¶£è¸«è¸è¸è¸¢è¸è¸©è¸è¸¡è¸èººè¼è¼è¼è¼©è¼¦è¼ªè¼è¼"],
["be40","è¼¥é©é®é¨é­é·é°é­é§é±éééééé»é·éªé¬é¤éé³é¼ééé°é²é­é±ééééé éééé ¡é «é é¢³é¤é¤é¤é¤é§é§é§é§é§é§é§é§éª·é«®é«¯é¬§é­é­é­·é­¯é´é´"],
["bea1","é´éº©éº¾é»å¢¨é½åååååååªåååå³åå«å¹å©å¤å¸åªå¨å¥å±å¯å¬å¢å¶å£å¢¾å£å£å¥®å¬å¬´å­¸å¯°å°å½æ²ææ©æææ¶æ¾æææ°ææææ»æ¼ææææææ¿æææ¾æ´æææ¹æææ¸æ¨½æ¨¸æ¨ºæ©æ©«æ©æ¨¹æ©æ©¢æ©¡æ©æ©æ¨µæ©æ©æ­æ­·æ°æ¿æ¾±æ¾¡"],
["bf40","æ¿æ¾¤æ¿æ¾§æ¾³æ¿æ¾¹æ¾¶æ¾¦æ¾ æ¾´ç¾çççççç¹çççççç¨çç£çççç¢ççç´ç¸çºç§ç¥ç ççç¥ç£¨ç£ç£¬ç£§ç¦¦ç©ç©ç©ç©ç©çªºç¯ç°ç¯ç¯¤ç¯ç¯¡ç¯©ç¯¦ç³ç³ç¸"],
["bfa1","ç¸ç¸ç¸ç¸£ç¸ç¸ç¸ç¸ç½¹ç¾²ç¿°ç¿±ç¿®è¨è³è©è¨è»èèèèèèè¨è©èèè­èªèèèèè¢èè¡¡è¤ªè¤²è¤¥è¤«è¤¡è¦ªè¦¦è«¦è«ºè««è«±è¬è«è«§è«®è«¾è¬è¬è«·è«­è«³è«¶è«¼è±«è±­è²è³´è¹è¸±è¸´è¹è¸¹è¸µè¼»è¼¯è¼¸è¼³è¾¨è¾¦éµé´é¸é²é¼éºé´éé é¶é¸é³é¯é¢é¼é«éé"],
["c040","éé¦é¡éé®éé»é§é¨éªéééééééééé¦éé °é ¸é »é ·é ­é ¹é ¤é¤é¤¨é¤é¤é¤¡é¤é§­é§¢é§±éª¸éª¼é«»é«­é¬¨é®é´é´£é´¦é´¨é´é´é»é»é¾é¾åªåå¡å²åµååååå"],
["c0a1","åå£å£å£å£å¬°å¬ªå¬¤å­ºå°·å±¨å¶¼å¶ºå¶½å¶¸å¹«å½å¾½ææææ¦ææ²æ´ææææ æ°æ¦æ¬æ±æ¢æ­æææææªæªæªæª¢æªæ«æª£æ©¾æªæªæª æ­æ®®æ¯æ°æ¿æ¿±æ¿æ¿ æ¿æ¿¤æ¿«æ¿¯æ¾æ¿¬æ¿¡æ¿©æ¿æ¿®æ¿°ç§çç®ç¦ç¥ç­ç¬ç´ç çµçç°ç²ç©ç°ç¦ç¨ççççªç³çªç°ç¬"],
["c140","ç§ç­ç¯ç£·ç£ºç£´ç£¯ç¤ç¦§ç¦ªç©çª¿ç°ç°ç¯¾ç¯·ç°ç¯ ç³ ç³ç³ç³¢ç³ç³ç³ç¸®ç¸¾ç¹ç¸·ç¸²ç¹ç¸«ç¸½ç¸±ç¹ç¹ç¸´ç¸¹ç¹ç¸µç¸¿ç¸¯ç½ç¿³ç¿¼è±è²è°è¯è³èèèºèèè¿è½èè¾è¨èè±èª"],
["c1a1","èè¾èèèè¯èèè¨èè§èèè³èèè«è»èºèèè¤»è¤¶è¥è¤¸è¤½è¦¬è¬è¬è¬è¬è¬è¬ è¬è¬è¬è±è°¿è±³è³ºè³½è³¼è³¸è³»è¶¨è¹è¹è¹è¹è½è¼¾è½è½è¼¿é¿é½ééééé¹é£ééééé¨éµéé¥ééé¾é¬éé°éééééééé±é¸éééé éé¡é¢¶é¤µé¨"],
["c240","é§¿é®®é®«é®ªé®­é´»é´¿éºé»é»é»é»é»é¼¾é½å¢åå®å£å£å¬¸å½æ£æ³æ´æ²æ¾ææºæ»æ·æ·ææ¦æª³æª¬æ«æª»æª¸æ«æª®æª¯æ­æ­¸æ®¯ççæ¿¾çæ¿ºççç»ç¼ç¾ç¸ç·çµç§ç¿ççç"],
["c2a1","çç½ç¿ç»ç¼ç¤ç¦®ç©¡ç©¢ç© ç«ç«ç°«ç°§ç°ªç°ç°£ç°¡ç³§ç¹ç¹ç¹ç¹ç¹¡ç¹ç¹ç½ç¿¹ç¿»è·è¶èèèèè©èèèè°èºè¹è¦è¯è¬è²è è¦è¦²è§´è¬¨è¬¹è¬¬è¬«è±è´è¹è¹£è¹¦è¹¤è¹è¹è»è½è½éééé«é¬ééééé¢é³é®é¬é°éééééééé¢ééééé¤é£é¦"],
["c340","é­é¹é¡é¡é¡é¡é¡é¢ºé¤¾é¤¿é¤½é¤®é¦¥é¨é«é¬é¬é­é­é­é¯é¯é¯½é¯é¯éµéµéµ é» é¼é¼¬å³å¥å£å£å£¢å¯µé¾å»¬æ²æ·æ¶æµæææ ææ«¥æ«æ«æ«ççç¨çççççççç¢ç¸"],
["c3a1","çºç½çç£çççç¡çç¤ç¦±ç©«ç©©ç°¾ç°¿ç°¸ç°½ç°·ç±ç¹«ç¹­ç¹¹ç¹©ç¹ªç¾ç¹³ç¾¶ç¾¹ç¾¸èè©èèªèè¤è¥è·è»è è è¹è¾è¥ è¥è¥è¥è­è­è­è­è­è­è­è­è­è´è´è¹¼è¹²èºè¹¶è¹¬è¹ºè¹´è½è½è¾­ééé±é®é¡éééééééé¢ééé¤éé¨éé´é£éªé§é¡éé»é¡"],
["c440","é¡é¡é¢¼é¥é¥é¨é¨é¬é¯¨é¯§é¯é¯é¶éµ¡éµ²éµªéµ¬éºéºéºéº´å¸å¨å·å¶å´å¼å£¤å­å­å­½å¯¶å·æ¸æºææææ¦æ§æ«¬ç¾ç°ç²çç»çç¢ç¥ç¤¦ç¤ªç¤¬ç¤«ç«ç«¶ç±ç±ç±ç³¯ç³°è¾®ç¹½ç¹¼"],
["c4a1","çºç½èèè¦è»è¹èèºèèèèè è è¥¤è¦ºè§¸è­°è­¬è­¦è­¯è­è­«è´è´èºèºèºèºé´éééé½é¡é°é£é¥é¥é¦¨é¨«é¨°é¨·é¨µé°é°é¹¹éºµé»¨é¼¯é½é½£é½¡å·å¸åååå¤å±¬å·æ¼æ¾ææææ©æ«»æ¬æ«ºæ®²ççç§ççç©çç±çºçºç¾¼èè­èè £è ¢è ¡è è¥ªè¥¬è¦½è­´"],
["c540","è­·è­½è´èºèºèºè½è¾¯éºé®é³éµéºé¸é²é«é¢é¸é¹é²é¿é¡§é¡¥é¥é©é©é©é¨¾é«é­é­é°­é°¥é¶¯é¶´é·é¶¸éºé»¯é¼é½é½¦é½§å¼å»åååå­¿å·å·å½æ¿æ¤æ¬æ­¡çççç¤çç®ç¬"],
["c5a1","ç¦³ç± ç±è¾è½èè¥²è¥¯è§¼è®è´è´èºèºè½¡ééééé½é¾ééé¡«é¥é©é©é«é¬é±é°±é°¾é°»é·é·é¼´é½¬é½ªé¾åå·ææ£æ«æªæ¬æ¬çç«ç±¤ç±£ç±¥çºçºçºè¢è¸è¿è ±è®ééé£é é¤é¨é¡¯é¥é©é©é©é«é«é«é±é±é±é·¥éºé»´åå£©æ¬çç±ç²çç½ç¾è ¶è ¹è¡¢è®è®"],
["c640","è®è·è´ééªééééé¡°é©é¬¢é­é±é·¹é·ºé¹¼é¹½é¼é½·é½²å»³æ¬ç£ç±¬ç±®è »è§èº¡éé²é°é¡±é¥é«é¬£é»ç¤çè®é·éé©¢é©¥çºè®èºªéé½é¾é¼é±·é±¸é»·è±é¿é¸ç¨é©ªé¬±é¸é¸ç±²"],
["c940","ä¹ä¹åµååä¸ä¸ä¹äºåï¨å±®å½³ä¸åä¸ä¸®äºä»ä»ä»åå¼å¬å¹å å¤å¤¬å°å·¿æ¡æ®³æ¯æ°ç¿ä¸±ä¸¼ä»¨ä»ä»©ä»¡ä»ä»åååå¢å£å¤å¤¯å®å®å°å°»å±´å±³å¸åºåºå¿æææ°"],
["c9a1","æ°¶æ±æ°¿æ°»ç®ç°çç¦¸èéä¼ä¼ä¼¬ä»µä¼ä»±ä¼ä»·ä¼ä¼ä¼ä¼ä¼¢ä¼ä¼ä»´ä¼å±åååå¦å¢ååååå¡åå®åªå´å¤¼å¦å¥¼å¦å¥»å¥¾å¥·å¥¿å­å°å°¥å±¼å±ºå±»å±¾å·å¹µåºå¼å¼å½´å¿å¿å¿æææ¤æ¡æ¦æ¢ææ ææ¥æ¯æ®æ¾æ¹æ¸æ»æºæ¿æ¼æ³æ°æ±æ±æ±æ±æ±æ±æ±"],
["ca40","æ±ç±çç´çµççªç¿ç©µç½è¸è¼èè½è¿èè¥¾éééééé¢é¤é é£ä½ä¼»ä½¢ä½ä½ä½¤ä¼¾ä½§ä½ä½ä½ä½ä¼­ä¼³ä¼¿ä½¡åå¹ååå¡å­å®åå£å²ååå°å·åªååååå¥å"],
["caa1","å½ååå¨å¤åå®å§å¥ååååååå¤å¥å¦¦å¦å¦ å¦å¦å¦¢å¦å¦å¦§å¦¡å®å®å°¨å°ªå²å²å²å²å²å²å²å²å²å²å· å¸å¸åºåºåºåºåºå¼å¼å½¸å½¶å¿å¿å¿å¿­å¿¨å¿®å¿³å¿¡å¿¤å¿£å¿ºå¿¯å¿·å¿»æå¿´æºæææææææ±æ»æºæ°æææ·æ½æ²æ´æ·æ°æ´æ³æ²æµææ"],
["cb40","æææææææææ¯æ°æ°æ±¸æ±§æ±«æ²æ²æ²æ±±æ±¯æ±©æ²æ±­æ²æ²æ²æ±¦æ±³æ±¥æ±»æ²ç´çºç£ç¿ç½ççççºçççççççºç¹çççç¤½è´èèèèèèèèèèè"],
["cba1","èèèè±¸è¿è¾¿éé¡é¥éé§é é°é¨é¯é­ä¸³ä¾ä½¼ä¾ä½½ä¾ä¾ä½¶ä½´ä¾ä¾ä½·ä½ä¾ä½ªä¾ä½¹ä¾ä½¸ä¾ä¾ä¾ä¾ä¾ä¾ä¾ä½«ä½®åå¼å¾åµå²å³åå±å¼ååå¼åååå¿ååååå«åºå¾å¥å¬å´å¦åå¯å¡å åå£å§å¤å·å¹å¯å²å­å«å±å°å¶ååµå»å³å´å¢"],
["cc40","å¨å½å¤å¥å¦µå¦ºå§å§å¦²å§å§å¦¶å¦¼å§å§å¦±å¦½å§å§å¦´å§å­¢å­¥å®å®å±å±å²®å²¤å² å²µå²¯å²¨å²¬å²å²£å²­å²¢å²ªå²§å²å²¥å²¶å²°å²¦å¸å¸å¸å¼¨å¼¢å¼£å¼¤å½å¾å½¾å½½å¿å¿¥æ­æ¦ææ²æ"],
["cca1","æ´æææ³æææ¬æ¢æææ®æææææææ½æ­æ´ææ¾æªæ¶ææ®æ³æ¯æ»æ©æ°æ¸æ½æ¨æ»ææ¼ææææ»æææææ½æææ¶æææ¬æææ¶æ»ææææ´æææºæææææ½ææ¸æ¹ææ¬¥æ®æ­¾æ¯æ°æ²æ³¬æ³«æ³®æ³æ²¶æ³æ²­æ³§æ²·æ³æ³æ²ºæ³æ³æ³­æ³²"],
["cd40","æ³æ³æ²´æ²æ²æ²æ³æ³æ´°æ³æ³æ²°æ³¹æ³æ³©æ³ççççççççççççªççççççççççç¤ç¡ç­ç¦ç¢ç ç¬ççç¨ç¿çç¾ççç¯ç³ç±ç°çµç¸ç¼ç¹ç»çº"],
["cda1","ç·ç¥ç¤¿ç§ç©¸ç©»ç«»ç±µç³½èµèè®è£è¸èµè­è è èè«èèèèµè§è®è¼èèºè´è¨è¡è©èè¤èè¶è¢è°è¯è­è®è±è¿è¿è¿è¿è¿è¿è¿é²é´é¯é³é°é¹é½é¼éºéä¿ä¿ä¿ä¾²ä¿ä¿ä¿ä¿ä¿ä¿ä¾»ä¾³ä¿ä¿ä¿ä¾ºä¿ä¾¹ä¿¬ååååå½å¼åååååºå¡å­å¥å"],
["ce40","åèå·å®åå¶ååå å°å¼å¢å¾å²åå°åµååå¤ååååååååå¥ååå£´å¤å¥å§¡å§å§®å¨å§±å§å§ºå§½å§¼å§¶å§¤å§²å§·å§å§©å§³å§µå§ å§¾å§´å§­å®¨å±å³å³å³å³å³å³"],
["cea1","å³å³å³å³å³å³å³å³å³å³å³å³å³å³¸å·¹å¸¡å¸¢å¸£å¸ å¸¤åº°åº¤åº¢åºåº£åº¥å¼å¼®å½å¾æ·æ¹ææ²æææææææææææ¤æææ¦æ®ææææææµæææ«æ¹æææ¸æ¶ææææºææ»æ°æææªæ¿æ¶æ¡æ²æµææ¦æ¢æ³æ«æºææ´æ¹æ®ææææ²ææº"],
["cf40","ææ»æ¸æææ·ææ«æ¤ææµææ³æ·æ¶æ®æ£ææ¹ææ§æ°æ²æ¼ææ­ææ®æ¦ææºææææªææ¬¨æ®æ®æ®¶æ¯æ¯æ¯ æ° æ°¡æ´¨æ´´æ´­æ´æ´¼æ´¿æ´æ´æ³æ´³æ´æ´æ´ºæ´æ´æ´æ´æµ"],
["cfa1","æ´æ´æ´·æ´æ´æµæ´æ´ æ´¬æ´æ´¢æ´æ´ç·çç¾ç±ç°ç¡ç´çµç©çççç¬ç°ç³ç®çç¤ç¨ç«ççªç¦ç£çççççç¹ç¶çµç´ç«ç¿çç¾ççç¸çç¬ç®ç®ççç§çªç¹ççççççç·ç»çºç§ç¨ç ç ç ç ç ç ç ç ç ç ç¥ç¥ç¥ç¥ç¥ç§ç§ç§ç§ç§çª"],
["d040","ç©¾ç«ç¬ç¬ç±ºç±¸ç±¹ç±¿ç²ç²ç´ç´ç´ç½ç¾ç¾ç¾¾èèèèè·èèè èèèèèè£èèèèèèèè¦èè¿è¡èèè¾è¹èè¨èèèºè«èè´è¬è¡è²èµèè»è¶è°èª"],
["d0a1","è¤è èºè³è­è·è´è¼è³è¡è¡è¡§è¡ªè¡©è§è¨è¨èµ²è¿£è¿¡è¿®è¿ é±é½é¿ééé¾éééééééééééååååå¢å°åä¿µä¿´å³å·å¬ä¿¶ä¿·ååå å§åµå¯å±åååååååååå¡åååååå¢åååå¦å¢ååå§å³å¤åå¿ååå«ååå±"],
["d140","åå»å·å¸å ååååååå ²åååºåå½å¼å¸å¶å¿ååå¹åå¤å¥å¨å¨å¨­å¨®å¨å¨å¨å¨å¨å¨³å­¬å®§å®­å®¬å°å±å±å³¬å³¿å³®å³±å³·å´å³¹å¸©å¸¨åº¨åº®åºªåº¬å¼³å¼°å½§æææ§"],
["d1a1","ææ¢æææææææææææææææ²æææ¬æææ¶ææ¤æ¹æææ¼æ©ææ´ææææ­ææ³æææ¸ææææææææææææææææ æ æ¡æ ²æ ³æ »æ¡æ¡æ æ ±æ æ µæ «æ ­æ ¯æ¡æ¡æ ´æ æ æ æ ¦æ ¨æ ®æ¡æ ºæ ¥æ  æ¬¬æ¬¯æ¬­æ¬±æ¬´æ­­èæ®æ¯¦æ¯¤"],
["d240","æ¯¨æ¯£æ¯¢æ¯§æ°¥æµºæµ£æµ¤æµ¶æ´æµ¡æ¶æµæµ¢æµ­æµ¯æ¶æ¶æ·¯æµ¿æ¶æµæµ§æµ æ¶æµ°æµ¼æµæ¶æ¶æ´¯æµ¨æ¶æµ¾æ¶æ¶æ´æ¶æµ»æµ½æµµæ¶çççççç¼¹ç¢çççç çççççççç¡çç¸"],
["d2a1","ç·ç¶ççºç´ç¾ç¶ç³ç»çççç¥çç¼ç§ç£ç©ççççççççç¨ççç´çµç¡ççç°çç»ççç¿ç¶çºçççççççç£ççççç¢ç§ç £ç ¬ç ¢ç µç ¯ç ¨ç ®ç «ç ¡ç ©ç ³ç ªç ±ç¥ç¥ç¥ç¥ç¥ç¥ç¥ç§«ç§¬ç§ ç§®ç§­ç§ªç§ç§ç§çªçªçªçªçªçªçªç«ç¬"],
["d340","ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç¬ç²ç²ç²ç²ç²ç²ç²ç´ç´ç´ç´ç´ç´ç´ç´ç´ç´ç´ç½ç½¡ç½ç½ ç½ç½ç¾ç¾ç¿ç¿ç¿èè¾è¹èºè²è¹èµèè»èèè¯è¥è³è­èèèè¥èè¿èè¦èè¢"],
["d3a1","èèèèªèè¼èèè¤è è·è¯è©èèèèèè¬èè§èèèè¢è¨èèèèèèèèèèè¥èè¡è§èèèèèèè¡è¡è¡­è¡µè¡¶è¡²è¢è¡±è¡¿è¡¯è¢è¡¾è¡´è¡¼è¨è±è±è±»è²¤è²£èµ¶èµ¸è¶µè¶·è¶¶è»è»è¿¾è¿µéè¿¿è¿»éè¿¼è¿¶éé ééé£éé¥ééééé¤é"],
["d440","éééé¢éééé¼é££é«é¬¯ä¹¿å°åªå¡åå åååå²åååååå¢åååå©å«å£å¤ååå®å³åååå«å­å¬å®ååå­ååµå¶å¼ååå´åªåå¢å¶åµå°åå"],
["d4a1","åå²å¥åå¹åå­å»ååååå»å å¢å¶åå´å å­å½å å¸å å³åå å®å£å²å¥å¬å¡å å¼å å§å å å±å©å°å å å¥å© å©å©å©§å©å¨¸å¨µå©­å©å©å©¥å©¬å©å©¤å©å©å©å©å©å©å©åªå¨¾å©å¨¹å©å©°å©©å©å©å©å©å©å­²å­®å¯å¯å±å´å´å´å´å´ å´å´¨å´å´¦å´¥å´"],
["d540","å´°å´å´£å´å´®å¸¾å¸´åº±åº´åº¹åº²åº³å¼¶å¼¸å¾å¾å¾ææææ¾æ°æºææææ¤ææææ±ææ·ææ¿ææææ²æ¥æææ½æ½ææ­æææ«ææ¯æææ®æ¯æµææ­æ®æ¼æ¤æ»æ"],
["d5a1","æ¸æææææ°æææ¥æ¡ææææ¢ææ¡¹æ¢æ¢æ¢æ¡­æ¡®æ¢®æ¢«æ¥æ¡¯æ¢£æ¢¬æ¢©æ¡µæ¡´æ¢²æ¢æ¡·æ¢æ¡¼æ¡«æ¡²æ¢ªæ¢æ¡±æ¡¾æ¢æ¢æ¢æ¢ æ¢æ¢¤æ¡¸æ¡»æ¢æ¢æ¢æ¡½æ¬¶æ¬³æ¬·æ¬¸æ®æ®æ®æ®æ®æ°ªæ·æ¶«æ¶´æ¶³æ¹´æ¶¬æ·©æ·¢æ¶·æ·¶æ·æ¸æ·æ· æ·æ·æ¶¾æ·¥æ·æ·æ·æ·´æ·æ¶½æ·­æ·°æ¶ºæ·æ·æ·æ·"],
["d640","æ·æ·²æ·æ·½æ·æ·æ·£æ¶»çºçç·çç´çç°çç³çç¼ç¿çççç¸ç¶çççç¾ç»ç¼ç¿çççççççç¿çççç¶ç¸çµççç½çççºç¼ç¿ççç´çç¤ç£ççç"],
["d6a1","çççççççç¹ç¯ç­ç±ç²ç´ç³ç½ç¥ç»çµç¡ç¡ç¡ç¡ç¡ç¡ç ¦ç¡ç¡ç¥¤ç¥§ç¥©ç¥ªç¥£ç¥«ç¥¡ç¦»ç§ºç§¸ç§¶ç§·çªçªçªç¬µç­ç¬´ç¬¥ç¬°ç¬¢ç¬¤ç¬³ç¬ç¬ªç¬ç¬±ç¬«ç¬­ç¬¯ç¬²ç¬¸ç¬ç¬£ç²ç²ç²ç²£ç´µç´½ç´¸ç´¶ç´ºçµç´¬ç´©çµçµç´¾ç´¿çµç´»ç´¨ç½£ç¾ç¾ç¾ç¾ç¿ç¿ç¿ç¿ç¿ç¿ç¿ç¿è"],
["d740","èèèèèèè¥èèè­èè¬èè¡èè§èè¢èè¸è³èºè´è²è´èè£è¨èèºè³è¤è´èèèèèµèè©è½èèèèèªèè¾è¥è¯èèè°è¿è¦èè®è¶èèèè¿è·"],
["d7a1","èèèèºè°èè¹è³è¸èè´è»è¼èè½è¾è¡è¢è¢è¢¨è¢¢è¢ªè¢è¢è¢¡è¢è¢è¢§è¢è¢è¢è¢¤è¢¬è¢è¢è¢è¦è§è§è§è¨°è¨§è¨¬è¨è°¹è°»è±è±è±½è²¥èµ½èµ»èµ¹è¶¼è·è¶¹è¶¿è·è»è»è»è»è»è» è»¡é¤ééééé¡é¯éªé°é´é²é³éé«é¬é©éééééé¬é´é±é³é¸é¤é¹éª"],
["d840","é«é·é¨é®éºééé¼é­é«é±é¯é¿éªé é£¥é¦åååååå£ååååå¨ååååååååå¤å§åå¨å¥å­å·åå¢ååååµåå£åå¤å½åå¦å¿åå¡ååå ©å ·"],
["d8a1","å å å §å £å ¨åµå¡å ¥å å å ³å ¿å ¶å ®å ¹å ¸å ­å ¬å »å¥¡åª¯åªåªå©ºåª¢åªå©¸åª¦å©¼åª¥åª¬åªåª®å¨·åªåªåªåªåªåª©å©»å©½åªåªåªåªåªå¯ªå¯å¯å¯å¯å¯å¯å°å°°å´·åµåµ«åµåµå´¿å´µåµåµåµå´³å´ºåµå´½å´±åµåµå´¹åµå´¸å´¼å´²å´¶åµåµå¹å¹å½å¾¦å¾¥å¾«ææ¹ææ¢æææ"],
["d940","æ²ææææµææ¸æ¼æ¾ææææææ¿æææææ±æ°ææ¥æ¨æ¯æææ³ææ æ¶ææ²æµæ¡ææ¾æææææææææææ°æææ²æ§æªæ¤ææ¨æ¥ææææ®ææ"],
["d9a1","æ¼æ¬æ»ææ±æ¹æªæ²ææ¤æ£æ¤æ£æ¤ªæ£¬æ£ªæ£±æ¤æ£æ£·æ£«æ£¤æ£¶æ¤æ¤æ£³æ£¡æ¤æ£æ¤æ¥°æ¢´æ¤æ£¯æ£æ¤æ£¸æ£æ£½æ£¼æ£¨æ¤æ¤æ¤æ£æ£æ£æ£æ£¦æ£´æ£æ¤æ£æ£©æ¤æ¤¥æ£æ¬¹æ¬»æ¬¿æ¬¼æ®æ®æ®æ®æ®½æ¯°æ¯²æ¯³æ°°æ·¼æ¹æ¹æ¸æ¹æºæ¸¼æ¸½æ¹æ¹¢æ¸«æ¸¿æ¹æ¹æ¹³æ¸æ¸³æ¹æ¹æ¹æ¸»æ¸æ¸®æ¹"],
["da40","æ¹¨æ¹æ¹¡æ¸±æ¸¨æ¹ æ¹±æ¹«æ¸¹æ¸¢æ¸°æ¹æ¹¥æ¸§æ¹¸æ¹¤æ¹·æ¹æ¹¹æ¹æ¹¦æ¸µæ¸¶æ¹ç çç¯ç»ç®ç±ç£ç¥ç¢ç²çç¨çºççççççççççç°ç¢ç±ç³ç§ç²ç­ç¦ç£çµçç®ç¬ç°ç«ç"],
["daa1","çç¡ç­ç±ç¤ç£çç©ç ç²ç»ç¯ç¯ç¬ç§çç¡ç¦ççç¤ççççççççççççççç¬ç¡ ç¡¤ç¡¥ç¡ç¡­ç¡±ç¡ªç¡®ç¡°ç¡©ç¡¨ç¡ç¡¢ç¥´ç¥³ç¥²ç¥°ç¨ç¨ç¨ç¨ç¨çªç«¦ç«¤ç­ç¬»ç­ç­ç­ç­ç­ç­ç­ç²¢ç²ç²¨ç²¡çµçµ¯çµ£çµçµçµ§çµªçµçµ­çµçµ«çµçµçµ©çµçµçµç¼¾ç¼¿ç½¥"],
["db40","ç½¦ç¾¢ç¾ ç¾¡ç¿èèèè¾èèèèèèè½èèºè¦è®è·è¸è¹èè¼è½è¿èµè»èè¹è£èè¨èè§è¤è¼è¶èèèè«è£è¿èèè¥èè¿è¡èèèèµèèèèèèèè³"],
["dba1","èèºèèèªèèè¬è®èè»èè¢èèè¾èè¢è¦èè£èèªèè«èè¬è©èè¨èè¡è¡è¡è¢ºè£è¢¹è¢¸è£è¢¾è¢¶è¢¼è¢·è¢½è¢²è¤è£è¦è¦è¦è§è§è§è©è©è¨¹è©è©è©è©è©è©è©è©è©è©è©è©è±è²è²è²ºè²¾è²°è²¹è²µè¶è¶è¶è·è·è·è·è·è·è·è·è·è·è·è·è»¯è»·è»º"],
["dc40","è»¹è»¦è»®è»¥è»µè»§è»¨è»¶è»«è»±è»¬è»´è»©é­é´é¯éé¬éé¿é¼éé¹é»éééééé¡é¤éé¢é ééé¥ééé¦ééééé¿é½ééé§ééé¤ééééé»ééééé¾é"],
["dca1","ééééééé±é°é¬é°é®é é¢©é£«é³¦é»¹äºäºäº¶å½å¿åå®ååå´ååå°ååºå±ååå¶å¸ååºå¸å»å¼ååååååååååå©å¿ååååå¢ååå²ååååå¡å¡¨å¡¤å¡å¡å¡å¡¯å¡å¡å¡å¡å¡¥å¡å ½å¡£å¡±å£¼å«å«å«åªºåª¸åª±åªµåª°åª¿å«åª»å«"],
["dd40","åª·å«å«åª´åª¶å«åª¹åªå¯å¯å¯å°å°³åµ±åµ£åµåµ¥åµ²åµ¬åµåµ¨åµ§åµ¢å·°å¹å¹å¹å¹å¹å»å»å»å»å»å½å¾¯å¾­æ·æææ«ææ¶æ²æ®ææ¯ææ©ææ é¨æ£æ¥æ¤ææ±æ«ææææ æ¤"],
["dda1","æ³æææææ¹æ·æ¢æ£ææ¦æ°æ¨ææµæ¯ææææ¥æ§ææ§ææ®æ¡ææ¯æææææææææææ¸æ æ¥¦æ¥æ¤¸æ¥æ¥¢æ¥±æ¤¿æ¥æ¥ªæ¤¹æ¥æ¥æ¥æ¥ºæ¥æ¥æ¤µæ¥¬æ¤³æ¤½æ¥¥æ£°æ¥¸æ¤´æ¥©æ¥æ¥¯æ¥æ¥¶æ¥æ¥æ¥´æ¥æ¤»æ¥æ¤·æ¥æ¥æ¥æ¤²æ¥æ¤¯æ¥»æ¤¼æ­æ­æ­æ­æ­æ­æ®ï¨æ¯»æ¯¼"],
["de40","æ¯¹æ¯·æ¯¸æºæ»æ»æºæ»æºæºæºæº æº±æº¹æ»æ»æº½æ»æºæ»æº·æº°æ»æº¦æ»æº²æº¾æ»æ»æ»æºæºæºæºæº¤æº¡æº¿æº³æ»æ»æºæº®æº£çççç£ç ççç¢ç²ç¸çªç¡ççççç°ççç"],
["dea1","çççççççççç¼çç»çºçççççççççççççççççç¡ç¿ç¾ç½çç¹ç·æ¦ç¯ççç·ç¾ç¼ç¹ç¸çç»ç¶ç­çµç½ççµçççç çççç©ç§ççç­ç ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢ç¢¡ç¢ç¡¹ç¢ç¢ç¢ç¡»ç¥¼ç¦ç¥½ç¥¹ç¨ç¨ç¨ç¨ç¨ç¨ç¨¢ç¨"],
["df40","ç¨ç¨çª£çª¢çªç««ç­¦ç­¤ç­­ç­´ç­©ç­²ç­¥ç­³ç­±ç­°ç­¡ç­¸ç­¶ç­£ç²²ç²´ç²¯ç¶ç¶ç¶ç¶çµ¿ç¶çµºç¶çµ»ç¶çµ¼ç¶ç¶ç¶çµ½ç¶ç½­ç½«ç½§ç½¨ç½¬ç¾¦ç¾¥ç¾§ç¿ç¿è¡è¤è è·èè©èè¢è²æ¡èè¶è§è¯"],
["dfa1","èè¡èèèèèèè±è¿èè¶è¹èèè¥èèèè§è°èè½èèè´è³èèèè·èºè´èºèè¸è²èè©èèè¯èè­èè°è¹èèèè¯èèè»èè¶è³è¨è¾èè«è èè®èèèè·èèºèèµèè¸èèèè¶èèè£è£è£è£è£è£è£è£è£è¦è¦è§è§¥è§¤"],
["e040","è§¡è§ è§¢è§è§¦è©¶èªè©¿è©¡è¨¿è©·èªèªè©µèªèªè©´è©ºè°¼è±è±è±¥è±¤è±¦è²è²è²è³èµ¨èµ©è¶è¶è¶è¶è¶è¶è¶è¶è¶è·°è· è·¬è·±è·®è·è·©è·£è·¢è·§è·²è·«è·´è¼è»¿è¼è¼è¼è¼è¼è¼è¼éé¿"],
["e0a1","ééé½ééééééééé®é¯ééé°éºé¦é³é¥ééé®ééé­é¬éé é§é¯é¶é¡é°é±éé£éé²ééééé²ééééééééééºé½é¸éµé³é·é¸é²é é é é¢¬é£¶é£¹é¦¯é¦²é¦°é¦µéª­éª«é­é³ªé³­é³§éºé»½å¦ååå¨å³ååªåå¤åå¬å°å¯å£å "],
["e140","åååå©å«å°å¬å§åååå¼ååååååºååå¿å¹å¢å¡¼å¢å¢å¢å¢å¡¿å¡´å¢å¡ºå¢å¢å¢å¡¶å¢å¢å¡»å¢å¢å£¾å¥«å«å«®å«¥å«å«ªå«å«­å««å«³å«¢å« å«å«¬å«å«å«å«¨å«å­·å¯ "],
["e1a1","å¯£å±£å¶å¶åµ½å¶åµºå¶åµ·å¶å¶å¶åµ¾åµ¼å¶åµ¹åµ¿å¹å¹å¹å»å»å»å»å»å»å»å»å»å½å½å½¯å¾¶æ¬æ¨æææ±æ³æææ²æ¬ææ´ææºææ¥æ»æªæ¡ææ©æ§æ«æ«ææææ´æ¶æ²æ³æ½æµæ¦æ¦æææææææ ææ¿æ¿æ¬æ«ææ¥æ·æ³æ æ¡æ ææææ¢æ¦±æ¦¶æ§"],
["e240","æ¦ æ§æ¦æ¦°æ¦¬æ¦¼æ¦æ¦æ¦æ¦§æ¦æ¦©æ¦¾æ¦¯æ¦¿æ§æ¦½æ¦¤æ§æ¦¹æ§æ¦æ§æ¦³æ¦æ¦ªæ¦¡æ¦æ§æ¦æ¦æ§æ¦µæ¦¥æ§æ­æ­æ­æ®æ®æ® æ¯æ¯æ¯¾æ»æ»µæ»±æ¼æ¼¥æ»¸æ¼·æ»»æ¼®æ¼æ½æ¼æ¼æ¼§æ¼æ¼»æ¼æ»­æ¼"],
["e2a1","æ¼¶æ½³æ»¹æ»®æ¼­æ½æ¼°æ¼¼æ¼µæ»«æ¼æ¼æ½æ¼æ»½æ»¶æ¼¹æ¼æ»¼æ¼ºæ¼æ¼æ¼æ¼æ¼¡çççççççç»ççççççççççççç¢ç³ç±çµç²ç§ç®çççç½ççççççççç¸çç¼ççç®çç¯ç¾çç¢²ç¢ªç¢´ç¢­ç¢¨ç¡¾ç¢«ç¢ç¢¥ç¢ ç¢¬ç¢¢ç¢¤ç¦ç¦ç¦ç¦ç¦ç¦ç¦"],
["e340","ç¦ç¦ç¦ç¦ç¨«ç©ç¨°ç¨¯ç¨¨ç¨¦çª¨çª«çª¬ç«®ç®ç®ç®ç®ç®ç®ç®ç®ç®ç®ç®ç®åç®ç®¤ç®ç²»ç²¿ç²¼ç²ºç¶§ç¶·ç·ç¶£ç¶ªç·ç·ç·ç¶ç·ç·ç·ç·ç·ç¶¯ç¶¹ç¶ç¶¼ç¶ç¶¦ç¶®ç¶©ç¶¡ç·ç½³ç¿¢ç¿£ç¿¥ç¿"],
["e3a1","è¤èèèèèèèèèèèè¤è¡èèºèèè¬è®è«è¹è´èèèªèè±èèè§è»è¢èèèèè©è¯è¨èèè¶èè èèèèè°èè¡è³è£è¨è«èè®èè¡èèèè¬èè¾èè è²èªè­è¼èèºè±èµèè¦è§è¸è¤èè°èè£·è£§è£±è£²è£ºè£¾è£®è£¼è£¶è£»"],
["e440","è£°è£¬è£«è¦è¦¡è¦è¦è§©è§«è§¨èª«èªèªèªèªèªè°½è±¨è±©è³è³è³è¶è¸è¸è·¿è¸è·½è¸è¸è¸è¸è¸è·¾è¸è¸è¼è¼è¼è¼é£éé é¢éééé¤é¡ééºé²é¹é³é¥é¤é¶ééºé ééªé"],
["e4a1","é¦éé«é¹éé¿é£é®éééé¢é½éé¡ééééé§é¾éé©ééé­éé¡é¿éé½éºé¾éééé»ééé¿ééé é¢­é¢®é¤é¤é¤é¦é¦é§é¦¹é¦»é¦ºé§é¦½é§éª±é«£é«§é¬¾é¬¿é­ é­¡é­é³±é³²é³µéº§å¿åå°å¸ååå¶å¾ååå½åååå±å¯ååååµååååå"],
["e540","ååå³å½å¬å¾å¸åªåºåå¢«å¢å¢±å¢ å¢£å¢¯å¢¬å¢¥å¢¡å£¿å«¿å«´å«½å«·å«¶å¬å«¸å¬å«¹å¬å¬å¬å¬å±§å¶å¶å¶å¶å¶¢å¶å¶å¶ å¶å¶¡å¶å¶å¹©å¹å¹ å¹ç·³å»å»å»¡å½å¾²æææ¹æ±æ°æ¢æ"],
["e5a1","æææ¯æ­æææªæ¡ææ¦æ³æ­æ®æ°ææ ææææææææ£ææ¨æ±ææ¶æºæ¹æ»æ²æ³æµæ°æ©æ²æ·æªæ¯æ¨æ¨æ¨æ§¥æ§¸æ¨æ§±æ§¤æ¨ æ§¿æ§¬æ§¢æ¨æ¨æ§¾æ¨§æ§²æ§®æ¨æ§·æ§§æ©æ¨æ§¦æ§»æ¨æ§¼æ§«æ¨æ¨æ¨æ¨¥æ¨æ§¶æ¨¦æ¨æ§´æ¨æ­æ®¥æ®£æ®¢æ®¦æ°æ°æ¯¿æ°æ½æ¼¦æ½¾æ¾æ¿æ¾"],
["e640","æ¾æ¾æ¾æ½¢æ½æ¾æ½æ¾æ½¶æ½¬æ¾æ½æ½²æ½æ½æ½æ¾æ¾æ½æ¼æ½¡æ½«æ½½æ½§æ¾æ½æ¾æ½©æ½¿æ¾æ½£æ½·æ½ªæ½»ç²ç¯çç°ç çç©çµçç¥çç¤ç¡çªçç§ç³ççççççç ççç¡çç"],
["e6a1","ç¢çççççç½ççç¼ç¹ççç¾ç¥ççççç£çç¨çççççççççç£ç¢»ç£ç£ç£ç£ç£ç£ç£ç£ç£ç¦ç¦¡ç¦ ç¦ç¦¢ç¦æ­¶ç¨¹çª²çª´çª³ç®·ç¯ç®¾ç®¬ç¯ç®¯ç®¹ç¯ç®µç³ç³ç³ç³ç··ç·ç·ªç·§ç·ç·¡ç¸ç·ºç·¦ç·¶ç·±ç·°ç·®ç·ç½¶ç¾¬ç¾°ç¾­ç¿­ç¿«ç¿ªç¿¬ç¿¦ç¿¨è¤è§è£è"],
["e740","èèè¢èèèèèèèèèè¤è»èèè©èèèèèè§èè»è«èºèèè´èªè²èè·è«è³è¼èèªè©èè¾è¨èè®èè½èè¶è±è¦è§è¨è°è¯è¹èè è°èèè¯è¢"],
["e7a1","èè£è¤è·è¡è³èèèèè¡èèèè­èªèèèèè¯è¬èºè®èè¥èè»èµè¢è§è©è¡è¤è¤è¤è¤è¤è¤è¤è¤è¤è¤è¤è¤è¦¢è¦¤è¦£è§­è§°è§¬è«è«èª¸è«è«è«è«èª»è«èª¾è«è«è«è«èªºèª½è«è°¾è±è²è³¥è³è³è³¨è³è³è³§è¶ è¶è¶¡è¶è¸ è¸£è¸¥è¸¤è¸®è¸è¸è¸è¸è¸è¸¦è¸§"],
["e840","è¸è¸è¸è¸è¸è¸è¸è¼¬è¼¤è¼è¼è¼ è¼£è¼è¼é³é°é¯é§é«é¯é«é©éªé²é¦é®ééééééééééééé¶éé±ééé©éééé¯éé¨éééé¦éééé éé§éé"],
["e8a1","éµé¡éé´é¼é¬é«é®é°é¤é¢ééééééééééé é é ¦é ©é ¨é  é é §é¢²é¤é£ºé¤é¤é¤é¤é¤é§é§é§é§é§é§é§é§é§é§é§é§éª³é«¬é««é«³é«²é«±é­é­é­§é­´é­±é­¦é­¶é­µé­°é­¨é­¤é­¬é³¼é³ºé³½é³¿é³·é´é´é³¹é³»é´é´é´éºé»é¼é¼ååååååå´å¡å°å å®"],
["e940","å³å¦å£å­å²åå·ååå£å¢½å£å¢¿å¢ºå£å¢¼å£å¬å¬å¬å¬¡å¬å¬å¬å¬å¬¨å¬å¬ å¬å¯¯å¶¬å¶±å¶©å¶§å¶µå¶°å¶®å¶ªå¶¨å¶²å¶­å¶¯å¶´å¹§å¹¨å¹¦å¹¯å»©å»§å»¦å»¨å»¥å½å¾¼ææ¨æææ´ææææº"],
["e9a1","æ¿æ¸æææææææ½ææææ³ææ³æ¿æ¼æ¢ææ¾æææææ½æ»æºææ£æ¨´æ©¦æ©æ©§æ¨²æ©¨æ¨¾æ©æ©­æ©¶æ©æ©æ¨¨æ©æ¨»æ¨¿æ©æ©ªæ©¤æ©æ©æ©æ©¯æ©©æ© æ¨¼æ©æ©æ©æ©æ©æ©æ­æ­æ­æ®§æ®ªæ®«æ¯æ¯æ°æ°æ°æ¾­æ¿æ¾£æ¿æ¾¼æ¿æ¿æ½æ¿æ¾½æ¾æ¿æ¾¨çæ¾¥æ¾®æ¾ºæ¾¬æ¾ªæ¿æ¾¿æ¾¸"],
["ea40","æ¾¢æ¿æ¾«æ¿æ¾¯æ¾²æ¾°ççç¿ç¸ççççççççç½çç¼çççççç©ç¦ç§ç¬ç¥ç«çªç¿çç çççç¡ççç¯ç­ç±ç½ç³ç¼çµç²ç°ç»ç¦ççç¡ççç¢ç£çç"],
["eaa1","çç£ç£©ç£¥ç£ªç£ç££ç£ç£¡ç£¢ç£­ç£ç£ ç¦¤ç©ç©ç©çª¶çª¸çªµçª±çª·ç¯ç¯£ç¯§ç¯ç¯ç¯¥ç¯ç¯¨ç¯¹ç¯ç¯ªç¯¢ç¯ç¯«ç¯ç¯ç³ç³ç³ç³ç³ç¸ç¸¡ç¸ç¸ç¸ç¸ ç¸ç¸ç¸ç¸ç¸ç¸¢ç¸ç¸ç¸ç¸ç¸ç¸¥ç¸¤ç½ç½»ç½¼ç½ºç¾±ç¿¯èªè©è¬è±è¦è®è¹èµè«è°è¬è´è²è·è§è²èèèèèè«èèè¡è"],
["eb40","èèè¤èè¢èèèè£è¾èè±èè®èµèè§è èè¦èèè¥è¬è£è¥è¤èèèèèèèèèè¹èè£èèèèèèèèèè¤è¤¦è¤°è¤­è¤®è¤§è¤±è¤¢è¤©è¤£è¤¯è¤¬è¤è§±è« "],
["eba1","è«¢è«²è«´è«µè«è¬è«¤è«è«°è«è«è«¡è«¨è«¿è«¯è«»è²è²è²è³µè³®è³±è³°è³³èµ¬èµ®è¶¥è¶§è¸³è¸¾è¸¸è¹è¹è¸¶è¸¼è¸½è¹è¸°è¸¿èº½è¼¶è¼®è¼µè¼²è¼¹è¼·è¼´é¶é¹é»ééºé³éµé¶éééééé§ééééééºé¸é¼éé£éééé­éééééºé¥éé¹é·é´éé¤é¿é©é¹éµéªéé"],
["ec40","éé¾ééé»éé¼éé¾é¹éºé¶é¿éµé½é©éééééééé°é¸é µé ¯é ²é¤¤é¤é¤§é¤©é¦é§®é§¬é§¥é§¤é§°é§£é§ªé§©é§§éª¹éª¿éª´éª»é«¶é«ºé«¹é«·é¬³é®é®é®é­¼é­¾é­»é®é®é®é®é­ºé®"],
["eca1","é­½é®é´¥é´é´ é´é´é´©é´é´é´¢é´é´é´éºéºéºéº®éº­é»é»é»ºé¼é¼½å¦å¥å¢å¤å å©å´ååååååå¾åå¿åå£å£å£å£å¬­å¬¥å¬²å¬£å¬¬å¬§å¬¦å¬¯å¬®å­»å¯±å¯²å¶·å¹¬å¹ªå¾¾å¾»ææµæ¼æ§æ æ¥æ¤æ¨ææ¯æ©æ£æ«æ¤æ¨æææ¶æææªæªæªæª¥æªæªæªæª¡æªæªæªæª"],
["ed40","æªæªæª¨æª¤æªæ©¿æª¦æªæªæªæªæ­æ®­æ°æ¿æ¾©æ¿´æ¿æ¿£æ¿æ¿­æ¿§æ¿¦æ¿æ¿²æ¿æ¿¢æ¿¨ç¡ç±ç¨ç²ç¤ç°ç¢ç³ç®ç¯çç²ç«ççªç­ç±ç¥ç¯çççççççççç¤ç©çµç«ç²ç·ç¶"],
["eda1","ç´ç±ç¨ç°ç£³ç£½ç¤ç£»ç£¼ç£²ç¤ç£¹ç£¾ç¤ç¦«ç¦¨ç©ç©ç©ç©ç©ç©çª¾ç«ç«ç°ç°ç¯²ç°ç¯¿ç¯»ç°ç¯´ç°ç¯³ç°ç°ç°ç°ç¯¸ç¯½ç°ç¯°ç¯±ç°ç°ç³¨ç¸­ç¸¼ç¹ç¸³é¡ç¸¸ç¸ªç¹ç¹ç¹ç¸©ç¹ç¸°ç¸»ç¸¶ç¹ç¸ºç½ç½¿ç½¾ç½½ç¿´ç¿²è¬è»èèèèèè¼è©èèèèèèè§èè èè£è»è¤èè"],
["ee40","è·è¼èè¡èºè¸èèèèèèèèè¢èèèè¹è¶èèèè¨è¾èªè­èè°è¬è¹èµè¼è®èèèèè·è¯èèè´è¶è¿è¸è½èè²è¤µè¤³è¤¼è¤¾è¥è¥è¤·è¥è¦­è¦¯è¦®è§²è§³è¬"],
["eea1","è¬è¬è¬è¬è¬è¬¢è¬è¬è¬è¬è¬è¬è¬è¬è¬è¬è±è±°è±²è±±è±¯è²è²è³¹èµ¯è¹è¹è¹è¹è¹è¹è½è½éé¾é¸éé¢éééé¡éé é¡éé¯é¤ééé¼ééé¶éééé é­éééªé¹ééééé±é·é»é¡éé£é§ééééééééé·é®é°é¬é éééééé¡é"],
["ef40","ééééé±é¡é¡é¡é¡é¡é¡é¤¥é¤«é¤¬é¤ªé¤³é¤²é¤¯é¤­é¤±é¤°é¦é¦£é¦¡é¨é§ºé§´é§·é§¹é§¸é§¶é§»é§½é§¾é§¼é¨éª¾é«¾é«½é¬é«¼é­é®é®¨é®é®é®¦é®¡é®¥é®¤é®é®¢é® é®¯é´³éµéµ§é´¶é´®é´¯é´±é´¸é´°"],
["efa1","éµéµéµé´¾é´·éµé´½ç¿µé´­éºéºéºéº°é»é»é»»é»¿é¼¤é¼£é¼¢é½é¾ å±å­å®ååååååå¥°å¬¼å±©å±ªå·å¹­å¹®æææ­æ®æ±æªæ°æ«ææ©æ¿ææ½æ¸æææ¼ææææææ«æª¹æª½æ«¡æ«æªºæª¶æª·æ«æª´æª­æ­æ¯æ°çççççççæ¿¿çæ¿»ç¦æ¿¼æ¿·ççç¿ç¹çç½ç¶"],
["f040","ç¸ççµçç¾ç¶ç»ççççç¤çççççç¦ç½ç¬ççºç£¿ç¤ç¤ç¤ç¤ç¤ç¤ç¤ç¦­ç¦¬ç©ç°ç°©ç°ç° ç°ç°­ç°ç°¦ç°¨ç°¢ç°¥ç°°ç¹ç¹ç¹ç¹£ç¹ç¹¢ç¹ç¹ç¹ ç¹ç¹ç¾µç¾³ç¿·ç¿¸èµèè"],
["f0a1","èèèè´èèèèè³èµè½èèè¿èèèèè±è¶èè¤è¸è·è¾è©è§è¦è¢èè«èªè¥èè³è¤èèèè­èè£è¤èèè è´è¨èè¥è¥è¥è¥è¥è¥è¥è¥è¬ªè¬§è¬£è¬³è¬°è¬µè­è¬¯è¬¼è¬¾è¬±è¬¥è¬·è¬¦è¬¶è¬®è¬¤è¬»è¬½è¬ºè±è±µè²è²è²è³¾è´è´è´è¹è¹¢è¹ è¹è¹è¹è¹¥è¹§"],
["f140","è¹è¹è¹¡è¹è¹©è¹è½è½è½è½é¨éºé»é¾é¨é¥é§é¯éªéµééé·éééé§ééªéé¦éééééé±éé²é¤é¨é´é£é¥éééé³ééå·éééé£é¢é¥é¬é®é¨é«é¤éª"],
["f1a1","é¢é¥éééééºé¡é¡é¡é¢¸é¥é¤¼é¤ºé¨é¨é¨é¨é¨é¨é¨é¨é¨é¨é«é«é¬é¬é¬é¬©é¬µé­é­é­é¯é¯é¯é®¿é¯é®µé®¸é¯é®¶é¯é®¹é®½éµéµéµéµéµéµéµéµéµéµéµéµéµéµéµéºéºé»é¼é¼é¼é¼¥é¼«é¼ªé¼©é¼¨é½é½å´åµåå·å´å«å­å¦å§åªå¬å£å£å£å¤å¬½å¬¾å¬¿å·å¹°"],
["f240","å¾¿æ»æææææææææææ«§æ« æ«æ«æ«æ«æ«æ«æ«æ««æ«æ«æ«æ­ æ®°æ°çç§ç çç«ç¡ç¢ç£ç©çç¤ççªçççççç¥ç¦ç¤ç£ç¡ççç·ççç çççç±ç¤ç¤"],
["f2a1","ç¤¡ç¤ç¤ç¤ç¦°ç©§ç©¨ç°³ç°¼ç°¹ç°¬ç°»ç³¬ç³ªç¹¶ç¹µç¹¸ç¹°ç¹·ç¹¯ç¹ºç¹²ç¹´ç¹¨ç½ç½ç¾ç¾ç¾·ç¿½ç¿¾è¸èèè¤è¡è£è«è±è­èè¡è¨èèè¬è²è¸èèè£èèè°è¦è¯èè¢è èºè è¶è·è è è è è¼è è¿è è è¥¢è¥è¥è¥è¥¡è¥è¥è¥è¥è¦è¦·è¦¶è§¶è­è­è­è­è­è­è­è­è­"],
["f340","è­è­è­è­è±è±·è±¶è²è´è´è´è¶¬è¶ªè¶­è¶«è¹­è¹¸è¹³è¹ªè¹¯è¹»è»è½è½è½è½è½è¾´éé¿é°é­ééééééé¹é¬ééé©é¦ééé®é£éééééé§é½ééé¡é©é«é¬é¨é¦"],
["f3a1","é³é·é¶éééé¡é¡é¡é¡é¢¿é¢½é¢»é¢¾é¥é¥é¥é¦¦é¦§é¨é¨é¨¥é¨é¨¤é¨é¨¢é¨ é¨§é¨£é¨é¨é¨é«é¬é¬é¬é¬é¬·é¯ªé¯«é¯ é¯é¯¤é¯¦é¯¢é¯°é¯é¯é¯¬é¯é¯é¯¥é¯é¯¡é¯éµ·é¶é¶é¶é¶éµ±é¶éµ¸é¶é¶é¶éµ½éµ«éµ´éµµéµ°éµ©é¶éµ³éµ»é¶éµ¯éµ¹éµ¿é¶éµ¨éºéºé»é»¼é¼­é½é½é½é½é½é½å·å²"],
["f440","åµå³å££å­å·å·å»®å»¯å¿å¿æ¹ææææææ¨æ£æ¤æ«³æ«°æ«ªæ«¨æ«¹æ«±æ«®æ«¯ç¼çµç¯ç·ç´ç±çç¸ç¿çºç¹çç»ç³çççç¨ç½ç¼çºç«çªç¾ç­ççççç²ç¤¥ç¤£ç¤§ç¤¨ç¤¤ç¤©"],
["f4a1","ç¦²ç©®ç©¬ç©­ç«·ç±ç±ç±ç±ç±ç³®ç¹»ç¹¾çºçºç¾ºç¿¿è¹èèèè¨è©è¢è¿èè¾èèè¶èèèèè½è è è è è è è¥£è¥¦è¦¹è§·è­ è­ªè­è­¨è­£è­¥è­§è­­è¶®èºèºèºè½è½è½è½è½è½éééé·éµé²é³ééé»é ééé¾ééé¨éééµéé·éééééºéé¸éé¿"],
["f540","é¼éé¶éééé éé®é¯é¹é»é½é¾é¡ é¡¢é¡£é¡é£é£é¥é¥é¥é¥é¥é¥é¨²é¨´é¨±é¨¬é¨ªé¨¶é¨©é¨®é¨¸é¨­é«é«é«é¬é¬é¬é°é°é¯·é°é°é¯¸é±é°é°é°é°é°é°é¶é¶é¶¤é¶é¶é¶é¶é¶"],
["f5a1","é¶ é¶é¶é¶ªé¶é¶¡é¶é¶¢é¶¨é¶é¶£é¶¿é¶©é¶é¶¦é¶§éºéºéºé»¥é»¤é»§é»¦é¼°é¼®é½é½ é½é½é½é¾åºå¹åååå½å¾å­å­å·å·å»±æ½ææ¬æ«¼æ¬æ«¸æ¬ççççççççççç¾ççªçç¤­ç¤±ç¤¯ç±ç±ç³²çºçºçºçºçºçºç½ç¾»è°èèèªè¦èè£èèè§è®è¡è è©èè¥"],
["f640","è ©è è è  è ¤è è «è¡è¥­è¥©è¥®è¥«è§ºè­¹è­¸è­è­ºè­»è´è´è¶¯èºèºè½è½è½éééé¹é¿é»é¶é©é½é¼é°é¹éªé·é¬éé±é¥é¤é£éµéºé¿é¡é¡¤é£é£é£é¥é¥é¨¹é¨½é©é©é©é©é¨º"],
["f6a1","é¨¿é«é¬é¬é¬é¬é¬ºé­é°«é°é°é°¬é°£é°¨é°©é°¤é°¡é¶·é¶¶é¶¼é·é·é·é·é¶¾é·é·é¶»é¶µé·é¶¹é¶ºé¶¬é·é¶±é¶­é·é¶³é·é¶²é¹ºéºé»«é»®é»­é¼é¼é¼é¼±é½é½¥é½¤é¾äº¹åååå¥±å­å­å·å·å»²æ¡æ æ¦æ¢æ¬æ¬æ¬æ°ççççççç©ç¿ççççç­ç­ç¤µç¦´ç©°ç©±ç±ç±ç±ç±ç±"],
["f740","ç³´ç³±çºç½ç¾èè«è´èµè³è¬è²è¶è ¬è ¨è ¦è ªè ¥è¥±è¦¿è¦¾è§»è­¾è®è®è®è®è­¿è´èºèºèºèºèºèºèºè½ è½¢éééééééééééé¿é£é¡ªé¡©é£é¥é¥é©é©é©é©é©é©é©"],
["f7a1","é©é©é©é«é¬é¬«é¬»é­é­é±é±é°¿é±é°¹é°³é±é°¼é°·é°´é°²é°½é°¶é·é·é·é·é·é·é·é·é·é·©é·é·é·é·µé·é·éº¶é»°é¼µé¼³é¼²é½é½«é¾é¾¢å½åå£¨å£§å¥²å­å·è ¯å½ææææ©æ¥ææ«æ¬æ¬æ¬æ¯ççç¢çççç°çç±§ç±¦çºè¬èºèè¹è¼è±è»è¾è °è ²è ®è ³è¥¶è¥´è¥³è§¾"],
["f840","è®è®è®è®è±è´èºè½¤è½£é¼é¢ééééééé é©é©é¬é¬é¬ é±é±é±é±é±é±é±é±é±é±é·»é··é·¯é·£é·«é·¸é·¤é·¶é·¡é·®é·¦é·²é·°é·¢é·¬é·´é·³é·¨é·­é»é»é»²é»³é¼é¼é¼¸é¼·é¼¶é½é½"],
["f8a1","é½±é½°é½®é½¯ååå­å±­æ­æ­æ®æ¬çç¡çç ç£çç¥çç¤¸ç¦·ç¦¶ç±ªçºç¾è­èè ¸è ·è µè¡è®è®èºèºèº èºé¾é½éé«é¨é©é¥ééééé¥é©é«é­é±£é±§é±¦é±¢é±é± é¸é·¾é¸é¸é¸é¸é¸é¸é¸é·¿é·½é¸éº é¼é½é½´é½µé½¶åæ®æ¸æ¬æ¬æ¬æ¬ç¢ç¦çªççç¤¹ç±©ç±«ç³¶çº"],
["f940","çºçºçºè è¡èèèè¥¹è¥ºè¥¼è¥»è§¿è®è®èº¥èº¤èº£é®é­é¯é±é³éé¡²é¥é±¨é±®é±­é¸é¸é¸é¸é¸é¸éº¡é»µé¼é½é½¸é½»é½ºé½¹åç¦ç±¯è ¼è¶²èº¦éé´é¸é¶éµé© é±´é±³é±±é±µé¸é¸é»¶é¼"],
["f9a1","é¾¤ç¨ç¥ç³·èªè ¾è ½è ¿è®è²èº©è»éé¡³é¡´é£é¥¡é¦«é©¤é©¦é©§é¬¤é¸é¸é½ææ¬ç§èèº¨éééé©©é©¨é¬®é¸ç©èè®éé±¹éº·çµé©«é±ºé¸ç©çªéº¤é½¾é½é¾ç¢é¹è£å¢»æç²§å«ºââ¦ââ â¬â£ââ©âââ¤âââªâ¡ââ§âââ¥âââ«â¢ââ¨ââââ­â®â°â¯â"]
]
