// Generated by CoffeeScript 1.12.6
(function() {
  var AFMFont, PDFFont, StandardFont, fs,
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  AFMFont = require('./afm');

  PDFFont = require('../font');

  fs = require('fs');

  StandardFont = (function(superClass) {
    var STANDARD_FONTS;

    extend(StandardFont, superClass);

    function StandardFont(document, name1, id) {
      var ref;
      this.document = document;
      this.name = name1;
      this.id = id;
      this.font = new AFMFont(STANDARD_FONTS[this.name]());
      ref = this.font, this.ascender = ref.ascender, this.descender = ref.descender, this.bbox = ref.bbox, this.lineGap = ref.lineGap;
    }

    StandardFont.prototype.embed = function() {
      this.dictionary.data = {
        Type: 'Font',
        BaseFont: this.name,
        Subtype: 'Type1',
        Encoding: 'WinAnsiEncoding'
      };
      return this.dictionary.end();
    };

    StandardFont.prototype.encode = function(text) {
      var advances, encoded, glyph, glyphs, i, j, len, positions;
      encoded = this.font.encodeText(text);
      glyphs = this.font.glyphsForString('' + text);
      advances = this.font.advancesForGlyphs(glyphs);
      positions = [];
      for (i = j = 0, len = glyphs.length; j < len; i = ++j) {
        glyph = glyphs[i];
        positions.push({
          xAdvance: advances[i],
          yAdvance: 0,
          xOffset: 0,
          yOffset: 0,
          advanceWidth: this.font.widthOfGlyph(glyph)
        });
      }
      return [encoded, positions];
    };

    StandardFont.prototype.widthOfString = function(string, size) {
      var advance, advances, glyphs, j, len, scale, width;
      glyphs = this.font.glyphsForString('' + string);
      advances = this.font.advancesForGlyphs(glyphs);
      width = 0;
      for (j = 0, len = advances.length; j < len; j++) {
        advance = advances[j];
        width += advance;
      }
      scale = size / 1000;
      return width * scale;
    };

    StandardFont.isStandardFont = function(name) {
      return name in STANDARD_FONTS;
    };

    STANDARD_FONTS = {
      "Courier": function() {
        return fs.readFileSync(__dirname + "/../font/data/Courier.afm", 'utf8');
      },
      "Courier-Bold": function() {
        return fs.readFileSync(__dirname + "/../font/data/Courier-Bold.afm", 'utf8');
      },
      "Courier-Oblique": function() {
        return fs.readFileSync(__dirname + "/../font/data/Courier-Oblique.afm", 'utf8');
      },
      "Courier-BoldOblique": function() {
        return fs.readFileSync(__dirname + "/../font/data/Courier-BoldOblique.afm", 'utf8');
      },
      "Helvetica": function() {
        return fs.readFileSync(__dirname + "/../font/data/Helvetica.afm", 'utf8');
      },
      "Helvetica-Bold": function() {
        return fs.readFileSync(__dirname + "/../font/data/Helvetica-Bold.afm", 'utf8');
      },
      "Helvetica-Oblique": function() {
        return fs.readFileSync(__dirname + "/../font/data/Helvetica-Oblique.afm", 'utf8');
      },
      "Helvetica-BoldOblique": function() {
        return fs.readFileSync(__dirname + "/../font/data/Helvetica-BoldOblique.afm", 'utf8');
      },
      "Times-Roman": function() {
        return fs.readFileSync(__dirname + "/../font/data/Times-Roman.afm", 'utf8');
      },
      "Times-Bold": function() {
        return fs.readFileSync(__dirname + "/../font/data/Times-Bold.afm", 'utf8');
      },
      "Times-Italic": function() {
        return fs.readFileSync(__dirname + "/../font/data/Times-Italic.afm", 'utf8');
      },
      "Times-BoldItalic": function() {
        return fs.readFileSync(__dirname + "/../font/data/Times-BoldItalic.afm", 'utf8');
      },
      "Symbol": function() {
        return fs.readFileSync(__dirname + "/../font/data/Symbol.afm", 'utf8');
      },
      "ZapfDingbats": function() {
        return fs.readFileSync(__dirname + "/../font/data/ZapfDingbats.afm", 'utf8');
      }
    };

    return StandardFont;

  })(PDFFont);

  module.exports = StandardFont;

}).call(this);
