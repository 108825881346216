module.exports = function (state, emitter) {

  state.templates = [
    {
      name: 'Front',
      slug: 'front',
      fields: [
        {slug: 'name', label: 'Product Name', type: 'text', placeholder: 'blueberry & raw honey jam', value: ''},
        {slug: 'size', label: 'Font Size', type: 'select', options: ['Big', 'Medium', 'Small'], value: 'Big'}
      ]
    },
    {
      name: 'Back',
      slug: 'back',
      fields: [
        {slug: 'ingredients', label: 'Ingredients', type: 'textarea', placeholder: 'American grown jala-\npeÃÂ±os, wild blueberries, raw \nhoney, water, more ingredients.', value: ''},
        {slug: 'bestby', label: 'Best-by Date', type: 'text', placeholder: '3/31/18', value: ''},
        {slug: `batch`, label: 'Batch Number', type: 'text', placeholder: '12', value: ''},
        {slug: `weight`, label: 'Net Weight', type: 'text', placeholder: '3.53 OZ (100g)', value: ''}
      ]
    }
  ]

  state.editing = state.templates[0]

  emitter.on('template:change', (slug) => {

    // save changes to structure
    for (var i = 0; i < state.templates.length; i++) {
      if(state.templates[i].slug == state.editing.slug) {
        state.templates[i] = state.editing
      }
    }

    // replace currently editing
    state.editing = state.templates.find(function (template) {
      return template.slug == slug
    })
    state.params.label = slug

    emitter.emit('render')
  })

  emitter.on('template:input', (template, field, value) => {
    // console.log(template.slug, ':', field, '->', value)

    // store into editing
    for (var i = 0; i < state.editing.fields.length; i++) {
      if (state.editing.fields[i].slug == field) {
        state.editing.fields[i].value = value
      }
    }

    emitter.emit('render')
  })

}