const paper = require('paper')
const fonts = require('../fonts/fonts.json')

module.exports = function (template, box) {
  console.log('Rendering front template', template.fields)
  let rendered = new paper.Group()

  const fields = template.fields
  let guide = box.clone()
  let unit = guide.size.width

  let padding = guide.size.width * 0.05
  let padding_left = new paper.Point(padding, 0)
  let padding_bottom = new paper.Point(0, padding)
  let padding_right = new paper.Point(padding, 0)

  // Draw an arc at the bottom
  let arc = new paper.Path.Arc({
    from: guide.bounds.leftCenter.add(padding_left),
    through: guide.bounds.bottomCenter.subtract(padding_bottom),
    to: guide.bounds.rightCenter.subtract(padding_right),
    strokeColor: new paper.Color(1, 1, 1, 0)
  })

  let archedText = null
  let fontSize = null
  switch (fields[1].value) {
    case 'Big':
      fontSize = guide.size.width * 0.055
      break

    case 'Medium':
      fontSize = guide.size.width * 0.050
      break

    case 'Small':
      fontSize = guide.size.width * 0.045
      break
  }

  if(fields[0].value) {
    archedText = createAlignedText(fields[0].value, arc, {fontSize: fontSize})
  }
  else {
    archedText = createAlignedText(fields[0].placeholder, arc, {fontSize: fontSize})
  }
  rendered.addChild(archedText)

  // EST
  let established = new paper.PointText(box.bounds.center)
  established.fontFamily = fonts.GTAmericaCompressedMedium.name
  established.fillColor = 'black'
  established.justification = 'center'
  established.fontSize = unit * 0.054
  established.leading = established.fontSize * 1.1
  established.position.y += guide.size.height * 0.2225
  established.content = 'LOWELL, MA\nEST. 2018'

  console.log('Template rendered Ã¢ÂÂ')
  return rendered
}

function createPointText (str, style) {
  let text = new paper.PointText()
  text.fillColor = 'black'
  text.fontFamily = 'GT America Extended Regular'
  text.fontSize = style.fontSize
  text.content = str.toUpperCase()

  return text
}

function createAlignedText (str, path, style) {
  if (str && str.length > 0 && path) {

    // Create PointText for each glyph
    let glyphTexts = []
    for (var i = 0; i < str.length; i++) {
      glyphTexts[i] = createPointText(str.substring(i, i+1), style)
      glyphTexts[i].justification = "center"
    }

    // Calculate letter-spacing
    let letterSpacing = path.bounds.width * 0.015
    let letterSpacingLength = letterSpacing * (glyphTexts.length - 4)

    // Get total length of text
    let tmp = createPointText(str, style)
    tmp.remove()
    let textLength = tmp.bounds.width

    // Center the text
    let initialOffset = path.length / 2 - textLength / 2 - letterSpacingLength / 2

    let xOffsets = [initialOffset]
    for (var i = 1; i < str.length; i++) {
        var pairText = createPointText(str.substring(i - 1, i + 1), style)
        pairText.remove()
        xOffsets[i] = xOffsets[i - 1] + pairText.bounds.width - glyphTexts[i - 1].bounds.width / 2 - glyphTexts[i].bounds.width / 2
        xOffsets[i] += letterSpacing
    }

    // Project glyphs onto path
    for (var i = 0; i < str.length; i++) {
        var centerOffs = xOffsets[i];
        
        if (centerOffs === undefined) {
            glyphTexts[i].remove()
        } else {
            var pathPoint = path.getPointAt(centerOffs)
            if (!pathPoint) {
              break
            }
            glyphTexts[i].point = pathPoint
            var tan = path.getTangentAt(centerOffs)
            glyphTexts[i].rotate(tan.angle, pathPoint)
        }
    }

    // Add glyphs to group and return
    let glyphGroup = new paper.Group()
    for (var i = 0; i < glyphTexts.length; i++) {
      glyphGroup.addChild(glyphTexts[i])
    }
    return glyphGroup.clone()
  }
}