// Generated by CoffeeScript 1.12.6

/*
PDFReference - represents a reference to another object in the PDF object heirarchy
By Devon Govett
 */

(function() {
  var PDFObject, PDFReference, stream, zlib,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  zlib = require('zlib');

  stream = require('stream');

  PDFReference = (function(superClass) {
    extend(PDFReference, superClass);

    function PDFReference(document, id, data) {
      this.document = document;
      this.id = id;
      this.data = data != null ? data : {};
      this.finalize = bind(this.finalize, this);
      PDFReference.__super__.constructor.call(this, {
        decodeStrings: false
      });
      this.gen = 0;
      this.deflate = null;
      this.compress = this.document.compress && !this.data.Filter;
      this.uncompressedLength = 0;
      this.chunks = [];
    }

    PDFReference.prototype.initDeflate = function() {
      this.data.Filter = 'FlateDecode';
      this.deflate = zlib.createDeflate();
      this.deflate.on('data', (function(_this) {
        return function(chunk) {
          _this.chunks.push(chunk);
          return _this.data.Length += chunk.length;
        };
      })(this));
      return this.deflate.on('end', this.finalize);
    };

    PDFReference.prototype._write = function(chunk, encoding, callback) {
      var base;
      if (!Buffer.isBuffer(chunk)) {
        chunk = new Buffer(chunk + '\n', 'binary');
      }
      this.uncompressedLength += chunk.length;
      if ((base = this.data).Length == null) {
        base.Length = 0;
      }
      if (this.compress) {
        if (!this.deflate) {
          this.initDeflate();
        }
        this.deflate.write(chunk);
      } else {
        this.chunks.push(chunk);
        this.data.Length += chunk.length;
      }
      return callback();
    };

    PDFReference.prototype.end = function(chunk) {
      PDFReference.__super__.end.apply(this, arguments);
      if (this.deflate) {
        return this.deflate.end();
      } else {
        return this.finalize();
      }
    };

    PDFReference.prototype.finalize = function() {
      var chunk, i, len, ref;
      this.offset = this.document._offset;
      this.document._write(this.id + " " + this.gen + " obj");
      this.document._write(PDFObject.convert(this.data));
      if (this.chunks.length) {
        this.document._write('stream');
        ref = this.chunks;
        for (i = 0, len = ref.length; i < len; i++) {
          chunk = ref[i];
          this.document._write(chunk);
        }
        this.chunks.length = 0;
        this.document._write('\nendstream');
      }
      this.document._write('endobj');
      return this.document._refEnd(this);
    };

    PDFReference.prototype.toString = function() {
      return this.id + " " + this.gen + " R";
    };

    return PDFReference;

  })(stream.Writable);

  module.exports = PDFReference;

  PDFObject = require('./object');

}).call(this);
